import ProgramImages from "src/assets/Home/Progams";
import TourInlandImages from "src/assets/Home/TourInland";
import TourInternationalImages from "src/assets/Home/TourInternational";
import NewImages from "src/assets/News";

export const Study_Nhat = `
 <div class='d-flex flex-column align-items-center'>
<img src=${ProgramImages.nhatban1} class='w-100' />
</div>
<div class='fw-bolder fs-1  text-success py-3'>
VÌ SAO NÊN DU HỌC NHẬT BẢN?
</div>

<div class=' fs-5 ps-5'  >
• Chất lượng giáo dục vô cùng tốt, giá trị bằng cấp quốc tế công nhận
</div>
<div class=' fs-5 ps-5' >
• Nền kinh tế TOP đầu thế giới.
</div>
<div class=' fs-5 ps-5' >
• Chế độ phúc lợi tốt, cơ hội vĩnh trú và định cư
</div>
<div class=' fs-5 ps-5' >
• Cơ hội việc làm đa dạng, thu nhật cao tại Nhật Bản cũng như về Việt Nam
</div>
<div class=' fs-5 ps-5' >
• Chi phí thấp, đa dạng chương trình, tỉ lệ đỗ Visa cao, phù hợp mọi hoàn cảnh
</div>
<div class='fw-bolder pt-5 fs-1  text-success pb-3 fs-5'>
CÁC CHƯƠNG TRÌNH DU HỌC NHẬT BẢN
</div>
<div class='fw-bolder text-success fs-4'>
DU HỌC TỰ TÚC
</div>
<div class=' fs-5 ps-5'>
Du học sinh cần đóng tất cả các khoản phí học tập, sinh hoạt, ký túc xá ở Việt Nam cũng như Nhật Bản, thu nhập 25-35 triệu/tháng.
</div>
<div class='py-2 ps-3 fw-bolder fs-5 '>
1. Tổng quan:
</div>
<div class='ps-5 fs-5 '>
Dành cho học sinh Việt Nam muốn tới Nhật Bản học tập và làm việc, sinh sống; Du học sinh sẽ tự chi trả các khoản phí sinh hoạt, học phí hoặc các khoản chi phí cá nhân khác; Tự do lựa chọn ngành nghề, việc làm thêm; Đi làm thêm thu nhập 25 - 35 triệu/tháng trở lên; Sau khi học chuyên ngành, có thể ở lại Nhật sinh sống và làm việc vô thời hạn.
</div>
<div class='py-2 ps-3 fw-bolder fs-5 '>
2. Điều kiện tuyển sinh:
</div>
<div class='ps-5 fs-5'>
Tốt nghiệp cấp 3 (THPT, GDTX), Cao đẳng, Đại học tại Việt Nam; Tuổi từ 18 đến 35 tuổi; Trình độ N4 hoặc N5, chưa có sẽ được đào tạo đến khi xuất cảnh; Không có tiền án, tiền sự và không có người thân (bố, mẹ, anh chị em ruột) đã hoặc đang bất hợp pháp tại Nhật; Nếu từng đi Nhật về thì phải không bất hợp pháp và còn giữ hồ sơ gốc;
</div>
<div class='pt-3 fw-bolder text-success fs-4 '>
DU HỌC BỔNG BÁO
</div>
<div class=' fs-5 ps-5'>
Được toà báo đài thọ học phí tại trường Nhật ngữ, hỗ trợ phí sinh hoạt và đi làm phát báo, thu nhập 20-25 triệu/tháng.
</div>
<div class='py-3 ps-3 fw-bolder fs-5 '>
1. Điều kiện tuyển sinh:
</div>
<div class='ps-5 fs-5'>
Dành cho các bạn muốn đến Nhật Bản với chi phí thấp, gia đình không có điều kiện; Điều kiện: Nam, nữ 18 - 25 tuổi; tốt nghiệp THPT, GDTX, Trung cấp/Cao đẳng/Đại học tại Việt Nam với điểm trung bình môn 7.0 trở lên; có bằng lái xe máy tại Việt Nam. Tiếng Nhật N5 hoặc N4 trước khi xuất cảnh; không có tiền án, tiền sự; không mắc các bệnh truyền nhiễm (HIV/AID, Viêm gan B, C,...); không có bố, mẹ, anh, chị, em ruột (trên sổ hộ khẩu) đã hoặc đang bất hợp pháp tại Nhật.
</div>
<div class='py-3 ps-3 fw-bolder fs-5 '>
2. Quyền lợi:
</div>
<div class='ps-5 fs-5 '>
Được tòa soạn báo hỗ trợ học phí lên đến 100% trong thời gian học tại trường Nhật ngữ, không cần hoàn lại. Công việc làm thêm là đi phát báo cho tòa soạn đó. Hỗ trợ tiền thuê nhà và sinh hoạt phí hằng tháng; Có lương làm thêm hằng tháng từ công việc đi phát báo, trung bình khoảng 20-25 triệu/tháng; Hỗ trợ tiền vé máy bay chiều đi sang Nhật lần đầu; Được tham gia bảo hiểm tai nạn lao động, bảo hiểm y tế; Hỗ trợ phương tiện di chuyển khi làm việc.
</div>
<div class='fw-bolder fw-bolder text-success fs-4 pt-3'>
DU HỌC ĐIỀU DƯỠNG
</div>
<div class=' fs-5 ps-5' >
Được hỗ trợ cho vay trả sau với lãi suất học phí trường Nhật ngữ và học chuyên ngành; làm Điều dưỡng, thu nhập 20-25 triệu/tháng.
</div>
<div class='py-3 ps-3 fw-bolder fs-5 '>
1. Tổng quan:
</div>
<div class='ps-5 fs-5 '>
Được tài trợ bởi các Viện dưỡng lão của Nhật, đổi lại là làm việc tại đó sau tốt nghiệp; Tự chi trả học phí 01 năm đầu tại trường Nhật ngữ hoặc sẽ được tài trợ (tuỳ chương trình) để đạt trình độ N3 và cho 02 năm học ngành Điều dưỡng tại Senmon.
</div>
<div class='py-3  ps-3 fw-bolder fs-5 '>
2. Điều kiện tuyển sinh:
</div>
<div class='ps-5 fs-5 '>
Tốt nghiệp THPT, GDTX, Cao đẳng, Đại học với điểm trung bình môn 7.0 trở lên; Tốt nghiệp không quá 5 năm; Tiếng Nhật N5 hoặc N4; Không có tiền án, tiền sự; Không mắc các bệnh truyền nhiễm (HIV/AID, Viêm gan B, C,...), sức khỏe tốt; Yêu thích chăm sóc người già;
</div>
<div class='py-3 ps-3 fw-bolder fs-5 '>
3. Cơ hội nghề nghiệp
</div>
<div class='ps-5 fs-5 '>
Chất lượng đào tạo y tế của Nhật thuộc TOP đầu thế giới; Làm thêm khi học tiếng và chuyên ngành với thu nhập 20-25 triệu/tháng; thu nhập sau tốt nghiệp 50-60 triệu/tháng trở lên; Ở lại làm việc vô thời hạn;
</div>
<div class='fw-bolder py-3 text-success fs-4'>
DU HỌC CHUYỂN ĐỔI ĐI LÀM TOKUTEI GINOU (KỸ NĂNG ĐẶC ĐỊNH)
</div>
<div class=' ps-3 fw-bolder pb-3 fs-5 '>
1. Điều kiện tham gia:
</div>
<div class=' ps-5 fs-5 '>
Tuổi từ 18 - 35 tuổi; Có bằng THPT, GDTX trở lên; Sức khỏe tốt, không bệnh truyền nhiễm; Không bị cấm xuất cảnh; Thực tập sinh về nước phải còn đủ giấy tờ gốc và không bất hợp pháp;
</div>
<div class=' ps-3 py-3 fw-bolder fs-5 '>
2. Lợi ích:
</div>
<div class=' ps-5 fs-5 '>
- Thu nhập 35-45 triệu 01 tháng trở lên
</div>
<div class=' ps-5 fs-5 '>
- Không phụ thuộc Công ty môi giới
</div>
<div class=' ps-5 fs-5 '>
- Được chuyển Công ty khi chưa hết hợp đồng
</div>
<div class=' ps-5 fs-5 '>
- Dễ dàng có được việc làm tại Nhật
</div>
<div class=' ps-5 fs-5 '>
- Cơ hội cấp Visa vĩnh trú tại Nhật Bản
</div>
<div class='py-3 ps-3 fw-bolder fs-5'>
3. Hình thức 1
</div>
<div class=' ps-5 fs-5 '>
Bước 1: Học tại DABOSA đến N4 kết hợp học chuyên môn tay nghề;
</div>
<div class=' ps-5 fs-5 '>
Bước 2: Thi chứng chỉ tiếng Nhật và sang Philippin hoặc Campuchia để thi chứng chỉ Kỹ năng Đặc định;
</div>
<div class=' ps-5 fs-5 '>
Bước 3: Về Việt Nam thi đơn hàng, xử lý giấy tờ và xuất cảnh sang Nhật.
</div>
<div class=' ps-5 fs-5  '>
Bước 4: Đi làm tại Nhật theo đơn hàng đã thi đỗ tại Việt Nam. Tổng thời gian tính đến xuất cảnh sang Nhật: 8 tháng - 1 năm.
</div>
<div class='py-3 ps-3 fw-bolder fs-5 '>
4. Hình thức 2
</div>
<div class=' ps-5 fs-5 '>
Bước 1: Học tiếng tại DABOSA đến N5 kết hợp phỏng vấn trường Nhật ngữ, xử lý giấy tờ;
</div>
<div class=' ps-5 fs-5 '>
Bước 2: Thi chứng chỉ tiếng Nhật TOP-J hoặc NAT-TEST tại Việt Nam;
</div>
<div class=' ps-5 fs-5 '>
Bước 3: Có COE (Tư cách Lưu trú), xin Visa và xuất cảnh sang Nhật kỳ tháng: 1,4,7,10;
</div>
<div class=' ps-5 fs-5 '>
Bước 4: Học trường Nhật ngữ 01 năm để đạt N4 hoặc N3, thi chứng chỉ tiếng Nhật JLPT, thi chứng chỉ Tokutei. Đi làm thêm 25-35 triệu/tháng.
</div>
<div class=' ps-5 fs-5 '>
Bước 5: Chuyển Visa đi làm. Tổng thời gian tính đến xuất cảnh: 4-6 tháng.
</div>
<div class=' ps-3 fs-5 py-3 fw-bolder'>
5. 14 NGÀNH TOKUTEI GINOU
</div>
<div class=' ps-5 fs-5 '>
Hộ lý, Vệ sinh tòa nhà, Vật liệu, Xây dựng, Chế tạo máy, Điện - Điện tử, Đóng tàu, Bảo dưỡng ô tô, Hàng không, Khách sạn, Nông nghiệp, Ngư nghiệp, Chế biến thực phẩm, Dịch vụ ăn uống.
</div>
<div class='py-3  fw-bolder text-success fs-4'>
DU HỌC CHUYỂN ĐỔI ĐI LÀM VISA SHYU (VISA KỸ SƯ)
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
1. Điều kiện tham gia:
</div>
<div class=' ps-5 pb-3 fs-5 '>
Tuổi từ 18 - 35 tuổi; Có bằng Cao đẳng, Đại học tại Việt Nam; Sức khỏe tốt, không bệnh truyền nhiễm; Không bị cấm xuất cảnh; Không có người thân bất hợp pháp tại Nhật;
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
2. Lợi ích:
</div>
<div class='pb-3 ps-5 fs-5 '>
Thu nhập cao từ 40-50 triệu trở lên/tháng, chưa tính trợ cấp, chế độ như người bản địa;
</div>
<div class='pb-3 ps-5 fs-5 '>
Bảo lãnh người thân
Bảo lãnh vợ/chồng/con sang sinh sống tại Nhật sau khi làm được 1-2 năm;
</div>
<div class='pb-3 ps-5 fs-5 '>
Được chuyển đổi công ty
Được chuyển đổi công ty cùng ngành làm việc sau 6 tháng - 1 năm;
</div>
<div class='pb-3 ps-5 fs-5 '>
Không phụ thuộc công ty môi giới
Không phụ thuộc hay mất phí quản lý cho công ty môi giới tại Nhật
</div>
<div class='pb-3 ps-5 fs-5 '>
Cơ hội vĩnh trú và định cư
Làm việc vô thời hạn, tiến tới vĩnh trú và định cư lâu dài, tự do đi lại Việt- Nhật;
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
3. Hình thức 1
</div>
<div class='pb-3 ps-5 fs-5 '>
Bước 1: Học tiếng tại DABOSA đến N4 hoặc N3 trở lên;
</div>
<div class='pb-3 ps-5 fs-5 '>
Bước 2: Thi chứng chỉ tiếng Nhật và thi đơn hàng;
</div>
<div class='pb-3 ps-5 fs-5 '>
Bước 3: Đỗ đơn hàng, xử lý giấy tờ xin COE và Visa;
</div>
<div class='pb-3 ps-5 fs-5 '>
Bước 4: Đi làm tại Nhật theo đơn hàng đã thi đỗ tại Việt Nam. Chỉ nhận các bằng Cao đẳng, Đại học liên quan đến ngành Kỹ thuật như: Điện, điện tử, xây dựng, CNC, công nghệ thông tin, ô tô, nông nghiệp, điều dưỡng, chế biến thực phẩm, chế tạo máy. Không có nhiều sự lựa chọn ngành nghề hay khu vực muốn đến của Nhật Bản; Tổng thời gian tính đến xuất cảnh sang Nhật: 6 tháng - 1 năm.
</div>
<div  class='pb-3 ps-3 fw-bolder fs-5 '>
4. Hình thức 2
</div>
<div  class='pb-3 ps-5 fs-5 '>
Bước 1: Học tiếng tại DABOSA đến N5 kết hợp phỏng vấn trường Nhật ngữ, xử lý giấy tờ;
</div>
<div  class='pb-3 ps-5 fs-5 '>
Bước 2: Thi chứng chỉ tiếng Nhật TOP-J hoặc NAT-TEST tại Việt Nam;
</div>
<div  class='pb-3 ps-5 fs-5 '>
Bước 3: Có COE, xin Visa và xuất cảnh sang Nhật kỳ tháng: 1,4,7,10 như du học sinh;
</div>
<div  class='pb-3 ps-5 fs-5 '>
Bước 4: Học tại trường Nhật ngữ 01 năm để đạt N4 hoặc N3, thi chứng chỉ tiếng Nhật JLPT kết hợp đi làm thêm thu nhập 25-35 triệu/tháng;
</div>
<div  class='pb-3 ps-5 fs-5 '>
Bước 5: Chuyển Visa Shyu đi làm; Không giới hạn chuyên ngành của bằng Cao đẳng, Đại học; Được tự do lựa chọn ngành nghề, doanh nghiệp, khu vực; Tổng thời gian tính đến xuất cảnh: 4-6 tháng.
</div>
<div class='py-3 fw-bolder text-success fs-1'>
LỘ TRÌNH DU HỌC NHẬT BẢN
</div>
<div class='pb-3 fw-bolder ps-3 fs-5 '>
Các kỳ bay
</div>
<div class='pb-3 ps-5 fs-5 '>
- Có 04 kỳ bay hằng năm vào tháng 01, 04, 07, 10. Có thể bay vào đúng tháng đó hoặc tháng trước đó.
</div>
<div class='pb-3 ps-3 fw-bolder fs-5'>
Kỳ tháng 01: Visa 1 năm 3 tháng
</div>
<div class='pb-3 ps-5 fs-5 '>
- Tuyển từ tháng 6 - tháng 8 năm trước; nhận kết quả COE (tư cách lưu trú) vào tháng 11 năm trước;
</div>
<div div class='pb-3 ps-3 fw-bolder fs-5 '>
Kỳ tháng 02: Visa 2 năm
</div>
<div div class='pb-3 ps-5 fs-5 '>
- Tuyển từ tháng 9 - tháng 11 năm trước; nhận kết quả COE (tư cách lưu trú) vào tháng 2 cùng năm;
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
Kỳ tháng 07: Visa 1 năm 9 tháng
</div>
<div class='pb-3 ps-5 fs-5 '>
- Tuyển từ tháng 12 năm trước - tháng 2 cùng năm; nhận kết quả COE (tư cách lưu trú) vào tháng 5 cùng năm;
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
Kỳ tháng 10: Visa 1 năm 6 tháng
</div>
<div class='pb-3 ps-5 fs-5 '>
- Tuyển từ tháng 3 - đầu tháng 5 cùng năm; nhận kết quả COE (tư cách lưu trú) vào tháng 8 cùng năm;
</div>
<div class='pb-3 fw-bolder text-success fs-1'>
SINH HOẠT PHÍ HÀNG THÁNG (THAM KHẢO)
</div>
<div class='pb-3 fw-bolder ps-3 fs-5 '>
Lưu ý: Chi phí biến đổi hơn/kém tuỳ theo tỉ giá Yên-VND, khu vực sinh sống và nhu cầu cá nhân cùng học phí từng ngành, trường.
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 ' >
Tiền thuê ký túc:
</div>
<div class='pb-3 ps-5 fs-5 '>
- Tokyo, Osaka, Kyoto khoảng 30.000-35.000 Yên (khoảng 5-7 triệu VND). Nếu ở vùng khác sẽ thấp hơn 20-30%. Ở ghép hoặc ngoại thành sẽ thấp hơn.
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
Tiền ăn:
</div>
<div class='pb-3 ps-5 fs-5 '>
- Nếu tự nấu ăn, trung bình khoảng 30.000 Yên (khoảng 5 triệu VND). Nếu ăn ngoài, trung bình khoảng 40.000 Yên (khoảng 7 triệu VND).
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
Tiền điện, nước, gas:
</div>
<div class='pb-3 ps-5 fs-5'>
- Điện và nước tính theo số điện và số khối nước. Trung bình 2.500-3.500 Yên (400-600 nghìn VND). Gas dùng cho nấu ăn và nước nóng. Trung bình 1.500-2.500 Yên (khoảng 250-450 nghìn VND).
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
Tiền điện thoại & Internet
</div>
<div class='pb-3 ps-5 fs-5 '>
- Tổng chi phí cho cả tiền điện thoại và tiền mạng hàng tháng thường sẽ khoảng 4.500 – 5.000 yên (khoảng 1 triệu VND).
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
Tiền đi lại:
</div>
<div class='pb-3 ps-5 fs-5'>
- Chi phí đi tàu điện ngầm dành cho du học sinh hàng tháng là khoảng 10.000 – 15.000 Yên (tức là khoảng 1.6– 2.5 triệu VND)
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
Tiền học phí:
</div>
<div class='pb-3 ps-5 fs-5'>
- Học phí 01 năm trường Nhật ngữ khoảng 650.000-750.000 Yên (110-130 triệu VND). Học phí Senmon 01 năm: 900.000 - 1.300.000 Yên (155-220 triệu VND)
</div>
<div class='py-3 fw-bolder text-success fs-1'>
QUY TRÌNH TẠI DABOSA GROUP
</div>
<div class='pb-3 ps-3 fw-bolder fs-5'>
1. Nhập học tại Dabosa
</div>
<div class='pb-3 ps-5 fs-5'>
Lắng nghe tư vấn chương trình phù hợp; Ký hợp đồng, nộp tiền đợt 1 và nhập học;
</div>
<div class='pb-3 ps-3 fw-bolder fs-5'>
2. Học tập tại Dabosa
</div>
<div class='pb-3 ps-5 fs-5'>
Học tiếng Nhật 04 kỹ năng đến N5 hoặc N4; Học kỹ năng mềm, văn hoá Nhật Bản;
</div>
<div class='pb-3 ps-3 fw-bolder fs-5'>
3. Phỏng vấn trường Nhật ngữ
</div>
<div class='pb-3 ps-5 fs-5 '>
Luyện tập phỏng vấn cùng giáo viên; Kết nối, phỏng vấn trường Nhật ngữ; Xử lý hồ sơ để xin COE;
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
4. Nhận tư cách lưu trú (COE)
</div>
<div class='pb-3 ps-5 fs-5 '>
Nhận COE, nộp học phí 01 năm đầu và các khoản phí còn lại theo; nộp hồ sơ xin Visa;
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
5. Nhập học trường Nhật ngữ
</div>
<div class='pb-3 ps-5 fs-5 '>
Học trường Nhật ngữ 1-2 năm đạt N3 hoặc N2; Đi làm thêm với thu nhập 25 - 35 triệu/tháng;
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
6. Học Senmon, Đại học hoặc chuyển Visa đi làm
</div>
<div class='pb-3 ps-5 fs-5 '>
Nếu học Senmon, cần có N3, học 02 năm; Nếu học Đại học, cần có N2, thi EJU đầu vào, học 3-4 năm; Hoặc chuyển Visa đi làm Tokutei hoặc Shyu;
</div>
<div class='py-3 fw-bolder text-success fs-1'>
VÌ SAO NÊN CHỌN DABOSA?
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
1. Tại Việt Nam
</div>
<div class='pb-3 ps-5 fs-5 '>
Trang bị ngoại ngữ, kỹ năng và kiến thức văn hóa, pháp luật Nhật Bản; Giao lưu cùng Senpai (tiền bối) có thêm kinh nghiệm khi du học; Hoạt động ngoại khóa, teambuilding; Cơ sở vật chất tiện nghi, hiện đại; Có khu giải trí nghỉ ngơi, đọc sách; Hướng dẫn tận tình, đưa đón sân bay; Được lựa chọn tỉnh muốn đến của Nhật; Chương trình đa dạng, chi phí ưu đãi.
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
2. Tại Nhật Bản
</div>
<div class='pb-3 ps-5 fs-5 '>
Đồng hành sát sao; Giới thiệu việc làm, nơi ở, hướng dẫn các giấy tờ cá nhân; Được làm thêm 28 giờ/tuần trong thời gian đi học; 40 giờ/tuần vào kỳ nghỉ, thu nhập 25 - 35 triệu/tháng; Cầu nối giữa học viên và gia đình. Hỗ trợ gia đình xử lý Visa thăm thân;
</div>
<div class='pb-6 fw-bolder text-success fs-1'>
TỔNG CHI PHÍ CÁC CHƯƠNG TRÌNH
</div>
<div class='pb-3 fw-bolder fs-5'>
Lưu ý: Chi phí thực tế có thể hơn hoặc kém do phụ thuộc vào biến động của tỉ giá ngoại tệ, giá vé máy bay, nhu cầu thực tế của khách hàng về chương trình.
</div>
<div class='pb-3 fw-bolder ps-3 fs-5 '>
1. Du học tự túc
</div>
<div class='pb-3 ps-5 fs-5 '>
Khoảng 160 triệu - 200 triệu VND: Tiền học tiếng, kỹ năng mềm, thi chứng chỉ tiếng, tài liệu học tập, đồng phục (3 áo), ký túc xá DABOSA, xử lý hồ sơ, đưa đón sân bay, vé máy bay, 6 tháng - 01 năm học phí đầu tiên tại trường Nhật ngữ.
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
2. Học bổng báo, điều dưỡng
</div>
<div class='pb-3 ps-5 fs-5 '>
Khoảng 70 triệu - 80 triệu VND: Tiền học tiếng, kỹ năng mềm, thi chứng chỉ tiếng, tài liệu học tập, đồng phục (3 áo), ký túc xá DABOSA, xử lý hồ sơ, đưa đón sân bay, vé máy bay.
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
3. Tokutei Ginou (kỹ sư)
</div>
<div class='pb-3 ps-5 fs-5 '>
Khoảng 120 triệu - 180 triệu VND: Tiền học tiếng, kỹ năng mềm, thi chứng chỉ tiếng, tài liệu học tập, đồng phục (3 áo), ký túc xá DABOSA, xử lý hồ sơ, đưa đón sân bay, vé máy bay, 06 tháng - 01 năm học phí đầu tiên tại trường Nhật ngữ. Khoảng 40 triệu - 120 triệu VND nếu chỉ cần xử lý giấy tờ và kết nối đơn hàng phù hợp và vé máy bay 01 chiều đi. Chi phí biến động theo chi phí đơn hàng và giá vé máy bay.
</div>
<div class='py-3 ps-3 fw-bolder fs-5 '>
4. Tiến độ đóng tiền du học
</div>
<div class='pb-3 ps-5 fs-5 '>
• Đợt 01: Đóng 28 triệu VND, chưa tính tiền Ký túc xá tại DABOSA. Nếu ở KTX, đóng 1,3 triệu/tháng, đã bao gồm điện, nước.
</div>
<div class='pb-1 ps-5 fs-5 '>
• Đợt 02: Đóng số tiền còn lại.
</div>
<div class='pb-3 ps-5 fs-5 '>
Lưu ý: Tiền học phí 01 năm đầu học viên có thể đóng tại ngân hàng.
</div>
<div class='py-3 fw-bolder fs-1 text-success'>
MỘT SỐ CÔNG VIỆC LÀM THÊM TẠI NHẬT BẢN:
</div>
<div class=' pb-3 ps-5 fs-5 '>
Làm thêm 28 giờ/tuần trong khi đi học và 40 giờ/tuần trong kỳ nghỉ với thu nhập 25-35 triệu/tháng trở lên; DABOSA đồng hành và giới thiệu việc làm thêm tại Nhật;
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
Nhân viên Nhà hàng
</div>
<div class='pb-3  ps-5 fs-5 '>
- Công việc phụ bếp, bưng bê hoặc rửa bát tại các Nhà hàng của Nhật
</div>
<div class='pb-3 ps-3 fw-bolder fs-5'>
Nhân viên Cửa hàng Tiện lợi
</div>
<div class='pb-3 ps-5 fs-5 '>
- Làm thu ngân, lau dọn, sắp xếp cửa hàng
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
Nhân viên Siêu thị
</div>
<div class='pb-3 ps-5 fs-5 '>
- Làm thu ngân, phục vụ hoặc chế biến thực phẩm
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
Nhân viên Phát báo
</div>
<div class='pb-3 ps-5 fs-5 '>
- Đi phát báo bằng xe máy vào buổi sáng, 2 giờ - 7 giờ sáng
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
Nhân viên Tạp vụ
</div>
<div class='pb-3 ps-5 fs-5 '>
- Lau dọn vệ sinh tòa nhà, khách sạn hoặc cơ sở làm việc.
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
Biên phiên dịch
</div>
<div class='pb-3 ps-5 fs-5 '>
- Dịch thuật, dạy tiếng Việt cho người Nhật hoặc làm việc tại Trung tâm Du lịch.
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
Một số kỳ nghỉ lễ của Nhật Bản
</div>
<div class='pb-3 ps-5 fs-5 '>
- DHS có thể làm đến 40 giờ/tuần thay vì 28 giờ/tuần như khi đi học, nâng cao thu nhập; DHS có thể đi thăm quan, trải nghiệm, khám phá cảnh đẹp, lễ hội và văn hóa Nhật Bản; DHS có thể về thăm nhà tại Việt Nam trong kỳ nghỉ lễ này.
</div>
<div class='pb-3 fw-bolder ps-3 fs-5 '>
Kỳ nghỉ xuân
</div>
<div class='ps-5 fs-5 '>
- Kỳ nghỉ xuân chính thức được bắt đầu vào cuối tháng 2 và kéo dài đến đầu tháng 3. Tuy nhiên, thời gian tối đa sinh viên được nghỉ chỉ có 2 – 3 tuần thường là 25/2 – 10/3.
</div>
<div class='py-3 fw-bolder ps-3 fs-5 '>
Kỳ nghỉ hè
</div>
<div class='ps-5 fs-5 '>
Du học sinh sẽ được nhà trường cho nghỉ hè khoảng 7 tuần. Thời gian có thể bắt đầu từ giữa tháng 7 và kéo dài đến cuối tháng 8.
</div>
<div class='py-3 ps-3 fw-bolder fs-5 '>
Kỳ nghỉ đông
</div>
<div class='ps-5 fs-5 '>
- Tết Dương lịch là Tết chính Nghỉ Quốc Khánh Nhật Bản 11/2 Ngày Xuân phân 20 hoặc 21/3 Lễ Tạ ơn Người lao động 23/11 Ngày sinh Nhật Hoàng 23/12 Tuần lễ Obon 13 -15/11 AL
</div>
<div class='py-3 ps-3 fw-bolder fs-5 '>
Kỳ nghỉ khác
</div>
<div class='ps-5 fs-5 '>
- Kỳ nghỉ đông từ cuối tháng 12 đến khoảng giữa tháng 1. Đa số các trường Nhật Bản thường cho sinh viên nghỉ đông từ 28/12 đến 14/1.
</div>
`;
export const Study_Han = `
<div class='d-flex flex-column align-items-center'>
	<img src=${ProgramImages.hanquoc1} class='w-100' />
   </div>
<div class='fs-1 text-success fw-bolder py-3'>
TOP 5 LÝ DO CHỌN HÀN QUỐC</div>
<div class='ps-5 fs-5'>
- Là một trong những nền giáo dục TOP đầu thế giới</div>
 
<div class='ps-5 fs-5'>
- Đất nước phát triển, môi trường chất lượng cao</div>
 
<div class='ps-5 fs-5'>
- Học phí và chi phí sinh hoạt hợp lý, nhiều học bổng</div>
 
<div class='ps-5 fs-5'>
- Điều kiện làm việc tốt, nhiều công việc cho sinh viên</div>
 
<div class='ps-5 fs-5'>
- Đa dạng văn hoá, khí hậu ôn hoà với 4 mùa rõ rệt</div>
 
<div class='fs-1 text-success fw-bolder py-3 ' >
CÁC CHƯƠNG TRÌNH HỌC TẠI HÀN QUỐC </div>
 
<div class='ps-5 fs-5'>
- VISA D4-1: Visa học tiếng trong tối đa 02 năm để đạt TOPIK 3 trở lên. </div>
 
<div class='ps-5 fs-5'>
- VISA D2-1: Visa học Cao đẳng trong 2-3 năm, cần có TOPIK 3 trở lên. </div>
 
<div class='ps-5 fs-5'>
- VISA D2-2: Visa học Đại học trong 4-5 năm, cần có TOPIK 3 trở lên. </div>
 
<div class='ps-5 fs-5'>
- VISA D2-3: Visa học Thạc sĩ trong 2-3 năm, cần có TOPIK 4 trở lên. </div>
 
<div class='ps-5 fs-5'>
- VISA E7: Visa kỹ sư. </div>
 
<div class='ps-5 fs-5'>
- VISA E9: Visa lao động phổ thông.</div>
 
<div class='fs-1 py-3 text-success fw-bolder' >
CÁC TOP TRƯỜNG TẠI HÀN QUỐC </div>
 
<div class='ps-3 fw-bolder fs-5' >
TRƯỜNG TOP 1</div>
 
<div class=' ps-5 fs-5'>
- Có tỉ lệ du học sinh bất hợp pháp dưới 1%/năm </div>
 
<div class='ps-5 fs-5'>
- Trường cấp Code xin Visa bay thẳng, không phỏng vấn Đại sứ quán </div>
 
<div class='ps-5 fs-5'>
- Trường ra thư mời, phỏng vấn Đại sứ quán để xin Visa </div>
 
<div class='ps-5 pb-3 fs-5'>
- Nặng học thuật, điều kiện đầu vào cao</div>
 
<div class='fw-bolder ps-3 fs-5' >
TRƯỜNG TOP 2</div>
 <div class='ps-5 fs-5'>
- Có tỉ lệ du học sinh bất hợp pháp trên 1% - dưới 10%/năm </div>
 
<div class='ps-5 fs-5'>
- Trường ra thư mời, phỏng vấn Đại sứ quán để cấp Code xin Visa </div>
 
<div class='ps-5 pb-3 fs-5'>
- Tỉ lệ đỗ Visa 50/50, điều kiện đầu vào dễ hơn TOP 1</div>
 
<div class='fw-bolder ps-3 fs-5'>
TRƯỜNG TOP 3</div>
 
<div class='ps-5 fs-5'>
- Có tỉ lệ du học sinh bất hợp pháp trên 10%/năm </div>
 
<div class='ps-5 fs-5'>
- Trường cấp Code xin Visa bay thẳng, không phỏng vấn Đại sứ quán </div>
 
<div class='ps-5 fs-5'>
- Điều kiện đầu vào dễ hơn TOP 1, dễ đi và chắc chắn nhất</div>
 
<div class='ps-5 fs-5 fs-5'>
- Cần mở sổ đóng băng 8-10 triệu Won Hàn, tính theo thời điểm mở</div>
 
<div class=' fs-1 text-success fw-bolder py-3' >
CÁC KỲ NHẬP HỌC</div>
 
<div class=' ps-3 fw-bolder fs-5' >
Kỳ tháng 3: Kỳ chính</div>
 
<div class='ps-5 fs-5'>
- Là kỳ nhập học chính của hệ tiếng Visa D4-1 và hệ chuyên ngành Visa D2 </div>
 
<div class=' ps-5 fs-5'>
- Nhiều sự lựa chọn </div>
 
<div class=' ps-5 fs-5'>
- Tỉ lệ cạnh tranh cao, xin Visa sẽ khó hơn </div>
 
<div class=' ps-5 fs-5'>
- Nộp hồ sơ từ tháng 9-tháng 11 năm trước</div>
 
<div class=' ps-5 pb-3 fs-5'>
- Thời tiết dễ chịu, thích ứng nhanh</div>
 
<div class=' ps-3 fw-bolder fs-5' >
Kỳ tháng 6: </div>
 
<div class='ps-5 fs-5'>
- Là kỳ nhập học của hệ tiếng Visa D4-1 </div>
 
<div class='ps-5 fs-5'>
- Tỉ lệ cạnh tranh thấp, tăng cao tỉ lệ đỗ Visa </div>
 
<div class='ps-5 fs-5'>
- Nộp hồ sơ từ tháng 12 năm trước-tháng 2 cùng năm</div>
 
<div class='ps-5 pb-3 fs-5'>
- Thời tiết nóng hơn</div>
 
<div class='ps-3 fw-bolder fs-5'>
Kỳ tháng 9: Kỳ chính</div>
 
<div class='ps-5 fs-5'>
- Là kỳ nhập học chính của hệ tiếng Visa D4-1 và hệ chuyên ngành Visa D2 </div>
 
<div class=' ps-5 fs-5'>
- Nhiều sự lựa chọn  </div>
 
<div class=' ps-5 fs-5'>
- Thời tiết dễ chịu, thích ứng nhanh</div>
 
<div class='ps-5 fs-5'>
- Tỉ lệ cạnh tranh cao, xin Visa sẽ khó hơn </div>
 
<div class='ps-5 pb-3 fs-5'>
- Nộp hồ sơ từ tháng 3-tháng 5 cùng năm </div>
 
<div  class=' ps-3 fw-bolder fs-5' >
Kỳ tháng 12:</div>
 
<div class='ps-5 fs-5'>
- Là kỳ nhập học của hệ tiếng Visa D4-1</div>
 
<div class='ps-5 fs-5'>
- Tỉ lệ cạnh tranh thấp, tăng cao tỉ lệ đỗ Visa; </div>
 
<div class='ps-5  fs-5'>
- Nộp hồ sơ từ tháng 12 năm trước-tháng 2 cùng năm </div>
 
<div class='ps-5 fs-5'>
- Thời tiết lạnh, mang hành lý nặng hơn </div>
<div class=' fs-1 text-success fw-bolder 3 '>
Các LOAI VISA DU HỌC HÀN QUỐC </div>
 
<div class=' fs-4 text-success fw-bolder ' >
VISA D4-1: Visa học tiếng tại Hàn</div>
 
<div class=' ps-5 fs-5'>
- Thời gian học tiếng tại Hàn tối đa 2 năm, cần đạt TOPIK 3 trở lên sau tốt nghiệp; </div>
 
<div class='ps-5 fs-5'>
- Sau tốt nghiệp, chuyển sang Visa D2-1 hoặc D2-2 học Cao đẳng/Đại học tại Hàn</div>
 
<div class='ps-5 fs-5'>
- Học từ thứ 2 đến thứ 6, nghỉ thứ 7 - Chủ nhật </div>
 
<div class='ps-5 fs-5'>
- Được đi làm thêm 20 giờ/tuần </div>
 
<div class='ps-5 fs-5'>
- Vào kỳ nghỉ được làm toàn thời gian </div>
 
<div class='ps-5 pb-3 fs-5'>
- Hiệu lực Visa 6 tháng - 1 năm, sau đó cần gia hạn</div>
 
<div class=' ps-3 fw-bolder fs-5' >
Điều kiện tham gia: </div>
<div class=' ps-5  fs-5'>
- Tốt nghiệp THPT, Cao đẳng, Đại học tại Việt Nam không quá 3 năm </div>
 
<div class='ps-5 fs-5'>
- Điểm trung bình GPA từ 6,5/10 trở lên </div>
 
<div class='ps-5 fs-5'>
- Nghỉ học không quá 10 buổi/3 năm (theo học bạ) </div>
 
<div class='ps-5 pb-3 fs-5'>
- Sức khoẻ tốt, không có bệnh truyền nhiễm </div>
 
<div class=' ps-3 fw-bolder fs-5' >
Chứng minh tài chính:</div>
 
<div class='ps-5 fs-5'>
- Trường TOP 1: Sổ tiết kiệm trị giá 8-10 triệu Won (150-190 triệu VND), thời hạn 12 tháng, gửi trước 6 tháng; không có sẽ được Công ty hướng dẫn </div>
<div class='ps-5 fs-5'>
- Trường TOP 2:  Sổ tiết kiệm trị giá 10.000 USD (khoảng 250 triệu VND), thời hạn 12 tháng, gửi trước 6 tháng; không có sẽ được Công ty hướng dẫn; </div>
 
 
<div class='ps-5 fs-5'>
- Trường TOP 3: Sổ đóng băng trị giá 8-10 triệu Won (150-190 triệu VND) </div>
 
 
<div class=' fs-4 text-success fw-bolder py-3 ' >
VISA D4-6: Visa học nghề tại Hàn</div>
 
<div class='ps-5 fs-5'>
- Thời gian học rất ít từ thứ 2 đến thứ 5, các buổi chiều và thứ 6, 7 và chủ nhật đi thực hành tại Công ty liên kết với trường và được hưởng lương. </div>
 
<div class='ps-5 fs-5'>
- Thời hạn học từ 2 – 3 năm tùy vào lựa chọn khóa học. </div>
 
<div class='ps-5 fs-5'>
- Sau khi tốt nghiệp khóa học nghề cơ hội được ở lại Hàn Quốc làm việc lâu dài với visa E7. </div>
 
<div class='ps-5 pb-3 fs-5'>
- Sau 3 tháng được sang học đi làm ngay, không bị giới hạn thời gian như chương trình visa D4-1 Đạt TOPIK 2 học luôn chuyên ngành nghề. </div>
 
<div class=' ps-3 fw-bolder fs-5'>
Điều kiện tham gia:</div>
 
<div class='ps-5 fs-5'>
- Tốt nghiệp THPT không quá 3 năm </div>
 
<div class='ps-5 fs-5'>
- Tuổi từ 18 đến 25 </div>
 
<div class='ps-5 fs-5'>
- Sinh viên đã hoặc đang học Trung cấp, Cao đẳng, Đại học </div>
 
<div class='ps-5 fs-5'>
- Có Topik 2 trở lên (được đào tạo) </div>
 
<div class='ps-5 fs-5'>
- Có trường Cao đẳng nghề của Hàn tiếp nhận </div>
 
<div class='ps-5 pb-3 fs-5'>
- Sức khoẻ tốt, không có bệnh truyền nhiễm</div>
 
<div class=' ps-3 fw-bolder fs-5'>
Một số điều cần lưu ý:</div>
 
<div class='ps-5 fs-5'>
- Bắt buộc phỏng vấn tại Đại sứ quán để xin Visa nên sẽ khó và tỉ lệ đỗ Visa 50/50 </div>
 
<div class='ps-5 fs-5'>
- Yêu cầu tiếng cao hơn so với du học sinh thường </div>
 
<div class='ps-5 fs-5'>
- Sau khi tốt nghiệp sinh viên được cấp chứng chỉ nghề, nếu không học lên lên chuyên ngành hệ Đại học hay Cao đẳng nghề thì khó xin Visa E7 </div>
 
<div class='ps-5 fs-5'>
- Chi phí đi cao hơn, khoảng hơn 10,000 USD và chứng minh tài chính qua sổ tiết kiệm 5000-10,000 USD (tuỳ trường) gửi trước 6 tháng</div>
 
<div class=' fs-4 text-success fw-bolder py-3 ' >
VISA D2-1, D2-2: Visa học Cao đẳng, Đại học</div>
 
<div class='ps-5 fs-5'>
- Thời hạn Visa là 1-2 năm, sau đó gia hạn thêm; Đi làm thêm 25 giờ/tuần; thứ 7, Chủ nhật và kỳ nghỉ được làm thoải mái </div>
 
<div class='ps-5 pb-3 fs-5'>
- Được bảo lãnh người thân (cha mẹ, vợ/chồng/con, anh chị em) sang du lịch dưới 90 ngày</div>
 
<div  class=' ps-3 fw-bolder fs-5'>
Visa D2-1: </div>
 
<div class='ps-5 pb-3 fs-5'>
Học Cao đẳng tại Hàn trong 2-3 năm, cần có TOPIK 3/IELTS 5.5 trở lên </div>
 
<div class='ps-3 fw-bolder fs-5'>
Visa D2-2: </div>
 
<div class='ps-5 pb-3 fs-5'>
Học Đại học tại Hàn Quốc trong 4-5 năm, cần có TOPIK 3/IELTS 5.5 trở lên </div>
 
<div class=' ps-3 fw-bolder fs-5' >
Điều kiện tham gia:</div>
 
<div class='ps-5 fs-5'>
- Tốt nghiệp THPT, Cao đẳng, Đại học tại Việt Nam không quá 3 năm </div>
 
<div class='ps-5 fs-5'>
- Điểm trung bình GPA từ 6,5/10 trở lên  </div>
 
<div class='ps-5 fs-5'>
- Nghỉ học không quá 10 buổi/3 năm (theo học bạ) </div>
 
<div class='ps-5 fs-5'>
- Topik 3 hoặc IELTS 5.5 trở lên </div>
 
<div class='ps-5 pb-3 fs-5'>
- Sức khoẻ tốt, không có bệnh truyền nhiễm</div>
<div class=' ps-3 fw-bolder fs-5' >
Chứng minh tài chính:</div>
<div class='ps-5 fs-5'>
Sổ tiết kiệm 16-20 triệu Won (khoảng 300-380 triệu VND) kỳ hạn 12 tháng, gửi trước tối thiểu 3 tháng</div>
 
<div class=' fs-4 text-success fw-bolder py-3 ' >
VISA D2-3: Visa học Thạc sĩ</div>
 
<div class='ps-5 fs-5'>
- Chương trình học chuyên ngành 2 năm, học 2 kỳ/năm, mỗi kỳ 2,5-3 tháng, tuần học 1-2 buổi, còn lại là nghỉ để tự học nghiên cứu hoặc đi làm thêm </div>
 
<div class='ps-5 fs-5'>
- Nếu có Topik 4 từ Việt Nam, sẽ học thẳng lên chuyên ngành từ năm đầu tại Hàn </div>
 
<div class='ps-5 fs-5'>
- Nếu chưa có Topik 4 từ Việt Nam, sang Hàn học tiếng đến Topik 4 rồi mới học chuyên ngành </div>
 
<div class='ps-5 fs-5'>
- Học phí dao động 4-8 triệu Won Hàn/kỳ (80 - 150 triệu VND/kỳ) </div>
 
<div class='ps-5 pb-3 fs-5'>
- Được làm thêm 35 giờ/tuần vào kỳ học, không giới hạn vào cuối tuần và kỳ nghỉ</div>
<div class=' ps-3  fw-bolder fs-5' >
Điều kiện tham gia:</div>
 
<div class='ps-5 fs-5'>
-Tốt nghiệp Đại học tại Việt Nam, tuổi từ 23-30 tuổi </div>
 
<div class='ps-5 fs-5'>
- Điểm trung bình GPA từ 6,5/10 trở lên hoặc 2.0/4.0 trở lên </div>
 
<div class='ps-5 fs-5'>
- Topik 4 hoặc IELTS 6.0 trở lên </div>
 
<div class='ps-5 pb-3 fs-5'>
- Sức khoẻ tốt, không có bệnh truyền nhiễm</div>
 
<div class=' ps-3 fw-bolder fs-5' >
Chứng minh tài chính:</div>
 
<div class='ps-5 fs-5'>
Sổ tiết kiệm 16-20 triệu Won (khoảng 300-380 triệu VND) hoặc 20,000 USD trở lên, kỳ hạn 12 tháng, gửi trước tối thiểu 3 tháng</div>
 
<div class=' fs-4 text-success fw-bolder py-3 fs-5' >
Chi phí sinh hoạt tại Hàn Quốc</div>
 
<div class=' ps-3 fw-bolder fs-5 '>
Tại thủ đô SEOUL</div>
 
<div class='ps-5 fs-5'>
+ Nhà ở: 180,000 - 500,000 Won/tháng (4-13 triệu VND/tháng)</div>
 
<div class='ps-5 fs-5'>
+ Ăn uống: 220,000 - 450,000 Won/tháng (4,5-8,5 triệu VND/tháng)</div>
 
<div class='ps-5 fs-5'>
+ Đi lại: 50,000 Won/tháng (1 triệu VND/tháng) </div>
 
<div class='ps-5 fs-5'>
+ Chi phí liên lạc: 30,000 Won/tháng (600,000 VND/tháng) </div>
 
<div class='ps-5 pb-3 fs-5'>
+ Chi phí mua sắm: 100,000 Won/tháng (2 triệu VND/tháng) </div>
 
<div class=' ps-3 fw-bolder fs-5'>
Tại BUSAN và các thành phố khác</div>
 
<div class='ps-5 fs-5'>
+ Nhà ở: 180,000 - 300,000 Won/tháng (4-6 triệu VND/tháng) </div>
 
<div class='ps-5 fs-5'>
+ Ăn uống: 200,000 - 300,000 Won/tháng (4,5-6 triệu VND/tháng)</div>
 
<div class='ps-5 fs-5'>
+ Đi lại: 30,000 Won/tháng (1 triệu VND/tháng)</div>
 
<div class='ps-5 fs-5'>
+ Chi phí liên lạc: 30,000 Won/tháng (600,000 VND/tháng)</div>
 
<div class='ps-5 fs-5'>
+ Chi phí mua sắm: 100,000 Won/tháng (2 triệu VND/tháng)</div>
 
<div  class=' fs-1 text-success fw-bolder py-3'>
QUY TRÌNH DU HỌC HÀN QUỐC TẠI DABOSA</div>
 
<div class=' ps-3 fw-bolder fs-5'>
Nhập học tại Dabosa</div>
 
<div class=' ps-5 fs-5' >
- Lắng nghe tư vấn chương trình phù hợp; Ký hợp đồng, nộp tiền đợt 1 và nhập học</div>
 
<div class=' ps-3 fw-bolder fs-5' >
Học tập tại Dabosa</div>
 
<div class=' ps-5 fs-5' >
- Học tiếng Hàn 04 kỹ năng đến Topik 1 hoặc 2; Học kỹ năng mềm, văn hoá Hàn Quốc</div>
 
<div class=' ps-3 fw-bolder fs-5'>
Chuẩn bị hồ sơ du học</div>
 
<div class=' ps-5 fs-5' >
- Trung tâm hướng dẫn học viên chuẩn bị, bổ sung và hoàn thiện các giấy tờ, thủ tục theo yêu cầu</div>
 
<div class=' ps-3 fw-bolder fs-5'>
Nộp học phí sang trường Hàn</div>
 
<div class=' ps-5 fs-5' >
- Nộp học phí theo INVOICE thông báo của trường Hàn gửi về</div>
 
<div class=' ps-3 fw-bolder fs-5'>
Xin Visa du học</div>
 
<div class=' ps-5 fs-5' >
- Tuỳ theo diện TOP trường, có thể sẽ cần phỏng vấn Đại sứ quán hoặc Visa thẳng</div>
 
<div class=' ps-3 fw-bolder fs-5'>
Học nâng cao tiếng Hàn và chuyên ngành</div>
 
<div class=' ps-5 fs-5'  >
- Học tiếng Hàn đến Topik 3 hoặc 4 trong 1-2 năm đầu rồi lên học chuyên ngành trong 3-4 năm tiếp theo, kết hợp với đi làm thêm</div>
 
<div class=' fs-1 text-success fw-bolder py-3 ' >
VÌ SAO NÊN CHỌN DABOSA?</div>
 
<div class=' ps-3 fw-bolder fs-5'>
Tại Việt Nam</div>
 
<div class='ps-5 fs-5'>
+ Trang bị ngoại ngữ, kỹ năng và kiến thức văn hóa, pháp luật Hàn Quốc </div>
 
<div class='ps-5 fs-5'>
+ Giao lưu cùng tiền bối có thêm kinh nghiệm khi du học </div>
 
<div class='ps-5 fs-5'>
+ Hoạt động ngoại khóa, teambuilding </div>
 
<div class='ps-5 fs-5'>
+ Cơ sở vật chất tiện nghi, hiện đại</div>
 
<div class='ps-5 fs-5'>
+ Có khu giải trí nghỉ ngơi, đọc sách</div>
 
<div class='ps-5 fs-5'>
+ Hướng dẫn tận tình, tận tâm </div>
 
<div class='ps-5 fs-5'>
+ Chương trình đa dạng, chi phí ưu đãi</div>
 
<div class=' ps-3 fw-bolder fs-5'>
Tại Hàn Quốc</div>
 
<div class='ps-5 fs-5'>
+ Đồng hành sát sao </div>
 
<div class='ps-5 fs-5'>
+ Giới thiệu việc làm, nơi ở, hướng dẫn các giấy tờ cá nhân</div>
 
<div class='ps-5 fs-5'>
+ Cầu nối giữa học viên và gia đình</div>
 
<div class='ps-5 fs-5'>
+ Hỗ trợ gia đình xử lý Visa thăm thân</div>
 
<div class='fs-1 text-success fw-bolder py-3 ' >
CHI PHÍ DU HỌC HÀN QUỐC</div>
 
<div class='ps-3 fw-bolder fs-5 '>Dao động khoảng 7000 - 9000 USD, bao gồm: </div>
 
<div class='ps-5 fs-5'>
+ Học tiếng Hàn 04 kỹ năng, thi chứng chỉ tiếng Hàn, đồng phục, giáo trình tài liệu học tập, học kỹ năng mềm, hoạt động ngoại khoá, ký túc xá tại DABOSA. </div>
 
<div class='ps-5 fs-5'>
+ Xử lý hồ sơ, giấy tờ, chuyển phát hồ sơ Việt-Hàn, khám sức khoẻ. </div>
 
<div class='ps-5 fs-5'>
+ Học phí 01 năm đầu tiên tại Hàn theo INVOICE. Vé máy bay 01 chiều. </div>
<div class='ps-3 fw-bolder fs-5'>
Lưu ý:</div>
<div class='ps-5 fs-5'>
- Chi phí biến động theo tỉ giá ngoại tệ (USD, Won Hàn) và mức học phí cần đóng sang Hàn cũng như mức độ hồ sơ của học viên. Chưa bao gồm tiền đóng băng, ký quỹ theo yêu cầu của trường Hàn.</div>
<div class=' fs-4 text-success fw-bolder py-3 ' >
Tiến độ đóng tiền</div>
 <div class='ps-3 fw-bolder fs-5'>
Đợt 01: </div>
 
<div class='ps-5 pb-3 fs-5' >
Đóng 28 triệu VND, nếu ở KTX tại DABOSA đóng 1,3 triệu/tháng, đã bao gồm điện, nước. </div>
 
<div class='ps-3 fw-bolder fs-5'>
Đợt 02: </div>
 
<div class='ps-5 fs-5'>
Đóng học phí 01 năm theo INVOICE của trường Hàn, khám sức khoẻ và dịch vụ chứng minh tài chính (nếu có). Đợt 03: Đóng số tiền còn lại khi có Code.</div>
 
<div class=' fs-1 text-success fw-bolder py-3' >
MỘT SỐ KỲ NGHỈ LỄ CỦA HÀN QUỐC</div>
 
<div class='ps-5 fs-5'>
+ DHS có thể làm không giới hạn trong kỳ nghỉ để nâng cao thu nhập </div>
 
<div class='ps-5 fs-5'>
+ DHS có thể đi thăm quan, trải nghiệm, khám phá cảnh đẹp, lễ hội và văn hóa Hàn Quốc </div>
 
<div class='ps-5 pb-3 fs-5'>
+ DHS có thể về thăm nhà tại Việt Nam trong kỳ nghỉ lễ này</div>
 
<div class='ps-3 fw-bolder fs-5 '>
Nghỉ Tết</div>
 
<div class='ps-5 fs-5'>
- Tết Dương 01 ngày 1/1 </div>
 
<div class='ps-5 fs-5'>
-  Nghỉ Tết Âm lịch (Seollal) 03 ngày, đó là: ngày 30, mùng 1 và mùng 2 âm lịch </div>
 
<div class='ps-5 pb-3 fs-5'>
- Nghỉ Tết Phật Đản 01 ngày</div>
 
<div class='ps-3 fw-bolder fs-5'>
Kỳ nghỉ hè</div>
 
<div class='ps-5 pb-3 fs-5' >
-  Kỳ nghỉ hè sẽ kéo dài 2 tháng, từ đầu tháng 7 đến cuối tháng 8, một số trường có thể được nghỉ đến giữa tháng 9.</div>
 
<div class='ps-3 fw-bolder fs-5'>
Kỳ nghỉ đông</div>
 
<div class='ps-5  pb-3 fs-5'>
-  Kỳ nghỉ mùa đông tại Hàn Quốc kéo dài từ tháng 1 đến hết tháng 2. Đây cũng là thời điểm lạnh nhất tại Hàn Quốc. Nhiệt độ luôn xuống dưới 0 độ C.</div>
 
<div class='ps-3 fw-bolder fs-5'>
Nghỉ lễ khác</div>
 
<div class='ps-5 fs-5'>
-  Quốc tế Lao động (1/5 DL) Ngày Lập quốc (03/10) Hàn văn Hangeullal (9/10 DL) Ngày độc lập (15/8 DL) Lễ Giáng sinh ( 25/12 DL)</div>
 
<div class=' fs-1 text-success fw-bolder py-3'>
MỘT SỐ CÔNG VIỆC LÀM THÊM TẠI HÀN:
</div>
 
<div class='ps-5 pb-3 fs-5'>
- Làm thêm 20-25 giờ/tuần trong khi đi học và không giới hạn trong kỳ nghỉ
</div>
 
<div class='ps-3 fw-bolder fs-5'>
Nhân viên Nhà hàng
</div>
 
<div class='ps-5 pb-3 fs-5'>
- Công việc phụ bếp, bưng bê hoặc rửa bát tại các Nhà hàng của Hàn
</div>
 
<div class='ps-3 fw-bolder fs-5'>
Làm tại nông trại
</div>
 
<div class='ps-5 pb-3 fs-5'>
- Phụ giúp công việc trồng trọt, thu hoạch, chăn nuôi tại nông trại
</div>
 
<div class='ps-3 fw-bolder fs-5'>
Nhân viên Cửa hàng Tiện lợi
</div>
 
<div class='ps-5 pb-3 fs-5'>
- Làm thu ngân, lau dọn, sắp xếp cửa hàng
</div>
 
<div class='ps-3 fw-bolder fs-5'>
Dạy tiếng Việt
</div>
<div class='ps-5 pb-3 fs-5'>
- Dạy tiếng Việt cho người Hàn Quốc
</div>
 
<div class='ps-3 fw-bolder fs-5'>
Nhân viên Siêu thị
</div>
 
<div class='ps-5 pb-3 fs-5'>
- Làm thu ngân, phục vụ hoặc chế biến thực phẩm
</div>
 
<div class='ps-3 fw-bolder fs-5'>
Phụ việc ở trường
</div>
 
<div class='ps-5 fs-5'>
- Phụ giúp công việc tại trường học, phòng thí nghiệm hoặc phụ việc Giáo sư
</div>
`;
export const Study_Duc = `
 <img src=${ProgramImages.duc1} class='w-100' />
<div class='fw-bolder  text-success  fs-1 py-3'>
ĐIỂM NỔI BẬT
</div>

<div class='ps-3 fw-bolder fs-5'>
• Miễn 100% học phí tại Đức :
</div>
<div class='ps-5 pb-3 fs-5'>
- Hầu hết các trường học nghề ở Đức đều miễn phí hoàn toàn tiền học phí cho sinh viên, nhằm khuyến khích sinh viên đi học để có kiến thức, tạo điều kiện giáo dục bình đẳng cho người dân.
</div>

<div class=' ps-3 fw-bolder fs-5'>
• Tỉ lệ đỗ Visa gần như 100% :
</div>
<div class='ps-5 pb-3 fs-5'>
- Chỉ cần có chứng chỉ tiếng Đức B1 trở lên với 4 kỹ năng kèm theo bản hợp đồng của doanh nghiệp tại Đức nơi học viên thực hành trong quá trình học nghề.
</div>

<div class='ps-3 fw-bolder fs-5 '>
• Có việc làm ngay trong tháng đầu sang Đức :
</div>
<div class='ps-5 pb-3 fs-5'>
- Học viên sang Đức diện vừa học nghề vừa thực hành nhận lương tại doanh nghiệp với thu nhập mỗi tháng 900 - 1200 Euro (khoảng 25 - 35 triệu/tháng, chưa tính thưởng, làm thêm). Sau khi tốt nghiệp, đi làm chính thức với thu nhập 2700 - 3500 Euro (70 - 90 triệu/tháng).
</div>

<div class=' ps-3 fw-bolder fs-5 '>
• Cơ hội ở lại Đức lâu dài :
</div>
<div class='ps-5 fs-5'>
- Sau 3 năm học nghề và 2 năm làm chính thức tại Đức, học viên có thể xin thẻ xanh hoặc xin cấp quốc tịch định cư để tự do đi lại giữa Việt Nam - Đức hoặc tự do đi lại trong Liên minh Châu Âu chỉ với Visa của Đức.
</div>

<div class='fw-bolder  text-success  fs-1 py-3 '>
ĐIỀU KIỆN TUYỂN SINH
</div>
<div class='ps-5 fs-5'>
- Nam, nữ Độ tuổi từ 18 - 35 tuổi
</div>
<div class='ps-5 fs-5'>
- Tốt nghiệp THPT/GDTX, Trung cấp, Cao đẳng hoặc Đại học
</div>
<div class='ps-5 fs-5'>
- Không yêu cầu mức điểm số Trình độ tiếng Đức B1 trở lên, chưa có sẽ được đào tạo
</div>

<div class='fw-bolder  text-success  fs-1 py-3'>
YÊU CẦU KHÁC
</div>

<div class='ps-5 fs-5'>
- Không mắc bệnh truyền nhiễm: HIV/AIDS, Viêm gan B, C.
</div>
<div class='ps-5 fs-5'>
- Đã tiêm Vắc-xin (viêm gan B,C, sởi, rubella, lao phổi,...).
</div>
<div class='ps-5 fs-5'>
- Không có người thân (bố, mẹ, anh, chị, em ruột) đang bất hợp pháp ở Đức.
</div>
<div class='ps-5 fs-5'>
- Không bị cấm xuất cảnh khỏi Việt Nam.
</div>

<div class='fw-bolder  text-success  fs-1 py-3'>
KỲ NHẬP HỌC
</div>

<div class='d-flex'>
<div class='fw-bolder fs-5 ps-3'>
Kỳ mùa hè:
</div>
<div class='ps-2 fs-5'>
Tháng 3 hoặc tháng 4.
</div>
</div>
<div class='d-flex'>
<div class='fw-bolder fs-5 ps-3'>
Kỳ mùa đông:
</div>
<div class='ps-2 fs-5'>
Tháng 8 hoặc tháng 9.
</div>
</div>
<div class='ps-5 fs-5'>
- Thời điểm xuất cảnh sang Đức phụ thuộc vào Visa và kỳ nhập học của các trường.
</div>
<div class='ps-5 fs-5'>
- Có thể sang Đức 02 tuần trước thời gian nhập học.
</div>


<div class='fw-bolder  text-success  fs-1 py-3'>
CÁC CHỨNG CHỈ TIẾNG ĐỨC
</div>
<div class='ps-3 fw-bolder fs-5'>
03 loại chứng chỉ phổ biến ở Việt Nam:
</div>
<div class='ps-5 fs-5'>
- Chứng chỉ của Viện Goethe 
</div>
<div class='ps-5 fs-5'>
- Chứng chỉ của TELC
</div>
<div class='ps-5 fs-5'>
- Chứng chỉ của ECL
</div>

<div class='fw-bolder  text-success  fs-1 py-3'>
QUY TRÌNH DU HỌC ĐỨC TẠI DABOSA
</div>
<div class='ps-5 fs-5'>
- Lắng nghe tư vấn và ký hợp đồng tham gia chương trình
</div>
<div class='ps-5 fs-5'>
- Đào tạo tiếng Đức 04 kỹ năng, học kỹ năng mềm và văn hóa Đức
</div>
<div class='ps-5 fs-5'>
- Phỏng vấn doanh nghiệp Đức nơi học viên thực hành nghề
</div>
<div class='ps-5 fs-5'>
- Nhận hợp đồng gốc và xin Visa tại Đại sứ quán Đức
</div>
<div class='ps-5 fs-5'>
- Đỗ Visa và xuất cảnh sang Đức theo thời gian trên Visa
</div>
<div class='ps-5 fs-5'>
- Học nghề và thực hành tại doanh nghiệp, nhận trợ cấp hàng tháng
</div>
<div class='fw-bolder  text-success  fs-1 py-3'>
LỘ TRÌNH DU HỌC ĐỨC
</div>
<div class='ps-3 fw-bolder '>
TẠI VIỆT NAM :
</div>
<div class='ps-5 fs-5'>
- Học tiếng Đức 04 kỹ năng: nghe, nói, đọc, viết đến B1 hoặc B2 và thi chứng chỉ tiếng Đức Goethe hoặc TELC.
</div>
<div class='ps-5 fs-5'>
- Trung bình khoảng 8-10 tháng.
</div>
<div class='ps-5 fs-5'>
- Học kỹ năng mềm: giao tiếp, làm việc nhóm, quản lý tài chính, thuyết trình,...
</div>
<div class='ps-5 pb-3 fs-5'>
- Tìm hiểu văn hóa, pháp luật nước Đức.
</div>
<div class='ps-3 fw-bolder fs-5  '>
TẠI CHLB ĐỨC :
</div>
<div class='ps-5 fs-5'>
- Học nghề kết hợp đi làm thực tập tại doanh nghiệp liên quan đến ngành nghề trong 03 năm, hưởng thu nhập 900 - 1200 Euro/tháng (khoảng 25 - 35 triệu VND).
</div>
<div class='ps-5 fs-5'>
- Sau khi học nghề, đi làm chính thức với thu nhập 2500 - 3500 Euro/tháng (khoảng 70 - 90 triệu).
</div>
<div class='ps-5 fs-5'>
- Sau 05 năm tại Đức, có thể xin thẻ xanh hoặc xét định cư.
</div>
<div class='fw-bolder   text-success  fs-1 py-3'>
CHI PHÍ
</div>
<div class='ps-5 fs-5'>
- Học tiếng Đức 4 kỹ năng đến B1, kỹ năng mềm và tìm hiểu văn hóa Đức, Ký túc xá tại Dabosa.
</div>
<div class='ps-5 fs-5'>
- 01 lần thi chứng chỉ tiếng Đức B1, xử lý hồ sơ, kết nối phỏng vấn trường học và doanh nghiệp Đức, xin hợp đồng, xin Visa, vé máy bay,...
</div>
<div class='ps-5 fs-5'>
- Chưa bao gồm chi phí đóng băng chứng minh tài chính (nếu bên Đức yêu cầu), dao động 1800 Euro - 2500/3000 Euro
</div>
<div class='ps-5 fs-5'>
* Lưu ý: Chí biến động tăng/giảm theo thời điểm do tỉ giá ngoại tệ Euro - VND, giá vé máy bay và chưa bao gồm chi phí chứng minh tài chính (nếu bên Đức yêu cầu).
</div>
<div class='pb-3 fs-5 ps-5 '>
TỔNG PHÍ: 8000 - 9000 Euro
</div>
<div class='fw-bolder  text-success  fs-1 py-3'>
TIẾN ĐỘ ĐÓNG TIỀN
</div>
<div class='ps-5 fs-5'>
• Đợt 1: Đóng 40 triệu VND khi ký hợp đồng du học cùng Dabosa. Nếu ở Ký túc xá của Dabosa, đóng 1,3 triệu/tháng, đã bao gồm điện, nước. Đóng 3 hoặc 6 tháng.
</div>
<div class='ps-5 fs-5'>
• Đợt 2: Đóng 30 triệu VND khi vào học chương trình A2 tiếng Đức (cách đợt 1 khoảng 3 tháng)
</div>
<div class='ps-5 fs-5'>
• Đợt 3: Đóng 130 triệu VND khi nhận hợp đồng của doanh nghiệp Đức (cách đợt 2 khoảng 5-7 tháng)
</div>
<div class='ps-5 fs-5'>
• Đợt 4: Đóng số còn lại khi có Visa
</div>
<div class='ps-5 fs-5'>
=> Trường hợp cần đóng băng chứng minh tài chính sẽ có thông báo sau khi phỏng vấn doanh nghiệp và nhận hợp đồng gửi về Việt Nam.
</div>
<div class='fw-bolder  text-success  fs-1 py-3'>
CHI PHÍ SINH HOẠT TẠI ĐỨC
</div>
<div class='ps-3 fs-5 fw-bolder '>
CÁC KHOẢN PHÍ :
</div>
<div class='ps-5 fs-5'>
- Tiền thuê nhà, nhà ở: 250 - 400 Euro/tháng
</div>
<div class='ps-5 fs-5'>
- Tiền ăn uống: 200 - 300 Euro/tháng
</div>
<div class='ps-5 fs-5'>
- Di chuyển bằng phương tiện công cộng: 80 - 150 Euro/tháng
</div>
<div class='ps-5 fs-5'>
- Bảo hiểm: 80 - 160 Euro/tháng
</div>
<div class='ps-5 pb-3 fs-5'>
- Điện, nước, internet: 100 - 150 Euro/tháng
</div>
<div class=' ps-3 fw-bolder fs-5 '>
TỔNG :
</div>
<div class='ps-5 fs-5'>
710 - 1310 Euro/tháng
</div>
<div class='fw-bolder  text-success  fs-1 py-3'>
CÁC KỲ NGHỈ Ở ĐỨC
</div>
<div class='ps-3 fw-bolder fs-5 '>
KỲ NGHỈ HÈ :
</div>
<div class='ps-5 fs-5'>
- Kỳ nghỉ hè sẽ có sự chênh lệch tùy theo khu vực, không diễn ra cùng thời điểm. Ở Tây Đức, kỳ nghỉ hè diễn ra từ tháng 8 đến tháng 10 hằng năm. Ở Đông Đức, kỳ nghỉ hè diễn ra từ giữa tháng 7 đến hết tháng 8 hằng năm.
</div>
<div class='ps-5 pb-3 fs-5'>
- Trong kỳ nghỉ hè, du học sinh có thể đi du lịch, nghỉ dưỡng, khám phá Đức hoặc làm thêm để nâng cao thu nhập cho bản thân hoặc gửi về Việt Nam phụ giúp gia đình.
</div>
<div class=' fw-bolder ps-3 fs-5 '>
KỲ NGHỈ ĐÔNG :
</div>
<div class='ps-5 fs-5'>
- Kỳ nghỉ đông ở Đức sẽ có thời gian nghỉ chung từ giữa tháng 12 đến hết tháng 1 năm sau, là thời điểm nhiệt độ xuống thấp nhất, lạnh nhất trong năm. Với kỳ nghỉ đông, du học sinh thường ở nhà nghỉ ngơi, tự ôn tập và nâng cao trình độ ngoại ngữ, kiến thức hoặc về thăm gia đình tại Việt Nam
</div>
<div class='fw-bolder  text-success  fs-1 py-3'>
CÁC NGÀNH NGHỀ LÀM THÊM
</div>
<div class='ps-3 fw-bolder fs-5 '>
NGÀNH NHÀ HÀNG :
</div>
<div class='ps-5 fs-5'>
- Mô tả công việc: Tư vấn cho khách hàng Tiếp nhận, xử lý yêu cầu, thu ngân đơn đặt hàng của khách Dọn dẹp, trang trí, bày biện không gian của nhà hàng Tham gia công tác tổ chức sự kiện tại nhà hàng
</div>
<div class='ps-5 fs-5'>
- Thời gian làm việc, thu nhập: Làm việc 40 giờ/tuần, theo lịch/ca được phân công Thu nhập khi học nghề khởi điểm từ 900 - 950 Euro Sau khi tốt nghiệp, từ 2500 Euro/tháng trở lên
</div>
<div class='ps-5 pb-3 fs-5'>
- Yêu cầu: Khả năng giao tiếp khá trở lên, cẩn thận, chăm chỉ, chu đáo Khả năng làm việc nhóm, chịu được áp lực và có sự linh hoạt
</div>
<div class=' ps-3 fw-bolder fs-5 '>
NGÀNH LÀM BÁNH :
</div>
<div class='ps-5 fs-5'>
- Mô tả công việc: Tính toán công thức, chuẩn bị nguyên liệu làm bánh Làm các loại bánh mì, bánh ngọt, bánh quy,...theo yêu cầu Kiểm soát quy trình làm bánh, nướng bánh theo hướng dẫn Đảm bảo vệ sinh không gian và vệ sinh thực phẩm
</div>
<div class='ps-5 fs-5'>
- Thời gian làm việc, thu nhập: Học 3 năm, làm việc 40 giờ/tuần, theo lịch/ca được phân công Thu nhập khi học nghề khởi điểm từ 900 - 950 Euro Sau khi tốt nghiệp, từ 2500 Euro/tháng trở lên
</div>
<div class='ps-5 pb-3 fs-5'>
- Yêu cầu: Khéo léo, sáng tạo, cẩn thận, chăm chỉ, chu đáo Khả năng làm việc nhóm, chịu được áp lực và sự linh hoạt, kỹ năng quản lý công việc
</div>
<div class=' ps-3 fw-bolder fs-5 '>
NGÀNH ĐẦU BẾP :
</div>
<div class='ps-5 fs-5'>
- Mô tả công việc: Lên thực đơn, chuẩn bị nguyên liệu Tính toán, nấu và trình bày món ăn Xây dựng quy trình nấu ăn, làm việc Dọn dẹp, vệ sinh nơi làm việc
</div>
<div class='ps-5 fs-5'>
- Thời gian làm việc, thu nhập: Học 3 năm và làm việc 40 giờ/tuần, làm việc lịch phân công Thu nhập khi học 900-950 Euro/tháng và 2500 Euro/tháng trở lên khi làm chính thức
</div>
<div class='ps-5 pb-3 fs-5'>
- Yêu cầu: Khéo léo, sáng tạo, cẩn thận, chăm chỉ, chu đáo Khả năng làm việc nhóm, chịu được áp lực và sự linh hoạt, kỹ năng quản lý công việc
</div>
<div class=' ps-3 fw-bolder fs-5 '>
CHẾ BIẾN THỰC PHẨM :
</div>
<div class='ps-5 fs-5'>
- Mô tả công việc: Kiểm tra chất lượng Chế biến các thực phẩm từ thịt và xúc xích Tư vấn bán hàng và học các kiến thức về thành phần Giữ gìn vệ sinh và bảo quản thực phẩm
</div>
<div class='ps-5 fs-5'>
- Thời gian làm việc, thu nhập: Làm việc 40 giờ/tuần, theo lịch/ca được phân công Thu nhập khi học nghề khởi điểm từ 900 - 950 Euro Sau khi tốt nghiệp, từ 2500 Euro/tháng trở lên
</div>
<div class='ps-5 pb-3 fs-5'>
- Yêu cầu: Cẩn thận, chăm chỉ, chu đáo Khả năng làm việc nhóm, chịu được áp lực và có sự linh hoạt
</div>
<div class=' ps-3 fw-bolder fs-5 '>
NGÀNH ĐIỀU DƯỠNG :
</div>
<div class='ps-5 fs-5'>
- Mô tả công việc: Học viên sẽ được đào tạo bài bản và chi tiết cách hướng dẫn, giáo dục, chăm sóc và hỗ trợ cho người có khiếm khuyết về thể chất và tinh thần. Từ đó giúp bệnh nhân phục hồi được các chức năng và có thể tự mình làm được sinh hoạt cá nhân.
</div>
<div class='ps-5 fs-5'>
- Thời gian làm việc, thu nhập: Học 3 năm và làm việc theo lịch phân công Thu nhập khi học 1100-1400 Euro/tháng và 3000 - 3500 Euro/tháng trở lên khi làm chính thức
</div>
<div class='pb-3 ps-5 fs-5'>
- Yêu cầu: Khéo léo, sáng tạo, cẩn thận, chăm chỉ, chu đáo, nhẫn nại Chịu được áp lực và sự linh hoạt, kỹ năng quản lý công việc
</div>
<div class=' ps-3 fw-bolder  fs-5'>
NGÀNH TRỢ LÝ NHA KHOA :
</div>
<div class='ps-5 fs-5'>
- Mô tả công việc: Hỗ trợ bác sĩ nha khoa, quản lý hoạt động chung của phòng khám, lên lịch hẹn và quản lý lịch làm việc của bác sĩ với bệnh nhân, chăm sóc bệnh nhân.
</div>
<div class='ps-5 fs-5'>
- Thời gian làm việc, thu nhập: Học 3 năm và làm việc theo lịch phân công Thu nhập khi học 1100-1400 Euro/tháng và 3000 - 3500 Euro/tháng trở lên khi làm chính thức
</div>
<div class='ps-5 pb-3 fs-5'>
- Yêu cầu: Khéo léo, sáng tạo, cẩn thận, chăm chỉ, chu đáo, nhẫn nại Chịu được áp lực và sự linh hoạt, kỹ năng quản lý công việc
</div>
<div class=' ps-3 fw-bolder fs-5 '>
NGÀNH CƠ KHÍ ĐIỆN TỬ :
</div>
<div class='ps-5 fs-5'>
- Mô tả công việc: Lập trình và vận hành máy móc, thiết bị gia công, sản xuất linh kiện kim loại, điện tử. Thiết kế bản vẽ vi mạch và làm việc với sơ dồ mạch. Kiểm tra, bảo dưỡng máy móc, thiết bị.
</div>
<div class='ps-5 fs-5'>
- Thời gian làm việc, thu nhập: Học 3-5 năm và làm việc theo lịch phân công Thu nhập khi học 900-1400 Euro/tháng và 2800 - 3500 Euro/tháng trở lên khi làm chính thức
</div>
<div class='pb-5 ps-5 fs-5'>
*Yêu cầu: Khéo léo, sáng tạo, cẩn thận, chăm chỉ, chu đáo, nhẫn nại Chịu được áp lực và sự linh hoạt, kỹ năng quản lý công việc
</div>
`;
export const Study_UC = `
 <img src=${ProgramImages.uc1} class='w-100' />
 <div class='py-3 fw-bolder text-success fs-1'>
PHÚC LỢI:
</div>
 <div class='pb-3 fs-5 '>
- Top các nền giáo dục hàng đầu thế giới
</div>
 <div class='pb-3 fs-5'>
- Nhiều cơ hội học bổng cho sinh viên quốc tế tại Úc
</div>
 <div class='pb-3 fs-5'>
- Cơ hội làm việc tại Úc rộng mở
</div>
 <div class='pb-3 fs-5'>
- Chi phí du học hợp lý
</div>

 <div class=' pb-3 fw-bolder  text-success  fs-1'>
ĐIỀU KIỆN TUYỂN SINH:
</div>

 <div class='pb-3 fs-5'>
- Nam, nữ tuổi từ 19 - 30 tuổi
</div>
 <div class='pb-3 fs-5'>
- Tốt nghiệp THPT, Cao đẳng, Đại học, điểm TB từ 6.5 trở lên, trống không quá 02 năm (từ khi tốt nghiệp đến khi đi làm có hợp đồng, bảo hiểm đầu tiên)
</div>
 <div class='pb-3 fs-5'>
- Trình độ tiếng Anh IELTS 5.5 - 6.0 trở lên hoặc PTE 46
</div>
 <div class='pb-3 fs-5'>
- Sức khỏe tốt, không mắc bệnh truyền nhiễm
</div>
 <div class='pb-3 fs-5'>
- Chứng minh tài chính: Thu nhập cố định của bố mẹ (40-60 triệu/tháng), tài sản đối ứng (bất động sản, ô tô,...), sổ tiết kiệm tại ngân hàng khoảng 800 triệu - 1 tỷ trở lên (nếu không có, liên hệ để được hỗ trợ)
</div>
 <div class='pb-3 fw-bolder  text-success  fs-1'>
QUY TRÌNH:
</div>
<div class='d-flex'>
<div class='pb-3 fw-bolder fs-5'>
Bước 1:
</div>
<div class='ps-3 pb-3 fs-5'>
Tư vấn lựa chọn và ký hợp đồng
</div>
</div> 

 <div class='d-flex'>
 <div class='pb-3 fw-bolder fs-5'>
Bước 2: 
</div>
<div class='ps-3 pb-3 fs-5'>
Học tiếng Anh và ôn luyện thi IELTS
</div>
</div>

<div class='d-flex'>
 <div class='pb-3 fw-bolder fs-5'>
Bước 3: 
</div>
<div class='ps-3 pb-3 fs-5'>
Xử lý hồ sơ giấy tờ nộp cho trường
</div>
</div>

<div class='d-flex'>
<div class='pb-3 fw-bolder fs-5'>
Bước 4:
</div>
<div class='ps-3 pb-3 fs-5'>
Nhận INVOICE, đóng học phí và tiền đặt cọc
</div>
</div> 

<div class='d-flex'>
<div class='pb-3 fw-bolder fs-5'>
Bước 5: 
</div>
<div class='ps-3 pb-3 fs-5'>
Xin Visa
</div>
</div> 

<div class='d-flex'>
<div class='pb-3 fw-bolder fs-5'>
Bước 6: 
</div>
<div class='ps-3 pb-3 fs-5'>
Khám sức khỏe, lấy vân tay
</div>
</div> 

<div class='d-flex' >
 <div class='pb-3 fw-bolder fs-5'>
Bước 7: 
</div> 
<div class='ps-3 pb-3 fs-5'>
Nhận Visa và xuất cảnh
</div>
</div>

`;
export const Study_TrungQuoc = `
 <img src=${ProgramImages.trungquoc1} class='w-100 pb-3' />
<div class='pb-3 fw-bolder text-success fs-1'>
1. ĐIỂM NỔI BẬT
</div>
<div class=' ps-5 fs-5'>
- Chất lượng giáo dục hàng đầu thế giới
</div>
<div class='ps-5 fs-5'>
- Chi phí học tập hợp lý
</div>
<div class='ps-5 fs-5'>
- Rất gần Việt Nam, an ninh trật tự tốt
</div>
<div class='ps-5 fs-5'>
- Chất lượng đào tạo tốt, cơ hội việc làm rộng mở
</div>
<div class='pb-3 ps-5 fs-5'>
- Thủ tục Visa đơn giản, nhanh chóng
</div>
<div class='pb-3 fw-bolder text-success fs-1'>
2. ĐIỀU KIỆN THAM GIA 
</div>
<div class='fw-bolder ps-3 fs-5'>
-   Hệ học tiếng 1 năm:
</div>
<div class='ps-5 fs-5'>
+ Tốt nghiệp THPT trở lên, GPA trên 7.0
</div>
<div class='ps-5 fs-5'>
+ Có chứng chỉ HSK3 trở lên
</div>
<div class='ps-5 fs-5'>
+ Giấy giới thiệu của Giáo viên, Hiệu trưởng, Trường học
</div>
<div class='pb-3 ps-5 fs-5'>
+ Giấy chứng nhận tham gia các hoạt động xã hội, ngoại khóa, nghiên cứu hoặc công việc.
</div>
<div class=' ps-3 fw-bolder fs-5'>
-   Hệ Đại học:
</div>
<div class=' ps-5 fs-5'>
+ Tốt nghiệp THPT trở lên, GPA trên 7.0
</div>
<div class=' ps-5 fs-5'>
+ Không quá 25 tuổi
</div>
<div class='ps-5 fs-5'>
+ Có chứng chỉ HSK4 trở lên, hoặc IELTS từ 5.0 trở lên
</div>
<div class='ps-5 fs-5'>
+ Giấy giới thiệu Giáo viên, Hiệu trưởng, Trường học,…
</div>
<div class='pb-3 ps-5 fs-5'>
+ Giấy chứng nhận tham gia các hoạt động xã hội, ngoại khóa, nghiên cứu hoặc công việc.
</div>
<div class='fw-bolder ps-3 fs-5'>
-  Hệ Thạc sĩ:
</div>
<div class=' ps-5 fs-5'>
+ Tốt nghiệp Đại học, GPA từ 7.0 trở lên
</div>
<div class=' ps-5 fs-5'>
+ Không quá 35 tuổi
</div>
<div class=' ps-5 fs-5'>
+ Có chứng chỉ HSK5 trở lên hoặc IELTS 6.0 trở lên
</div>
<div class='ps-5 fs-5'>
+ 02 Giấy giới thiệu từ Giáo sư, Tiến sĩ
</div>
<div class='ps-5 fs-5'>
+ 01 Bản kế hoạch học tập/nghiên cứu viết 800 – 1500 chữ
</div>
<div class='pb-3 ps-5 fs-5'>
+ Giấy chứng nhận tham gia các hoạt động xã hội, ngoại khóa, nghiên cứu hoặc công việc.
</div>
<div class='pb-3 fw-bolder text-success fs-1'>
3. MỘT SỐ NGÀNH THÔNG DỤNG
</div>
<div class='pb-3 ps-5 fs-5'>
- Ngôn ngữ Trung, Y khoa, Kinh tế, Du lịch, Sản xuất phim,...
</div>
<div class='pb-3 fw-bolder  text-success fs-1'>
4. QUY TRÌNH
</div>
<div class='pb-3 ps-5 fs-5'>
- Bước 1: Tư vấn, lựa chọn và ký hợp đồng
</div>
<div class='pb-3 ps-5 fs-5'>
- Bước 2: Học tiếng và thi chứng chỉ
</div>
<div class='pb-3 ps-5 fs-5'>
- Bước 3: Xử lý hồ sơ
</div>
<div class='pb-3 ps-5 fs-5'>
- Bước 4: Xin Visa
</div>
<div class='pb-3 ps-5 fs-5'>
- Bước 5: Xuất cảnh
</div>
<div class='pb-3 fw-bolder text-success fs-1'>
5. CHI PHÍ
</div>
<div class='pb-3 ps-5 fs-5'>
- Chỉ từ 80 triệu VND
</div>

`;
export const Study_DaiLoan = `
 <img src=${ProgramImages.dailoan1} class='w-100' />
<div class='pb-3 fw-bolder text-success fs-1'>
1. PHÚC LỢI
</div>
<div class='fs-5 ps-5'>
- Nền giáo dục Đài Loan hiện nay xếp hạng thứ 17 thế giới.
</div>
<div class='ps-5 fs-5'>
- Chương trình giáo dục theo chuẩn của Mỹ 100%
</div>
<div class='ps-5 fs-5'>
- Bằng tốt nghiệp tại các trường Đại học ở Đài Loan được trên 200 quốc gia trên thế giới công nhận.
</div>
<div class=' ps-5 fs-5'>
- Cơ hội việc làm rộng mở ngay sau khi tốt nghiệp
</div>
<div class=' ps-5 fs-5'>
- Chuyên ngành đào tạo phong phú với nhiều ngành nghề 
</div>
<div class=' ps-5 fs-5'>
- Mức chi phí vừa phải, hợp với túi tiền của đại đa số người dân Việt Nam
</div>
<div class=' ps-5 fs-5'>
- Yêu cầu đầu vào vô cùng dễ dàng, nhiều chương trình học bổng
</div>
<div class='pb-3 ps-5 fs-5'>
- Thời gian đi học ít, chỉ tầm 2 – 3 tiếng/ngày nên có thể đi làm thêm 20h/tuần
</div>
<div class='pb-3 fw-bolder text-success fs-1'>
2. ĐIỀU KIỆN TUYỂN SINH
</div>
<div class='ps-5 fs-5'>
- Nam, nữ từ 18 - 28 tuổi
</div>
<div class=' ps-5 fs-5'>
- Tốt nghiệp THPT, GDTX, Trung cấp, Cao đẳng, Đại học từ 6.0 trở lên
</div>
<div class='pb-3 ps-5 fs-5'>
- Sức khỏe tốt, không mắc bệnh truyền nhiễm
</div>
<div class='pb-3 fw-bolder  text-success fs-1'>
3. CHI PHÍ
</div>
<div class='pb-3 ps-5 fs-5'>
- Chỉ từ 60 triệu trở lên, tùy theo tỷ giá ngoại tệ và gói dịch vụ
</div>
<div class='pb-3 fw-bolder text-success fs-1'>
4. CÁC CHƯƠNG TRÌNH
</div>
<div class='fw-bolder fs-5  ps-3 '>
4.1. Du học tiếng
</div>
<div class='ps-5 fs-5'>
- Dành cho những bạn học sinh chưa đáp ứng điều kiện tiếng Trung để theo học khóa học chính quy hệ Cao đẳng, Đại học hay Thạc sĩ.
</div>
<div class=' ps-5 fs-5'>
- Thời gian khóa học sẽ kéo dài từ 6 tháng – 01 năm tùy theo năng lực
</div>
<div class='pb-3 ps-5 fs-5'>
- Có chứng chỉ năng lực Hoa ngữ TOCFL cấp độ 1 trở lên hoặc chứng chỉ tiếng Anh TOEIC 350 điểm trở lên tại Việt Nam
</div>
<div class='fw-bolder fs-5 ps-3'>
4.2. Du học Đại học
</div>
<div class='ps-5 fs-5'>
- Có chứng chỉ năng lực Hoa ngữ TOCFL cấp độ 3 trở lên hoặc chứng chỉ tiếng Anh IELTS 5.5 trở lên nếu học bằng tiếng Anh, tùy theo trường và tùy theo ngành
</div>
<div class='pb-3 ps-5 fs-5'>
- Thời gian học 4 năm, ngành nghề phong phú, đa dạng
</div>
<div class='fw-bolder ps-3 fs-5'>
4.3. Du học Thạc sĩ
</div>
<div class=' ps-5 fs-5'>
- Có chứng chỉ năng lực Hoa ngữ TOCFL cấp độ 4 trở lên hoặc chứng chỉ tiếng Anh IELTS 6.0 trở lên nếu học bằng tiếng Anh, tùy theo trường và tùy theo ngành
</div>
<div class='ps-5 fs-5'>
- Thời gian học 2 - 4 năm
</div>
<div class='pb-3 fw-bolder text-success fs-1'>
5. QUY TRÌNH
</div>
<div class='pb-1 ps-5 fs-5'>
- Bước 1: Lắng nghe tư vấn và quyết định tham gia, nộp giấy tờ theo hướng dẫn
</div>
<div class='pb-1 ps-5 fs-5'>
- Bước 2: Học tiếng
</div>
<div class='pb-1 ps-5 fs-5'>
- Bước 3: Xác nhận hồ sơ Việt - Anh hoặc Việt - Trung
</div>
<div class='pb-1 ps-5 fs-5'>
- Bước 4: Xác nhận hồ sơ qua Cục Lãnh sự
</div>
<div class='pb-1 ps-5 fs-5'>
- Bước 5: Xác nhận hồ sơ tại Văn phòng Đài Bắc
</div>
<div class='pb-1 ps-5 fs-5'>
- Bước 6: Nộp hồ sơ xin Visa
</div>
<div class='pb-1 ps-5 fs-5'>
- Bước 7: Lấy kết quả Visa
</div>
<div class='pb-1 ps-5 fs-5'>
- Bước 8: Xuất cảnh
</div>
<div class='pb-3 fw-bolder text-success fs-1'>
6. KỲ NHẬP HỌC
</div>
<div class=' ps-5 fs-5'>
- Kỳ tháng 9: Nộp hồ sơ tháng 1 - tháng 3 cùng năm
</div>
<div class='pb-3 ps-5 fs-5 '>
- Kỳ tháng 2: Nộp hồ sơ tháng 7 - tháng 10 năm trước
</div>
 
`;
export const cam_nang_nhat = `
<div class='fw-semibold fs-1 text-success py-3'>
Cẩm nang Du học Nhật Bản 2024: Ngành nào phù hợp với bạn?
</div>
<div  class='pb-3 fs-5 ps-5'>
Du học Nhật Bản đang trở thành xu hướng với nền giáo dục chất lượng cao, môi trường học tập hiện đại và cơ hội phát triển đa dạng. Năm 2024, các ngành học tại Nhật tiếp tục có sự phát triển mạnh mẽ, đặc biệt là những ngành gắn liền với công nghệ, kinh doanh và văn hóa. Nếu bạn đang cân nhắc du học Nhật, đây là lúc lý tưởng để tìm hiểu những ngành học phù hợp nhất!
</div>
<div class='fw-semibold fs-1 text-success py-3'>
1. Ngành Công nghệ Thông tin (CNTT)
</div>
<div class='pb-3 fs-5 ps-5' >
• Tại sao nên chọn CNTT? Nhật Bản là một trong những quốc gia hàng đầu về công nghệ và kỹ thuật số. Chính phủ Nhật đang đầu tư mạnh vào các ngành công nghệ mũi nhọn như trí tuệ nhân tạo (AI), dữ liệu lớn (Big Data) và an ninh mạng. Học ngành CNTT ở Nhật Bản sẽ mang lại cho bạn cơ hội học tập trong một môi trường tiên tiến, trang thiết bị hiện đại và đội ngũ giảng viên hàng đầu.
</div>
<div class='pb-3 fs-5 ps-5' >
• Chương trình học: Sinh viên sẽ được học về lập trình, phát triển phần mềm, trí tuệ nhân tạo và các kỹ thuật tiên tiến trong an ninh mạng.
</div>
<div class='pb-3 fs-5 ps-5' >
• Cơ hội việc làm: Các công ty Nhật Bản luôn cần nhân sự công nghệ có tay nghề cao. Sinh viên quốc tế tốt nghiệp có thể xin việc tại Nhật với mức lương hấp dẫn và nhiều cơ hội thăng tiến.
</div>
<div class='fw-semibold fs-1 text-success py-3'>
2. Ngành Kinh tế – Quản trị
</div>
<div class='pb-3 fs-5 ps-5' >
• Lý do nên chọn ngành Kinh tế – Quản trị: Nhật Bản là một trong những nền kinh tế lớn nhất thế giới, và các công ty Nhật nổi tiếng với cách quản trị hiệu quả. Ngành này phù hợp với những ai muốn học về kinh tế toàn cầu, quản lý và vận hành doanh nghiệp theo phong cách Nhật Bản.
</div>
<div class='pb-3 fs-5 ps-5' >
• Nội dung đào tạo: Chương trình học tập trung vào các kiến thức về kinh tế, tài chính, quản trị kinh doanh, và quản lý chuỗi cung ứng.
</div>
<div class='pb-3 fs-5 ps-5' >
• Cơ hội nghề nghiệp: Sinh viên có thể làm việc tại các tập đoàn đa quốc gia hoặc các công ty Nhật ở Việt Nam và trên thế giới.
</div>
<div class='fw-semibold fs-1 text-success py-3'>
3. Ngành Ngôn ngữ & Văn hóa Nhật Bản
</div>
<div class='pb-3 fs-5 ps-5' >
• Nếu bạn yêu thích văn hóa và ngôn ngữ Nhật Bản, ngành học này chắc chắn là lựa chọn hoàn hảo. Bạn sẽ được đào sâu về ngôn ngữ, văn hóa, lịch sử và xã hội Nhật Bản.
</div>
<div class='pb-3 fs-5 ps-5' >
• Chương trình học: Các khóa học tiếng Nhật nâng cao, văn học Nhật Bản, văn hóa truyền thống và hiện đại.
</div>
<div class='pb-3 fs-5 ps-5' >
• Cơ hội việc làm: Sinh viên tốt nghiệp có thể trở thành phiên dịch viên, biên dịch viên, giáo viên tiếng Nhật hoặc làm việc trong ngành du lịch, sự kiện và truyền thông.
</div>
<div class='fw-semibold fs-1 text-success py-3'>
4. Các ngành Kỹ thuật và Sáng tạo
</div>
<div class='pb-3 fs-5 ps-5' >
• Nhật Bản là đất nước đi đầu trong các ngành công nghiệp kỹ thuật và sáng tạo, đặc biệt là kỹ thuật ô tô và thiết kế. Sinh viên sẽ được đào tạo chuyên sâu và có cơ hội thực hành tại các tập đoàn lớn như Toyota, Honda, Sony.
</div>
<div class='pb-3 fs-5 ps-5' >
• Nội dung đào tạo: Các môn học về kỹ thuật cơ khí, kỹ thuật điện, thiết kế và sáng tạo.
</div>
<div class='pb-3 fs-5 ps-5' >
• Cơ hội nghề nghiệp: Sinh viên tốt nghiệp có thể làm việc trong ngành ô tô, hàng không, điện tử hoặc trở thành nhà thiết kế sáng tạo.
</div>
<div class='pb-3 fw-bolder fs-5 ps-3' >
Kết luận: Làm sao để chọn ngành phù hợp khi du học Nhật Bản?
</div>
<div class='pb-3 fs-5 ps-5' >
• Chọn ngành học phù hợp không chỉ dựa trên sở thích mà còn cần cân nhắc đến xu hướng việc làm và cơ hội phát triển cá nhân. Nếu bạn yêu thích công nghệ, quản trị hay văn hóa Nhật Bản, Nhật Bản chắc chắn sẽ mang đến cho bạn nền tảng tốt để phát triển sự nghiệp. Để chuẩn bị tốt nhất, hãy tìm hiểu kỹ về từng ngành học và lộ trình phát triển để có thể tận dụng tối đa các cơ hội mà du học Nhật Bản mang lại.
</div>
`;
export const cam_nang_nghe_duc = `
    <div class='d-flex flex-column align-items-center'>
	<img src=${ProgramImages.duc} class='w-100' />
   </div>
<div class='fw-bolder fs-1  text-success py-3'>
Cẩm nang Du học Nghề Đức từ A – Z
</div>
<div class='ps-5  fs-5'>
Du học nghề tại Đức là một trong những lựa chọn phổ biến dành cho sinh viên quốc tế, nhờ chi phí hợp lý, cơ hội việc làm cao và chương trình đào tạo thực tế. Đức cũng được biết đến với hệ thống giáo dục nghề nghiệp song hành (Dual System), kết hợp giữa lý thuyết và thực hành tại doanh nghiệp, giúp sinh viên sẵn sàng cho thị trường lao động ngay sau khi tốt nghiệp. </div>
<div class='fw-bolder fs-1  text-success py-3'>
1. Lý do chọn du học nghề tại Đức </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
• Chi phí thấp và có lương trợ cấp:  </div>
<div class='ps-5  fs-5'>
- Du học nghề tại Đức đặc biệt hấp dẫn vì học viên thường không phải trả học phí và còn được hưởng trợ cấp hàng tháng từ các công ty thực tập. Điều này giúp giảm gánh nặng tài chính cho sinh viên quốc tế. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
• Chất lượng giáo dục nghề cao:  </div>
<div class='ps-5  fs-5'>
- Đức nổi tiếng với các ngành nghề kỹ thuật và công nghệ, và sinh viên được đào tạo bởi các giảng viên chuyên nghiệp kết hợp với việc thực hành ngay tại doanh nghiệp. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
• Cơ hội việc làm rộng mở:  </div>
<div class='ps-5  fs-5'>
- Sau khi tốt nghiệp, sinh viên nghề có cơ hội làm việc ngay tại Đức với các công ty hàng đầu. Chính phủ Đức cũng hỗ trợ sinh viên quốc tế gia hạn visa để tìm việc sau khi học xong. </div>
<div class='fw-bolder fs-1  text-success py-3'>
2. Các ngành nghề phổ biến tại Đức </div>
<div class=' fw-bolder ps-3 fs-5'>
• Điều dưỡng:
</div>
<div class='ps-5 fs-5'>
- Ngành điều dưỡng tại Đức luôn thiếu nhân lực, là lựa chọn hàng đầu với mức lương và cơ hội làm việc lâu dài.
 </div>
<div class='ps-3 fw-bolder fs-5'>
• Kỹ thuật cơ khí và điện tử: 
</div>
<div class='ps-5 fs-5'>
- Đức là cái nôi của ngành kỹ thuật, nơi sinh viên có thể tiếp cận công nghệ tiên tiến, môi trường làm việc chuyên nghiệp. ]
</div>
<div class='ps-3 fw-bolder fs-5'>
• Kỹ thuật cơ khí và điện tử:
 </div>
<div class='ps-5 fs-5'>
- Đức là cái nôi của ngành kỹ thuật, nơi sinh viên có thể tiếp cận công nghệ tiên tiến, môi trường làm việc chuyên nghiệp.
 </div>
<div class='fw-bolder fs-1  text-success py-3'>
3. Điều kiện và hồ sơ cần thiết </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
• Yêu cầu học vấn: </div>
<div class='ps-5 fs-5'>
- Thường tối thiểu là tốt nghiệp trung học phổ thông (THPT). </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
• Trình độ tiếng Đức:  </div>
<div class='ps-5 fs-5'>
- Thông thường từ trình độ B1 đến B2, tùy ngành học và chương trình yêu cầu. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
• Hồ sơ du học nghề:  </div>
<div class='ps-5 fs-5'>
- Hồ sơ gồm giấy tờ học vấn, chứng chỉ tiếng Đức, thư động lực (Motivationsschreiben), sơ yếu lý lịch (Lebenslauf), và đôi khi cần thư mời thực tập từ công ty Đức. </div>
<div class='fw-bolder fs-1  text-success py-3'>
4. Chương trình đào tạo và lộ trình nghề nghiệp </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
• Thời gian học:  </div>
<div class='ps-5 fs-5'>
- Chương trình du học nghề thường kéo dài từ 2-3 năm. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
• Phương pháp đào tạo: </div>
<div class='ps-5 fs-5'>
- Kết hợp giữa học lý thuyết tại trường và thực hành tại doanh nghiệp. Học viên sẽ làm việc từ 3-4 ngày/tuần và học lý thuyết từ 1-2 ngày còn lại. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
• Phương pháp đào tạo:  </div>
<div class='ps-5 fs-5'>
- Kết hợp giữa học lý thuyết tại trường và thực hành tại doanh nghiệp. Học viên sẽ làm việc từ 3-4 ngày/tuần và học lý thuyết từ 1-2 ngày còn lại. </div>
<div class='fw-bolder fs-1  text-success py-3'>
5. Lợi ích và thách thức khi du học nghề tại Đức </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
• Lợi ích:  </div>
<div class='ps-5 fs-5'>
- Tiết kiệm chi phí, có thu nhập trong quá trình học, và cơ hội định cư tại Đức. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
• Thách thức: </div> 
<div class='ps-5 fs-5'>
- Rào cản ngôn ngữ là một khó khăn lớn đối với nhiều sinh viên quốc tế, đồng thời việc thích nghi với văn hóa làm việc nghiêm túc của Đức cũng cần thời gian. </div>
<div class='ps-5 fs-5'>
- Du học nghề tại Đức là một hướng đi tiết kiệm và hiệu quả cho các bạn trẻ. Lựa chọn ngành nghề phù hợp, học hỏi tiếng Đức và nắm rõ thông tin về thị trường lao động sẽ giúp bạn có một hành trình học tập và làm việc thành công tại quốc gia châu Âu này. </div>

`;

export const cam_nang_uc = `
 
   <div class='d-flex flex-column align-items-center'>
	<img src=${ProgramImages.uc} class='w-100' />
   </div>
<div class='fw-bolder fs-1  text-success py-3'>
Những điều thú vị khi sinh sống và học tập tại Úc
</div>
<div class='ps-5 fs-5'>
Úc là điểm đến lý tưởng cho sinh viên quốc tế nhờ vào môi trường sống năng động, nền giáo dục chất lượng cao, và cơ hội phát triển cá nhân. Khi sinh sống và học tập tại đây, bạn sẽ được trải nghiệm nhiều điều thú vị từ văn hóa, khí hậu cho đến phong cách sống. </div>
<div class='fw-bolder fs-1  text-success py-3'>
1. Khám phá văn hóa đa dạng </div>
<div class='ps-5 fs-5'>
- Úc là nơi giao thoa của nhiều nền văn hóa, thu hút người dân từ khắp nơi trên thế giới. Bạn có thể dễ dàng bắt gặp những nét văn hóa từ châu Á, châu Âu, và thổ dân Úc bản địa trong đời sống hàng ngày. </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
• Phong tục và lễ hội đặc sắc: </div>
<div class='ps-5 fs-5'>
- Các lễ hội như Australia Day, Sydney Festival, Melbourne International Comedy Festival tạo không khí sôi động quanh năm. </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
• Ẩm thực đa dạng: </div>
<div class='ps-5 fs-5'>
- Bạn có thể tìm thấy hàng trăm món ăn từ các nền văn hóa khác nhau, từ nhà hàng châu Á đến những món ăn đặc trưng của người Úc như bánh Lamington hay thịt Kangaroo. </div>
<div class='fw-bolder fs-1  text-success py-3'>
2. Khí hậu và cảnh đẹp tự nhiên </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
• Khí hậu dễ chịu: </div>
<div class='ps-5  fs-5'>
- Úc có bốn mùa rõ rệt nhưng lại tương đối ấm áp quanh năm. Các thành phố lớn như Sydney, Melbourne, Brisbane mang lại trải nghiệm sống tiện nghi với khí hậu thoải mái.</div>
<div class='py-2 ps-3 fw-bolder fs-5'>
• Cảnh đẹp nổi tiếng:  </div> 
<div class='ps-5  fs-5'>
- Những điểm đến như Sydney Opera House, Great Barrier Reef, và Blue Mountains là nơi lý tưởng cho các hoạt động ngoài trời.</div>
<div class='fw-bolder fs-1  text-success py-3'>
3. Hệ thống giáo dục hiện đại </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
• Các trường đại học hàng đầu: </div>
<div class='ps-5  fs-5'>
- Úc có nhiều trường đại học thuộc top thế giới như Đại học Sydney, Đại học Melbourne, Đại học Quốc gia Úc (ANU).</div>
<div class='py-2 ps-3 fw-bolder fs-5'>
• Phương pháp học đa dạng: </div>
<div class='ps-5  fs-5'>
- Học sinh có thể tự do lựa chọn giữa học online, học tại lớp và các hoạt động nghiên cứu thực tế. </div>
<div class='fw-bolder fs-1  text-success py-3'>
4. Cân bằng giữa học tập và làm thêm </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
• Chính sách làm việc bán thời gian: </div>
<div class='ps-5  fs-5'>
- Úc cho phép sinh viên quốc tế làm việc 20 giờ/tuần trong thời gian học và toàn thời gian vào kỳ nghỉ. </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
• Trải nghiệm học tập và làm thêm: </div>
<div class='ps-5  fs-5'>
- Sinh viên có thể tham gia các công việc trong ngành dịch vụ, bán lẻ hoặc thậm chí thực tập tại các công ty lớn, vừa học vừa làm để tích lũy kinh nghiệm. </div>
<div class='ps-5  fs-5'>
- Học tập và sinh sống tại Úc không chỉ giúp bạn có được nền giáo dục chất lượng mà còn mang lại cơ hội khám phá và phát triển bản thân. Với môi trường thân thiện và nhiều tiềm năng, Úc chắc chắn là một trong những lựa chọn hàng đầu cho các bạn trẻ muốn vươn xa. </div>

`;

export const cam_nang_hanquoc = `
 
   <div class='d-flex flex-column align-items-center'>
	<img src=${ProgramImages.han} class='w-100' />
   </div>
<div class='fw-bolder fs-1  text-success py-3'>
8 lý do bạn nên du học Hàn Quốc
</div>
<div class='ps-5 fs-5'  >
• Hàn Quốc là điểm đến hấp dẫn cho sinh viên quốc tế với nền giáo dục tiên tiến, văn hóa độc đáo và sự phát triển nhanh chóng của các ngành công nghiệp. Dưới đây là 8 lý do hàng đầu mà bạn nên cân nhắc du học Hàn Quốc.
</div>
<div class='fw-bolder fs-1  text-success py-3'>
1. Chất lượng giáo dục cao và chi phí hợp lý </div>
<div class='ps-5 fs-5'  >
• Hệ thống giáo dục tiên tiến: Hàn Quốc có nhiều trường đại học nằm trong top thế giới, với chương trình học đa dạng và được cập nhật thường xuyên. </div>
<div class='ps-5 fs-5'>
• Chi phí sinh hoạt hợp lý: So với các nước phát triển, chi phí sinh hoạt và học phí tại Hàn Quốc dễ chịu hơn, phù hợp với nhiều sinh viên quốc tế. </div>
<div class='fw-bolder fs-1  text-success py-3'>
2. Cơ hội học bổng phong phú </div>
<div class='ps-5 fs-5'>
• Chính phủ Hàn Quốc và các trường đại học cung cấp nhiều học bổng hỗ trợ học phí, sinh hoạt phí và thậm chí hỗ trợ toàn phần cho sinh viên quốc tế. </div>
<div class='fw-bolder fs-1  text-success py-3'>
3. Ngành công nghiệp giải trí và công nghệ phát triển mạnh mẽ </div>
<div class='ps-5 fs-5'>
• Hàn Quốc nổi tiếng với K-pop, phim truyền hình và công nghệ tiên tiến. Bạn sẽ có cơ hội học tập và tham gia vào ngành công nghiệp sáng tạo và công nghệ cao. </div>
<div class='fw-bolder fs-1  text-success py-3'>
4. Văn hóa đặc sắc và thân thiện với sinh viên quốc tế </div>
<div class='ps-5 fs-5'>
• Cộng đồng đa dạng: Các trường học và cộng đồng quốc tế tại Hàn Quốc luôn thân thiện và hỗ trợ sinh viên nước ngoài hòa nhập. </div>
<div class='ps-5 fs-5'>
• Hoạt động ngoại khóa đa dạng: Các trường đại học tổ chức nhiều sự kiện, câu lạc bộ và hoạt động giúp sinh viên gắn kết và giao lưu. </div>
<div class='fw-bolder fs-1  text-success py-3'>
5. Hệ thống cơ sở vật chất hiện đại </div>
<div class='ps-5  fs-5'>
• Các trường đại học ở Hàn Quốc có cơ sở vật chất hiện đại, phục vụ tốt cho việc học tập, nghiên cứu và sinh hoạt của sinh viên. </div>
<div class='fw-bolder fs-1  text-success py-3'>
6. Visa du học dễ dàng </div>
<div class='ps-5 fs-5'>
• Chính phủ Hàn Quốc đơn giản hóa thủ tục visa cho sinh viên quốc tế, tạo điều kiện thuận lợi cho các bạn trẻ từ khắp nơi trên thế giới đến học tập. </div>
<div class='fw-bolder fs-1  text-success py-3'>
7. Cộng đồng du học sinh sôi động </div>
<div class='ps-5 fs-5'>
• Hàn Quốc có cộng đồng du học sinh lớn từ nhiều quốc gia, là nơi bạn có thể tìm kiếm sự hỗ trợ và kết bạn quốc tế. </div>
<div class='fw-bolder fs-1  text-success py-3'>
8. Cơ hội việc làm sau khi tốt nghiệp </div>
<div class='ps-5 fs-5'>
• Với nền kinh tế phát triển, sinh viên quốc tế sau khi tốt nghiệp có thể dễ dàng tìm việc làm tại Hàn Quốc, đặc biệt trong các ngành công nghệ, giải trí và giáo dục.
</div>
<div class='fw-bolder fs-1  text-success py-3'>
9. Kết luận  </div>
<div class='ps-5 fs-5'>
• Du học Hàn Quốc là một hành trình đáng trải nghiệm với môi trường học tập chất lượng và văn hóa độc đáo. Hãy cân nhắc các cơ hội học tập tại đây để mở rộng tầm nhìn và phát triển tương lai của bạn.
</div>

`;
export const cam_nang_mientrung = `
<div class='fw-semibold py-3'>
Top 10 bãi biển đẹp nhất miền Trung
</div>
<div class='pb-3' >
Miền Trung Việt Nam là điểm đến lý tưởng với những bãi biển hoang sơ, cát trắng và nước biển trong xanh. Dưới đây là 10 bãi biển đẹp nhất miền Trung mà bạn nên ghé thăm:
</div>
<div class='pb-3 fw-bolder' >
1. Bãi biển Mỹ Khê – Đà Nẵng
</div>
<div class='pb-3' >
Mỹ Khê luôn nằm trong danh sách những bãi biển đẹp nhất hành tinh do Forbes bình chọn. Với làn nước trong vắt, bãi cát mịn và hệ thống tiện ích hiện đại, đây là điểm đến lý tưởng cho cả gia đình và những ai yêu thích thể thao biển.
</div>
<div class='pb-3 fw-bolder'>
2. Bãi biển Non Nước – Đà Nẵng
</div>
<div class='pb-3' >
Không xa Mỹ Khê, Non Nước sở hữu không gian yên tĩnh, phong cảnh hữu tình và là nơi lý tưởng để thư giãn. Bạn có thể trải nghiệm các dịch vụ du thuyền và lướt ván ở đây.
</div>
<div class='pb-3 fw-bolder'>
3. Cù Lao Chàm – Quảng Nam
</div>
<div class='pb-3' >
Với hệ sinh thái biển đa dạng và phong cảnh tuyệt đẹp, Cù Lao Chàm là một điểm đến lý tưởng cho những ai thích khám phá thiên nhiên. Bạn có thể lặn ngắm san hô và thưởng thức hải sản tươi ngon.
</div>
<div class='pb-3 fw-bolder'>
4. Bãi biển An Bàng – Hội An
</div>
<div class='pb-3' >
An Bàng giữ được vẻ đẹp hoang sơ với bãi cát trắng mịn và biển xanh mát. Các quán cafe và nhà hàng ven biển ở đây cũng có không gian thư giãn lý tưởng.
</div>
<div class='pb-3 fw-bolder'>
5. Bãi Xép – Phú Yên
</div>
<div class='pb-3' >
Bãi Xép được chọn làm bối cảnh trong bộ phim "Tôi thấy hoa vàng trên cỏ xanh" với cảnh sắc thiên nhiên hùng vĩ. Đứng trên đồi cát, bạn có thể ngắm nhìn toàn cảnh biển xanh biếc trải dài bất tận.
</div>
<div class='pb-3 fw-bolder'>
6. Bãi biển Lăng Cô – Huế
</div>
<div class='pb-3' >
Lăng Cô là một trong những bãi biển đẹp nhất Việt Nam, nơi có cát trắng, nước biển trong và hệ sinh thái phong phú. Bãi biển này nằm gần nhiều địa điểm du lịch nổi tiếng như Vườn quốc gia Bạch Mã.
</div>
<div class='pb-3 fw-bolder'>
7. Bãi Dài – Khánh Hòa
</div>
<div class='pb-3' >
Bãi Dài mang vẻ đẹp yên bình với bờ cát dài, nước biển xanh và không gian hoang sơ. Đây cũng là điểm đến lý tưởng để cắm trại và ngắm hoàng hôn.
</div>
<div class='pb-3 fw-bolder'>
8. Bãi biển Nha Trang – Khánh Hòa
</div>
<div class='pb-3' >
Nha Trang là trung tâm du lịch biển lớn của miền Trung với các bãi biển nổi tiếng. Bạn có thể tham gia các hoạt động như lặn biển, chèo thuyền kayak và khám phá các đảo quanh đây.
</div>
<div class='pb-3 fw-bolder'>
9. Bãi biển Thiên Cầm – Hà Tĩnh
</div>
<div class='pb-3' >
Thiên Cầm mang vẻ đẹp dịu dàng và yên bình, ít bị tác động bởi du lịch nên vẫn giữ được nét hoang sơ. Đây là nơi lý tưởng để nghỉ dưỡng và tận hưởng không khí trong lành.
</div>
<div class='pb-3 fw-bolder'>
10. Bãi biển Cửa Đại – Quảng Nam
</div>
<div class='pb-3' >
Cửa Đại nổi bật với cảnh biển trong xanh và các dải cát trắng dài. Đây là nơi thích hợp để dạo chơi, ngắm hoàng hôn và thưởng thức các món hải sản tươi ngon.
</div>
`;
export const cam_nang_phuquoc = `
<div class='fw-semibold py-3'>
Review Du lịch Phú Quốc
</div>
<div class='pb-3' >
Phú Quốc, đảo ngọc của Việt Nam, không chỉ là thiên đường nhiệt đới với biển xanh, cát trắng mà còn mang đến vô vàn trải nghiệm thú vị từ thiên nhiên, văn hóa đến ẩm thực phong phú. Dưới đây là hướng dẫn chi tiết giúp bạn có một kỳ nghỉ trọn vẹn và ý nghĩa khi đến với Phú Quốc
</div>
<div class='pb-3 fw-bolder'>
1. Thời điểm lý tưởng để đến Phú Quốc
</div>
<div class='pb-3 '>
Phú Quốc có khí hậu nhiệt đới ẩm với hai mùa rõ rệt:
</div>
<div class='pb-3 '>
Mùa khô (từ tháng 11 đến tháng 4): Đây là mùa cao điểm du lịch Phú Quốc vì thời tiết nắng ráo, biển trong xanh, ít mưa, thuận lợi cho các hoạt động ngoài trời.
</div>
<div class='pb-3 '>
Mùa mưa (từ tháng 5 đến tháng 10): Phú Quốc vào mùa mưa sẽ có những cơn mưa lớn kéo dài, đôi khi biển động. Tuy nhiên, nếu thích sự yên tĩnh, tránh đông đúc và muốn tiết kiệm chi phí, bạn có thể lựa chọn thời điểm này để đến đảo.
</div>
<div class='pb-3 fw-bolder'>
2. Các điểm tham quan nổi bật tại Phú Quốc
</div>
<div class='pb-3 '>
Phú Quốc sở hữu nhiều điểm đến đẹp và hấp dẫn, từ những bãi biển hoang sơ, làng chài truyền thống đến các khu vui chơi hiện đại:
</div>
<div class='pb-3 '>
• Bãi Sao: Được mệnh danh là bãi biển đẹp nhất Phú Quốc với cát trắng mịn màng và làn nước trong xanh. Đây là nơi lý tưởng để tắm biển, chụp ảnh sống ảo, và thư giãn. Bạn có thể thưởng thức nước dừa tươi và thuê ghế ngồi dọc bãi biển.
</div>
<div class='pb-3 '>
• Bãi Khem: Cách thị trấn Dương Đông khoảng 25km, Bãi Khem là nơi lý tưởng để tận hưởng vẻ đẹp hoang sơ và yên bình. Nước biển ở đây trong veo và bãi cát trắng, thích hợp cho các hoạt động tắm biển và lặn ngắm san hô.
</div>
<div class='pb-3 '>
• VinWonders Phú Quốc: Là khu vui chơi giải trí lớn nhất Việt Nam với đa dạng trò chơi từ công viên nước, khu vui chơi trong nhà đến rạp chiếu phim 4D. VinWonders thích hợp cho các gia đình, nhóm bạn trẻ muốn tận hưởng ngày vui chơi sôi động và nhiều hoạt động giải trí phong phú.
</div>
<div class='pb-3 '>
• Vinpearl Safari: Là vườn thú bán hoang dã đầu tiên tại Việt Nam với hàng trăm loài động vật từ khắp nơi trên thế giới. Vinpearl Safari là nơi lý tưởng để khám phá và trải nghiệm gần gũi với thiên nhiên.
</div>
<div class='pb-3 '>
• Làng chài Hàm Ninh: Đây là làng chài truyền thống lâu đời tại Phú Quốc, nổi tiếng với vẻ đẹp giản dị và bình yên. Tại đây, bạn có thể thưởng thức hải sản tươi ngon và ngắm cảnh hoàng hôn tuyệt đẹp trên biển.
</div>
<div class='pb-3 '>
• Hòn Thơm: Bạn có thể đi cáp treo vượt biển từ Phú Quốc đến Hòn Thơm để khám phá thiên đường san hô, lặn ngắm cá và san hô tự nhiên. Hòn Thơm còn có công viên nước và nhiều trò chơi mạo hiểm hấp dẫn.
</div>
<div class='pb-3 fw-bolder'>
3. Trải nghiệm ẩm thực độc đáo
</div>
<div class='pb-3 '>
Phú Quốc là thiên đường ẩm thực với nhiều món hải sản và đặc sản độc đáo. Dưới đây là những món ăn bạn không nên bỏ qua:
</div>
<div class='pb-3 '>
• Nhum biển: Nhum thường được nướng mỡ hành hoặc hấp, có hương vị béo ngậy và mùi thơm đặc trưng. Đây là món ăn nhiều dinh dưỡng, có thể ăn sống kèm mù tạt và chanh.
</div>
<div class='pb-3 '>
• Ghẹ Hàm Ninh: Ghẹ ở làng chài Hàm Ninh nhỏ nhưng thịt ngọt và chắc. Ghẹ có thể hấp, rang muối, hoặc nướng tùy theo sở thích, kèm theo nước chấm muối tiêu chanh rất ngon.
</div>
<div class='pb-3 '>
• Gỏi cá trích: Đây là món ăn đặc trưng của Phú Quốc, cá trích tươi được làm sạch, trộn cùng hành, rau sống, dừa nạo và đậu phộng rang. Khi ăn, bạn cuốn gỏi với bánh tráng và chấm nước mắm Phú Quốc đậm đà.
</div>
<div class='pb-3 '>
• Bún kèn: Là món ăn sáng nổi tiếng tại Phú Quốc, bún kèn được chế biến từ cá nhồng hoặc cá ngân, nấu với nước cốt dừa, ăn kèm rau sống và giá đỗ. Hương vị bún kèn thơm ngậy, cay nồng, rất đáng thử.
</div>
<div class='pb-3 fw-bolder'>
4. Địa điểm lưu trú tại Phú Quốc
</div>
<div class='pb-3 '>
Phú Quốc có nhiều loại hình lưu trú từ khách sạn, resort cao cấp đến các homestay bình dân. Dưới đây là một số gợi ý:
</div>
<div class='pb-3 '>
• Resort cao cấp: JW Marriott Phú Quốc, InterContinental Phú Quốc Long Beach Resort.
</div>
<div class='pb-3 '>
• Khách sạn tầm trung: Seashells Phú Quốc Hotel & Spa, Salinda Resort.
</div>
<div class='pb-3 '>
• Homestay: D'Hotel, 9Station Hostel.
</div>
<div class='pb-3 '>
Kết luận
</div>
<div class='pb-3 '>
Phú Quốc là thiên đường nghỉ dưỡng, thích hợp cho kỳ nghỉ ngắn hoặc dài. Với các bãi biển tuyệt đẹp, nhiều điểm vui chơi và nền ẩm thực phong phú, Phú Quốc chắc chắn sẽ mang đến trải nghiệm tuyệt vời cho bất kỳ ai ghé thăm.
</div>
`;
export const cam_nang_hoian = `
<div class='fw-semibold py-3'>
5 món ăn bạn không thể bỏ qua khi đến Hội An
</div>
<div class='pb-3 '>
Hội An không chỉ nổi tiếng với phố cổ và cảnh quan thơ mộng mà còn sở hữu nền ẩm thực phong phú, đặc trưng với những món ăn truyền thống hấp dẫn. Dưới đây là 5 món ăn đặc trưng bạn nhất định phải thử khi đến Hội An.
</div>
<div class='pb-3  fw-bolder'>
1. Cao lầu
</div>
<div class='pb-3 '>
Cao lầu là món mì truyền thống chỉ có tại Hội An, được làm từ sợi mì đặc biệt dai và vàng óng nhờ sử dụng nước giếng Bá Lễ và tro từ cây tràm. Mì ăn kèm với thịt heo, rau sống, bánh tráng và nước sốt đậm đà. Cao lầu mang hương vị độc đáo, vừa giòn của bánh tráng, vừa ngọt của thịt heo và mát lạnh từ rau sống.
</div>
<div class='pb-3  fw-bolder'>
2. Bánh mì Phượng
</div>
<div class='pb-3 '>
Bánh mì Phượng nổi tiếng quốc tế và đã từng được nhiều tạp chí ẩm thực khen ngợi. Bánh mì Hội An đặc biệt nhờ lớp vỏ giòn tan, bên trong là nhân pate, thịt nguội, chả, dưa leo, và rau thơm, tạo nên hương vị thơm ngon, khó quên. Bạn có thể tìm thấy bánh mì Phượng tại nhiều cửa hàng nhỏ, hoặc thử bánh mì gánh của các cô bán hàng rong quanh phố cổ.
</div>
<div class='pb-3  fw-bolder'>
3. Bánh bao – bánh vạc
</div>
<div class='pb-3 '>
Bánh bao và bánh vạc là hai loại bánh làm từ bột gạo mềm mịn, hấp dẫn và độc đáo. Bánh bao có nhân tôm, thịt, hành, bánh vạc được làm từ tôm giã nhuyễn với hành tây và tiêu. Hai món bánh này thường được ăn kèm với nước mắm pha chua ngọt, mang đến hương vị dân dã mà đậm đà.
</div>
<div class='pb-3  fw-bolder'>
4. Cơm gà Hội An
</div>
<div class='pb-3 '>
Cơm gà Hội An là món ăn đơn giản nhưng có hương vị đậm đà và hấp dẫn. Gà được xé nhỏ, trộn với hành tây, rau răm và các loại gia vị, ăn kèm với cơm vàng ươm và nước mắm chua ngọt. Đây là món ăn phổ biến, được phục vụ ở hầu hết các quán ăn, nhà hàng tại Hội An, mang lại trải nghiệm ẩm thực đặc trưng của phố cổ.
</div>
<div class='pb-3  fw-bolder'>
5. Hoành thánh
</div>
<div class='pb-3 '>
Hoành thánh Hội An có nhiều loại, từ hoành thánh chiên, hấp cho đến hoành thánh nước, nhưng nổi tiếng nhất là hoành thánh chiên với nhân tôm thịt, vỏ ngoài giòn rụm. Hoành thánh thường được ăn kèm với nước chấm ngọt chua, thích hợp làm món ăn vặt hoặc khai vị.
</div>
<div class='pb-3 '>
Kết luận
</div>
<div class='pb-3 '>
Đến Hội An, bạn không chỉ được chiêm ngưỡng vẻ đẹp cổ kính mà còn có cơ hội khám phá nền ẩm thực độc đáo. Những món ăn đặc trưng tại đây không chỉ là một phần của trải nghiệm du lịch mà còn là nét văn hóa đặc trưng, giúp bạn hiểu thêm về con người và cuộc sống nơi đây.
</div>
`;
export const tour_sapa = `
<div class=' fs-1' >
Tour Sapa
</div>
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.sapa1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.sapa2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.sapa3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.sapa1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.sapa2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.sapa3} class='rounded-3' width='49%' height='220' />
</div>
</div>
<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5 ' >
• Biển mây và phong cảnh hùng vĩ tại Tà Xùa: Từ đỉnh Chiêu Lầu Thi đến sống lưng khủng long Háng Đồng, du khách có cơ hội trải nghiệm cảnh sắc mây núi đan xen, tạo nên không gian thiên nhiên vừa hoang sơ vừa trữ tình.
</div>
 <div class='ps-5 fs-5 '>
• Cây cô đơn giữa trời Tà Xùa: Hình ảnh cây đơn độc giữa khung cảnh rộng lớn và mây phủ nhẹ nhàng là một biểu tượng đặc trưng và cũng là điểm đến không thể bỏ qua.
</div>
<div class='ps-5 fs-5 '>
• Hồ Suối Chiếu trữ tình: Chuyến du thuyền trên mặt hồ xanh biếc cùng khung cảnh non nước xinh đẹp và những ngôi nhà sàn của người dân tộc tạo nên một bức tranh thủy mặc ấn tượng.
</div>
<div class='ps-5 fs-5 '>
• Trải nghiệm tắm khoáng nóng Ban Mai retreat: Ngâm mình trong suối khoáng nóng giữa núi rừng là trải nghiệm thư giãn giúp du khách phục hồi sức khỏe và tận hưởng không gian thiên nhiên yên bình.
</div>
</div>
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
SAPA
</div>
</div>


<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Fansipan
</div>
<div class='pb-3 fs-5' >
Đỉnh núi cao nhất Đông Dương, Fansipan là "Nóc nhà Đông Dương," mang đến cho du khách cơ hội chinh phục đỉnh cao và chiêm ngưỡng cảnh sắc núi rừng hùng vĩ từ trên cao.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa7} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Đèo Ô Quy Hồ 
</div>
<div class='pb-3 fs-5' >
Một trong "tứ đại đỉnh đèo" của Việt Nam, nổi tiếng với khung cảnh tráng lệ của núi rừng Tây Bắc, đặc biệt đẹp vào lúc hoàng hôn và bình minh.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa11} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Bản Cát Cát
</div>
<div class='pb-3 fs-5' >
Làng cổ của người H'Mông, với các nghề thủ công truyền thống, những ngôi nhà mộc mạc và cánh đồng ruộng bậc thang thơ mộng, là điểm đến lý tưởng để khám phá văn hóa vùng cao.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa15} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Bản Y Tý
</div>
<div class='pb-3 fs-5' >
Địa điểm nổi tiếng với cảnh biển mây bồng bềnh và ruộng bậc thang đẹp mắt, đặc biệt là vào mùa lúa chín, thu hút du khách thích khám phá và chụp ảnh.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa19} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Bản Lao Chải - Tả Van
</div>
<div class='pb-3 fs-5' >
Hai bản làng của người H'Mông và người Dáy, nổi bật với vẻ đẹp tự nhiên của những thửa ruộng bậc thang và văn hóa truyền thống đậm chất núi rừng.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa23} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Bản Mường Hoa
</div>
<div class='pb-3 fs-5' >
Thung lũng nổi tiếng với những bãi đá cổ, khắc họa các hình vẽ bí ẩn cùng với đồng ruộng bậc thang, mang đến phong cảnh thơ mộng cho du khách.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa27} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Núi Hàm Rồng
</div>
<div class='pb-3 fs-5' >
Khu du lịch có vị trí đắc địa với vườn hoa đầy sắc màu và các khu vực ngắm nhìn toàn cảnh Sapa từ trên cao, nơi du khách có thể tận hưởng không khí trong lành và phong cảnh hữu tình.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa31} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
8. Thác Tình Yêu
</div>
<div class='pb-3 fs-5' >
Thác nước đẹp và nên thơ, nằm giữa rừng cây xanh mát, là nơi lý tưởng cho các cặp đôi hoặc những người yêu thích cảnh thiên nhiên yên bình.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa32} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa33} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa34} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa35} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa32} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa33} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa34} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa35} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
9. Chợ Bắc Hà
</div>
<div class='pb-3 fs-5' >
Phiên chợ vùng cao lớn nhất ở Lào Cai, nơi bày bán các sản vật đặc trưng và là nơi giao lưu văn hóa độc đáo của các dân tộc thiểu số như H'Mông, Dao, Tày.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa36} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa37} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa38} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa39} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa36} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa37} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa38} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa39} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
`;
export const tour_hoangsuphi = `
 
<div class=' fs-1 ' >
Tour Hoàng Su Phì
</div>
 
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.suphi1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.suphi2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.suphi3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
 
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.suphi1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.suphi2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.suphi3} class='rounded-3' width='49%' height='220' />
</div>
</div>
<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5 ' >
• Chinh phục đỉnh Chiêu Lầu Thi – Trải nghiệm săn mây độc đáo, đứng trên đỉnh cao 2402m ngắm nhìn núi non và thác mây tuyệt đẹp.
</div>
 <div class='ps-5 fs-5 '>
• Khám phá bản Nậm Hồng (Thông Nguyên) – Chiêm ngưỡng những thửa ruộng bậc thang danh thắng quốc gia và tìm hiểu văn hóa đặc sắc của người Dao đỏ.
</div>
<div class='ps-5 fs-5 '>
• Tham quan Thảo nguyên Suôi Thầu hoặc Bản Phùng – Khung cảnh hùng vĩ, hoang sơ, với những cánh đồng ruộng bậc thang tuyệt đẹp, đậm nét thơ mộng vùng cao.
</div>
<div class='ps-5 fs-5 '>
• Trải nghiệm chợ Bắc Hà – Hòa mình vào văn hóa đặc trưng của chợ phiên Tây Bắc, giao lưu với người dân tộc và khám phá ẩm thực địa phương.
</div>
<div class='ps-5 fs-5 '>
• Dinh Hoàng A Tưởng – Thăm quan công trình kiến trúc lịch sử, nơi lưu giữ giá trị văn hóa độc đáo của người H'Mông từ thời Pháp thuộc.
</div>
</div>
 
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Hoàng Su Phì
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Bản Nậm Hồng
</div>
<div class='pb-3 fs-5' >
Làng của người Dao đỏ nổi tiếng với ruộng bậc thang trải dài trên sườn đồi và chè Shan tuyết cổ thụ, tạo nên một bức tranh thiên nhiên xanh mướt và bình dị.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.suphi4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.suphi4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.suphi5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.suphi6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.suphi7} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Chiêu Lầu Thi
</div>
<div class='pb-3 fs-5' >
Đỉnh núi cao 2.402m, nơi lý tưởng để săn mây, chiêm ngưỡng những dòng mây trắng mềm mại vờn quanh núi, mang đến trải nghiệm ngoạn mục và đáng nhớ.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.suphi8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.suphi8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.suphi9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.suphi10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.suphi11} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Bản Phùng
</div>
<div class='pb-3 fs-5' >
Trái tim của Hoàng Su Phì với cảnh quan ruộng bậc thang tầng tầng lớp lớp, ôm ấp mái nhà dân tộc trong làn mây sớm, tạo nên vẻ đẹp thơ mộng không nơi nào sánh được.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.suphi12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.suphi12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.suphi13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.suphi14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.suphi15} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Thảo nguyên Suôi Thầu
</div>
<div class='pb-3 fs-5' >
Địa điểm mới nổi mang vẻ đẹp hoang sơ tựa trời Âu, với thảo nguyên rộng lớn xanh mát, là điểm đến hấp dẫn cho những người yêu thiên nhiên.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.suphi16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.suphi16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.suphi17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.suphi18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.suphi19} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Chợ Bắc Hà
</div>
<div class='pb-3 fs-5' >
Phiên chợ nổi tiếng và sôi động nhất vùng Tây Bắc, nơi hội tụ đa dạng các mặt hàng thủ công, sản vật và món ngon dân dã, giúp du khách hòa mình vào đời sống và văn hóa dân tộc.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.suphi20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.suphi20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.suphi21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.suphi22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.suphi23} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Dinh Hoàng A Tưởng
</div>
<div class='pb-3 fs-5' >
Công trình kiến trúc độc đáo từ thời Pháp thuộc, nơi ở của vua H'Mông, là một biểu tượng văn hóa, lịch sử quý báu của vùng Bắc Hà.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.suphi24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.suphi24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.suphi25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.suphi26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.suphi27} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
`;
export const tour_taxua = `
 
<div class=' fs-1 ' >
Tour Tà Xùa
</div>
 
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.taxua1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.taxua2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.taxua3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.taxua1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.taxua2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.taxua3} class='rounded-3' width='49%' height='220' />
</div>
</div>
<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5 ' >
• Đồi chè Thanh Sơn (Phú Thọ) - Đồi chè Thanh Sơn xanh mướt trải dài tạo nên không gian trong lành, yên bình, mang đến cho du khách những giây phút thư giãn giữa thiên nhiên mát dịu.
</div>
 <div class='ps-5 fs-5 '>
• Cây cô đơn (Tà Xùa) - Cây cô đơn đứng lẻ loi giữa vùng núi mây Tà Xùa là điểm dừng chân lý tưởng, nổi bật với vẻ đẹp lãng mạn và là nơi cho ra những bức ảnh độc đáo.
</div>
<div class='ps-5 fs-5 '>
• Mỏm cá heo - Với hình dáng nhô ra độc đáo như cá heo, mỏm đá này là điểm check-in lý tưởng, giúp du khách có cảm giác như đang lơ lửng giữa biển mây.
</div>
<div class='ps-5 fs-5 '>
• Đỉnh Gió - Đỉnh Gió là điểm săn mây và ngắm hoàng hôn đẹp nhất tại Tà Xùa, nơi du khách được chiêm ngưỡng cảnh sắc mây núi hùng vĩ trong ánh chiều tà.

</div>
<div class='ps-5 fs-5 '>
• Sống lưng khủng long (Háng Đồng) - Nổi bật với con đường đèo cheo leo và biển mây trải dài, sống lưng khủng long tại Háng Đồng là điểm đến không thể bỏ lỡ trong hành trình khám phá Tà Xùa.

</div>
</div>
 
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Tà Xùa
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Đồi chè Thanh Sơn
</div>
<div class='pb-3 fs-5' >
Đồi chè xanh mướt tại Thanh Sơn là điểm dừng chân mang lại cảm giác trong lành, giúp du khách thư giãn giữa phong cảnh yên bình của đồi chè trải dài.
</div>

<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.taxua4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.taxua4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.taxua5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.taxua6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.taxua7} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Cây cô đơn
</div>
<div class='pb-3 fs-5' >
"Cây cô đơn" nổi tiếng đứng lẻ loi giữa vùng mây núi Tà Xùa, là điểm đến hấp dẫn cho du khách muốn khám phá vẻ đẹp lãng mạn và chụp ảnh độc đáo.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.taxua8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.taxua8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.taxua9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.taxua10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.taxua11} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Mỏm cá heo
</div>
<div class='pb-3 fs-5' >
Mỏm đá hình cá heo nhô ra giữa trời tạo nên một điểm check-in cực chất, thu hút giới trẻ đến trải nghiệm cảm giác lơ lửng giữa biển mây huyền ảo.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.taxua12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.taxua12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.taxua13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.taxua14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.taxua15} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Đỉnh Gió
</div>
<div class='pb-3 fs-5' >
Điểm săn mây lý tưởng, ngắm hoàng hôn đẹp nhất tại Tà Xùa. Từ đây, du khách có thể thấy toàn cảnh mây núi hùng vĩ khi trời chiều buông.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.taxua16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.taxua16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.taxua17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.taxua18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.taxua19} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Sống lưng khủng long (Háng Đồng)
</div>
<div class='pb-3 fs-5' >
Với đường đèo hùng vĩ và biển mây ngút ngàn, sống lưng khủng long tại Háng Đồng là điểm nhấn đặc biệt trong hành trình khám phá Tà Xùa.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.taxua20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.taxua20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.taxua21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.taxua22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.taxua23} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Hồ Suối Chiếu
</div>
<div class='pb-3 fs-5' >
Hồ nước xanh biếc, nơi du khách được ngắm cảnh và trải nghiệm du thuyền lòng hồ. Với vẻ đẹp yên bình, đây là điểm đến lý tưởng để thư giãn giữa thiên nhiên.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.taxua24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.taxua24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.taxua25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.taxua26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.taxua27} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
`;
export const tour_mucangchai = `
 
<div class=' fs-1 ' >
Tour Mù Cang Chải
</div>
 
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.cangchai1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.cangchai2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.cangchai3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.cangchai1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.cangchai2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.cangchai3} class='rounded-3' width='49%' height='220' />
</div>
</div>
<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5 ' >
• Chinh phục đèo Khau Phạ - Trải nghiệm hành trình đầy kịch tính qua đèo Khau Phạ, một trong "tứ đại đèo" của miền Bắc, với tầm nhìn ngoạn mục ra thung lũng lúa chín vàng.
</div>
 <div class='ps-5 fs-5 '>
• Khám phá ruộng bậc thang Mâm Xôi - Check-in tại ruộng bậc thang Mâm Xôi, nơi sở hữu cảnh quan thiên nhiên tuyệt đẹp, được ví như mâm xôi vàng óng vào mùa thu hoạch.
</div>
<div class='ps-5 fs-5 '>
• Thưởng thức đặc sản Tây Bắc - Tham gia bữa tối tại nhà hàng địa phương với những món ăn đặc trưng của vùng Tây Bắc, đem lại trải nghiệm ẩm thực độc đáo cho du khách.
</div>
<div class='ps-5 fs-5 '>
• Trải nghiệm văn hóa bản làng - Tham quan bản người Thái và chợ Mù Cang Chải, nơi du khách có thể tìm hiểu đời sống địa phương và mua sắm các sản phẩm thủ công mỹ nghệ.
</div>
<div class='ps-5 fs-5 '>
• Ngắm cảnh thiên nhiên hùng vĩ - Thỏa sức đắm chìm trong khung cảnh thiên nhiên tuyệt đẹp của Mù Cang Chải, từ những cánh đồng lúa xanh mướt đến những dãy núi trùng điệp.
</div>
</div>
 
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Mù Cang Chải
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Đèo Khau Phạ
</div>
<div class='pb-3 fs-5' >
Đèo Khau Phạ, một trong tứ đại đèo nổi tiếng của miền Bắc, mang đến cảnh sắc hùng vĩ và những khúc cua hiểm trở tuyệt đẹp.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cangchai4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai7} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Thung Lũng Lìm Mông
</div>
<div class='pb-3 fs-5' >
Thung lũng Lìm Mông nổi bật với những ruộng bậc thang uốn lượn, tạo nên cảnh quan thiên nhiên tươi đẹp, bình yên.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cangchai8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai11} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Xã Tú Lệ
</div>
<div class='pb-3 fs-5' >
Xã Tú Lệ nổi tiếng với những cánh đồng lúa bậc thang tuyệt đẹp và văn hóa đặc sắc của các dân tộc Mông, Thái.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cangchai12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai15} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Đồi Mâm Xôi
</div>
<div class='pb-3 fs-5' >
Đồi Mâm Xôi là một đỉnh núi có hình dáng độc đáo như chiếc mâm xôi khổng lồ, tạo nên cảnh quan hấp dẫn.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cangchai16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai19} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Đồi Móng Ngựa
</div>
<div class='pb-3 fs-5' >
Đồi Móng Ngựa có hình dáng giống chiếc móng ngựa khổng lồ, thu hút du khách với vẻ đẹp hoang sơ và hùng vĩ.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cangchai20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai23} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Rừng Trúc Bản Nả Háng Túa
</div>
<div class='pb-3 fs-5' >
Rừng trúc Bản Nả Háng Túa là khu rừng trúc nguyên sinh tĩnh lặng, huyền bí, lý tưởng cho khám phá thiên nhiên hoang dã.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cangchai24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai27} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Suối Khoáng Nóng Trạm Tấu
</div>
<div class='pb-3 fs-5' >
Suối khoáng nóng Trạm Tấu là địa điểm thư giãn nổi tiếng với nước khoáng tự nhiên và cảnh sắc thiên nhiên tuyệt đẹp.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cangchai28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai31} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
`;
export const tour_hagiang = `
 
<div class=' fs-1 ' >
Tour Hà Giang
</div>
 
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.hagiang1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.hagiang2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.hagiang3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.hagiang1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.hagiang2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.hagiang3} class='rounded-3' width='49%' height='220' />
</div>
</div>
<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5 ' >
• Khám phá Cổng Trời Quản Bạ: Ngắm nhìn toàn cảnh Núi đôi Cô Tiên và thị trấn Tam Sơn từ độ cao ấn tượng.
</div>
 <div class='ps-5 fs-5 '>
• Chinh phục Cao nguyên đá Đồng Văn: Chiêm ngưỡng vẻ đẹp hoang sơ và hùng vĩ của công viên địa chất toàn cầu, cùng với những ngôi nhà cổ của người H'mông.
</div>
<div class='ps-5 fs-5 '>
• Thăm Cột Cờ Lũng Cú: Đứng tại điểm cực Bắc của Tổ quốc, nơi mang lại cảm giác tự hào về đất nước.
</div>
<div class='ps-5 fs-5 '>
• Trải nghiệm Hẻm Tu Sản và du thuyền trên Sông Nho Quế: Thưởng ngoạn cảnh quan hùng vĩ của hẻm vực sâu nhất Việt Nam cùng dòng sông Nho Quế trong xanh.
</div>
<div class='ps-5 fs-5 '>
• Khám phá Chợ phiên Đồng Văn: Hòa mình vào không khí sôi động, tìm hiểu văn hóa địa phương và mua sắm các sản phẩm độc đáo từ vùng cao.
</div>
</div>
 
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Hà Giang
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Cột mốc số 0 TP. Hà Giang
</div>
<div class='pb-3 fs-5' >
Điểm khởi đầu của hành trình khám phá vùng núi phía Bắc, nơi du khách có thể chụp hình và ghi dấu ấn trên bản đồ Việt Nam.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hagiang4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang7} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Cổng Trời Quản Bạ
</div>
<div class='pb-3 fs-5' >
Nằm ở độ cao lý tưởng, từ đây bạn có thể ngắm nhìn toàn cảnh thị trấn Tam Sơn và chiêm ngưỡng vẻ đẹp của Núi đôi Cô Tiên.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hagiang8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang11} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Cột Cờ Lũng Cú
</div>
<div class='pb-3 fs-5' >
Điểm cực Bắc của Tổ quốc, nơi du khách có thể chụp hình và cảm nhận niềm tự hào dân tộc.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hagiang12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang15} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Đèo Mã Pì Lèng
</div>
<div class='pb-3 fs-5' >
Một trong những con đèo đẹp nhất Việt Nam, nối liền giữa Đồng Văn và Mèo Vạc, với cảnh quan thiên nhiên hùng vĩ.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hagiang16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang19} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Hẻm Tu Sản và Sông Nho Quế
</div>
<div class='pb-3 fs-5' >
Khám phá hẻm vực sâu nhất của Việt Nam, nơi có dòng sông Nho Quế chảy qua với khung cảnh tuyệt đẹp.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hagiang20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang23} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Chợ phiên Đồng Văn
</div>
<div class='pb-3 fs-5' >
Trải nghiệm không khí mua sắm sôi động tại chợ phiên, nơi quy tụ nhiều sản phẩm địa phương và văn hóa của người dân tộc.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hagiang24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang27} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Phố Cổ Đồng Văn
</div>
<div class='pb-3 fs-5' >
Dạo chơi và thưởng thức cà phê tại phố cổ, nơi lưu giữ nhiều giá trị văn hóa và lịch sử của vùng cao Hà Giang.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hagiang28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang31} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
`;
export const tour_ninhbinh = `
 
<div class=' fs-1 ' >
Tour Ninh Bình
</div>
 
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.ninhbinh1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.ninhbinh2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.ninhbinh3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.ninhbinh1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.ninhbinh2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.ninhbinh3} class='rounded-3' width='49%' height='220' />
</div>
</div>
<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5 ' >
• Khám Phá Quần Thể Danh Thắng Tràng An: Chìm đắm trong cảnh quan thiên nhiên kỳ vĩ với núi non hùng vĩ và các hang động huyền bí, được UNESCO công nhận là Di sản thế giới.
</div>
 <div class='ps-5 fs-5 '>
• Tham Quan Chùa Bái Đính: Một trong những ngôi chùa lớn nhất Việt Nam, nổi bật với kiến trúc độc đáo và các tượng Phật hoành tráng, mang đậm giá trị văn hóa và tâm linh.
</div>
<div class='ps-5 fs-5 '>
• Viếng Cố Đô Hoa Lư: Tìm hiểu lịch sử Việt Nam tại di tích lịch sử quan trọng này, nơi có các đền thờ các vua Đinh, Lê và những dấu ấn văn hóa của triều đại đầu tiên.
</div>
<div class='ps-5 fs-5 '>
• Trải Nghiệm Đi Thuyền Ở Tam Cốc: Chiêm ngưỡng vẻ đẹp tuyệt mỹ của "Vịnh Hạ Long trên cạn" với những cánh đồng xanh mướt và hang động tự nhiên trong hành trình đi thuyền dọc theo sông Ngô Đồng.
</div>
<div class='ps-5 fs-5 '>
• Chinh Phục Hang Múa: Leo lên đỉnh núi Múa để ngắm toàn cảnh Tam Cốc và tận hưởng không khí trong lành, cùng với cơ hội chụp những bức hình đẹp tuyệt vời.
</div>
</div>
 
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Ninh Bình
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Quần thể danh thắng Tràng An
</div>
<div class='pb-3 fs-5' >
Nơi có cảnh quan thiên nhiên tuyệt đẹp với những dãy núi đá vôi, hệ thống hang động phong phú và dòng sông trong xanh, được UNESCO công nhận là Di sản thế giới.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.ninhbinh4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.ninhbinh4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.ninhbinh5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.ninhbinh6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.ninhbinh7} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Chùa Bái Đính
</div>
<div class='pb-3 fs-5' >
Là một trong những ngôi chùa lớn nhất Việt Nam, nổi tiếng với bức tượng Phật Thích Ca bằng đồng nặng 100 tấn và kiến trúc độc đáo. Chùa Bái Đính còn thu hút du khách với các nghi lễ và lễ hội lớn.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.ninhbinh8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.ninhbinh8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.ninhbinh9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.ninhbinh10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.ninhbinh11} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Cố đô Hoa Lư
</div>
<div class='pb-3 fs-5' >
Di tích lịch sử quan trọng với các đền thờ vua Đinh Tiên Hoàng và Lê Đại Hành, thể hiện giá trị văn hóa, lịch sử của triều đại đầu tiên trong lịch sử Việt Nam.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.ninhbinh12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.ninhbinh12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.ninhbinh13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.ninhbinh14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.ninhbinh15} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Tam Cốc - Bích Động
</div>
<div class='pb-3 fs-5' >
Được mệnh danh là "Vịnh Hạ Long trên cạn," nơi có cảnh quan tuyệt đẹp với những cánh đồng xanh, núi đá vôi, và những dòng sông uốn lượn. Du khách có thể tham gia trải nghiệm đi thuyền ngắm cảnh và khám phá các hang động.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.ninhbinh16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.ninhbinh16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.ninhbinh17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.ninhbinh18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.ninhbinh19} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Hang Múa
</div>
<div class='pb-3 fs-5' >
Nổi tiếng với những bậc thang đá dẫn lên đỉnh núi, từ đây du khách có thể chiêm ngưỡng toàn cảnh Tam Cốc và những cánh đồng lúa chín vàng. Đây cũng là một địa điểm lý tưởng cho những bức ảnh sống động.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.ninhbinh20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.ninhbinh20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.ninhbinh21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.ninhbinh22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.ninhbinh23} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
`;
export const tour_quangbinh = `
<div class=' fs-1 ' >
Tour Quảng Bình
</div>

<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.quangbinh1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.quangbinh2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.quangbinh3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.quangbinh1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.quangbinh2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.quangbinh3} class='rounded-3' width='49%' height='220' />
</div>
</div>
<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5 ' >
• Khám Phá Hang Sơn Đoòng: Trải nghiệm khám phá hang động lớn nhất thế giới, nơi bạn sẽ được chiêm ngưỡng vẻ đẹp hùng vĩ và độc đáo của thiên nhiên cùng hệ sinh thái phong phú.
</div>
 <div class='ps-5 fs-5  '>
• Vườn Quốc Gia Phong Nha - Kẻ Bàng: Tham quan di sản thiên nhiên thế giới với những hang động nổi tiếng, trekking qua rừng nguyên sinh và tận hưởng vẻ đẹp hoang sơ của núi rừng.
</div>
<div class='ps-5 fs-5  '>
• Chìm Đắm Tại Bãi Biển Nhật Lệ: Thư giãn trên bãi biển cát trắng mịn, tắm biển và tham gia các hoạt động thể thao nước thú vị, hòa mình vào không gian biển xanh.
</div>
<div class='ps-5 fs-5  '>
• Tham Quan Thành Phố Đồng Hới: Khám phá những di tích lịch sử, văn hóa tại thành phố trung tâm, như Đồn Hải Thành và Nhà thờ Tam Tòa, đồng thời thưởng thức ẩm thực địa phương.
</div>
<div class='ps-5 fs-5  '>
• Viếng Đền Thánh Cha Quang: Trải nghiệm không gian tôn nghiêm và thanh tịnh tại đền thờ Cha Quang, nơi có kiến trúc đẹp mắt và giá trị văn hóa đặc sắc, thu hút du khách tìm hiểu về tín ngưỡng địa phương.
</div>
</div>
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Quảng Bình
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Hang Sơn Đoòng
</div>
<div class='pb-3 fs-5' >
Được coi là hang động lớn nhất thế giới, Sơn Đoòng nổi tiếng với vẻ đẹp hoang sơ và hệ sinh thái đa dạng. Du khách có thể tham gia tour khám phá hang động để trải nghiệm không gian kỳ diệu này.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.quangbinh4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangbinh5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangbinh6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangbinh7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.quangbinh4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.quangbinh5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.quangbinh6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.quangbinh7} class='rounded-3' width='49%' height='220'  />
</div>
</div>

 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Vườn Quốc Gia Phong Nha - Kẻ Bàng
</div>
<div class='pb-3 fs-5' >
Di sản thiên nhiên thế giới với nhiều hang động nổi tiếng, như Hang Phong Nha và Hang Tiên Sơn. Nơi đây cũng là nơi lý tưởng để trekking, cắm trại và chiêm ngưỡng vẻ đẹp của rừng núi.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.quangbinh8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangbinh9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangbinh10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangbinh11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.quangbinh8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.quangbinh9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.quangbinh10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.quangbinh11} class='rounded-3' width='49%' height='220'  />
</div>
</div>

 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Bãi Biển Nhật Lệ
</div>
<div class='pb-3 fs-5' >
Với bãi cát trắng mịn và làn nước trong xanh, Nhật Lệ là điểm đến lý tưởng cho những ai muốn thư giãn và tắm biển. Nơi đây cũng có nhiều hoạt động thể thao nước thú vị.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.quangbinh12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangbinh13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangbinh14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangbinh15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.quangbinh12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.quangbinh13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.quangbinh14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.quangbinh15} class='rounded-3' width='49%' height='220'  />
</div>
</div>

 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Thành phố Đồng Hới
</div>
<div class='pb-3 fs-5' >
Là trung tâm kinh tế, văn hóa của Quảng Bình, Đồng Hới có nhiều di tích lịch sử như Đồn Hải Thành, Nhà thờ Tam Tòa, cùng các khu chợ địa phương sôi động.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.quangbinh16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangbinh17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangbinh18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangbinh19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.quangbinh16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.quangbinh17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.quangbinh18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.quangbinh19} class='rounded-3' width='49%' height='220'  />
</div>
</div>

 

 
`;
export const tour_quangdongquangtay = `
<div class=' fs-1' >
Tour Quảng Đông - Quảng Tây
</div>

<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>

<img src=${TourInlandImages.quangdong1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.quangdong2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.quangdong3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>

<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.quangdong1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.quangdong2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.quangdong3} class='rounded-3' width='49%' height='220' />
</div>
</div>

<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>

<div class='ps-5 fs-5' >
• Thăm Lệ Ba - Di sản thiên nhiên Thế Giới
</div>
 <div class='ps-5 fs-5'>
• Thăm Tiểu Thất Khổng - được mệnh danh là “Cửu Trại Câu”của Quý Châu
</div>
<div class='ps-5 fs-5'>
• Thăm Thiên Hộ Miêu Trại - Làng dân tộc Miêu lớn nhất Trung Quốc
</div>
<div class='ps-5 fs-5 '>
• Quý khách sẽ được trải nghiệm hành trình hơn 800km bằng tàu cao tốc của Trung Quốc, có đoạn tàu chạy đến 350 km/giờ, trên đường quý khách vừa thoải mái vừa được ngắm nhìn sơn thủy hữu tình của đất nước TQ từ đường sắt cao tốc trên cao
</div>

</div>
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Quảng Đông - Quảng Tây
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Thành phố Bằng Tường
</div>
<div class='pb-3 fs-5' >
Nằm ở tỉnh Quảng Tây, Trung Quốc, Bằng Tường là một thành phố phát triển với nhiều khu vực thương mại và dịch vụ. Nơi đây nổi bật với văn hóa và ẩm thực địa phương, cũng như các điểm tham quan tự nhiên gần kề.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.quangdong4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangdong5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangdong6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangdong7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.quangdong4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.quangdong5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.quangdong6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.quangdong7} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Nam Ninh
</div>
<div class='pb-3 fs-5' >
Thủ phủ của tỉnh Quảng Tây, Trung Quốc, Nam Ninh nổi tiếng với phong cảnh thiên nhiên tươi đẹp, các công trình kiến trúc hiện đại và ẩm thực phong phú. Thành phố còn là trung tâm giao thương quan trọng giữa Việt Nam và Trung Quốc.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa11} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Phố cổ Lệ Ba
</div>
<div class='pb-3 fs-5' >
Là một trong những khu phố cổ nổi tiếng ở Trung Quốc, Lệ Ba thu hút du khách với kiến trúc truyền thống, đường phố nhỏ nhắn và các cửa hàng thủ công mỹ nghệ. Buổi tối, phố cổ trở nên sống động với ánh đèn và các hoạt động văn hóa.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa15} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Tiểu Thất Khổng
</div>
<div class='pb-3 fs-5' >
Là di sản thiên nhiên thế giới được UNESCO công nhận, Tiểu Thất Khổng có cảnh quan hùng vĩ với những thác nước đẹp mắt và những cây cầu đá cổ kính. Đây là điểm đến lý tưởng cho những ai yêu thích thiên nhiên và khám phá.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa19} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Trấn Viễn Cổ Trấn
</div>
<div class='pb-3 fs-5' >
Là một trấn cổ của người Miêu, nổi tiếng với cảnh đẹp ven sông và những cây cầu truyền thống. Trấn Viễn cũng là nơi lưu giữ nhiều giá trị văn hóa, với các hoạt động như thả hoa đăng trên sông.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa23} class='rounded-3' width='49%' height='220'  />
</div>

</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Sông Vũ Dương
</div>
<div class='pb-3 fs-5' >
Dòng sông này nổi tiếng với vẻ đẹp thơ mộng và không gian yên bình, là nơi lý tưởng cho các hoạt động du thuyền. Khung cảnh hai bên bờ sông rất quyến rũ, mang đến cho du khách cảm giác thư giãn tuyệt vời.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa27} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Thiên Hộ Miêu Trại:
</div>
<div class='pb-3 fs-5' >
Là một làng truyền thống của người Miêu, nơi du khách có thể khám phá văn hóa và phong tục tập quán của dân tộc này. Tại đây, bạn cũng có thể tham gia vào các hoạt động nghệ thuật và thưởng thức ẩm thực đặc sản.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa31} class='rounded-3' width='49%' height='220'  />
</div>
</div>



`;
export const tour_bangkok = `
<div class=' fs-1' >
Tour Lệ Giang - Shangri-La
</div>

<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>

<img src=${TourInlandImages.legiang1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.legiang2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.legiang3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>

<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.legiang1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.legiang2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.legiang3} class='rounded-3' width='49%' height='220' />
</div>
</div>

<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>

<div class='ps-5 fs-5' >
• 03 đêm Lệ Giang
</div>
 <div class='ps-5 fs-5'>
• TẶNG vé xem show diễn Ấn Tượng Lệ Giang
</div>
<div class='ps-5 fs-5'>
• Bay thẳng hàng không Bamboo Airways chỉ với 2 tiếng đồng hồ
</div>
<div class='ps-5 fs-5 '>
• Khám phá thành cổ Lệ Giang thơ mộng

</div>
<div class='ps-5 fs-5 '>
• Trải nghiệm Núi tuyết Ngọc Long quanh năm bao phủ bởi tuyết, dưới chân núi tuyết là thung lũng Lam Nguyệt có màu xanh độc đáo, uốn lượn
</div>
<div class='ps-5 fs-5 '>
• Đi tìm “Đường chân trời đã mất” ở Shangri-La, nơi đất và trời hòa vào làm một
</div>
<div class='ps-5 fs-5 '>
• Lịch trình thông minh, tiết kiệm thời gian
</div>



</div>
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Lệ Giang - Shangri-La
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Thành Cổ Lệ Giang

</div>
<div class='pb-3 fs-5' >
Thành Cổ Lệ Giang, được UNESCO công nhận là di sản văn hóa thế giới, có lịch sử hơn 800 năm. Nơi đây nổi bật với kiến trúc truyền thống độc đáo và hệ thống kênh rạch chảy qua từng con phố, tạo nên không gian thơ mộng và thanh bình, thường được mệnh danh là "Venice của phương Đông".
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.legiang4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang7} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Công viên Hắc Long Đàm
</div>
<div class='pb-3 fs-5' >
Công Viên Hắc Long Đàm là một địa điểm du lịch nổi tiếng tại Lệ Giang, nơi du khách có thể ngắm nhìn núi tuyết Ngọc Long hùng vĩ và thư giãn bên hồ nước trong xanh. Công viên còn là nơi lý tưởng để khám phá cảnh quan thiên nhiên và tận hưởng không khí trong lành.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.legiang8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang11} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Núi Tuyết Ngọc Long

</div>
<div class='pb-3 fs-5' >
Núi Tuyết Ngọc Long cao 5.596 m, là ngọn núi tuyết nổi tiếng ở Vân Nam. Nơi đây có cảnh sắc tuyệt đẹp và băng vĩnh cửu ánh lên màu xanh như ngọc. Núi được xem như một thách thức cho những người leo núi vì sự bí ẩn và hoang sơ của nó.

</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.legiang12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang15} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Thung Lũng Lam Nguyệt

</div>
<div class='pb-3 fs-5' >
Thung Lũng Lam Nguyệt nằm dưới chân núi Tuyết Ngọc Long, nổi bật với cảnh quan hùng vĩ và dòng Bạch Thủy Hà trong xanh. Đây là điểm đến lý tưởng để chụp ảnh và khám phá thiên nhiên tươi đẹp của vùng cao nguyên.

</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.legiang16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang19} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Ngọc Thủy Trại (Làng cổ của tộc người Naxi)

</div>
<div class='pb-3 fs-5' >
Ngọc Thủy Trại là ngôi làng cổ của tộc người Naxi, nơi gìn giữ những nét văn hóa độc đáo. Du khách có thể tìm hiểu về phong tục tập quán của người Naxi và tham quan những hồ nước xếp tầng đẹp mắt trong không gian thanh bình của làng quê.

</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.legiang20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang23} class='rounded-3' width='49%' height='220'  />
</div>

</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Tu viện Songzanlin

</div>
<div class='pb-3 fs-5' >
Tu viện Songzanlin là tu viện Phật giáo Mật Tông Tây Tạng lớn nhất ở Vân Nam, xây dựng vào năm 1679. Nơi đây không chỉ nổi bật với kiến trúc giống cung điện Potala mà còn mang đậm không khí linh thiêng và cảnh quan thiên nhiên tuyệt đẹp xung quanh.

</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.legiang24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang27} class='rounded-3' width='49%' height='220'  />
</div>

</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Thành cổ DuKe Zong

</div>
<div class='pb-3 fs-5' >
Thành cổ DuKe Zong, có tuổi đời hơn 1.300 năm, là nơi cư trú lâu đời của người Tạng. Nơi đây có hàng trăm ngôi nhà cổ với kiến trúc Tây Tạng độc đáo, giúp du khách hiểu rõ hơn về văn hóa và lối sống của người dân địa phương.

</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.legiang28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang31} class='rounded-3' width='49%' height='220'  />
</div>

</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
8. Vườn Quốc gia Pudacuo

</div>
<div class='pb-3 fs-5' >
Vườn Quốc gia Pudacuo là công viên quốc gia đầu tiên của Trung Quốc, nổi bật với hệ động thực vật phong phú và cảnh quan thiên nhiên tuyệt đẹp. Nơi đây là thiên đường cho những người yêu thích chụp ảnh, với bốn mùa mỗi mùa có vẻ đẹp riêng.

</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang32} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang33} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang34} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang35} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.legiang32} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang33} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang34} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang35} class='rounded-3' width='49%' height='220'  />
</div>
</div>

`;
export const tour_thanhdo = `
<div class=' fs-1' >
Tour Thành Đô - Trùng Khánh - Cửu Trại Câu
</div>

<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>

<img src=${TourInlandImages.thanhdo1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.thanhdo2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.thanhdo3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>

<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.thanhdo1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.thanhdo2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.thanhdo3} class='rounded-3' width='49%' height='220' />
</div>
</div>

<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>

<div class='ps-5 fs-5' >
• Khám Phá Văn Hóa Địa Phương: Hành trình mang đến cơ hội tìm hiểu về văn hóa và lịch sử của Trùng Khánh thông qua các cổ trấn, phố cổ và các di tích lịch sử

</div>
 <div class='ps-5 fs-5'>
• Thiên Nhiên Hùng Vĩ: Du khách sẽ được chiêm ngưỡng vẻ đẹp tuyệt mỹ của các hang động, thác nước và hồ nước trong xanh tại Hồng Nhai Động và Cửu Trại Câu

</div>
<div class='ps-5 fs-5'>
• Ẩm Thực Đặc Sắc: Thưởng thức các món ăn truyền thống độc đáo của khu vực Tứ Xuyên, đặc biệt là các món ăn cay và lẩu nổi tiếng tại Thành Đô

</div>
<div class='ps-5 fs-5 '>
• Trải Nghiệm Độc Đáo Tại Các Ngõ Phố: Khám phá những ngõ rộng và ngõ hẹp đầy thú vị, nơi du khách có thể tìm thấy những món quà lưu niệm độc đáo và trải nghiệm cuộc sống hàng ngày của người dân địa phương
</div>
<div class='ps-5 fs-5 '>
• Chụp Ảnh Với Biểu Tượng Gấu Trúc: Dừng chân tại Quảng Trường Gấu Trúc để chụp ảnh với những bức tượng gấu trúc đáng yêu, biểu tượng đặc trưng của vùng Tứ Xuyên
</div>
</div>
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Thành Đô - Trùng Khánh - Cửu Trại Câu
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Trùng Khánh


</div>
<div class='pb-3 fs-5' >
Trùng Khánh là một thành phố thuộc tỉnh Tứ Xuyên, Trung Quốc, nổi tiếng với phong cảnh thiên nhiên kỳ vĩ và các điểm du lịch lịch sử. Nơi đây có nhiều di tích văn hóa và cảnh quan thiên nhiên, bao gồm các hang động và khu bảo tồn đa dạng sinh học.

</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thanhdo4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo7} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Từ Khí Khẩu Cổ Trấn

</div>
<div class='pb-3 fs-5' >
Từ Khí Khẩu Cổ Trấn là một trong những điểm du lịch nổi tiếng với kiến trúc cổ kính và các con phố nhỏ lát đá. Cổ trấn mang đậm dấu ấn văn hóa của người Trung Quốc, với nhiều cửa hàng truyền thống, quán trà và các món ăn đặc sản địa phương.

</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thanhdo8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo11} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Hồng Nhai Động


</div>
<div class='pb-3 fs-5' >
Hồng Nhai Động là một trong những hang động lớn và đẹp nhất ở Trùng Khánh. Nơi đây nổi tiếng với những hình thù đá vôi kỳ lạ và các tác phẩm nghệ thuật tự nhiên, tạo ra một không gian huyền bí và hấp dẫn cho du khách.


</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thanhdo12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo15} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Phố Cổ Cẩm Lý


</div>
<div class='pb-3 fs-5' >
Phố Cổ Cẩm Lý nổi bật với các ngôi nhà cổ kính và không khí truyền thống, nơi bạn có thể tìm thấy nhiều cửa hàng bán đồ thủ công mỹ nghệ và món ăn địa phương. Phố cổ mang đến cho du khách cảm giác trở về quá khứ với những con đường hẹp và những di tích lịch sử.


</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thanhdo16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo19} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Ngõ Rộng


</div>
<div class='pb-3 fs-5' >
Ngõ Rộng là một khu vực với không gian thoáng đãng, được bao quanh bởi các tòa nhà cổ và cây xanh. Đây là nơi lý tưởng để thư giãn, trò chuyện và thưởng thức không khí yên bình của khu phố.


</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thanhdo20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo23} class='rounded-3' width='49%' height='220'  />
</div>

</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Ngõ Hẹp


</div>
<div class='pb-3 fs-5' >
Ngõ Hẹp mang lại trải nghiệm khám phá thú vị với những con đường nhỏ hẹp và các cửa hàng đặc trưng. Du khách có thể tìm hiểu về cuộc sống hàng ngày của người dân địa phương và khám phá những điều bất ngờ tại các góc phố.


</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thanhdo24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo27} class='rounded-3' width='49%' height='220'  />
</div>

</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Thành Đô


</div>
<div class='pb-3 fs-5' >
Thành Đô là thủ phủ của tỉnh Tứ Xuyên, nổi tiếng với văn hóa ẩm thực phong phú, đặc biệt là món lẩu Tứ Xuyên và các món ăn cay. Đây cũng là nơi có nhiều điểm tham quan nổi bật, như Công viên Nhân dân và Bảo tàng Tứ Xuyên.


</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thanhdo28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo31} class='rounded-3' width='49%' height='220'  />
</div>

</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
8. Cửu Trại Câu
</div>
<div class='pb-3 fs-5' >
Cửu Trại Câu là một trong những điểm đến nổi tiếng nhất ở Trung Quốc với cảnh quan thiên nhiên tuyệt đẹp, hồ nước trong xanh, thác nước hùng vĩ và hệ động thực vật phong phú. Nơi đây được UNESCO công nhận là Di sản Thiên nhiên Thế giới, thu hút hàng triệu du khách mỗi năm.


</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo32} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo33} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo34} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo35} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thanhdo32} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo33} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo34} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo35} class='rounded-3' width='49%' height='220'  />
</div>
</div>

`;
export const tour_han = `
<div class=' fs-1 ' >
Tour Hàn Quốc
</div>

<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.hanquoc1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.hanquoc2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.hanquoc3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.hanquoc1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.hanquoc2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.hanquoc3} class='rounded-3' width='49%' height='220' />
</div>
</div>

<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5' >
• Khám phá 3 thành phố lớn nhất của Hàn Quốc
</div>
 <div class='ps-5 fs-5'>
• Chùa Haedong Yonggungsa: là 1 trong những ngôi chùa có địa thế đẹp và linh thiêng nhất Hàn Quốc
</div>
<div class='ps-5 fs-5'>
• Khám phá Gamcheon - một Santorini dịu dàng của Hàn Quốc
</div>
<div class='ps-5 fs-5'>
• Trải nghiệm đạp xe trượt trên đường ray qua Cầu Nakdong
</div>
<div class='ps-5 fs-5'>
• Ngắm hòn đảo Nami xinh đẹp với những hàng cây ngân hạnh vàng óng và những cây phong đỏ rực lá
</div>
<div class='ps-5 fs-5'>
• Tặng vé trải nghiệm đi Phà thả mồi cho Hải Âu ăn ngắm hoàng hôn
</div>
<div class='ps-5 fs-5'>
• Tham gia làm kim chi và diện trang phục truyền thống Hàn quốc – Hanbok
</div>
</div>
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
HÀN QUỐC
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Đường hầm rượu vang Phúc Bồn Tử
</div>
<div class='pb-3 fs-5' >
Đường hầm rượu vang Phúc Bồn Tử là nơi bạn có thể thưởng thức rượu vang thơm ngon, lạc vào thế giới ánh sáng với hệ thống đèn LED lung linh trong không gian nghệ thuật.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hanquoc4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc7} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Cầu Nakdong
</div>
<div class='pb-3 fs-5' >
Cầu Nakdong là một cây cầu sắt nổi tiếng ở Gimhae, nơi bạn có thể đạp xe và ngắm cảnh đẹp của dòng sông Nakdong trong một trải nghiệm thú vị.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hanquoc8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc11} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Chùa Haedong Yonggungsa
</div>
<div class='pb-3 fs-5' >
Chùa Haedong Yonggungsa nằm bên bờ biển Busan, nổi bật với kiến trúc độc đáo và cảnh quan tuyệt đẹp, mang lại cảm giác bình yên cho du khách khi tham quan. 
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hanquoc12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc15} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Bãi biển Haeundae
</div>
<div class='pb-3 fs-5' >
Bãi biển Haeundae là bãi biển lớn nhất và nổi tiếng nhất tại Busan, nơi lý tưởng để tắm biển, thưởng thức hải sản và tham gia các hoạt động thể thao nước.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hanquoc16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc19} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Làng văn hóa Gamcheon Culture Village
</div>
<div class='pb-3 fs-5' >
Làng văn hóa Gamcheon Culture Village là một khu phố nghệ thuật độc đáo với những ngôi nhà sắc màu rực rỡ, tạo ra không gian thú vị cho những ai yêu thích nghệ thuật và nhiếp ảnh.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hanquoc20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc23} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Tàu cao tốc KTX (Busan - Seoul)
</div>
<div class='pb-3 fs-5' >
Tàu cao tốc KTX là phương tiện di chuyển nhanh nhất giữa Busan và Seoul, giúp rút ngắn thời gian di chuyển chỉ còn khoảng 2,5 giờ, mang lại sự tiện lợi cho du khách.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hanquoc24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc27} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Đảo Nami
</div>
<div class='pb-3 fs-5' >
Đảo Nami là một thiên đường lãng mạn nổi tiếng từ bộ phim "Bản tình ca mùa đông", nơi bạn có thể dạo chơi, chụp ảnh và tận hưởng không gian yên bình giữa thiên nhiên.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hanquoc28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc31} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
8. Khu phố Myeongdong
</div>
<div class='pb-3 fs-5' >
Khu phố Myeongdong là một trong những điểm mua sắm sầm uất nhất ở Seoul, nổi bật với các cửa hàng thời trang, mỹ phẩm và những món ăn đường phố hấp dẫn.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc32} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc33} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc34} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc35} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hanquoc32} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc33} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc34} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc35} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
9. Cung điện Gyeongbok (Cảnh Phúc Cung)
</div>
<div class='pb-3 fs-5' >
Cung điện Gyeongbok là biểu tượng của triều đại Joseon, nổi bật với kiến trúc truyền thống và bảo tàng dân gian, mang lại cái nhìn sâu sắc về lịch sử Hàn Quốc.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc36} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc37} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc38} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc39} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hanquoc36} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc37} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc38} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc39} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >

</div>
<div class='pb-3 fs-5' >

</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc40} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc41} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc42} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc43} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hanquoc40} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc41} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc42} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc43} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
10. Bảo tàng dân gian Quốc gia (National Folk Museum)
</div>
<div class='pb-3 fs-5' >
Bảo tàng dân gian Quốc gia là nơi trưng bày hơn 4.000 hiện vật văn hóa, giúp du khách hiểu rõ hơn về đời sống và phong tục tập quán của người Hàn Quốc qua các thời kỳ.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc44} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc45} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc46} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc47} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hanquoc44} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc45} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc46} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc47} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
11. Nhà Xanh (The Blue House)
</div>
<div class='pb-3 fs-5' >
Nhà Xanh là nơi làm việc và cư trú của Tổng thống Hàn Quốc, nổi bật với kiến trúc truyền thống và khuôn viên rộng lớn, là biểu tượng của chính phủ Hàn Quốc.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc48} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc49} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc50} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc51} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hanquoc48} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc49} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc50} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc51} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
12. Khu phố tàu China town
</div>
<div class='pb-3 fs-5' >
Khu phố tàu China town ở Incheon là nơi sinh sống của cộng đồng người Hoa, nổi bật với ẩm thực phong phú và những hoạt động văn hóa đặc sắc, thu hút du khách đến khám phá.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc52} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc53} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc54} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc55} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hanquoc52} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc53} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc54} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc55} class='rounded-3' width='49%' height='220'  />
</div>
</div>


`;
export const tour_nhat = `
<div class=' fs-1 ' >
Tour Nhật Bản
</div>

<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.nhatban1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.nhatban2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.nhatban3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.nhatban1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.nhatban2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.nhatban3} class='rounded-3' width='49%' height='220' />
</div>
</div>

<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-5' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5' >
• Khám Phá Cố Đô Nara: Tham quan Đền Todaiji và Công viên Nara, nơi có hươu nai tự do và kiến trúc gỗ cổ kính
</div>
 <div class='ps-5 fs-5'>
• Trải Nghiệm Osaka: Chiêm ngưỡng Lâu đài Osaka lừng lẫy và thưởng thức món lẩu Chanko đặc trưng của Nhật Bản
</div>
<div class='ps-5 fs-5'>
• Di Sản Văn Hóa Kyoto: Tham quan Chùa Kiyomizudera và Đền Fushimi Inari, khám phá vẻ đẹp và linh thiêng của cố đô Kyoto
</div>
<div class='ps-5 fs-5'>
• Chinh Phục Núi Phú Sĩ: Ngắm nhìn Đỉnh Núi Phú Sĩ từ Trạm số 5 và dạo chơi tại Làng cổ Oshino Hakkai dưới chân núi
</div>
<div class='ps-5 fs-5'>
• Khám Phá Tokyo Hiện Đại: Tham quan Chùa Asakusa, Tháp Tokyo Skytree và mua sắm tại Akihabara và Ginza, trải nghiệm sự kết hợp giữa truyền thống và hiện đại
</div>
</div>
<div class='fw-bolder fs-5 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
NHẬT BẢN
</div>
</div>



<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Đền Todaiji
</div>
<div class='pb-3 fs-5' >
Nằm ở Nara, đền Todaiji là công trình gỗ lớn nhất thế giới, nơi có tượng Phật Daibutsu khổng lồ, biểu tượng của đạo Phật Nhật Bản.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatban4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban7} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Công viên Nara
</div>
<div class='pb-3 fs-5' >
Công viên Nara nổi tiếng với đàn hươu nai hoang dã tự do đi lại. Đây là nơi lý tưởng để thư giãn và tận hưởng không khí trong lành.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatban8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban11} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Osaka
</div>
<div class='pb-3 fs-5' >
Là thành phố lớn thứ ba của Nhật Bản, Osaka nổi tiếng với nền ẩm thực phong phú, văn hóa đa dạng và những điểm tham quan hấp dẫn.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatban12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban15} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Lâu đài Osaka
</div>
<div class='pb-3 fs-5' >
Biểu tượng của Osaka, lâu đài này có kiến trúc nguy nga với chi tiết mạ vàng, mang đến trải nghiệm tham quan lịch sử độc đáo.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatban16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban19} class='rounded-3' width='49%' height='220'  />
</div>
</div>


<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Chùa Kiyomizudera
</div>
<div class='pb-3 fs-5' >
Ngôi chùa cổ kính ở Kyoto, nổi tiếng với tầm nhìn tuyệt đẹp và kiến trúc độc đáo, là nơi thu hút đông đảo du khách đến chiêm bái.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatban20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban23} class='rounded-3' width='49%' height='220'  />
</div>
</div>


<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Đền Fushimi Inari
</div>
<div class='pb-3 fs-5' >
Nổi tiếng với hàng ngàn cổng Torii màu đỏ, đền Fushimi Inari là địa điểm linh thiêng, biểu tượng của sự thịnh vượng và may mắn.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatban24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban27} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Núi Phú Sĩ
</div>
<div class='pb-3 fs-5' >
Biểu tượng nổi bật của Nhật Bản, núi Phú Sĩ mang đến cảnh đẹp hùng vĩ và là điểm đến lý tưởng cho những người yêu thiên nhiên.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatban28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban31} class='rounded-3' width='49%' height='220'  />
</div>
</div>


<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
8. Làng cổ Oshino Hakkai
</div>
<div class='pb-3 fs-5' >
Nằm dưới chân núi Phú Sĩ, ngôi làng cổ này nổi bật với kiến trúc truyền thống và 8 hồ nước trong vắt, tạo nên khung cảnh yên bình.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban32} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban33} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban34} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban35} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatban32} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban33} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban34} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban35} class='rounded-3' width='49%' height='220'  />
</div>
</div>


<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
9. Tokyo
</div>
<div class='pb-3 fs-5' >
Thủ đô sầm uất của Nhật Bản, Tokyo kết hợp giữa truyền thống và hiện đại, nổi bật với những công trình kiến trúc độc đáo và nền văn hóa phong phú.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban36} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban37} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban38} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban39} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatban36} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban37} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban38} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban39} class='rounded-3' width='49%' height='220'  />
</div>
</div>


<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
10. Chùa Asakusa – Sensoji
</div>
<div class='pb-3 fs-5' >
Ngôi chùa Phật giáo cổ nhất Tokyo, Sensoji thu hút du khách với không khí linh thiêng và kiến trúc đặc sắc cùng khu phố mua sắm sôi động.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban40} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban41} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban42} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban43} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatban40} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban41} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban42} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban43} class='rounded-3' width='49%' height='220'  />
</div>
</div>


<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
11. Tháp Tokyo Skytree
</div>
<div class='pb-3 fs-5' >
Là tháp truyền hình cao nhất thế giới, Tokyo Skytree mang đến tầm nhìn 360 độ về thành phố, là một biểu tượng hiện đại của Tokyo.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban44} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban45} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban46} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban47} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatban44} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban45} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban46} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban47} class='rounded-3' width='49%' height='220'  />
</div>
</div>


<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
12. Akihabara
</div>
<div class='pb-3 fs-5' >
Khu phố nổi tiếng với đồ điện tử, game và văn hóa otaku, Akihabara thu hút người yêu công nghệ và fan hâm mộ anime.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban48} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban49} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban50} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban51} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatban48} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban49} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban50} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban51} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
13. Ginza
</div>
<div class='pb-3 fs-5' >
Trung tâm mua sắm sầm uất của Tokyo, Ginza nổi tiếng với các thương hiệu cao cấp, cửa hàng thời trang và ẩm thực tinh tế.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban52} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban53} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban54} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban55} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatban52} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban53} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban54} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban55} class='rounded-3' width='49%' height='220'  />
</div>
</div>

`;
export const tour_danang = `
<div class=' fs-1 ' >
Tour Đà Nẵng
</div>

<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.danang1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.danang2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.danang3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.danang1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.danang2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.danang3} class='rounded-3' width='49%' height='220' />
</div>
</div>

<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5 ' >
• Khám Phá Bà Nà Hills: Trải nghiệm cáp treo dài nhất thế giới, tham quan các công trình kiến trúc độc đáo như chùa Linh Ứng và ngắm cảnh từ cầu Vàng nổi tiếng.
</div>
 <div class='ps-5 fs-5 '>
• Tham Quan Cầu Rồng: Chiêm ngưỡng vẻ đẹp của cầu Rồng, một biểu tượng của Đà Nẵng, và không bỏ lỡ màn phun lửa và phun nước vào các buổi tối cuối tuần.
</div>
<div class='ps-5 fs-5 '>
• Tắm Biển Mỹ Khê: Thư giãn trên bãi biển Mỹ Khê với cát trắng và nước biển trong xanh, tham gia các hoạt động thể thao nước hoặc chỉ đơn giản là thưởng thức không khí biển.
</div>
<div class='ps-5 fs-5 '>
• Khám Phá Ngũ Hành Sơn: Leo núi và khám phá các hang động, chùa chiền tại Ngũ Hành Sơn, nơi mang đến những cảnh quan tuyệt đẹp và không gian tĩnh lặng.
</div>
<div class='ps-5 fs-5 '>
• Tham Gia Asia Park: Vui chơi tại Asia Park với nhiều trò chơi giải trí hấp dẫn và chiêm ngưỡng thành phố từ vòng quay Sun Wheel, một trong những vòng quay cao nhất thế giới.
</div>
</div>
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Đà Nẵng
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >

1. Bà Nà Hills
</div>
<div class='pb-3 fs-5' >
Nổi tiếng với cáp treo dài nhất thế giới, Bà Nà Hills còn có những cảnh quan tuyệt đẹp, chùa Linh Ứng và cầu Vàng ấn tượng, mang đến trải nghiệm tham quan không thể quên.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.danang4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang7} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >

2. Cầu Rồng
</div>
<div class='pb-3' >
Biểu tượng của thành phố Đà Nẵng, cầu Rồng không chỉ là một công trình kiến trúc đẹp mà còn có màn phun lửa và phun nước vào cuối tuần, thu hút đông đảo du khách.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.danang8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang11} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Ngũ Hành Sơn
</div>
<div class='pb-3' >
Khu danh thắng với những ngọn núi đá vôi và hang động huyền bí, là nơi lý tưởng để khám phá và chiêm ngưỡng các di tích văn hóa, chùa chiền.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.danang12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang15} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Bãi biển Mỹ Khê
</div>
<div class='pb-3' >
Một trong những bãi biển đẹp nhất thế giới, với cát trắng mịn và nước biển trong xanh, nơi du khách có thể tắm biển, tham gia các hoạt động thể thao nước hoặc thư giãn.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.danang16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang19} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Bán Đảo Sơn Trà
</div>
<div class='pb-3' >
Bán đảo Sơn Trà là một khu bảo tồn thiên nhiên nổi tiếng, với cảnh quan hoang sơ, bãi biển trong xanh và những đỉnh núi hùng vĩ.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.danang20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang23} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Đèo Hải Vân
</div>
<div class='pb-3' >
Đèo Hải Vân, với những khúc cua uốn lượn, nối liền Huế và Đà Nẵng, là một trong những con đèo đẹp nhất Việt Nam, mang đến cảnh sắc tuyệt vời của núi non và biển cả.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.danang24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang27} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Rạn Nam Ô
</div>
<div class='pb-3' >
Rạn Nam Ô là khu vực biển đá tự nhiên, nổi bật với làn nước trong xanh và những rạn san hô phong phú, là điểm đến lý tưởng cho những ai yêu thích khám phá dưới nước.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.danang28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang31} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
8. Ghềnh Bàng
</div>
<div class='pb-3' >
Ghềnh Bàng, với bãi đá đẹp mắt và nước biển trong vắt, là một điểm du lịch nổi tiếng cho những ai yêu thích vẻ đẹp hoang sơ và yên tĩnh.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang32} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang33} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang34} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang35} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.danang32} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang33} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang34} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang35} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
9. Cầu Sông Hàn
</div>
<div class='pb-3' >
Cầu Sông Hàn là biểu tượng của Đà Nẵng, nổi bật với kiến trúc độc đáo và khả năng quay 90 độ vào mỗi đêm, tạo nên cảnh tượng ấn tượng.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang36} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang37} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang38} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang39} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.danang36} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang37} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang38} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang39} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
10. Bảo Tàng Nghệ Thuật Điêu Khắc Chăm
</div>
<div class='pb-3' >
Bảo tàng Nghệ thuật Điêu khắc Chăm lưu giữ và trưng bày những tác phẩm điêu khắc độc đáo của nền văn hóa Champa, là điểm đến không thể bỏ qua cho những ai yêu thích nghệ thuật và lịch sử.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang40} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang41} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang42} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang43} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.danang40} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang41} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang42} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang43} class='rounded-3' width='49%' height='220'  />
</div>
</div>
`;
export const tour_hoian = `
<div class=' fs-1 ' >
Tour Hội An
</div>

<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.hoian1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.hoian2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.hoian3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.hoian1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.hoian2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.hoian3} class='rounded-3' width='49%' height='220' />
</div>
</div>

<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5 ' >
• Khám Phá Phố Cổ Hội An: Dạo bộ trong khu phố cổ kính, ngắm nhìn các ngôi nhà gỗ và những chiếc đèn lồng lung linh, hòa mình vào không khí nhộn nhịp của chợ đêm và thưởng thức các món ăn đường phố.
</div>
 <div class='ps-5 fs-5 '>
• Chùa Cầu - Biểu Tượng Hội An: Tham quan Chùa Cầu, một công trình kiến trúc độc đáo kết hợp giữa văn hóa Nhật Bản và Việt Nam, nơi du khách có thể tìm hiểu về tín ngưỡng và phong tục của người dân địa phương.
</div>
<div class='ps-5 fs-5 '>
• Trải Nghiệm Làm Gốm tại Làng Gốm Thanh Hà: Tham gia vào quy trình làm gốm truyền thống tại Làng Gốm Thanh Hà, nơi bạn có thể tự tay tạo ra sản phẩm gốm sứ và hiểu rõ hơn về nghệ thuật gốm của người dân nơi đây.
</div>
<div class='ps-5 fs-5 '>
• Thư Giãn Tại Biển Cửa Đại: Dành thời gian thư giãn tại bãi biển Cửa Đại với cát trắng mịn và nước biển trong xanh, tham gia các hoạt động thể thao nước hoặc đơn giản là tắm nắng.
</div>
<div class='ps-5 fs-5 '>
• Khám Phá Rừng Dừa Bảy Mẫu: Đi thuyền thúng khám phá hệ sinh thái rừng ngập mặn tại Rừng Dừa Bảy Mẫu, nơi bạn có thể thưởng thức các món ăn địa phương ngon miệng và tìm hiểu về cuộc sống của ngư dân địa phương.
</div>
</div>
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Hội An
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3 ' >
1. Phố Cổ Hội An
</div>
<div class='pb-3 fs-5' >
Nổi tiếng với kiến trúc cổ kính, những ngôi nhà được bảo tồn tốt và không khí lịch sử. Đây là nơi tuyệt vời để dạo bộ, ngắm nhìn đèn lồng và trải nghiệm văn hóa địa phương.

<div class='d-none d-sm-block'>
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hoian4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian7} class='rounded-3' width='49%' height='220'  />
</div>
</div>



<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Chùa Cầu
</div>
<div class='pb-3 fs-5' >
Biểu tượng của Hội An, chùa được xây dựng vào thế kỷ 17 với kiến trúc độc đáo, kết hợp giữa văn hóa Nhật Bản và Trung Quốc. Đây là nơi lý tưởng để chụp ảnh và tìm hiểu về tín ngưỡng địa phương.
</div>

 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hoian8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian11} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 


<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >

3. Nhà Cổ Tấn Ký
</div>
<div class='pb-3 fs-5' >
Một trong những ngôi nhà cổ nổi tiếng nhất ở Hội An, được xây dựng từ thế kỷ 17. Nhà cổ này mang đậm dấu ấn văn hóa giao thoa của người Việt, Trung Quốc và Nhật Bản.

</div>


<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hoian12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian15} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >

4. Bảo Tàng Văn Hóa Dân Gian
</div>
<div class='pb-3 fs-5' >
Nơi trưng bày các hiện vật văn hóa đặc sắc của Hội An và các vùng lân cận, giúp du khách hiểu rõ hơn về đời sống và phong tục tập quán của người dân nơi đây.
</div>


<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hoian16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian19} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >

5. Biển Cửa Đại
</div>
<div class='pb-3 fs-5 ' >
Một bãi biển tuyệt đẹp với cát trắng mịn và nước biển trong xanh. Đây là nơi lý tưởng để thư giãn, tắm biển và tham gia các hoạt động thể thao nước.
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hoian20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian23} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Làng Gốm Thanh Hà
</div>
<div class='pb-3 fs-5' >
Du khách có thể tham gia vào quá trình làm gốm truyền thống và tìm hiểu về nghệ thuật gốm sứ của người dân địa phương tại làng gốm nổi tiếng này.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hoian24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian27} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Rừng Dừa Bảy Mẫu
</div>
<div class='pb-3' >
Một khu vực rừng ngập mặn độc đáo, nơi du khách có thể trải nghiệm đi thuyền thúng và khám phá hệ sinh thái phong phú, đồng thời thưởng thức các món ăn địa phương.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hoian28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian31} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
`;
export const tour_hue = `
<div class=' fs-1 ' >
Tour Huế
</div>

<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>

<img src=${TourInlandImages.hue1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.hue2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.hue3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>

<div class='d-block d-md-none'>
<img src=${TourInlandImages.hue1} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.hue2} class='rounded-3 my-2' width='100%' height='300' />
<img src=${TourInlandImages.hue3} class='rounded-3' width='100%' height='300' />
</div>

<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5 ' >
• Khám Phá Cố Đô Huế - Tham quan di sản văn hóa thế giới Cố Đô Huế, nơi lưu giữ những công trình kiến trúc hoàng gia như Đại Nội và các lăng tẩm của triều đại Nguyễn.
</div>
 <div class='ps-5 fs-5 '>
• Thuyền Trên Sông Hương - Tận hưởng cảm giác thư giãn khi du thuyền trên dòng sông Hương, ngắm cảnh sắc thơ mộng và khám phá các làng nghề ven sông.
</div>
<div class='ps-5  fs-5 '>
• Chiêm Ngưỡng Chùa Thiên Mụ - Thăm ngôi chùa nổi tiếng bên bờ sông Hương, với tháp Phước Duyên và không gian yên bình, mang đậm nét văn hóa tâm linh của người Việt.

</div>
<div class='ps-5 fs-5 '>
• Tham Quan Lăng Tự Đức: Khám phá lăng mộ của vua Tự Đức, nơi có kiến trúc độc đáo và phong cảnh hữu tình, phản ánh sự kết hợp hoàn hảo giữa thiên nhiên và nghệ thuật.
</div>
<div class='ps-5 fs-5 '>
• Trải Nghiệm Ẩm Thực Địa Phương: Thưởng thức các món ăn đặc sản nổi tiếng của Huế như bún bò Huế, bánh khoái và nem lụi tại các quán ăn truyền thống và chợ Đông Ba.
</div>
</div>
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
HUẾ
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success' >
1. Cố Đô Huế
</div>
<div class='pb-3 fs-5' >
Di sản văn hóa thế giới, nơi lưu giữ những công trình kiến trúc hoàng gia như Đại Nội, nơi từng là trung tâm chính trị của triều đại Nguyễn.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hue4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hue4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hue5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hue6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hue7} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Chùa Thiên Mụ
</div>
<div class='pb-3 fs-5' >
Một trong những ngôi chùa nổi tiếng nhất Việt Nam, tọa lạc bên bờ sông Hương, với tháp Phước Duyên 7 tầng và không gian thanh bình.
</div>

<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hue8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hue8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hue9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hue10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hue11} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >

3. Lăng Tự Đức
</div>
<div class='pb-3 fs-5' >
Kiến trúc độc đáo và cảnh quan tuyệt đẹp, lăng mộ của vua Tự Đức là nơi kết hợp hài hòa giữa thiên nhiên và nghệ thuật kiến trúc.
</div>


<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hue12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hue12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hue13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hue14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hue15} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Sông Hương
</div>
<div class='pb-3 fs-5' >
Biểu tượng của thành phố Huế, du khách có thể tận hưởng cảnh đẹp thơ mộng khi đi thuyền trên sông và thưởng thức các món ăn địa phương.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hue16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hue20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hue21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hue22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hue23} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Đại Nội
</div>
<div class='pb-3 fs-5' >
Khu di tích lịch sử quan trọng, nơi từng là cung điện của các vua triều Nguyễn, với kiến trúc mang đậm dấu ấn văn hóa truyền thống Việt Nam.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hue24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hue24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hue25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hue26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hue27} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Chợ Đông Ba
</div>
<div class='pb-3 fs-5' >
Trung tâm thương mại sầm uất, nơi du khách có thể khám phá ẩm thực địa phương, mua sắm hàng hóa đặc sản và quà lưu niệm.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hue28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hue28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hue29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hue30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hue31} class='rounded-3' width='49%' height='220'  />
</div>
</div>
`;
export const tour_nhatrang = `
<div class=' fs-1 ' >
Tour Nha Trang
</div>

<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>

<img src=${TourInlandImages.nhatrang1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.nhatrang2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.nhatrang3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>

<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.nhatrang1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.nhatrang2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.nhatrang3} class='rounded-3' width='49%' height='220' />
</div>
</div>


<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5 ' >
• Khám Phá Vịnh Nha Trang: Tham gia các tour du thuyền khám phá các hòn đảo xung quanh, như Hòn Mun và Hòn Tằm, nổi bật với làn nước trong xanh và hệ sinh thái san hô đa dạng.

</div>
 <div class='ps-5 fs-5 '>
• Thăm Tháp Bà Ponagar: Chiêm ngưỡng kiến trúc độc đáo của tháp Chăm và tìm hiểu về văn hóa, lịch sử của dân tộc Chăm tại một trong những công trình cổ xưa nhất Việt Nam.

</div>
<div class='ps-5 fs-5 '>
• Vui Chơi Tại Vinpearl Land: Trải nghiệm các trò chơi giải trí hấp dẫn và tham gia vào công viên nước tại Vinpearl Land, một điểm đến lý tưởng cho gia đình và trẻ em.
</div>
<div class='ps-5 fs-5 '>
• Thư Giãn Tại Biển Trần Phú: Thỏa sức tắm biển, tham gia các hoạt động thể thao nước hoặc đơn giản là nằm dài trên bãi biển cát trắng, tận hưởng không khí biển trong lành.
</div>
<div class='ps-5 fs-5 '>
• Chiêm Bái Tượng Phật Trắng Tại Chùa Long Sơn: Tham quan chùa Long Sơn, nơi có tượng Phật Trắng lớn nổi tiếng, và tận hưởng không gian yên bình cùng những giá trị tâm linh đặc sắc.
</div>
</div>

<div class='fw-bolder fs-5 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
NHA TRANG
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Vịnh Nha Trang
</div>
<div class='pb-3 fs-5 ' >
Nổi tiếng với vẻ đẹp hoang sơ, làn nước trong xanh và các hòn đảo xung quanh như Hòn Mun, Hòn Tằm, nơi lý tưởng để lặn biển và ngắm san hô.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatrang4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang7} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 








<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Tháp Bà Ponagar
</div>
<div class='pb-3 fs-5' >
Một trong những công trình kiến trúc cổ xưa nhất, tháp Bà Ponagar mang đậm dấu ấn văn hóa Chăm Pa và là nơi thờ nữ thần Ponagar, rất thu hút khách du lịch đến tham quan và cầu nguyện.
</div>


 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatrang8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang11} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Vinpearl Land Nha Trang
</div>
<div class='pb-3 fs-5' >
Khu vui chơi giải trí trên đảo với nhiều trò chơi hấp dẫn, công viên nước, khu nghỉ dưỡng và khu vực mua sắm, tạo nên một điểm đến lý tưởng cho cả gia đình
</div>

<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatrang12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang15} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 


<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Biển Trần Phú
</div>
<div class='pb-3 fs-5 ' >
Bãi biển đẹp và sôi động nhất Nha Trang, nơi du khách có thể tắm biển, tham gia các hoạt động thể thao nước hoặc đơn giản là thư giãn dưới ánh nắng mặt trời.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatrang16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang19} class='rounded-3' width='49%' height='220'  />
</div>
</div>





<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Hòn Nội
</div>
<div class='pb-3 fs-5' >
Hòn Nội nổi tiếng với bãi tắm đôi độc đáo và làn nước trong xanh, nơi du khách có thể lặn ngắm san hô, tận hưởng thiên nhiên hoang sơ, và thư giãn giữa cảnh sắc tuyệt đẹp.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatrang20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang23} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Nhà thờ Nha Trang
</div>
<div class='pb-3 fs-5' >
Nhà thờ Nha Trang (Nhà thờ Núi) mang kiến trúc Gothic cổ kính và nằm trên một ngọn đồi cao, là địa điểm thu hút với vẻ đẹp cổ điển và là nơi lý tưởng để ngắm cảnh thành phố.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatrang24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang27} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 






<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Hòn Mun
</div>
<div class='pb-3 fs-5' >
Hòn Mun là khu bảo tồn biển nổi tiếng với hệ sinh thái san hô đa dạng, nơi tuyệt vời cho những ai yêu thích lặn biển và muốn khám phá vẻ đẹp dưới lòng đại dương.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatrang28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang31} class='rounded-3' width='49%' height='220'  />
</div>
</div>

 `;
export const tour_phanthiet = `
 <div class=' fs-1 ' >
 Tour Phan Thiết
 </div>
 
 <div class='d-none d-md-block'>
 <div class='d-flex align-items-center gap-2 pt-3 '>
 
 <img src=${TourInlandImages.phanthiet1} class='rounded-3' width='60%' height='608' />
 <div class='w-100 gap-2 d-flex flex-column'>
 <img src=${TourInlandImages.phanthiet2} class='rounded-3' width='100%' height='300' />
 <img src=${TourInlandImages.phanthiet3} class='rounded-3' width='100%' height='300' />
 </div>
 </div>
 </div>
 
 
 
 <div class='d-block d-md-none pt-3 '>
 <img src=${TourInlandImages.phanthiet1} class='rounded-3' width='100%' height='300' />
 <div class='d-flex align-items-center gap-3'>
 <img src=${TourInlandImages.phanthiet2} class='rounded-3 my-2' width='49%' height='220' />
 <img src=${TourInlandImages.phanthiet3} class='rounded-3' width='49%' height='220' />
 </div>
 </div>
 
 
 <div class='bg-light p-3 rounded-3 mt-3' >
 <div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
 Điểm nhấn của hành trình:
 </div>
 <div class='ps-5 fs-5 ' >
 • Khám Phá Đồi Cát Mũi Né: Trải nghiệm những cồn cát trắng mịn, nơi bạn có thể tham gia hoạt động trượt cát và ngắm hoàng hôn tuyệt đẹp.
 </div>
  <div class='ps-5 fs-5 '>
 • Tham Quan Suối Tiên: Chiêm ngưỡng vẻ đẹp độc đáo của suối với dòng nước chảy qua những cồn cát, tạo nên cảnh quan thiên nhiên tuyệt vời cho những bức ảnh ấn tượng.
 </div>
 <div class='ps-5 fs-5 '>
 • Thăm Ngọn Hải Đăng Mũi Né: Leo lên ngọn hải đăng cổ kính để thưởng ngoạn toàn cảnh biển Mũi Né và khám phá những câu chuyện lịch sử thú vị.
 </div>
 <div class='ps-5 fs-5 '>
 • Thư Giãn Tại Bãi Biển Mũi Né: Thưởng thức làn sóng vỗ về và tham gia các hoạt động thể thao nước như lướt ván diều và lặn biển tại bãi biển nổi tiếng.
 </div>
 <div class='ps-5 fs-5 '>
 • Khám Phá Chùa Phú Hải: Tìm hiểu về văn hóa và tâm linh địa phương tại chùa Phú Hải, nơi có kiến trúc độc đáo và không gian thanh tịnh, lý tưởng để cầu bình an.
 </div>
 </div>
 <div class='fw-bolder fs-4 pt-4' >
 Các điểm đến nổi bật:
 </div>
 <div class='d-flex w-100 justify-content-center'>
 <div class='fw-bolder fs-4 text-success' >
 Phan Thiết
 </div>
 </div>
 
 
 <div class='w-100 border border-1 border-dark'>
 </div>
 <div class='fw-bolder fs-4  text-success pt-3 ' >
 1. Hồ Tàu Ô
 </div>
 <div class='pb-3 fs-5 ' >
 Nằm giữa cánh đồng xanh mướt, hồ Tàu Ô nổi tiếng với màu nước trong xanh và cảnh quan thơ mộng. Đây là nơi lý tưởng để thư giãn và chụp ảnh.
 </div>
 <div class='d-none d-sm-block'>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
 <img src=${TourInlandImages.phanthiet4} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet5} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet6} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet7} class='rounded-3' width='22.2%' height='220'  />
 </div>
 </div>
 <div class='d-block d-sm-none'>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
 <img src=${TourInlandImages.phanthiet4} class='rounded-3' width='49%' height='220'  />
 <img src=${TourInlandImages.phanthiet5} class='rounded-3' width='49%' height='220'  />
 </div>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
 <img src=${TourInlandImages.phanthiet6} class='rounded-3' width='49%' height='220'  />
 <img src=${TourInlandImages.phanthiet7} class='rounded-3' width='49%' height='220'  />
 </div>
 </div>
 <div class='w-100 border border-1 border-dark'>
 </div>
 <div class='fw-bolder fs-4  text-success pt-3' >
 2. Đồi Cát Mũi Né
 </div>
 <div class='pb-3 fs-5' >
 Điểm đến không thể bỏ qua với những cồn cát vàng trắng, nơi du khách có thể tham gia các hoạt động như trượt cát, đi xe địa hình và ngắm hoàng hôn tuyệt đẹp.
 </div>
<div class='d-none d-sm-block'>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
 <img src=${TourInlandImages.phanthiet8} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet9} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet10} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet11} class='rounded-3' width='22.2%' height='220'  />
 </div>
 </div>
 <div class='d-block d-sm-none'>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
 <img src=${TourInlandImages.phanthiet8} class='rounded-3' width='49%' height='220'  />
 <img src=${TourInlandImages.phanthiet9} class='rounded-3' width='49%' height='220'  />
 </div>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
 <img src=${TourInlandImages.phanthiet10} class='rounded-3' width='49%' height='220'  />
 <img src=${TourInlandImages.phanthiet11} class='rounded-3' width='49%' height='220'  />
 </div>
 </div>
 <div class='w-100 border border-1 border-dark'>
 </div>
 <div class='fw-bolder fs-4  text-success pt-3' >
 3. Lighthouse (Ngọn Hải Đăng) Mũi Né
 </div>
 <div class='pb-3 fs-5 ' >
 Ngọn hải đăng cổ kính nằm trên đỉnh đồi cao, nơi du khách có thể chiêm ngưỡng toàn cảnh biển Mũi Né và vùng biển bao la.
 </div>
 <div class='d-none d-sm-block'>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
 <img src=${TourInlandImages.phanthiet12} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet13} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet14} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet15} class='rounded-3' width='22.2%' height='220'  />
 </div>
 </div>
 <div class='d-block d-sm-none'>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
 <img src=${TourInlandImages.phanthiet12} class='rounded-3' width='49%' height='220'  />
 <img src=${TourInlandImages.phanthiet13} class='rounded-3' width='49%' height='220'  />
 </div>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
 <img src=${TourInlandImages.phanthiet14} class='rounded-3' width='49%' height='220'  />
 <img src=${TourInlandImages.phanthiet15} class='rounded-3' width='49%' height='220'  />
 </div>
 </div>
 <div class='w-100 border border-1 border-dark'>
 </div>
 <div class='fw-bolder fs-4  text-success pt-3' >
 4. Bãi Biển Mũi Né
 </div>
 <div class='pb-3 fs-5' >
 Bãi biển nổi tiếng với cát trắng mịn, làn sóng nhẹ nhàng và các dịch vụ thể thao nước như lướt ván diều và lặn biển, thu hút nhiều du khách.
 </div>
 <div class='d-none d-sm-block'>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
 <img src=${TourInlandImages.phanthiet16} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.phanthiet18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.phanthiet19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.phanthiet16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.phanthiet17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.phanthiet18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.phanthiet19} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
 <div class='fw-bolder fs-4  text-success pt-3' >
 5. Suối Tiên
 </div>
 <div class='pb-3 fs-5 ' >
 Một suối nước chảy qua những cồn cát với màu nước đỏ cam nổi bật, tạo nên một khung cảnh độc đáo và đẹp mắt, lý tưởng cho những bức ảnh sống động.
 </div>
 <div class='d-none d-sm-block'>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
 <img src=${TourInlandImages.phanthiet20} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet21} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet22} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet23} class='rounded-3' width='22.2%' height='220'  />
 </div>
 </div>
 <div class='d-block d-sm-none'>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
 <img src=${TourInlandImages.phanthiet20} class='rounded-3' width='49%' height='220'  />
 <img src=${TourInlandImages.phanthiet21} class='rounded-3' width='49%' height='220'  />
 </div>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
 <img src=${TourInlandImages.phanthiet22} class='rounded-3' width='49%' height='220'  />
 <img src=${TourInlandImages.phanthiet23} class='rounded-3' width='49%' height='220'  />
 </div>
 </div>
 <div class='w-100 border border-1 border-dark'>
 </div>
 <div class='fw-bolder fs-4  text-success pt-3' >
 6. Tháp Chàm Poshanư
 </div>
 <div class='pb-3 fs-5' >
 Di tích văn hóa Chăm cổ nằm trên đỉnh đồi, với kiến trúc độc đáo và tầm nhìn tuyệt đẹp ra biển. Đây là nơi lý tưởng để tìm hiểu về văn hóa và lịch sử Chăm.
 </div>
 <div class='d-none d-sm-block'>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
 <img src=${TourInlandImages.phanthiet24} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet25} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet26} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet27} class='rounded-3' width='22.2%' height='220'  />
 </div>
 </div>
 <div class='d-block d-sm-none'>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
 <img src=${TourInlandImages.phanthiet24} class='rounded-3' width='49%' height='220'  />
 <img src=${TourInlandImages.phanthiet25} class='rounded-3' width='49%' height='220'  />
 </div>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
 <img src=${TourInlandImages.phanthiet26} class='rounded-3' width='49%' height='220'  />
 <img src=${TourInlandImages.phanthiet27} class='rounded-3' width='49%' height='220'  />
 </div>
 </div> 
 `;
export const tour_phuonghoangcotran = `
<div class=' fs-1' >
Tour Phượng Hoàng Cổ Trấn
</div>
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.cotran1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.cotran2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.cotran3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.cotran1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.cotran2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.cotran3} class='rounded-3' width='49%' height='220' />
</div>
</div>
<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5' >
• Khám Phá Cảnh Quan Hùng Vĩ: Chiêm ngưỡng vẻ đẹp ngoạn mục của Trương Gia Giới với những dãy núi đá vôi và thung lũng xanh tươi, được UNESCO công nhận là Di sản Thiên nhiên Thế giới
</div>
 <div class='ps-5 fs-5'>
• Tham Quan Phượng Hoàng Cổ Trấn: Khám phá nét đẹp cổ kính và văn hóa đặc sắc của Phượng Hoàng Cổ Trấn, nơi có những ngôi nhà gỗ truyền thống và các cây cầu đá duyên dáng bên dòng Đà Giang
</div>
<div class='ps-5 fs-5'>
• Trải Nghiệm Văn Hóa Người Miêu: Tìm hiểu về văn hóa và phong tục tập quán độc đáo của người Miêu tại Miêu Trại Tương Tây, nơi có các hoạt động văn hóa và ẩm thực đặc sắc
</div>
<div class='ps-5 fs-5 '>
• Thám Hiểm Thiên Môn Sơn: Trải nghiệm cáp treo dài nhất thế giới và đi bộ trên Sạn Đạo Kính, nơi mang đến cảm giác hồi hộp và cơ hội ngắm nhìn cảnh sắc thiên nhiên tuyệt đẹp từ trên cao
</div>
<div class='ps-5 fs-5 '>
• Thả Thuyền Tại Hồ Bảo Phong: Thư giãn trên hồ Bảo Phong, nơi từng là bối cảnh trong phim Tây Du Ký, với phong cảnh như tranh vẽ và âm nhạc truyền thống du dương từ người dân địa phương
</div>
</div>
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Phượng Hoàng Cổ Trấn
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Trương Gia Giới
</div>
<div class='pb-3 fs-5' >
Trương Gia Giới nổi tiếng với những dãy núi đá vôi hùng vĩ và cảnh quan thiên nhiên tuyệt đẹp. Nơi đây được UNESCO công nhận là Di sản Thiên nhiên Thế giới, thu hút du khách bởi những vách đá cao chót vót, thung lũng xanh tươi và hệ sinh thái phong phú. Đây cũng là nguồn cảm hứng cho khung cảnh trong bộ phim nổi tiếng "Avatar".
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cotran4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran7} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Phượng Hoàng Cổ Trấn
</div>
<div class='pb-3 fs-5' >
Phượng Hoàng Cổ Trấn là một trong những cổ trấn đẹp nhất Trung Quốc, nổi bật với kiến trúc cổ kính và cảnh quan thơ mộng bên dòng Đà Giang. Tại đây, du khách có thể khám phá những ngôi nhà gỗ cổ, những cây cầu đá xinh đẹp, cùng nhiều điểm tham quan nổi tiếng như Tháp Vạn Danh và Cầu Hồng Kiều, tạo nên bức tranh sống động của văn hóa và lịch sử.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cotran8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran11} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Miêu Trại Tương Tây
</div>
<div class='pb-3 fs-5' >
Miêu Trại Tương Tây là ngôi làng lớn nhất của người Miêu tại Hồ Nam, nổi bật với nét văn hóa độc đáo và tập tục truyền thống. Tại đây, du khách có thể tìm hiểu về cuộc sống, trang phục và các phong tục tập quán của người Miêu, thưởng thức các món ăn đặc sản và tham gia vào các hoạt động văn hóa truyền thống.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cotran12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran15} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Vũ Lăng Nguyên
</div>
<div class='pb-3 fs-5' >
Vũ Lăng Nguyên là một khu vực nổi tiếng với cảnh quan thiên nhiên hùng vĩ và những kỳ quan kiến trúc độc đáo như Tòa nhà 72 kỳ lầu. Nơi đây mang đến cho du khách trải nghiệm tuyệt vời với các khung cảnh đẹp mắt, các hoạt động ngoài trời thú vị và cơ hội tìm hiểu về lịch sử và văn hóa địa phương.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cotran16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran19} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Thiên Môn Sơn
</div>
<div class='pb-3 fs-5' >
Thiên Môn Sơn được biết đến với cáp treo dài nhất thế giới và Sạn Đạo Kính - con đường kính nổi tiếng trên vách núi. Đứng từ đỉnh núi, du khách có thể ngắm toàn cảnh thiên nhiên hùng vĩ, các đỉnh núi và những làn mây trắng lững lờ trôi, tạo nên một không gian như chốn bồng lai tiên cảnh.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cotran20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran23} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Hồ Bảo Phong
</div>
<div class='pb-3 fs-5' >
Hồ Bảo Phong nằm trong khu thắng cảnh Vũ Lăng Nguyên, nổi tiếng với phong cảnh thơ mộng và vẻ đẹp tự nhiên tuyệt vời. Du khách có thể thả thuyền trên hồ, ngắm nhìn những dãy núi xanh ngắt và nghe những bài hát truyền thống du dương của người dân địa phương.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cotran24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran27} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Cầu Kính Đại Hiệp Cốc
</div>
<div class='pb-3 fs-5' >
Cầu Kính Đại Hiệp Cốc là cầu kính dài 430m và cao 300m, nổi bật với thiết kế ấn tượng và cảnh quan tuyệt đẹp. Được xây dựng trên vách đá, cầu mang đến trải nghiệm đầy cảm giác hồi hộp cho du khách khi ngắm nhìn vẻ đẹp hùng vĩ của công viên Trương Gia Giới bên dưới.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cotran28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran31} class='rounded-3' width='49%' height='220'  />
</div>
</div>
`;
export const tour_singapore = `
<div class=' fs-1 ' >
Tour Singapore
</div>
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.singapore1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.singapore2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.singapore3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.singapore1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.singapore2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.singapore3} class='rounded-3' width='49%' height='220' />
</div>
</div>
<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5  fs-5' >
• Khám Phá Sân bay Changi: Trải nghiệm sân bay nổi tiếng với kiến trúc ấn tượng và các tiện ích độc đáo như khu vườn trong nhà và thác nước trong nhà lớn nhất thế giới
</div>
 <div class='ps-5 fs-5'>
• Tham Quan Đảo Sentosa: Tận hưởng các hoạt động giải trí đa dạng, từ bãi biển đẹp đến các công viên giải trí hấp dẫn như Universal Studios Singapore
</div>
<div class='ps-5 fs-5'>
• Chiêm Ngưỡng Bay South Gardens by the Bay: Tham quan công viên sinh thái độc đáo với những "siêu cây" và khu nhà kính, nơi có nhiều loài thực vật đa dạng và phong phú
</div>
<div class='ps-5 fs-5'>
• Thưởng Thức Vịnh Marina Bay: Dạo chơi bên bờ vịnh, chiêm ngưỡng cảnh quan hiện đại của thành phố và các công trình nổi tiếng như Marina Bay Sands
</div>
<div class='ps-5 fs-5'>
• Khám Phá Công viên sư tử biển Merlion Park: Chụp hình bên biểu tượng Merlion của Singapore, hòa mình vào không khí nhộn nhịp và ngắm nhìn vẻ đẹp của vịnh Marina
</div>
</div>
<div class='fw-bolder fs-5 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
SINGAPORE
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Sân bay Changi
</div>
<div class='pb-3 fs-5' >
Được biết đến là một trong những sân bay tốt nhất thế giới, Changi nổi bật với kiến trúc hiện đại, các khu vườn trong nhà, và nhiều tiện ích giải trí hấp dẫn cho hành khách.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.singapore4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore7} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Đảo Sentosa
</div>
<div class='pb-3 fs-5' >
Hòn đảo nghỉ dưỡng nổi tiếng với bãi biển tuyệt đẹp, công viên giải trí, khu nghỉ dưỡng sang trọng, và nhiều hoạt động vui chơi giải trí cho cả gia đình.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.singapore8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore11} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Universal Studios
</div>
<div class='pb-3 fs-5' >
Công viên giải trí theo chủ đề đầu tiên ở Đông Nam Á, nơi du khách có thể thưởng thức các trò chơi cảm giác mạnh, biểu diễn hấp dẫn và trải nghiệm thế giới phim ảnh nổi tiếng.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.singapore12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore15} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Bay South Gardens by the Bay
</div>
<div class='pb-3 fs-5' >
Công viên sinh thái độc đáo với những "siêu cây" năng lượng mặt trời, vườn hoa tuyệt đẹp và khu nhà kính đa dạng, tạo nên không gian xanh mát giữa lòng thành phố.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.singapore16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore19} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Nhà hát Esplanade
</div>
<div class='pb-3 fs-5' >
Trung tâm nghệ thuật biểu diễn nổi bật với kiến trúc độc đáo, nơi tổ chức nhiều sự kiện văn hóa, hòa nhạc và biểu diễn nghệ thuật đẳng cấp quốc tế.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.singapore20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore23} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Vịnh Marina Bay
</div>
<div class='pb-3 fs-5' >
Khu vực đẹp tuyệt vời với cảnh quan hiện đại, bao gồm Marina Bay Sands, Gardens by the Bay và nhiều địa điểm giải trí khác, mang lại trải nghiệm tuyệt vời cho du khách.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.singapore24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore27} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Công viên sư tử biển Merlion Park
</div>
<div class='pb-3 fs-5' >
Biểu tượng của Singapore, Merlion là bức tượng nửa sư tử nửa cá, thu hút hàng triệu du khách đến tham quan và chụp hình bên bờ vịnh Marina.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.singapore28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore31} class='rounded-3' width='49%' height='220'  />
</div>
</div>
`;
export const tour_thailan = `
<div class=' fs-1' >
Tour Thái Lan: Bangkok - Pattaya
</div>
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.thailan1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.thailan2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.thailan3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.thailan1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.thailan2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.thailan3} class='rounded-3' width='49%' height='220' />
</div>
</div>
<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5' >
• Thăm quan vườn nhiệt đới Nong Nooch
</div>
 <div class='ps-5 fs-5'>
• Tham quan bảo tàng nghệ thuật ánh sáng và khinh khí cầu
</div>
<div class='ps-5 fs-5'>
• Show Alcazar/Colosseum
</div>
<div class='ps-5 fs-5 '>
• Đi tàu cao tốc và tắm biển tại đảo san hô – Coral Island
</div>
<div class='ps-5 fs-5 '>
• Khách sạn tiêu chuẩn 4* tại Bangkok và 5* tại Pattaya
</div>
<div class='ps-5 fs-5 '>
• Bay hàng không vietnam airline
</div>
<div class='ps-5 fs-5 '>
• Trải nghiệm ăn tối trên du thuyền 5* sông Chaophraya
</div>
<div class='ps-5 fs-5 '>
• Lễ phật bốn mặt vô cùng linh thiêng tại đền thờ Erawan
</div>
</div>
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Bangkok - Pattaya
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Trân Bảo Phật Sơn Khao Chee Chan
</div>
<div class='pb-3 fs-5' >
Là ngọn núi nổi tiếng với bức tượng Phật khắc bằng vàng lớn nhất Thái Lan, cao 130m và được chế tác từ 999kg vàng. Xung quanh là khu vườn xinh đẹp, mang đến không gian yên bình cho du khách chiêm bái và thưởng ngoạn.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thailan4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan7} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Wat Phra Yai (Chùa Phật Lớn)
</div>
<div class='pb-3 fs-5' >
Nằm trên đồi Pratumnak, chùa có bức tượng Phật cao 18m, được xây dựng từ những năm 1940. Đây là ngôi chùa linh thiêng, thu hút đông đảo du khách và người dân địa phương đến cầu nguyện, ngắm nhìn vẻ đẹp hùng vĩ của bức tượng.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thailan8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan11} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Đảo San Hô (Coral Island)
</div>
<div class='pb-3 fs-5' >
Là điểm đến lý tưởng cho những ai yêu thích biển xanh và cát trắng. Du khách có thể tham gia nhiều hoạt động thể thao dưới nước như lướt ván, lặn biển và tắm nắng trên bãi biển tuyệt đẹp, tận hưởng không khí trong lành của thiên nhiên.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thailan12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan15} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Vườn Nhiệt Đới Nong Nooch
</div>
<div class='pb-3 fs-5' >
Khu vườn nhiệt đới nổi tiếng với những cảnh quan thiên nhiên tuyệt đẹp, đa dạng loài thực vật và các chương trình biểu diễn văn hóa. Đây là nơi lý tưởng để khám phá vẻ đẹp tự nhiên và tìm hiểu về văn hóa Thái Lan.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thailan16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan19} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Bảo Tàng Nghệ Thuật Ánh Sáng và Khinh Khí Cầu
</div>
<div class='pb-3 fs-5' >
Một địa điểm nghệ thuật độc đáo, nơi ánh sáng và nghệ thuật kết hợp để tạo ra những trải nghiệm trực quan sống động. Du khách có thể khám phá các tác phẩm nghệ thuật mô phỏng các địa danh nổi tiếng của Thái Lan.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thailan20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan23} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Chùa Thuyền (Wat Yannawa)
</div>
<div class='pb-3 fs-5' >
Chùa có hình dáng như một con thuyền buôn Trung Hoa, mang đậm kiến trúc Thái Lan. Nơi đây có bức tượng nhà vua Rama I, tạo nên không khí trang nghiêm, giúp du khách cảm nhận được sự thanh tịnh và vẻ đẹp văn hóa Thái.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thailan24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan27} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Sông Chaophraya
</div>
<div class='pb-3 fs-5' >
Được mệnh danh là "dòng sông của các vị vua", Chaopraya chảy qua trung tâm Bangkok, mang lại vẻ đẹp thơ mộng cho thành phố. Du khách có thể thưởng ngoạn phong cảnh sông nước, khám phá các điểm đến lịch sử và văn hóa ven sông.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thailan28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan31} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
8. Đền Phật Bốn Mặt Erawan
</div>
<div class='pb-3 fs-5' >
Là một trong những ngôi đền linh thiêng nhất Thái Lan, thu hút đông đảo du khách và người dân địa phương đến cầu nguyện. Đền nổi tiếng với bức tượng Phật Bốn Mặt, biểu tượng của sự may mắn và thịnh vượng.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan32} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan33} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan34} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan35} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thailan32} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan33} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan34} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan35} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
9. Central World
</div>
<div class='pb-3 fs-5' >
Là trung tâm mua sắm lớn nhất Thái Lan, Central World không chỉ là nơi mua sắm mà còn là địa điểm giải trí sôi động. Tại đây, du khách có thể tìm thấy hàng ngàn sản phẩm từ thời trang đến ẩm thực, cùng với các sự kiện hấp dẫn diễn ra thường xuyên.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan36} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan37} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan38} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan39} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thailan36} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan37} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan38} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan39} class='rounded-3' width='49%' height='220'  />
</div>
</div>
`;
export const tour_trungquoc = `
<div class=' fs-1' >
Tour Hải Nam - Hải Khẩu - Tam Á
</div>
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.hainam1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.hainam2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.hainam3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.hainam1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.hainam2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.hainam3} class='rounded-3' width='49%' height='220' />
</div>
</div>
<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5' >
• Khám Phá Di Sản Văn Hóa: Tham quan Phố cổ Kỳ Lầu với kiến trúc cổ kính và lịch sử hơn 700 năm, mang lại cái nhìn sâu sắc về văn hóa và truyền thống của Trung Quốc
</div>
<div class='ps-5 fs-5'>
• Trải Nghiệm Hải Hoa Đảo: Khám phá Hải Hoa Đảo, hòn đảo nhân tạo lớn nhất thế giới với nhiều hoạt động giải trí hấp dẫn, từ bãi biển tuyệt đẹp đến các khu vui chơi và ẩm thực độc đáo
</div>
<div class='ps-5 fs-5'>
• Thiên Nhiên Hùng Vĩ tại Tam Á: Thưởng ngoạn cảnh đẹp tại khu du lịch văn hóa Nam Sơn với bức tượng Quan Âm nổi tiếng và khung cảnh núi biển hòa quyện tuyệt đẹp
</div>
<div class='ps-5 fs-5 '>
• Thư Giãn tại Bãi Biển: Tận hưởng không gian thư giãn tại Vịnh Á Long, với bãi cát trắng mịn và nước biển trong xanh, là nơi lý tưởng cho các hoạt động tắm biển và vui chơi
</div>
<div class='ps-5 fs-5 '>
• Trải Nghiệm Độc Đáo: Bay trực thăng trên Đảo Phượng Hoàng, mang đến cảm giác phiêu lưu và cái nhìn toàn cảnh đẹp mắt về vùng biển tuyệt vời của Tam Á
</div>
</div>
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Hải Nam - Hải Khẩu - Tam Á
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Phố Cổ Kỳ Lầu
</div>
<div class='pb-3 fs-5' >
Nằm ở thành phố Hải Khẩu, Phố cổ Kỳ Lầu là một trong những địa điểm du lịch nổi tiếng với kiến trúc lịch sử độc đáo. Đặc biệt, tòa nhà Sipai Lou có lịch sử hơn 700 năm, mang đậm nét văn hóa và lịch sử Trung Quốc, thu hút du khách đến khám phá và chụp ảnh.
</div>
 <div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hainam4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hainam4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hainam5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hainam6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hainam7} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Hải Hoa Đảo
</div>
<div class='pb-3 fs-5' >
Được mệnh danh là "Dubai của Phương Đông," Hải Hoa Đảo là hòn đảo nhân tạo lớn nhất thế giới. Nơi đây có nhiều điểm tham quan thú vị như bãi biển, công viên giải trí và khu vực ẩm thực phong phú, là điểm đến lý tưởng cho những ai yêu thích sự sôi động và thư giãn.
</div>
 <div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hainam8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hainam8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hainam9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hainam10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hainam11} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Tam Á
</div>
<div class='pb-3 fs-5' >
Là một trong những địa điểm du lịch biển nổi tiếng tại Trung Quốc, Tam Á thu hút du khách bởi bãi biển đẹp và khí hậu ôn hòa. Nơi đây cung cấp nhiều hoạt động giải trí dưới nước và khám phá thiên nhiên tuyệt đẹp.
</div>
 <div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hainam12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hainam12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hainam13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hainam14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hainam15} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Khu du lịch văn hóa Nam Sơn
</div>
<div class='pb-3 fs-5' >
Đây là một trong những danh lam thắng cảnh nổi bật của Trung Quốc, kết hợp văn hóa Phật giáo với cảnh sắc thiên nhiên. Tượng Quan Âm cao 108m là điểm nhấn chính, thu hút đông đảo du khách đến cầu nguyện và chiêm ngưỡng.
</div>
 <div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hainam16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hainam16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hainam17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hainam18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hainam19} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Thiên Nhai Hải Giác
</div>
<div class='pb-3 fs-5' >
Nằm bên bờ biển, Thiên Nhai Hải Giác là danh lam thắng cảnh cấp cao với phong cảnh tự nhiên tuyệt đẹp, bao gồm biển xanh, cát trắng và những khối đá kỳ vĩ. Đây là nơi lý tưởng để thư giãn và chụp những bức ảnh ấn tượng.
</div>
 <div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hainam20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hainam20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hainam21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hainam22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hainam23} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Vịnh Á Long
</div>
<div class='pb-3 fs-5' >
Với bãi biển trải dài 7 km và nước biển trong xanh, Vịnh Á Long được biết đến là "vịnh đẹp nhất thế giới." Đây là điểm đến lý tưởng cho những ai yêu thích sự bình yên và vẻ đẹp thiên nhiên.
</div>
 <div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hainam24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hainam24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hainam25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hainam26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hainam27} class='rounded-3' width='49%' height='220'  />
</div>
`;
export const ky_thuat_tai_uc = `
 
   <div class='d-flex flex-column align-items-center'>
	<img src=${ProgramImages.uc} class='w-100' />
   </div>
<div class='fw-bolder fs-1  text-success py-3'>
Tại Sao Học Kỹ Thuật Tại Úc Là Lựa Chọn Hoàn Hảo Cho Bạn?
</div>
<div class='ps-5  fs-5'>
Học kỹ thuật luôn là một trong những lựa chọn hàng đầu của các bạn trẻ, đặc biệt khi hướng tới những cơ hội nghề nghiệp tiềm năng và môi trường học tập tiên tiến. Úc, với hệ thống giáo dục đẳng cấp thế giới, đang ngày càng khẳng định vị thế là điểm đến lý tưởng cho những ai muốn theo đuổi lĩnh vực kỹ thuật. Vậy tại sao học kỹ thuật tại Úc là lựa chọn hoàn hảo cho bạn? Dưới đây là những lý do thuyết phục mà bạn không thể bỏ qua. </div>
<div class='fw-bolder text-success fs-4'>
1. CHẤT LƯỢNG GIÁO DỤC ĐẲNG CẤP THẾ GIỚI </div>
<div class='ps-5  fs-5'>
• Úc tự hào là nơi có nhiều trường đại học hàng đầu thế giới về đào tạo kỹ thuật, bao gồm Đại học Melbourne, Đại học Sydney, Đại học New South Wales (UNSW), và Đại học Monash. Các trường này liên tục được xếp hạng cao trong bảng xếp hạng toàn cầu như QS World Rankings hay Times Higher Education.  </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Điểm nổi bật trong giáo dục kỹ thuật tại Úc: </div>
<div class='fs-5 ps-5'>
• Chương trình đào tạo được thiết kế bài bản, cập nhật theo nhu cầu thực tiễn của thị trường lao động toàn cầu. </div>
<div class='fs-5 ps-5'>
• Các khóa học mang tính ứng dụng cao, kết hợp giữa lý thuyết và thực hành, giúp sinh viên sẵn sàng bước vào môi trường làm việc ngay sau khi tốt nghiệp. </div>
<div class='fs-5 ps-5'>
• Đội ngũ giảng viên là các chuyên gia trong ngành, sở hữu kinh nghiệm phong phú và kết nối chặt chẽ với ngành công nghiệp. </div>
<div class='fw-bolder text-success fs-4'>
2. HỆ THỐNG TRANG THIẾT BỊ HIỆN ĐẠI </div>
<div class='fs-5 ps-5'>
• Ngành kỹ thuật đòi hỏi cơ sở vật chất tiên tiến để phục vụ cho nghiên cứu và thực hành. Úc nổi tiếng với các phòng thí nghiệm hiện đại, hệ thống máy móc kỹ thuật công nghệ cao và các trung tâm nghiên cứu đạt chuẩn quốc tế.   
Sinh viên kỹ thuật tại Úc được tiếp cận với: </div>
<div class='fs-5 ps-5'>
• Các dự án nghiên cứu tiên tiến trong các lĩnh vực như trí tuệ nhân tạo, năng lượng tái tạo, kỹ thuật hàng không, và xây dựng bền vững. </div>
<div class='fs-5 ps-5'>
• Các chương trình thực tập tại các tập đoàn lớn như Boeing, Siemens, và BHP, giúp tích lũy kinh nghiệm thực tế quý báu. </div>
<div class='fw-bolder text-success fs-4'>
3. CHƯƠNG TRÌNH HỌC ĐA DẠNG VÀ LINH HOẠT </div>
<div class='ps-5  fs-5'>
• Úc cung cấp nhiều lựa chọn ngành học trong lĩnh vực kỹ thuật, từ kỹ thuật cơ khí, kỹ thuật dân dụng, kỹ thuật điện tử đến kỹ thuật phần mềm và công nghệ sinh học. </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Lợi ích từ sự đa dạng trong chương trình học:  </div>
<div class='fs-5 ps-5'>
• Bạn có thể chọn chuyên ngành phù hợp với sở thích và mục tiêu nghề nghiệp của mình. </div>
<div class='fs-5 ps-5'>
Nhiều trường đại học tại Úc còn cho phép sinh viên kết hợp các chuyên ngành để mở rộng kiến thức và cơ hội việc làm sau này.  </div>
<div class='fw-bolder text-success fs-4'>
4. CƠ HỘI NGHỀ NGHIỆP RỘNG MỞ </div>
<div class='ps-5  fs-5'>
Úc đang phải đối mặt với tình trạng thiếu hụt lao động kỹ thuật chất lượng cao, điều này mang lại lợi thế lớn cho sinh viên ngành kỹ thuật. Sau khi tốt nghiệp, bạn có thể dễ dàng tìm kiếm việc làm trong các lĩnh vực như xây dựng, công nghệ thông tin, kỹ thuật môi trường, và năng lượng. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Ưu điểm khi làm việc tại Úc: </div>
<div class='fs-5 ps-5'>
• Chính sách thị thực làm việc sau tốt nghiệp (Post-Study Work Visa) cho phép bạn ở lại Úc làm việc từ 2-4 năm. </div>
<div class='fs-5 ps-5'>
• Mức lương cạnh tranh, với mức lương khởi điểm cho kỹ sư mới tốt nghiệp dao động từ 60.000 đến 80.000 AUD/năm. </div>
<div class='fs-5 ps-5'>
• Cơ hội định cư cao nhờ các chính sách ưu tiên định cư cho ngành kỹ thuật. </div>
<div class='fw-bolder text-success fs-4'>
5. MÔI TRƯỜNG SỐNG VÀ HỌC TẬP LÝ TƯỞNG </div>
<div class='ps-5  fs-5'>
• Úc không chỉ nổi tiếng với hệ thống giáo dục hàng đầu mà còn là một quốc gia có chất lượng cuộc sống cao. Các thành phố lớn như Sydney, Melbourne, Brisbane và Perth đều nằm trong danh sách những thành phố đáng sống nhất thế giới. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
 Những điểm hấp dẫn của cuộc sống tại Úc: </div>
<div class='fs-5 ps-5'>
• Xã hội an toàn, đa văn hóa và thân thiện.</div>
<div class='fs-5 ps-5'>
• Khí hậu ôn hòa, dễ chịu, phù hợp với du học sinh từ khắp nơi trên thế giới. </div>
<div class='fs-5 ps-5'>
• Nhiều cơ hội tham gia các hoạt động ngoại khóa, từ thể thao, nghệ thuật đến các sự kiện cộng đồng. </div>
<div class='fw-bolder text-success fs-4'>
6. CƠ HỘI KẾT NỐI TOÀN CẦU </div>
<div class='ps-5  fs-5'>
• Du học Úc mang lại cơ hội mở rộng mạng lưới quan hệ quốc tế. Sinh viên kỹ thuật tại đây thường xuyên được tham gia các hội thảo, cuộc thi sáng tạo kỹ thuật và các chương trình trao đổi sinh viên quốc tế. </div>
<div class='ps-3 fw-bolder  fs-5'>
Bạn sẽ có cơ hội: </div>
<div class='fs-5 ps-5'>
• Làm quen với bạn bè từ khắp nơi trên thế giới, mở rộng hiểu biết và kỹ năng giao tiếp đa văn hóa. </div>
<div class='fs-5 ps-5'>
• Hợp tác với các doanh nghiệp lớn thông qua các dự án thực tập và nghiên cứu. </div>
<div class='fw-bolder text-success fs-4'>
7. HỌC BỔNG VÀ HỖ TRỢ TÀI CHÍNH </div>
<div class='ps-5  fs-5'>
• Chi phí du học Úc có thể là một rào cản, nhưng đừng lo lắng, các trường đại học tại Úc cung cấp rất nhiều chương trình học bổng dành cho sinh viên quốc tế, đặc biệt là trong lĩnh vực kỹ thuật. </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Một số học bổng tiêu biểu: </div>
<div class='fs-5 ps-5'>
• Học bổng Chính phủ Úc (Australia Awards Scholarships). </div>
<div class='fs-5 ps-5'>
• Học bổng từ các trường đại học như UNSW Engineering International Scholarships, Melbourne Graduate Research Scholarships. </div>
<div class='fs-5 ps-5'>
• Học bổng hỗ trợ từ các tổ chức công nghiệp. </div>
<div class='ps-5  fs-5'>
• Với chất lượng giáo dục hàng đầu, cơ sở vật chất hiện đại, cơ hội nghề nghiệp rộng mở và môi trường sống lý tưởng, Úc chính là lựa chọn hoàn hảo cho những ai muốn theo đuổi ngành kỹ thuật. Nếu bạn đang tìm kiếm một nơi để phát triển bản thân và xây dựng sự nghiệp bền vững, hãy cân nhắc đến Úc – vùng đất của những cơ hội và thành công.
Hãy bắt đầu hành trình chinh phục giấc mơ kỹ thuật của bạn tại Úc ngay hôm nay! </div>
`;
export const  top5_hanquoc = `
 
   <div class='d-flex flex-column align-items-center'>
	<img src=${ProgramImages.han} class='w-100' />
   </div>
<div class='fw-bolder fs-1  text-success py-3'>
Top 5 Khu Vực Nên Chọn Khi Đi Du Học Ở Hàn Quốc
</div>
<div class='ps-5  fs-5'>
Hàn Quốc, một trong những điểm đến hấp dẫn của du học sinh quốc tế, không chỉ nổi tiếng với nền giáo dục chất lượng mà còn sở hữu những thành phố sôi động, môi trường sống hiện đại và nền văn hóa phong phú. Tuy nhiên, việc chọn khu vực học tập phù hợp không hề dễ dàng, bởi mỗi nơi đều có những điểm mạnh riêng. Dưới đây là danh sách 5 khu vực nổi bật nhất tại Hàn Quốc mà bạn nên cân nhắc khi du học. </div>
<div class='fw-bolder text-success fs-4'>
1. SEOUL - TRÁI TIM CỦA HÀN QUỐC?  </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Tại sao nên chọn Seoul?  </div>
<div class='ps-5  fs-5'>
• Seoul, thủ đô và trung tâm kinh tế, chính trị, văn hóa của Hàn Quốc, là nơi lý tưởng cho du học sinh quốc tế. Thành phố này không chỉ sở hữu những trường đại học hàng đầu như Đại học Quốc gia Seoul, Đại học Yonsei và Đại học Korea mà còn mang đến cơ hội tiếp xúc với công nghệ hiện đại, mạng lưới giao thông thuận tiện và cuộc sống năng động.  </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Điểm nổi bật: </div>
<div class='ps-5 fs-5'>
• Các chương trình học bổng đa dạng từ các trường danh tiếng. </div>
<div class='ps-5 fs-5'>
• Cơ hội việc làm thêm tại các công ty, quán cà phê và trung tâm thương mại. </div>
<div class='ps-5 fs-5'>
• Văn hóa giải trí phát triển mạnh với những khu phố như Hongdae, Myeongdong và Gangnam. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Hạn chế:  </div>
<div class='ps-5 fs-5'>
• Chi phí sinh hoạt cao, đặc biệt là tiền thuê nhà và chi phí ăn uống. </div>
<div class='fw-bolder text-success fs-4'>
2. BUSAN - THÀNH PHỐ BIỂN NĂNG ĐỘNG  </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Tại sao nên chọn Busan?  </div>
<div class='ps-5 fs-5'>
• Busan, thành phố lớn thứ hai của Hàn Quốc, là điểm đến hoàn hảo cho những ai yêu thích cuộc sống gần biển. Với các trường đại học chất lượng như Đại học Quốc gia Busan, thành phố này mang đến một môi trường học tập thoải mái và giá cả hợp lý hơn so với Seoul. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Điểm nổi bật: </div>
<div class='ps-5 fs-5'>
• Khí hậu dễ chịu, thích hợp với những ai yêu thiên nhiên và biển cả.  </div>
<div class='ps-5 fs-5'>
• Chi phí sinh hoạt thấp hơn, đặc biệt là giá thuê nhà.  </div>
<div class='ps-5 fs-5'>
• Các sự kiện văn hóa độc đáo như Liên hoan phim Quốc tế Busan (BIFF).  </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Hạn chế: </div>
<div class='ps-5 fs-5'>
• Ít cơ hội làm thêm hơn so với Seoul. </div>
<div class='ps-5 fs-5'>
• Cách xa trung tâm kinh tế và chính trị của Hàn Quốc. </div>
<div class='fw-bolder text-success fs-4'>
3. DAEGU - TRUNG TÂM CỦA GIÁO DỤC MIỀN TRUNG HÀN QUỐC 
</div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Tại sao nên chọn Daegu? </div>
<div class='ps-5 fs-5'>
• Daegu nổi tiếng là trung tâm giáo dục và văn hóa của miền Trung Hàn Quốc, với các trường đại học uy tín như Đại học Kyungpook. Thành phố này thích hợp cho những ai muốn học tập trong một môi trường yên tĩnh nhưng không kém phần hiện đại. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Điểm nổi bật:  </div>
<div class='ps-5 fs-5'>
• Học phí và chi phí sinh hoạt thấp. </div>
<div class='ps-5 fs-5'>
• Cơ hội khám phá văn hóa truyền thống qua các lễ hội và di sản lịch sử. </div>
<div class='ps-5 fs-5'>
• Hệ thống giao thông hiện đại, dễ dàng kết nối với các thành phố lớn khác. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Hạn chế: </div>
<div class='ps-5 fs-5'>
• Không có nhiều hoạt động giải trí so với Seoul và Busan. </div>
<div class='fw-bolder text-success fs-4'>
4. Incheon – Cửa ngõ quốc tế của Hàn Quốc </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Tại sao nên chọn Incheon?  </div>
<div class='ps-5 fs-5'>
• Là nơi có sân bay quốc tế lớn nhất Hàn Quốc, Incheon không chỉ là cửa ngõ kết nối thế giới mà còn là một trung tâm giáo dục đang phát triển nhanh chóng. Các trường đại học nổi bật ở đây bao gồm Đại học Inha và Đại học Quốc gia Incheon.   </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Điểm nổi bật:  </div>
<div class='ps-5 fs-5'>
• Gần Seoul, thuận tiện cho việc đi lại. </div>
<div class='ps-5 fs-5'>
• Cơ hội tham gia các chương trình học tập quốc tế.</div>
<div class='ps-5 fs-5'>
• Môi trường sống hiện đại và năng động. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Hạn chế: </div>
<div class='ps-5 fs-5'>
• Không khí có thể bị ảnh hưởng bởi ô nhiễm từ các khu công nghiệp. </div>
<div class='fw-bolder text-success fs-4'>
5. GWANGJU - THÀNH PHỐ VĂN HOÁ VÀ NGHỆ THUẬT </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Tại sao nên chọn Gwangju? </div>
<div class='ps-5 fs-5'>
• Gwangju, thành phố nổi tiếng với di sản văn hóa và nghệ thuật, là nơi lý tưởng cho những du học sinh yêu thích sự sáng tạo. Đại học Chosun và Đại học Quốc gia Gwangju là những trường học tiêu biểu tại đây.  </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Điểm nổi bật: </div>
<div class='ps-5 fs-5'>
• Môi trường học tập yên bình, tập trung cao cho việc nghiên cứu. </div>
<div class='ps-5 fs-5'>
• Chi phí sinh hoạt thấp, phù hợp với du học sinh. </div>
<div class='ps-5 fs-5'>
• Nhiều hoạt động văn hóa như triển lãm nghệ thuật, lễ hội âm nhạc. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Hạn chế: </div>
<div class='ps-5 fs-5'>
• Ít cơ hội tiếp xúc với môi trường kinh doanh quốc tế. </div>
<div class='ps-5 fs-5'>
• Việc lựa chọn khu vực du học ở Hàn Quốc phụ thuộc vào sở thích, mục tiêu học tập và khả năng tài chính của mỗi cá nhân. Nếu bạn muốn trải nghiệm sự hiện đại và náo nhiệt, Seoul là lựa chọn hàng đầu. Ngược lại, nếu bạn tìm kiếm một môi trường học tập bình yên và chi phí hợp lý, Busan hay Daegu sẽ phù hợp hơn. Hãy cân nhắc kỹ lưỡng và chọn cho mình một điểm đến phù hợp để có trải nghiệm du học tuyệt vời tại Hàn Quốc! </div>
`;
export const  camnang_duhoc_nendiduhocnhatbanhayhanquoc = `
 
   <div class='d-flex flex-column align-items-center'>
	<img src=${ProgramImages.nhatban} class='w-100' />
   </div>
<div class='fw-bolder fs-1  text-success py-3'>
Nên đi du học Nhật Bản hay Hàn Quốc?
</div>
<div class='ps-5 pb-3 fs-5'>
- Khi cân nhắc giữa việc du học Nhật Bản và Hàn Quốc, bạn cần xem xét nhiều yếu tố như điều kiện nhập học, chi phí, môi trường học tập, cơ hội việc làm và văn hóa. Dưới đây là phân tích chi tiết giúp bạn đưa ra quyết định phù hợp. </div>
<div class='fw-bolder text-success pb-3 fs-4'>
ĐIỀU KIỆN DU HỌC
</div>
<div class='ps-3 fw-bolder  fs-5'>
Độ tuổi: </div>

<div class='ps-5 pb-3 fs-5'>
- Cả Nhật Bản và Hàn Quốc đều chấp nhận du học sinh từ 18 đến 30 tuổi, tùy thuộc vào chương trình học.</div>
<div class=' ps-3 fw-bolder  fs-5'>
Trình độ ngôn ngữ: </div>
<div class='ps-3 fw-bolder  fs-5'>
+ Nhật Bản: </div>
<div class='ps-5  fs-5'>
- Chương trình tiếng Nhật: Yêu cầu tối thiểu N5 hoặc đã học cơ bản ít nhất 150 giờ tại Việt Nam.</div>
<div class='ps-5  fs-5'>
- Chương trình Đại học: Yêu cầu tối thiểu N2.</div>
<div class='ps-5  fs-5'>
- Chương trình tiếng Anh: Không yêu cầu tiếng Nhật đầu vào.</div>
<div class='ps-3 fw-bolder  fs-5'>
+ Hàn Quốc: </div>
<div class='ps-5  fs-5'>
- Chương trình tiếng Hàn: Không yêu cầu TOPIK nhưng cần học cơ bản 3-6 tháng tại Việt Nam. </div>
<div class='ps-5  fs-5'>
- Chương trình Đại học: Yêu cầu tối thiểu TOPIK 3 hoặc 4. </div>
<div class='ps-5 pb-3 fs-5'>
- Chương trình tiếng Anh: Không yêu cầu tiếng Hàn đầu vào. </div>
<div class=' ps-3 fw-bolder  fs-5'>
Trình độ học vấn: </div>
<div class='ps-5  fs-5'>
- Nhật Bản: Tốt nghiệp THPT với điểm trung bình trên 6.5.  </div>
<div class='ps-5 pb-3 fs-5'>
- Hàn Quốc: Tốt nghiệp THPT với điểm trung bình trên 6.0.  </div>
<div class=' ps-3 fw-bolder  fs-5'>
Chứng minh tài chính:  </div>
<div class='ps-5  fs-5'>
- Nhật Bản: Sổ tiết kiệm tối thiểu 500 – 600 triệu VNĐ, mở trước ít nhất 3 tháng. </div>
<div class='ps-5 pb-3 fs-5'>
- Hàn Quốc: Hệ tiếng: Sổ ngân hàng tối thiểu 10.000 USD; Hệ Đại học/Thạc sĩ: Sổ ngân hàng tối thiểu 20.000 USD. </div>

<div class='fw-bolder pb-3 text-success fs-4'>
THỜI GIAN DU HỌC </div>
<div class='ps-5  fs-5'>
- Thời gian học tập tại Nhật Bản và Hàn Quốc tương đương nhau đối với các chương trình Đại học, Cao đẳng, Thạc sĩ và học nghề. Riêng chương trình tiếng, thời gian học phụ thuộc vào từng trường và kỳ nhập học. </div>
<div class='ps-5  fs-5'>
- Nhật Bản: Kỳ nhập học vào tháng 1, 4, 7, 10. </div>
<div class='ps-5  fs-5 pb-3'>
- Hàn Quốc: Kỳ nhập học vào tháng 3, 6, 9, 12. </div>
<div class='fw-bolder text-success pb-3 fs-4'>
CHI PHÍ DU HỌC </div>
<div class=' ps-3 fw-bolder  fs-5'>
Học phí: </div>
<div class='ps-5  fs-5'>
- Nhật Bản: Học phí năm đầu khoảng 160 – 215 triệu VNĐ, tùy khu vực và trường học. </div>
<div class='ps-5 pb-3 fs-5'>
- Hàn Quốc: Học phí dao động từ 3.000 – 6.000 USD/năm, tùy ngành học và trường. </div>
<div class='ps-3 fw-bolder  fs-5'>
Chi phí sinh hoạt: </div>
<div class='ps-5  fs-5'>
- Nhật Bản: Khoảng 800 – 1.000 USD/tháng. </div>
<div class='ps-5 pb-3 fs-5'>
- Hàn Quốc: Khoảng 600 – 800 USD/tháng. </div>

<div class='fw-bolder pb-3 text-success fs-4'>
CƠ HỘI VIỆC LÀM THÊM </div>

<div class='ps-5  fs-5'>
- Cả hai quốc gia đều cho phép du học sinh làm thêm. </div>
<div class='ps-5  fs-5'>
- Nhật Bản: Được làm 28 giờ/tuần với mức lương trung bình 800 – 1.000 JPY/giờ. </div>
<div class='ps-5  fs-5'>
- Hàn Quốc: Được làm 20 – 25 giờ/tuần với mức lương trung bình 7.000 – 10.000 KRW/giờ. </div>

<div class='fw-bolder py-3 text-success fs-4'>
CƠ HỘI VIỆC LÀM SAU TỐT NGHIỆP </div>
<div class='ps-5  fs-5'>
- Nhật Bản và Hàn Quốc đều có nhu cầu nhân lực cao trong các lĩnh vực như kỹ thuật, công nghệ thông tin, y tế và dịch vụ. Tuy nhiên, Nhật Bản có tỷ lệ già hóa dân số cao, dẫn đến nhu cầu tuyển dụng lao động nước ngoài lớn hơn. </div>

<div class='fw-bolder py-3 text-success fs-4'>
VĂN HOÁ VÀ MÔI TRƯỜNG SỐNG
</div>
<div class='ps-5  fs-5'>
- Cả hai quốc gia đều có nền văn hóa phong phú và môi trường sống an toàn. Nhật Bản nổi tiếng với sự kỷ luật và công nghệ tiên tiến, trong khi Hàn Quốc được biết đến với sự năng động và ngành công nghiệp giải trí phát triển. </div>

<div class='fw-bolder text-success py-3 fs-4'>
KẾT LUẬN
</div>
<div class='ps-5  fs-5'>
- Việc lựa chọn du học Nhật Bản hay Hàn Quốc phụ thuộc vào mục tiêu cá nhân, khả năng tài chính và sở thích văn hóa của bạn. Hãy cân nhắc kỹ lưỡng các yếu tố trên để đưa ra quyết định phù hợp nhất cho tương lai của mình. </div>
`;
export const  camnang_dulich_top10baibiendep = `

<div class='fw-bolder fs-1  text-success py-3'>
Top 10 Bãi Biển Đẹp Nhất Miền Trung: Điểm Đến Hoàn Hảo Cho Kỳ Nghỉ
</div>
<div class='ps-5 fs-5'>
Miền Trung Việt Nam được thiên nhiên ưu ái với những bãi biển tuyệt đẹp, trải dài từ Thanh Hóa đến Bình Thuận. Đây là vùng đất hội tụ sự kết hợp hài hòa giữa núi rừng, biển cả và văn hóa bản địa độc đáo. Hãy cùng khám phá 10 bãi biển đẹp nhất miền Trung, nơi bạn có thể tận hưởng trọn vẹn vẻ đẹp tự nhiên và những trải nghiệm nghỉ dưỡng đẳng cấp. </div>

<div class='fw-bolder text-success fs-4'>
1. BÃI BIỂN MỸ KHÊ - ĐÀ NẴNG </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Vẻ đẹp vượt thời gian: </div>
<div class='ps-5 fs-5'>
• Được tạp chí Forbes bình chọn là một trong những bãi biển đẹp nhất thế giới, Mỹ Khê sở hữu bãi cát trắng mịn, làn nước trong xanh và ánh nắng vàng rực rỡ quanh năm. Đây là điểm đến không thể bỏ qua khi ghé thăm Đà Nẵng. </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Hoạt động nổi bật: </div>
<div class='ps-5 fs-5'>
• Ngoài việc tắm biển, Mỹ Khê còn là nơi lý tưởng cho các môn thể thao nước như lướt ván, chèo SUP hay lặn biển. Bạn cũng có thể thư giãn tại các quán café ven biển, ngắm nhìn cảnh hoàng hôn tuyệt đẹp. </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Mẹo du lịch: </div>
<div class='ps-5 fs-5'>
• Hãy đến Mỹ Khê vào sáng sớm để tận hưởng không khí trong lành, hoặc vào buổi chiều để cảm nhận khung cảnh hoàng hôn rực rỡ nhất. </div>
<div class='fw-bolder text-success fs-4'>
2. BÃI BIỂN AN BÀNG - HỘI AN </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Không gian yên bình: </div>
<div class='ps-5 fs-5'>
• Cách phố cổ Hội An khoảng 5km, bãi biển An Bàng mang đến sự yên tĩnh và hoang sơ. Với đường bờ biển dài, cát trắng mịn và làn nước xanh ngọc, đây là nơi lý tưởng để nghỉ ngơi sau những ngày bận rộn. </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Trải nghiệm không thể bỏ qua: </div>
<div class='ps-5 fs-5'>
• Ngoài việc tận hưởng vẻ đẹp thiên nhiên, du khách có thể tham gia các lớp yoga, thưởng thức hải sản tươi ngon tại những quán ăn gia đình hoặc thuê xe đạp để khám phá làng chài gần đó. </div>
<div class='fw-bolder text-success fs-4'>
3. BÃI BIỂN LĂNG CÔ - THỪA THIÊN HUẾ </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Vịnh biển tuyệt đẹp:  </div>
<div class='ps-5 fs-5'>
• Lăng Cô từng được xếp vào danh sách những vịnh biển đẹp nhất thế giới bởi vẻ đẹp hài hòa giữa biển cả, núi non và đầm phá. Nằm gần đèo Hải Vân, nơi đây là một bức tranh thiên nhiên đầy sức hút. </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Các hoạt động hấp dẫn: </div>
<div class='ps-5 fs-5'>
• Tại đây, bạn có thể cắm trại bên bờ biển, khám phá đầm Lập An hay ghé thăm các khu resort cao cấp để trải nghiệm dịch vụ nghỉ dưỡng 5 sao. </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Lưu ý: </div>
<div class='ps-5 fs-5'>
• Nếu đến Lăng Cô vào mùa hè, bạn sẽ có cơ hội thưởng thức hải sản tươi ngon như tôm hùm, mực lá hay ghẹ xanh. </div>
<div class='fw-bolder text-success fs-4'>
4. Bãi Biển Non Nước - Đà Nẵng  </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Kết hợp giữa biển và văn hóa: </div>
<div class='ps-5 fs-5'>
• Non Nước không chỉ là một bãi biển đẹp mà còn nằm gần danh thắng Ngũ Hành Sơn, nơi bạn có thể tham quan các hang động, chùa chiền và làng đá mỹ nghệ truyền thống.
</div>
<div class='py-2 ps-3  fw-bolder fs-5'>
Hoạt động gợi ý: </div>
<div class='ps-5 fs-5'>
• Sau khi thư giãn trên bãi biển, hãy dành thời gian khám phá Ngũ Hành Sơn, nơi bạn có thể ngắm toàn cảnh Đà Nẵng từ trên cao. </div>
<div class='fw-bolder text-success fs-4'>
5. Bãi Biển Nhật Lệ - Quảng Bình </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Thiên đường yên bình: </div>
<div class='ps-5 fs-5'>
• Nằm ngay trung tâm thành phố Đồng Hới, Nhật Lệ nổi bật với vẻ đẹp nguyên sơ, thích hợp cho những ai muốn tìm kiếm sự yên bình và thư giãn. </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Điểm nhấn: 
</div>
<div class='ps-5 fs-5'>
• Du khách có thể kết hợp tham quan các địa điểm nổi tiếng như động Phong Nha – Kẻ Bàng, sông Chày – hang Tối hoặc thưởng thức ẩm thực Quảng Bình tại các nhà hàng ven biển. </div>
<div class='fw-bolder text-success fs-4'>
6. Bãi Biển Cửa Đại - Hội An </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Địa điểm du lịch nổi tiếng: </div>
<div class='ps-5 fs-5'>
• Là một trong những bãi biển nổi tiếng nhất miền Trung, Cửa Đại hấp dẫn bởi vẻ đẹp thoáng đãng, làn nước trong xanh và những hàng dừa rợp bóng mát. </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Hoạt động nổi bật: </div>
<div class='ps-5 fs-5'>
• Tản bộ dọc bờ biển, đạp xe quanh khu vực hoặc tham gia các tour câu cá cùng ngư dân địa phương. Đừng quên thưởng thức món đặc sản bánh bao – bánh vạc nổi tiếng của Hội An. </div>
<div class='fw-bolder text-success fs-4'>
7. Bãi Biển Nha Trang - Khánh Hòa </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Sức hút của thành phố biển: </div>
<div class='ps-5 fs-5'>
• Không chỉ là một bãi biển đẹp, Nha Trang còn là trung tâm du lịch sôi động với hệ thống đảo đẹp, các khu vui chơi giải trí và dịch vụ nghỉ dưỡng đẳng cấp. </div>
<div class='py-2 ps-5 fw-bolder fs-5'>
Trải nghiệm không thể bỏ lỡ:  </div>
<div class='ps-5 fs-5'>
• Lặn biển ngắm san hô tại Hòn Mun, thử dù lượn trên không hoặc khám phá thiên đường giải trí VinWonders Nha Trang. </div>
<div class='fw-bolder text-success fs-4'>
8. Bãi Biển Đại Lãnh - Khánh Hòa </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Vẻ đẹp hoang sơ: </div>
<div class='ps-5 fs-5'>
• Đại Lãnh nằm giữa Phú Yên và Khánh Hòa, nổi bật với cảnh quan hoang sơ, yên bình. Đây là điểm đến lý tưởng cho những ai yêu thích sự tĩnh lặng và gần gũi thiên nhiên. </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Hoạt động thú vị: </div>
<div class='ps-5 fs-5'>
• Tại Đại Lãnh, bạn có thể tham gia các hoạt động như chèo thuyền, câu cá hoặc khám phá làng chài địa phương để hiểu hơn về đời sống của ngư dân. </div>
<div class='fw-bolder text-success fs-4'>
9. Bãi Biển Sa Huỳnh - Quảng Ngãi </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Màu sắc độc đáo: </div>
<div class='py-2 ps-5  fs-5'>
• Sa Huỳnh thu hút du khách với bãi cát vàng óng ánh, khác biệt so với những bãi biển cát trắng khác. Đây là điểm dừng chân lý tưởng trên hành trình khám phá miền Trung. </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Đừng quên: </div>
<div class='ps-5 fs-5'>
• Hãy ghé thăm khu di tích văn hóa Sa Huỳnh để tìm hiểu thêm về lịch sử lâu đời và thưởng thức đặc sản mắm nhum nổi tiếng. </div>
<div class='fw-bolder text-success fs-4'>
10. Bãi Biển Quy Nhơn - Bình Định </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Thành phố của biển cả: </div>
<div class='py-2 ps-5 fs-5'>
• Quy Nhơn không chỉ có những bãi biển đẹp mà còn sở hữu nhiều điểm du lịch nổi tiếng như Eo Gió, Kỳ Co, Hòn Khô. Đây là nơi kết hợp hoàn hảo giữa du lịch biển và khám phá văn hóa. </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Hoạt động nên thử: </div>
<div class='py-2 ps-5  fs-5'>
• Đi thuyền ra đảo, thưởng thức hải sản tươi ngon và tham quan các làng nghề truyền thống như làm bánh tráng, dệt chiếu. </div>
<div class='py-2 ps-5 fs-5'>
• Miền Trung với vẻ đẹp thiên nhiên hùng vĩ và sự thân thiện của người dân địa phương luôn là lựa chọn hàng đầu cho những ai yêu biển. Hãy lên kế hoạch và khám phá những bãi biển tuyệt đẹp này để có những trải nghiệm khó quên! </div>
`;
export const  camnang_dulich_khamphamucangchai = `
<div class='fw-bolder fs-1  text-success py-3'>
Khám Phá Mù Cang Chải – Thiên Đường Giữa Mùa Lúa Vàng Tây Bắc
</div>

<div class='ps-5 fs-5'>
Mù Cang Chải, cách Hà Nội hơn 300 km về phía Bắc, là một điểm đến không thể bỏ qua, đặc biệt trong mùa lúa chín. Nằm tại tỉnh Yên Bái, huyện vùng cao này không chỉ thu hút du khách bởi cảnh đẹp của những thửa ruộng bậc thang vàng óng mà còn bởi các hoạt động văn hóa đặc sắc, những món đặc sản nổi tiếng và không khí mát mẻ của vùng cao. </div>
<div class='fw-bolder fs-3  text-success py-3'>
Mù Cang Chải – Mùa Nào Cũng Đẹp </div>
<div class='ps-5 fs-5'>
• Mù Cang Chải khoác lên mình vẻ đẹp bốn mùa. Vào mùa xuân, khung cảnh nơi đây được nhuộm màu xanh tươi mát của mạ non, những thửa ruộng bậc thang phản chiếu trong làn nước, tạo nên bức tranh vô cùng thơ mộng. Mùa hè là lúc ruộng lúa xanh mướt trải dài khắp nơi, tạo thành một bức tranh đồng quê vô cùng tươi mới. Tuy nhiên, Mù Cang Chải đẹp nhất vào hai mùa đặc biệt: mùa nước đổ và mùa lúa chín. </div>
<div class='fs-5 ps-5'>
• Mùa nước đổ (cuối tháng 5 – đầu tháng 6) là thời điểm tuyệt vời để chiêm ngưỡng những thửa ruộng bậc thang như "tráng gương", nơi mặt nước phản chiếu bầu trời và cảnh vật xung quanh. </div>
<div class='fs-5 ps-5'>
• Mùa lúa chín (tháng 9 - tháng 10) là lúc Mù Cang Chải biến thành một bức tranh vàng óng ả, thu hút rất nhiều nhiếp ảnh gia và du khách tới ngắm nhìn vẻ đẹp kỳ vĩ của thiên nhiên vùng cao. </div>
<div class='ps-5 fs-5'>
• Mùa đông, Mù Cang Chải lại mang một vẻ đẹp rất riêng với những thửa ruộng như bức tranh thủy mặc giữa núi rừng. Đặc biệt, cuối tháng 12 là mùa đào rừng nở rộ, tạo nên một khung cảnh lãng mạn, hấp dẫn những người yêu thích thiên nhiên. </div>
<div class='fw-bolder fs-3  text-success py-3'>
Di Chuyển Đến Mù Cang Chải </div>
<div class='ps-5 fs-5'>
• Mù Cang Chải cách Hà Nội khoảng 300 km, tuy không quá xa nhưng đường đi khá gian nan. Bạn sẽ phải vượt qua những con đèo quanh co, dốc cao nối tiếp nhau. Nếu bạn đi xe khách, các chuyến xe từ bến xe Mỹ Đình sẽ đi qua thị xã Nghĩa Lộ, nơi bạn có thể nghỉ ngơi trước khi tiếp tục hành trình. </div>
<div class='ps-5 fs-5'>
• Một lựa chọn khác là đi xe riêng theo tuyến đường 32. Bạn sẽ bắt đầu từ Mỹ Đình, đi qua Sơn Tây, Ba Vì, Thanh Thuỷ, rồi tiếp tục qua các huyện như Văn Chấn, Tú Lệ và Đèo Khau Phạ, nơi bạn sẽ tiếp tục di chuyển để đến thị trấn Mù Cang Chải. Đoạn đường này khá dài, nhưng mang đến cơ hội cho bạn chiêm ngưỡng vẻ đẹp của cảnh vật miền núi Tây Bắc. </div>
<div class='fw-bolder fs-3  text-success py-3'>
Lưu Trú Tại Mù Cang Chải </div>
<div class='ps-5 fs-5'>
• Mù Cang Chải chủ yếu có các loại hình lưu trú dân dã như nhà sàn, homestay, hoặc khách sạn bình dân. Tại các bản làng Thái, bạn có thể nghỉ trong những ngôi nhà sàn truyền thống, nơi du khách không chỉ được nghỉ ngơi mà còn có thể trải nghiệm đời sống của người dân bản địa. Các khu nghỉ dưỡng như Le Champ Tú Lệ cũng cung cấp dịch vụ nghỉ dưỡng cao cấp hơn, với không gian yên tĩnh và cảnh quan thiên nhiên tuyệt đẹp. </div>
<div class='ps-5 fs-5'>
• Ngoài ra, nếu muốn trải nghiệm một phong cách nghỉ dưỡng sinh thái, bạn có thể thử các "Ecolodge" như Hello MCC hoặc MCC Eco Lodge ở La Pán Tẩn, nơi bạn có thể thư giãn giữa núi rừng và ruộng bậc thang. </div>
<div class='fw-bolder fs-1  text-success py-3'>
Các Điểm Tham Quan Nổi Bật </div>
<div class='fw-bolder text-success fs-4'>
ĐÈO KHAU PHẠ: </div>
<div class='ps-5 fs-5'>
• Là một trong "tứ đại đỉnh đèo" của Việt Nam, Đèo Khau Phạ nổi tiếng với cảnh đẹp hoang sơ và khí hậu mát mẻ. Tại đây, bạn có thể tham gia lễ hội dù lượn, bay trên bầu trời trong mùa lúa chín và ngắm nhìn thung lũng Lìm Mông, Lìm Thái từ trên cao. </div>
<div class='fw-bolder text-success fs-4'>
THUNG LŨNG LÌM MÔNG VÀ LÌM THÁI </div>
<div class='ps-5 fs-5'>
• Đây là những khu vực có bản làng người Thái nằm dưới chân đèo Khau Phạ. Bạn có thể đi bộ giữa những ruộng lúa chín vàng, chụp ảnh cùng người dân địa phương hoặc thưởng thức các món ăn đặc sản của vùng. </div>
<div class='fw-bolder text-success fs-4'>
ĐỒI MÂM XÔI </div>
<div class='ps-5 fs-5'>
• Một trong những địa điểm nổi tiếng ở Mù Cang Chải, đặc biệt vào mùa lúa chín. Đồi này có những thửa ruộng bậc thang tuyệt đẹp, là điểm đến yêu thích của các nhiếp ảnh gia. </div>
<div class='fw-bolder text-success fs-4'>
RỪNG TRÚC BẢN NẢ HÁNG TỦA </div>
<div class='ps-5 fs-5'>
• Nơi đây có rừng trúc 60 năm tuổi và là điểm đến lý tưởng để tìm hiểu về đời sống của người dân địa phương. Tuy nhiên, bạn cần chú ý vì đường đến đây khá khó khăn, đặc biệt vào mùa mưa. </div>
<div class='fw-bolder text-success fs-4'>
ZIPLINE TÚ LỆ </div>
<div class='ps-5 fs-5'>
• Nếu bạn yêu thích các hoạt động mạo hiểm, không thể bỏ qua tuyến zipline dài 1,2 km tại Tú Lệ, mang lại cơ hội trải nghiệm cảnh đẹp tuyệt vời từ trên cao. </div>
<div class='fw-bolder text-success fs-4'>
SUỐI KHOÁNG NÓNG TRẠM TẤU </div>
<div class='ps-5 fs-5'>
• Cách Mù Cang Chải khoảng 100 km, Trạm Tấu là điểm đến lý tưởng để thư giãn với suối khoáng nóng tự nhiên. Nước nóng ở đây có tác dụng rất tốt cho sức khỏe, đặc biệt là những người bị đau nhức xương khớp. </div>
<div class='fw-bolder fs-3  text-success py-3'>
Lịch Trình Gợi Ý </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Ngày 1: </div>
<div class='ps-5 fs-5'>
• Xuất phát từ Hà Nội, di chuyển đến Trạm Tấu (5 giờ đi xe). Tham quan suối khoáng nóng và nghỉ lại tại homestay địa phương. </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Ngày 2: </div>
<div class='ps-5 fs-5'>
• Tiếp tục hành trình đến Mù Cang Chải, tham quan các điểm như Đèo Khau Phạ, Thung Lũng Lìm Mông, và Đồi Mâm Xôi. Nghỉ lại tại các homestay hoặc nhà sàn địa phương. </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Ngày 3: </div>
<div class='ps-5 fs-5'>
• Tham quan các điểm như Rừng Trúc Bản Nả Háng Tủa và Zipline Tú Lệ trước khi trở về Hà Nội. </div>

<div class='ps-5 fs-5'>
• Mù Cang Chải không chỉ là một điểm đến tuyệt vời cho những người yêu thiên nhiên mà còn là nơi bạn có thể tìm thấy sự bình yên và khám phá những giá trị văn hóa đặc sắc của vùng cao Tây Bắc.
`;
export const camnang_dulich_thienduongdulichdaongoc = `


<div class='fw-bolder  text-success  fs-1 py-3'>
Phú Quốc – Thiên Đường Du Lịch Đảo Ngọc
</div>
<div class=' ps-5 fs-5' >
Phú Quốc, còn được gọi là Đảo Ngọc, là một quần đảo nổi tiếng nằm trong vịnh Thái Lan, cách Thành phố Hồ Chí Minh khoảng 400 km về phía Tây Nam. Với vị trí thuận lợi và cảnh quan tuyệt đẹp, Phú Quốc thu hút không chỉ du khách trong nước mà còn quốc tế, nhờ vào hệ thống đảo phong phú, bãi biển tuyệt đẹp và hệ sinh thái đa dạng. Phú Quốc bao gồm 22 đảo lớn nhỏ, với tổng diện tích khoảng 589,23 km². Trong đó, đảo Phú Quốc chính là đảo lớn nhất, chia thành Bắc Đảo và Nam Đảo, với thị trấn Dương Đông là trung tâm hành chính và phát triển du lịch của đảo.
</div>
<div class='fw-bolder  text-success  fs-3 py-3'>
Mùa Du Lịch Phú Quốc: Khi Nào Là Lý Tưởng Nhất?
</div>
<div class=' ps-5 fs-5' >
• Phú Quốc là điểm đến lý tưởng quanh năm, nhưng thời gian đẹp nhất để ghé thăm đảo Ngọc là từ tháng 11 đến tháng 4 năm sau. Đây là mùa khô tại khu vực Nam Bộ, với thời tiết ít mưa, trời trong xanh, biển lặng sóng và nắng ấm, tạo điều kiện lý tưởng cho các hoạt động ngoài trời như tắm biển, lặn ngắm san hô, hay tham quan các danh lam thắng cảnh. Mùa này, các hoạt động nghỉ dưỡng, tham quan đảo được ưa chuộng và dễ dàng tận hưởng sự thư giãn tuyệt đối.
</div>
<div class=' ps-5 fs-5' >
• Ngược lại, từ tháng 5 đến tháng 10 là mùa mưa ở Phú Quốc, thời tiết có thể không thuận lợi cho các chuyến du lịch biển, nhưng Phú Quốc vẫn thu hút đông đảo du khách, đặc biệt là trong kỳ nghỉ hè. Tuy nhiên, nếu muốn tránh đông đúc và giá cả tăng cao, bạn có thể chọn đi vào cuối tháng 4, khi thời tiết vẫn đẹp và chưa bước vào mùa cao điểm.
</div>
<div class='fw-bolder  text-success  fs-3 py-3'>
Cách Di Chuyển Đến Phú Quốc
</div>
<div class=' ps-5 fs-5' >
• Để đến Phú Quốc, bạn có thể lựa chọn nhiều phương tiện giao thông khác nhau, với các chuyến bay thẳng từ các thành phố lớn như Hà Nội, Hải Phòng, Thanh Hóa, Vinh, Đà Nẵng, Nha Trang, Đà Lạt, Buôn Ma Thuột… Các hãng hàng không như Vietnam Airlines, Bamboo Airways và Vietjet Air cung cấp các chuyến bay thẳng đến Phú Quốc, với giá vé dao động từ một triệu đồng tùy vào thời điểm và chương trình khuyến mãi.
</div>
<div class=' ps-5 fs-5' >
• Nếu bạn ở TP.HCM, Phú Quốc cũng có thể dễ dàng tiếp cận bằng các phương tiện khác như xe máy, ô tô, tàu cao tốc hoặc phà. Đi tàu cao tốc từ Hà Tiên hoặc Rạch Giá là sự lựa chọn phổ biến và thú vị. Chuyến đi từ Hà Tiên chỉ mất khoảng 1 giờ 30 phút và khá thuận tiện với giá vé phải chăng. Những ai tự lái xe có thể chọn đi ban đêm để kịp chuyến phà sáng sớm, thưởng thức bình minh tuyệt đẹp trên biển.
</div>
<div class='fw-bolder  text-success  fs-3 py-3'>
Phương Tiện Di Chuyển Trên Đảo
</div>
<div class=' ps-5 fs-5' >
• Phú Quốc có rất nhiều phương tiện để di chuyển quanh đảo. Xe máy là phương tiện phổ biến và dễ dàng thuê với giá từ 100.000 - 150.000 đồng/ngày. Bạn có thể thuê xe ở nhiều điểm trong thị trấn Dương Đông hoặc các khu nghỉ dưỡng. Tuy nhiên, trước khi đi, bạn cần kiểm tra kỹ hệ thống phanh và đèn xe để đảm bảo an toàn.
</div>
<div class=' ps-5 fs-5' >
• Ngoài ra, taxi và các dịch vụ thuê xe ô tô cũng khá phổ biến, với các tour tham quan bắc đảo và nam đảo trong khoảng nửa ngày hoặc một ngày, với giá từ 500.000 - 700.000 đồng. Dịch vụ xe bus trên đảo có giá từ 10.000 - 40.000 đồng, tuy nhiên, do các điểm du lịch thường khá xa trục đường chính, đi xe bus có thể không thuận tiện.
</div>
<div class='fw-bolder  text-success  fs-3 py-3'>
Nơi Lưu Trú Phú Quốc: Từ Homestay Đến Resort Cao Cấp
</div>
<div class='fs-5 ps-5'>
• Về lưu trú, Phú Quốc có một loạt các lựa chọn từ nhà nghỉ bình dân đến resort cao cấp. Nếu bạn đi theo nhóm bạn bè hoặc gia đình, có thể chọn ở homestay hoặc khách sạn bình dân để tiết kiệm chi phí. Nếu muốn nghỉ dưỡng và tận hưởng các tiện nghi cao cấp, bạn có thể lựa chọn những resort đẳng cấp quốc tế như JW Marriott, Vinpearl Phú Quốc Resort hay Fusion Resort Phú Quốc. Mức giá cho các resort cao cấp dao động từ 1.500.000 đến 7.000.000 đồng mỗi đêm, tùy thuộc vào vị trí và tiện ích.
</div>
<div class=' ps-5 fs-5' >
• Bên cạnh đó, khu vực trung tâm thị trấn Dương Đông cũng có nhiều khách sạn 3-4 sao, với mức giá từ 1.000.000 - 1.500.000 đồng. Các khu nghỉ dưỡng gần bãi biển là lựa chọn tuyệt vời nếu bạn muốn tận hưởng không gian yên tĩnh, thư giãn và gần gũi với thiên nhiên.
</div>
<div class='fw-bolder  text-success  fs-3 py-3'>
Những Điểm Tham Quan Phú Quốc
</div>
<div class=' ps-5 fs-5' >
• Phú Quốc nổi bật với các khu du lịch phân chia thành bốn khu vực chính: Bắc đảo, Nam đảo, Đông đảo và khu vực trung tâm. Mỗi khu vực đều có những điểm du lịch độc đáo và phong cảnh tuyệt vời, phù hợp cho các loại hình du lịch từ nghỉ dưỡng đến khám phá thiên nhiên.
</div>
<div class=' ps-5 fs-5' >
• Bắc Đảo Phú Quốc: Nơi đây sở hữu cảnh sắc thiên nhiên hoang sơ với rừng nguyên sinh, bãi biển vắng lặng và những làng chài bình dị. Bạn không thể bỏ qua Vườn Quốc Gia Phú Quốc với diện tích lên tới hơn 31.400 ha, nơi có hệ sinh thái đa dạng với hàng trăm loài động vật và thực vật. Bạn cũng có thể tham quan Rạch Vẹm – "vương quốc sao biển", nơi có những cây cầu gỗ dẫn ra biển và là điểm lý tưởng để chụp ảnh, thưởng thức hải sản tươi ngon.
</div>
<div class=' ps-5 fs-5' >
• Nam Đảo Phú Quốc: Khu vực này nổi tiếng với các bãi biển đẹp như Bãi Sao, nơi có làn nước trong xanh và cát trắng mịn. Bãi Sao cũng là điểm lý tưởng để tham gia các hoạt động thể thao dưới nước như chèo thuyền kayak. Mũi Gành Dầu là một trong những địa điểm hoang sơ tuyệt vời cho những ai yêu thích sự yên tĩnh và vẻ đẹp tự nhiên.
</div>
<div class=' ps-5 fs-5' >
• Vinpearl Safari và VinWonders: Nếu bạn đi du lịch cùng gia đình, nhất định đừng bỏ qua Vinpearl Safari, nơi chăm sóc hàng nghìn loài động vật trong môi trường tự nhiên. Gần đó là VinWonders, công viên giải trí với nhiều trò chơi hấp dẫn cho mọi lứa tuổi.
</div>
<div class=' ps-5 fs-5' >
• Bãi Trường: Đây là một trong những điểm ngắm hoàng hôn đẹp nhất trên đảo. Bạn có thể ghé thăm Sunset Sanato Beach Club để chụp những bức ảnh độc đáo bên bờ biển.
</div>
<div class=' ps-5 pb-3 fs-5'>
• Phú Quốc là một thiên đường du lịch không thể bỏ qua với những bãi biển xinh đẹp, hệ sinh thái đa dạng, các khu nghỉ dưỡng cao cấp, cùng những hoạt động vui chơi giải trí phong phú. Dù bạn đến Phú Quốc vào mùa khô hay mùa mưa, hòn đảo này luôn có những điểm đến và trải nghiệm đáng nhớ dành cho mọi du khách.
</div>

`;
export const camnang_dulich_5monanhoian = `
<div class='fw-bolder  text-success  fs-1 py-3'>
5 Món Ăn Bạn Không Thể Bỏ Qua Khi Đến Hội An
</div>
<div class=' ps-5 fs-5'>
Hội An, thành phố cổ kính mang đậm dấu ấn văn hóa và lịch sử của miền Trung Việt Nam, không chỉ nổi tiếng với những con phố cổ, những chiếc đèn lồng đầy màu sắc, mà còn với nền ẩm thực phong phú và đặc sắc. Từng món ăn ở Hội An đều mang một câu chuyện riêng, phản ánh nét đẹp văn hóa kết hợp giữa các yếu tố dân gian và những ảnh hưởng từ các nền văn hóa khác. Dưới đây là 5 món ăn mà bạn nhất định phải thử khi đến Hội An.
</div>
<div class='fw-bolder  text-success  fs-3 py-3'>
1. Cao Lầu – Món Ăn Đặc Sản Nổi Tiếng Của Hội An
</div>
<div class=' ps-5 fs-5'>
• Cao Lầu là một món ăn không thể thiếu trong danh sách những món ăn đặc sản của Hội An. Được chế biến từ sợi mì dày, có màu vàng óng, cao lầu được ăn kèm với thịt heo xíu, rau sống tươi ngon và bánh phồng chiên giòn. Điều đặc biệt là nước dùng trong món cao lầu không phải là nước dùng thông thường, mà là một thứ nước hầm đặc biệt, có thể mang lại vị ngọt thanh, hơi đậm đà, tạo nên sự khác biệt so với các món mì khác trong cả nước.
</div>
<div class=' ps-5 pb-3 fs-5'>
• Cách làm cao lầu cũng rất cầu kỳ. Nước dùng được ninh từ xương heo và các loại gia vị đặc trưng, giúp tạo nên hương vị khó quên cho món ăn. Chính vì thế, bạn có thể thưởng thức món ăn này ở bất kỳ góc phố nào tại Hội An, nhưng để có trải nghiệm trọn vẹn, hãy đến những quán ăn lâu đời như Cao Lầu Trung Bắc hay Cao Lầu Liên.
</div>
<div class='fw-bolder  text-success  fs-3 py-3'>
2. Mì Quảng – Hương Vị Đậm Đà Của Miền Trung
</div>
<div class=' ps-5 fs-5'>
• Mì Quảng là một món ăn nổi tiếng của vùng đất Quảng Nam, nhưng khi đến Hội An, bạn sẽ không thể bỏ qua hương vị thơm ngon của món mì này. Mì Quảng được chế biến từ những sợi mì dai, vàng óng, ăn kèm với thịt gà, tôm, hoặc thịt heo, và được chan một ít nước dùng đậm đà. Món ăn này thường được trang trí thêm với đậu phộng rang giòn, rau sống và bánh tráng nướng, tạo nên sự kết hợp hoàn hảo giữa các hương vị ngọt, mặn, cay và chua.
</div>
<div class=' ps-5 pb-3 fs-5'>
• Nước dùng của mì Quảng thường rất đậm đà, được ninh từ xương và gia vị, tạo ra một vị ngọt tự nhiên. Mì Quảng có thể được ăn vào bất kỳ bữa nào trong ngày, nhưng món này đặc biệt ngon vào buổi sáng khi vừa mới nấu xong, khi mùi hương của nước dùng và thịt nướng vẫn còn bốc lên nghi ngút.
</div>
<div class='fw-bolder  text-success  fs-3 py-3'>
3. Bánh Mì Hội An – Được UNESCO Công Nhận Là Món Ăn Thế Giới
</div>
<div class=' ps-5 fs-5'>
• Bánh mì Hội An là một trong những món ăn đường phố nổi tiếng nhất ở Việt Nam và đã được UNESCO công nhận là món ăn đặc sắc của nền ẩm thực thế giới. Bánh mì tại Hội An có phần vỏ giòn rụm, bên trong là sự kết hợp hoàn hảo giữa thịt nướng (thịt heo, thịt gà, hoặc chả), rau sống, dưa chuột, và các loại gia vị đậm đà.
</div>
<div class=' ps-5 pb-3 fs-5'>
• Điều làm nên sự khác biệt của bánh mì Hội An chính là phần nước sốt đặc biệt, có vị ngọt, mặn và cay hài hòa. Hãy ghé qua các quán bánh mì nổi tiếng như Bánh Mì Phượng, Bánh Mì Madam Khánh, nơi bạn có thể thưởng thức bánh mì với nhiều lựa chọn nhân phong phú và không gian ấm cúng.
</div>
<div class='fw-bolder  text-success  fs-3 py-3'>
4. Hoành Thánh – Món Ăn Từ Trung Hoa Được Biến Tấu Đặc Sắc
</div>
<div class=' ps-5 fs-5'>
• Hoành thánh, hay còn gọi là sủi cảo, là món ăn có nguồn gốc từ Trung Quốc, nhưng khi du nhập vào Hội An đã được biến tấu để phù hợp với khẩu vị của người Việt. Những miếng hoành thánh mềm mại được làm từ bột mỳ mỏng, nhân thịt heo hoặc tôm tươi ngon. Món hoành thánh có thể được ăn kèm với nước dùng trong hoặc xào với rau củ tươi, tùy thuộc vào sở thích.
</div>
<div class=' ps-5 pb-3 fs-5'>
• Nước dùng hoành thánh ở Hội An rất đặc biệt, thơm ngon và ngọt thanh, thường được nấu từ xương heo và gia vị, tạo ra hương vị hài hòa và dễ chịu. Món ăn này là sự kết hợp hoàn hảo giữa độ mềm của hoành thánh và vị ngọt của nước dùng, đem đến trải nghiệm ẩm thực tuyệt vời. Bạn có thể thưởng thức món này tại các quán ăn trong khu phố cổ hoặc các quán hoành thánh lâu đời như Hoành Thánh Hội An.
</div>
<div class='fw-bolder  text-success  fs-3 py-3'>
5. Bánh Bao Bánh Vạc – Món Ăn Dễ Dàng Lôi Cuốn Từng Người
</div>
<div class=' ps-5 fs-5'>
• Bánh Bao Bánh Vạc là món ăn nhẹ mà bạn có thể dễ dàng tìm thấy trên các con phố nhỏ ở Hội An. Món bánh bao có phần vỏ mềm mịn, nhân thịt heo băm nhỏ, nấm mèo và các gia vị đặc trưng. Bánh vạc, hay còn gọi là bánh hoa hồng trắng, có hình dáng giống như một chiếc hoa hồng đang nở, làm từ bột gạo và nhân tôm. Mỗi chiếc bánh được làm tỉ mỉ, được hấp chín và thưởng thức kèm với nước mắm chua ngọt, tạo ra sự kết hợp hoàn hảo giữa các hương vị mặn, ngọt và cay.
</div>
<div class=' ps-5 pb-3 fs-5'>
• Bánh Bao Bánh Vạc không chỉ là món ăn ngon mà còn là món ăn mang tính biểu tượng của Hội An. Món ăn này được xem là món quà đặc sản mà bất kỳ du khách nào khi đến Hội An cũng nên thử ít nhất một lần.
</div>
<div class=' ps-5 pb-3 fs-5'>
• Hội An không chỉ có những con phố cổ thơ mộng, mà còn là một thiên đường ẩm thực với những món ăn ngon, đặc sắc và khó quên. Từ món cao lầu đầy đậm đà, mì Quảng thơm ngon, đến bánh mì Hội An nổi tiếng quốc tế, mỗi món ăn ở đây đều chứa đựng một phần hồn cốt của thành phố này. Đừng quên thưởng thức tất cả những món ăn này trong chuyến du lịch Hội An của bạn để cảm nhận trọn vẹn hương vị của thành phố cổ kính này!
</div>

`;
export const  camnang_dulich_top5diemcheckinchaymaytaidanang = `
 
   <div class='d-flex flex-column align-items-center'>
	<img src=${ProgramImages.nhatban1} class='w-100' />
   </div>
<div class='fw-bolder fs-1  text-success py-3'>
Top 5 Điểm Check-in Cháy Máy Tại Đà Nẵng
</div>

<div class='ps-5  fs-5'>
Đà Nẵng, thành phố năng động và hiện đại bên bờ biển miền Trung, không chỉ nổi bật với cảnh quan thiên nhiên tuyệt đẹp mà còn là điểm đến lý tưởng cho những tín đồ du lịch và nhiếp ảnh. Với những bãi biển dài, những cây cầu độc đáo, cùng các công trình kiến trúc nổi bật, Đà Nẵng luôn là "background" hoàn hảo để bạn có những bức ảnh check-in tuyệt đẹp. Dưới đây là 5 địa điểm bạn không thể bỏ qua khi đến Đà Nẵng để cháy máy ảnh của mình! </div>
<div class='fw-bolder fs-1  text-success py-3'>
1. Bà Nà Hills – Vườn Thiên Đường Trên Mây </div>
<div class='ps-5  fs-5'>
• Bà Nà Hills là một trong những điểm check-in nổi tiếng nhất tại Đà Nẵng. Với độ cao 1.400m so với mực nước biển, nơi đây mang đến một không gian mát mẻ quanh năm và cảnh quan thiên nhiên hùng vĩ. Đặc biệt, Bà Nà Hills nổi bật với Cầu Vàng – cây cầu nổi bật với hai bàn tay khổng lồ nâng đỡ, tạo thành một hình ảnh ấn tượng cho bất kỳ bức ảnh nào. Khung cảnh tuyệt đẹp từ trên cao kết hợp với không khí trong lành sẽ khiến bạn không muốn rời đi. </div>
<div class='ps-5  fs-5'>
• Bên cạnh đó, khu vực Bà Nà còn có các công trình kiến trúc cổ điển châu Âu, khu vườn hoa rực rỡ, những cây cầu vắt qua các thung lũng và các khu vui chơi giải trí. Đây chắc chắn là địa điểm lý tưởng để bạn thỏa sức "check-in" với mọi góc độ và ánh sáng. </div>
<div class='fw-bolder fs-1  text-success py-3'>
2. Cầu Rồng – Biểu Tượng Đặc Sắc Của Đà Nẵng </div>
<div class='ps-5  fs-5'>
• Cầu Rồng là một trong những cây cầu nổi tiếng không chỉ tại Đà Nẵng mà còn trên thế giới. Với hình dáng như con rồng uốn lượn, cây cầu này mang ý nghĩa tượng trưng cho sự thịnh vượng, may mắn. Cảnh sắc vào ban đêm càng trở nên lung linh và huyền ảo khi cầu được chiếu sáng bởi hàng nghìn bóng đèn LED. </div>
<div class='ps-5  fs-5'>
• Đặc biệt, vào cuối tuần, cầu Rồng sẽ thở ra lửa và phun nước, tạo nên một màn trình diễn ngoạn mục, vô cùng hấp dẫn cho những tín đồ nhiếp ảnh. Đứng trên cầu hay ngắm nhìn từ các góc khác nhau, bạn sẽ có những bức ảnh "cháy máy" đẹp như mơ. </div>
<div class='fw-bolder fs-1  text-success py-3'>
3. Bãi Biển Mỹ Khê – Thiên Đường Của Những Tín Đồ Check-in </div>
<div class='ps-5  fs-5'>
• Mỹ Khê được xem là một trong những bãi biển đẹp nhất Việt Nam, nổi tiếng với cát trắng mịn màng, làn nước trong xanh và sóng vỗ nhẹ nhàng. Đây là nơi lý tưởng để bạn vừa tắm biển, vừa tận hưởng không khí trong lành, vừa có những bức ảnh check-in tuyệt đẹp. </div>
<div class='ps-5  fs-5'>
• Bạn có thể đến những khu vực yên tĩnh của bãi biển để tận hưởng vẻ đẹp tự nhiên, hoặc tìm những quán cà phê ven biển với view đẹp để "chill" và thỏa sức tạo dáng. Buổi sáng sớm và chiều tà là thời điểm lý tưởng để bạn có những bức ảnh lung linh với ánh sáng tự nhiên. </div>
<div class='fw-bolder fs-1  text-success py-3'>
4. Ngũ Hành Sơn – Đặc Sắc Và Bí Ẩn </div>
<div class='ps-5  fs-5'>
• Ngũ Hành Sơn là một quần thể núi đá vôi nổi bật với 5 ngọn núi: Kim Sơn, Mộc Sơn, Thủy Sơn, Hỏa Sơn và Thổ Sơn. Mỗi ngọn núi đều có vẻ đẹp riêng, với những ngôi chùa cổ, hang động huyền bí, cùng các bậc thang đá dẫn lên đỉnh núi, tạo nên một không gian vừa linh thiêng, vừa huyền bí. </div>
<div class='ps-5  fs-5'>
• Khi đến Ngũ Hành Sơn, bạn không chỉ có cơ hội chiêm ngưỡng vẻ đẹp thiên nhiên kỳ vĩ mà còn được khám phá những ngôi chùa, tượng Phật, và các di tích lịch sử. Đừng quên mang máy ảnh để lưu lại những khoảnh khắc ấn tượng với những bức ảnh đẹp mắt từ các góc nhìn khác nhau. </div>
<div class='fw-bolder fs-1  text-success py-3'>
5. Cầu Tình Yêu – Không Gian Lãng Mạn Cho Các Cặp Đôi </div>
<div class='ps-5  fs-5'>
• Cầu Tình Yêu là một cây cầu nhỏ xinh nối giữa bờ sông Hàn và công viên phía Tây của thành phố Đà Nẵng. Được trang trí bằng những chiếc ổ khóa tình yêu và những dãy đèn lung linh, đây là một điểm đến lãng mạn cho các cặp đôi và du khách yêu thích chụp ảnh. </div>
<div class='ps-5  fs-5'>
• Buổi tối là thời gian lý tưởng để đến cầu Tình Yêu, khi đèn sáng lung linh và không gian trở nên mờ ảo, lãng mạn. Những bức ảnh được chụp tại đây sẽ mang lại cho bạn những kỷ niệm ngọt ngào và dễ dàng cháy máy. </div>
<div class='fw-bolder fs-1  text-success py-3'>
Kết Luận </div>
<div class='ps-5  fs-5'>
• Đà Nẵng không chỉ hấp dẫn bởi vẻ đẹp tự nhiên mà còn bởi những điểm check-in tuyệt vời cho những tín đồ đam mê chụp ảnh. Từ những cây cầu huyền thoại như cầu Rồng đến những bãi biển thơ mộng như Mỹ Khê, và những ngọn núi huyền bí như Ngũ Hành Sơn, Đà Nẵng chắc chắn sẽ là một địa điểm lý tưởng để bạn "cháy máy" và tạo ra những bức ảnh để đời. Hãy chuẩn bị máy ảnh và đến Đà Nẵng để ghi lại những khoảnh khắc tuyệt vời này nhé! </div>
`;
export const camnang_dulich_bikipdulichhainam = `

<div class='fw-bolder  text-success  fs-1 py-3'>
Bỏ Túi Bí Kíp Du Lịch Hải Nam – Thiên Đường Nhiệt Đới Của Trung Quốc
</div>
<div class=' ps-5 fs-5'>
Hải Nam, hòn đảo xinh đẹp nằm ở phía Nam của Trung Quốc, không chỉ nổi bật với những bãi biển cát trắng mịn mà còn là điểm đến lý tưởng cho những ai yêu thích khám phá văn hóa, thiên nhiên và ẩm thực. Với khí hậu nhiệt đới quanh năm và các địa danh nổi tiếng, Hải Nam ngày càng thu hút du khách trong và ngoài nước. Nếu bạn đang có kế hoạch đến thăm hòn đảo này, đừng bỏ qua những bí kíp du lịch sau để có một chuyến đi hoàn hảo.
</div>
<div class='fw-bolder  text-success  fs-3 py-3'>
1. Lựa Chọn Thời Gian Du Lịch Hải Nam
</div>
<div class=' ps-5 fs-5'>
• Hải Nam có khí hậu nhiệt đới, vì vậy, thời tiết quanh năm đều khá nóng bức. Tuy nhiên, nếu bạn muốn tránh cái nóng oi ả và những cơn bão, thời gian lý tưởng để du lịch Hải Nam là từ tháng tháng 10 đến tháng 4. Mùa hè (tháng 6 đến tháng 8) khá nóng, có thể lên đến 35°C hoặc cao hơn, và cũng là mùa bão ở khu vực này.
</div>
<div class=' ps-5 pb-3 fs-5'>
• Mùa xuân và mùa thu là thời điểm đẹp nhất với thời tiết mát mẻ và ít mưa, thích hợp cho các hoạt động ngoài trời và thăm quan.
</div>
<div class='fw-bolder  text-success  fs-3 py-3'>
2. Những Địa Điểm Không Thể Bỏ Qua
</div>
<div class=' ps-5 fs-5'>
• Thành phố Sanya – Bãi biển Dàdōnghǎi Sanya là thành phố nổi tiếng nhất tại Hải Nam, với những bãi biển tuyệt đẹp và khí hậu ôn hòa. Bãi biển Dàdōnghǎi nổi bật với làn nước trong xanh, bãi cát trắng mịn màng, là nơi lý tưởng để thư giãn, tắm nắng và tham gia các hoạt động thể thao dưới nước như lướt sóng, dù lượn hay chèo thuyền kayak.
</div>
<div class=' ps-5 pb-3 fs-5'>
• Công viên địa chất Quốc gia Hải Nam Nếu bạn yêu thích khám phá thiên nhiên và các kỳ quan địa chất, đừng bỏ qua Công viên địa chất Quốc gia Hải Nam. Nơi đây nổi bật với các hẻm núi đá vôi, hồ nước ngọt, các suối nước nóng và hệ sinh thái động thực vật phong phú. Bạn có thể dành cả ngày để tham quan, leo núi và ngắm cảnh.
</div>
<div class=' ps-5 pb-3 fs-5'>
• Làng văn hóa dân tộc Li và Miao Nếu bạn muốn khám phá văn hóa đặc sắc của các dân tộc thiểu số tại Hải Nam, làng Li và Miao sẽ là điểm đến lý tưởng. Tại đây, bạn có thể tìm hiểu về các phong tục, tập quán và đời sống của người dân địa phương, thưởng thức những món ăn đặc sản và tham gia các lễ hội truyền thống.
</div>
<div class=' ps-5 pb-3 fs-5'>
• Đảo Thiên Đường Wuzhizhou là một hòn đảo nằm cách thành phố Sanya khoảng 30 phút đi tàu. Đảo này nổi tiếng với cảnh sắc thiên nhiên tuyệt đẹp và hệ sinh thái biển phong phú. Đây là nơi lý tưởng để tham gia các hoạt động lặn biển, khám phá các rạn san hô, hoặc chỉ đơn giản là thư giãn và tắm nắng trên những bãi biển hoang sơ.
</div>
<div class='fw-bolder  text-success  fs-3 py-3'>
3. Ăn Gì Khi Du Lịch Hải Nam?
</div>
<div  class='fw-bolder ps-3 fs-5' >
Ẩm thực Hải Nam rất đa dạng và phong phú, với những món ăn đặc sản nổi bật:
</div>
<div class=' ps-5 pb-3 fs-5'>
• Cơm gà Hải Nam (Hainan Chicken Rice): Món ăn nổi tiếng nhất của Hải Nam với cơm trắng mềm, thơm kết hợp với thịt gà mềm mịn, chấm với nước sốt gừng đặc biệt. Món ăn này có thể được thưởng thức tại hầu hết các nhà hàng ở Hải Nam.
</div>
<div class=' ps-5 pb-3 fs-5'>
• Cua biển Hải Nam: Hải Nam nổi tiếng với các loại hải sản tươi ngon, trong đó cua biển là món ăn không thể bỏ qua. Cua ở đây có thịt ngọt, chắc, và thường được chế biến thành nhiều món như cua hấp, cua xào, hoặc cua rang me.
</div>
<div class=' ps-5 pb-3 fs-5'>
• Mì Hải Nam: Đây là món mì ngon với nước dùng thanh ngọt, thường được ăn kèm với thịt gà hoặc thịt heo. Mì Hải Nam cũng có nhiều biến tấu khác nhau tùy thuộc vào từng địa phương.
</div>
<div class=' ps-5 pb-3 fs-5'>
• Món cá tươi sống: Hải Nam có rất nhiều loại cá tươi ngon, đặc biệt là cá mú, cá hồi, cá chép. Các món cá được chế biến theo kiểu hấp, nướng hoặc xào, luôn khiến du khách mê mẩn.
</div>
<div class='fw-bolder  text-success  fs-3 py-3'>
4. Di Chuyển Trong Hải Nam
</div>
<div class='fw-bolder ps-3 fs-5'>
Hải Nam không quá rộng, và các phương tiện giao thông rất tiện lợi để di chuyển giữa các điểm du lịch. Bạn có thể sử dụng:
</div>

<div class=' ps-5 fs-5'>
• Taxi và xe buýt: Taxi khá phổ biến tại các thành phố lớn như Sanya và Haikou. Bạn có thể dễ dàng gọi taxi qua ứng dụng hoặc tại các điểm đón xe. Các tuyến xe buýt cũng khá phát triển và có mức giá hợp lý.
</div>
<div class=' ps-5 fs-5'>
• Thuê xe máy hoặc xe đạp: Nếu bạn muốn khám phá những ngóc ngách của đảo, thuê xe máy hoặc xe đạp là lựa chọn tuyệt vời. Bạn có thể thuê xe tại các cửa hàng hoặc khách sạn.
</div>
<div class=' ps-5 fs-5'>
• Tàu và thuyền: Hải Nam có nhiều tuyến tàu và thuyền nối các đảo nhỏ như Wuzhizhou, đảo Tây Sa, hay đảo Hải Nam. Các chuyến tàu khá an toàn và thoải mái.
</div>
<div class='fw-bolder  text-success  fs-3 py-3'>
5. Mua Sắm Gì Khi Đến Hải Nam?
</div>
<div class=' ps-5 fs-5'>
• Đặc sản Hải Nam: Hải Nam nổi tiếng với các sản phẩm như trà, hải sản khô, mật ong, dược liệu và các sản phẩm làm từ vỏ sò biển. Bạn có thể tìm mua các đặc sản này tại các chợ địa phương hoặc các cửa hàng lưu niệm.
</div>
<div class=' ps-5 fs-5'>
• Quần áo, đồ thủ công mỹ nghệ: Các cửa hàng ở các thành phố lớn như Sanya hoặc Haikou bán nhiều loại quần áo, đồ thủ công mỹ nghệ, tranh vẽ, đồ lưu niệm truyền thống với giá cả hợp lý.
</div>
<div class='fw-bolder  text-success  fs-3 py-3'>
6. Một Số Lưu Ý Quan Trọng
</div>
<div class=' ps-5 fs-5'>
• Visa: Du khách từ Việt Nam cần visa Trung Quốc để du lịch Hải Nam. Tuy nhiên, nếu bạn đi theo tour hoặc tham gia các chương trình miễn visa tại Hải Nam (dành cho một số quốc gia), bạn có thể không cần visa.
</div>
<div class=' ps-5 pb-3 fs-5'>
Tiền tệ: Hải Nam sử dụng đồng Nhân Dân Tệ (CNY). Bạn có thể dễ dàng đổi tiền tại các ngân hàng, quầy đổi tiền hoặc rút tiền từ ATM.
</div>
<div class=' ps-5 pb-3 fs-5'>
• Ngôn ngữ: Người dân tại Hải Nam chủ yếu nói tiếng Trung Quốc. Tuy nhiên, tại các khu du lịch, bạn sẽ thấy nhiều người có thể giao tiếp bằng tiếng Anh.
</div>
<div class='fw-bolder  text-success  fs-3 py-3'>
Kết Luận
</div>
<div class=' ps-5 fs-5'>
• Hải Nam là một điểm đến tuyệt vời cho những ai yêu thích sự kết hợp giữa biển, núi, văn hóa và ẩm thực. Với những bí kíp trên, hy vọng bạn sẽ có một chuyến du lịch đáng nhớ tại hòn đảo nhiệt đới xinh đẹp này. Hãy lên kế hoạch và chuẩn bị để khám phá Hải Nam – thiên đường du lịch của Trung Quốc!
</div>

`;
export const camnang_dulich_lamhochieuonline = `
<div class='fw-bolder  text-success  fs-1 py-3'>
Hướng Dẫn Tự Làm Hộ Chiếu Online Tại Nhà
</div>
<div class=' ps-5 fs-5'>
Việc làm hộ chiếu đã không còn là điều khó khăn với những bước thực hiện đơn giản và có thể làm trực tuyến tại nhà. Dưới đây là hướng dẫn chi tiết về cách tự làm hộ chiếu online tại nhà cho công dân Việt Nam, giúp bạn tiết kiệm thời gian và công sức.
</div>
<div class='fw-bolder  text-success  fs-3 py-3'>
1. Điều Kiện Cần Thiết Để Làm Hộ Chiếu Online
</div>
<div class=' ps-3 fw-bolder fs-5'>
Trước khi tiến hành làm hộ chiếu online, bạn cần đảm bảo rằng mình đáp ứng đủ các yêu cầu sau:
</div>
<div class=' ps-5 fs-5'>
• Công dân Việt Nam: Là công dân Việt Nam đủ 14 tuổi trở lên (có thể làm hộ chiếu phổ thông).
</div>
<div class=' ps-5 fs-5'>
• Có Chứng Minh Nhân Dân/ Căn Cước Công Dân (CCCD): Đây là một trong những giấy tờ cần thiết để làm hộ chiếu.
</div>
<div class=' ps-5 fs-5'>
• Điều kiện về sức khỏe: Không có bệnh lý tâm thần hoặc các vấn đề ảnh hưởng đến khả năng nhận diện khi chụp ảnh.
</div>
<div class=' ps-5 fs-5'>
• Chưa có hộ chiếu hết hạn hoặc bị mất: Nếu bạn đã có hộ chiếu và muốn gia hạn hoặc cấp lại vì mất, bạn cần đến cơ quan cấp hộ chiếu thay vì làm online.
</div>
<div class='fw-bolder  text-success  fs-3 py-3'>
2. Truy Cập Hệ Thống Cấp Hộ Chiếu Online
</div>
<div class='fw-bolder ps-3 fs-5'>
Để làm hộ chiếu online, bạn cần truy cập vào Cổng Dịch Vụ Công Quốc Gia (Cổng dịch vụ công của Bộ Công An):
</div>
<div class=' ps-5 fs-5'>
• Website chính thức: https://dichvucong.xuatnhapcanh.gov.vn
</div>
<div class=' ps-5 fs-5'>
• Đây là nơi bạn có thể đăng ký thủ tục làm hộ chiếu phổ thông trực tuyến mà không cần phải đến trực tiếp cơ quan công an.
</div>
<div class='fw-bolder  text-success  fs-3 py-3'>
3. Các Bước Thực Hiện
</div>
<div class=' ps-3 fw-bolder fs-5'>
Bước 1: Đăng Nhập và Tạo Tài Khoản
</div>
<div class=' ps-5 fs-5'>
• Truy cập vào website Cổng Dịch Vụ Công Quốc Gia.
</div>
<div class=' ps-5 fs-5'>
• Nếu chưa có tài khoản, bạn cần tạo tài khoản mới bằng cách điền thông tin cá nhân và đăng ký tài khoản qua email hoặc số điện thoại.
</div>
<div class=' ps-5 fs-5 pb-3 '>
• Nếu đã có tài khoản, bạn chỉ cần đăng nhập.
</div>
<div class=' ps-3 fw-bolder fs-5'>
Bước 2: Lựa Chọn Dịch Vụ Làm Hộ Chiếu
</div>
<div class=' ps-5 fs-5'>
• Sau khi đăng nhập thành công, bạn sẽ được chuyển đến giao diện chính của hệ thống.
</div>
<div class=' ps-5 fs-5 pb-3'>
• Tìm và chọn mục "Cấp hộ chiếu phổ thông" (hoặc "Cấp lại hộ chiếu" nếu cần).
</div>
<div class=' ps-3 fw-bolder fs-5'>
Bước 3: Điền Thông Tin Cá Nhân
</div>
<div class=' ps-5 fs-5'>
• Điền đầy đủ thông tin cá nhân vào mẫu đơn yêu cầu cấp hộ chiếu như: họ tên, ngày tháng năm sinh, số căn cước công dân (hoặc số chứng minh nhân dân), địa chỉ liên hệ, thông tin về nơi cấp hộ chiếu, các thông tin khác theo yêu cầu.
</div>
<div class=' ps-5 fs-5 pb-3 '>
• Hệ thống sẽ yêu cầu bạn cung cấp một số thông tin như thông tin về cha mẹ, nơi cấp hộ chiếu (tỉnh/thành phố).
</div>
<div class=' ps-3 fw-bolder fs-5'>
Bước 4: Chụp và Tải Lên Ảnh Chân Dung
</div>
<div class=' ps-5 fs-5'>
• Ảnh chân dung: Bạn cần tải lên một bức ảnh chân dung chuẩn (chụp nền trắng, ánh sáng tốt, mặt nhìn thẳng).
</div>
<div class=' ps-5 fs-5 pb-3'>
• Lưu ý: Ảnh phải rõ nét, không mờ, không bị che khuất bởi tóc, mũ hoặc các vật dụng khác.
</div>
<div class=' ps-3 fw-bolder fs-5'>
Bước 5: Chọn Cơ Quan Nhận Hộ Chiếu
</div>
<div class=' ps-5 fs-5'>
• Chọn địa điểm tiếp nhận hồ sơ (công an tỉnh, thành phố nơi bạn đăng ký).
</div>
<div class=' ps-5 fs-5  pb-3'>
• Bạn có thể chọn địa điểm gần nhất hoặc gần nơi bạn sinh sống.
</div>
<div class=' ps-3 fw-bolder fs-5'>
Bước 6: Thanh Toán Phí
</div>
<div class=' ps-5 fs-5'>
• Sau khi hoàn tất các bước trên, hệ thống sẽ hiển thị thông tin về lệ phí cấp hộ chiếu (thông thường khoảng 200.000 VND cho hộ chiếu phổ thông).
</div>
<div class=' ps-5 fs-5  pb-3'>
• Thanh toán lệ phí qua các phương thức trực tuyến như thẻ ngân hàng, ví điện tử.
</div>
<div class=' ps-3 fw-bolder fs-5'>
Bước 7: Xác Nhận và Nộp Đơn
</div>
<div class=' ps-5 fs-5'>
• Kiểm tra lại thông tin đã nhập một lần nữa.
</div>
<div class=' ps-5 fs-5'>
• Sau khi kiểm tra kỹ càng, bạn bấm "Gửi hồ sơ" để nộp đơn xin cấp hộ chiếu.
</div>
<div class='fw-bolder  text-success  fs-3 py-3'>
4. Theo Dõi Tiến Trình và Nhận Hộ Chiếu
</div>
<div class=' ps-5 fs-5'>
• Sau khi hoàn tất thủ tục đăng ký, bạn sẽ nhận được thông báo qua email hoặc tin nhắn về tình trạng hồ sơ.
</div>
<div class=' ps-5 fs-5'>
• Nếu hồ sơ hợp lệ, bạn sẽ nhận được thông báo lịch hẹn để đến nhận hộ chiếu tại cơ quan cấp hộ chiếu (hoặc có thể nhận hộ chiếu qua dịch vụ chuyển phát nếu có yêu cầu).
</div>
<div class='ps-5 fs-5'>
• Thông thường, thời gian cấp hộ chiếu sẽ mất từ 7 đến 10 ngày làm việc (không tính ngày lễ, cuối tuần).
</div>
<div class='fw-bolder  text-success  fs-3 py-3'>
5. Lưu Ý Quan Trọng
</div>
<div class=' ps-5 fs-5'>
• Đảm bảo thông tin chính xác: Kiểm tra kỹ tất cả thông tin đã điền trong đơn trước khi gửi đi, vì việc sửa đổi sau khi hồ sơ đã được gửi đi sẽ gây tốn thời gian.
</div>
<div class=' ps-5 fs-5'>
• Đảm bảo ảnh chụp đúng quy chuẩn: Ảnh chân dung cần đáp ứng các yêu cầu kỹ thuật như kích thước, độ phân giải, nền trắng, không được chỉnh sửa hoặc sử dụng ảnh cũ.
</div>
<div class=' ps-5 fs-5'>
• Theo dõi quá trình: Sau khi hoàn thành, bạn có thể theo dõi tiến trình cấp hộ chiếu qua hệ thống. Nếu có vấn đề gì cần giải quyết, bạn sẽ được thông báo.
</div>
<div class='fw-bolder text-success fs-3 py-3'>
6. Kết Luận
</div>
<div class=' ps-5 fs-5'>
• Làm hộ chiếu online tại nhà là một giải pháp tiện lợi và tiết kiệm thời gian cho những ai có nhu cầu cấp hộ chiếu phổ thông. Bằng cách thực hiện theo các bước hướng dẫn trên, bạn có thể dễ dàng hoàn thành thủ tục mà không cần phải đến cơ quan công an. Hãy chắc chắn rằng bạn đã chuẩn bị đầy đủ giấy tờ và tuân thủ các quy định về ảnh và thông tin để quá trình xin cấp hộ chiếu diễn ra suôn sẻ!
</div>

`;
export const  camnang_dulich_nenmanggikhididulichthailan5ngay4dem = `
 
   <div class='d-flex flex-column align-items-center'>
	<img src=${ProgramImages.nhatban1} class='w-100' />
   </div>
<div class='fw-bolder fs-1  text-success py-3'>
Nên mang gì khi đi du lịch Thái Lan 5 ngày 4 đêm?
</div>
<div class='ps-5 fs-5'>
Khi đi du lịch Thái Lan 5 ngày 4 đêm, bạn sẽ cần chuẩn bị đồ đạc sao cho đầy đủ, tiện lợi và phù hợp với khí hậu, văn hóa và các hoạt động mà bạn dự định tham gia. Dưới đây là danh sách những món đồ bạn nên mang theo để có một chuyến đi thuận tiện và thoải mái: </div>
<div class='fw-bolder fs-3  text-success py-3'>
1. Giấy Tờ và Tiền </div>
<div class=' ps-5 fs-5'>
• Hộ chiếu: Đảm bảo hộ chiếu còn hiệu lực ít nhất 6 tháng. </div>
<div class='fs-5 ps-5'>
• Visa (nếu cần): Nếu bạn không thuộc diện miễn visa, hãy chuẩn bị visa Thái Lan. </div>
<div class='fs-5 ps-5'>
• Phiếu nhập cảnh: Đảm bảo bạn điền đầy đủ thông tin khi nhập cảnh vào Thái Lan. </div>
<div class='fs-5 ps-5'>
• Thẻ tín dụng/ Tiền mặt: Dù Thái Lan có hệ thống ATM phổ biến, nhưng bạn cũng nên mang theo một ít tiền mặt (baht Thái) để chi tiêu trong những trường hợp cần thiết. 
</div>
<div class='fs-5 ps-5'>
• Bảo hiểm du lịch: Nếu bạn đã mua bảo hiểm, hãy mang theo giấy tờ chứng nhận. </div>
<div class='fw-bolder fs-3  text-success py-3'>
2. Trang Phục </div>
<div class='fs-5 ps-5'>
• Quần áo mùa hè: Thái Lan có khí hậu nóng ẩm quanh năm, nên hãy mang theo quần áo nhẹ, thoáng mát, thoải mái như áo phông, quần short, váy và đầm. </div>
<div class='fs-5 ps-5'>
• Quần áo che kín khi đi đến chùa: Để thể hiện sự tôn trọng đối với văn hóa Thái Lan, bạn cần mặc quần áo kín đáo khi vào các địa điểm tôn giáo như chùa chiền, tránh áo ba lỗ và quần short quá ngắn. </div>
<div class='fs-5 ps-5'>
• Giày thoải mái: Vì bạn sẽ đi bộ nhiều khi tham quan các địa điểm, hãy mang theo giày thể thao hoặc dép sandal thoải mái. </div>
<div class='fs-5 ps-5'>
• Đồ bơi: Nếu bạn dự định tham gia các hoạt động dưới nước như bơi lội, đi biển, hãy mang theo đồ bơi và khăn tắm. </div>
<div class='fs-5 ps-5'>
• Mũ, kính mát: Để bảo vệ mắt và đầu khỏi ánh nắng mặt trời. </div>
<div class='fw-bolder fs-3  text-success py-3'>
3. Đồ Dùng Cá Nhân </div>
<div class='fs-5 ps-5'>
• Kem chống nắng: Thái Lan có nắng rất gay gắt, vì vậy kem chống nắng là vật dụng không thể thiếu. </div>
<div class='fs-5 ps-5'>
• Thuốc chống côn trùng: Vì bạn có thể gặp phải muỗi và côn trùng ở các khu vực ngoài trời, đừng quên mang theo thuốc xịt chống muỗi hoặc kem bôi. </div>
<div class='fs-5 ps-5'>
• Dầu gió hoặc thuốc giảm đau: Để phòng khi bị đau nhức cơ thể do đi bộ hoặc tham gia các hoạt động ngoài trời. </div>
<div class='fs-5 ps-5'>
• Kem dưỡng ẩm: Khi di chuyển ngoài trời nhiều, làn da bạn có thể bị khô. Hãy mang theo kem dưỡng để giữ da mềm mại. </div>
<div class='fs-5 ps-5'>
• Sản phẩm vệ sinh cá nhân: Đừng quên mang theo bàn chải, kem đánh răng, sữa tắm, dầu gội, và các vật dụng vệ sinh cá nhân khác. </div>
<div class='fs-5 ps-5'>
• Thuốc tây thông thường: Thuốc cảm, thuốc tiêu hóa, thuốc chống dị ứng, hay thuốc chống say tàu xe là những thứ nên chuẩn bị sẵn. </div>
<div class='fw-bolder fs-3  text-success py-3'>
4. Đồ Công Nghệ </div>
<div class='fs-5 ps-5'>
• Điện thoại di động và sạc: Bạn cần mang theo điện thoại để liên lạc và tra cứu thông tin khi cần. </div>
<div class='fs-5 ps-5'>
• Cục sạc dự phòng: Đảm bảo bạn có đủ năng lượng cho điện thoại khi di chuyển hoặc tham quan. </div>
<div class='fs-5 ps-5'>
• Máy ảnh: Nếu bạn thích chụp ảnh, đừng quên mang máy ảnh hoặc máy quay để ghi lại những khoảnh khắc đẹp trong chuyến đi. </div>
<div class='fs-5 ps-5'>
• Adapter chuyển điện: Thái Lan sử dụng ổ cắm loại A, B và C với điện áp 220V, vì vậy nếu bạn mang thiết bị điện tử từ nước khác, đừng quên mang theo bộ chuyển đổi phù hợp. </div>
<div class='fw-bolder fs-3  text-success py-3'>
5. Các Vật Dụng Khác </div>
<div class='fs-5 ps-5'>
• Túi du lịch nhỏ hoặc balo: Dùng để mang theo đồ dùng cá nhân khi bạn đi tham quan hoặc mua sắm. </div>
<div class='fs-5 ps-5'>
• Nước uống: Mặc dù Thái Lan có rất nhiều quầy bán nước, nhưng việc mang theo chai nước sẽ giúp bạn tiết kiệm thời gian và chi phí. </div>
<div class='fs-5 ps-5'>
• Túi đựng rác: Để giữ gìn vệ sinh và tiện lợi trong việc bỏ rác khi tham gia các hoạt động ngoài trời. </div>
<div class='fs-5 ps-5'>
• Khẩu trang y tế: Nếu bạn có nhu cầu hoặc cần bảo vệ sức khỏe trong những khu vực đông đúc hoặc ô nhiễm. </div>
<div class='fw-bolder fs-3  text-success py-3'>
6. Các Vật Dụng Khẩn Cấp </div>
<div class='fs-5 ps-5'>
• Túi y tế cá nhân: Bao gồm bông gòn, băng dính, thuốc sát trùng và thuốc bôi ngoài da.
</div>
<div class='fs-5 ps-5'>
• Đồ dùng phòng chống mưa: Thái Lan có khí hậu nhiệt đới, bạn có thể gặp phải mưa bất chợt, vì vậy một chiếc ô hoặc áo mưa là cần thiết.
</div>
<div class='fw-bolder fs-3  text-success py-3'>
7. Các Đồ Dùng Khác </div>
<div class='fs-5 ps-5'>
• Sách hướng dẫn, bản đồ: Nếu bạn thích tự khám phá và tìm hiểu về các địa điểm du lịch, hãy mang theo một cuốn sách hướng dẫn hoặc tải ứng dụng bản đồ về điện thoại. </div>
<div class='fs-5 ps-5'>
• Túi chống nước cho thiết bị điện tử: Nếu bạn tham gia các hoạt động như chèo thuyền hay đi du thuyền, túi chống nước là một lựa chọn tuyệt vời để bảo vệ điện thoại và máy ảnh của bạn. </div>
<div class='ps-5  fs-5 ps-5'>
• Khi đi du lịch Thái Lan 5 ngày 4 đêm, bạn sẽ cần mang theo một số đồ dùng cơ bản để chuyến đi trở nên thuận lợi và thú vị. Hãy lên kế hoạch chuẩn bị đồ đạc sao cho gọn gàng và vừa đủ để không gặp phải bất kỳ bất tiện nào. Chúc bạn có một chuyến đi tuyệt vời và khám phá được những điều thú vị tại "xứ sở Chùa Vàng"! 
</div>
`;

export const tintuc_sukien_bai1 = `
<div class='fw-bolder  text-success  fs-1 py-3'>
Hiệu trưởng và thầy cô giáo trường Nhật ngữ Aoyama đến tham quan trụ sở Dabosa Group.
</div>
<div class=' ps-3 fs-5'>
• Sáng ngày 22/7/2024, Dabosa hân hạnh được tiếp đón Quý đối tác Nhật Bản đến tham quan và tìm hiểu về doanh nghiệp. Buổi tham quan diễn ra trong không khí vui tươi. Sự tiếp đón nồng hậu cùng những chia sẻ về định hướng, tầm nhìn từ Ban lãnh đạo đã để lại rất nhiều ấn tượng trong lòng Quý đối tác.
</div>
<div class=' ps-3 pb-3 fs-5'>
• Có thể nói, đây là tiền đề tốt đẹp hướng đến sự hợp tác, phát triển bền vững lâu dài trong tương lai. Đồng thời khẳng định vị thế uy tín, chuyên nghiệp, chất lượng trong lĩnh vực du học, du lịch của Dabosa.
</div>
<div class=' ps-3 pb-3 fs-5 fw-bolder'>
Dưới đây là những hình ảnh được ghi nhận:
</div>
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.aoyama1} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.aoyama2} class='rounded-3' width='100%' height='400' />
</div>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.aoyama3} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.aoyama4} class='rounded-3' width='100%' height='400' />
</div>
</div>
</div>

<div class='d-block d-md-none pt-3'>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.aoyama1} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.aoyama2} class='rounded-3' width='49%' height='170' />
</div>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.aoyama3} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.aoyama4} class='rounded-3' width='49%' height='170' />
</div>
</div>
`;
export const tintuc_sukien_bai2 = `
<div class='fw-bolder  text-success  fs-1 py-3'>
DABOSA KÝ KẾT HỢP TÁC VỚI CHUYÊN GIA CỐ VẤN ĐÀO TẠO NGOẠI NGỮ
</div>
<div class=' ps-5 fs-5'>
• Ngoại ngữ được ví như tấm vé thông hành giúp các học viên bước lên hành trình du học, thắp sáng tương lai. Vì vậy, ngoài việc xử lý hồ sơ du học chuyên nghiệp, DABOSA còn rất chú trọng nâng cao chất lượng đào tạo ngoại ngữ.
</div>
<div class=' ps-5 pb-3 fs-5'>
• Với tinh thần đó, vừa qua, DABOSA đã vinh dự được đón tiếp và ký kết hợp tác với chuyên gia cố vấn đào tạo tiếng Đức và tiếng Anh. Các chuyên gia đánh giá rất cao về định hướng đào tạo và sự đầu tư vào việc học tập ngoại ngữ dành cho học viên của DABOSA.
</div>
<div class=' ps-5 pb-3 fs-5'>
• Sau lễ ký kết, các bạn học viên DABOSA đã trực tiếp được giao lưu và lắng nghe những lời khuyên, chia sẻ từ các chuyên gia. Từ đó, các bạn có thêm động lực, quyết tâm để chinh phục được ngôn ngữ mà mình đang miệt mài học tập. 
</div>

<div class=' ps-3 fw-bolder pb-3 fs-5'>
Dưới đây là những hình ảnh ghi nhận:
</div>
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.ngoaingu1} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.ngoaingu2} class='rounded-3' width='100%' height='400' />
</div>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.ngoaingu3} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.ngoaingu4} class='rounded-3' width='100%' height='400' />
</div>
</div>
</div>

<div class='d-block d-md-none pt-3'>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.ngoaingu1} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.ngoaingu2} class='rounded-3' width='49%' height='170' />
</div>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.ngoaingu3} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.ngoaingu4} class='rounded-3' width='49%' height='170' />
</div>
</div>
`;
export const tintuc_sukien_bai3= `
<div class='fw-bolder  text-success  fs-1 py-3'>
ĐÓN TẾT TRUNG THU CÙNG DABOSA
</div>
<div class=' pb-3 ps-5 fs-5'>
• Tại DABOSA, Tết Trung thu không chỉ là dịp để các bạn học viên vui chơi mà còn là cơ hội để khám phá và trân trọng văn hóa truyền thống. Các hoạt động như làm bánh Trung Thu, bầy mâm ngũ quả, trang trí gian hàng giúp các bạn phát triển kĩ năng làm việc nhóm, đồng thời hiểu thêm về ý nghĩa văn hóa đằng sau mỗi hoạt động.
</div>
<div class=' pb-3 ps-5 fs-5'>
• Đặc biệt, chương trình chấm điểm và trao giải cho đội có bày gian hàng, làm bánh, thuyết trình hay nhất và đẹp nhất sẽ là điểm nhấn thú vị, khơi dậy khả năng sáng tạo và tinh thần đoàn kết của từng đội chơi.
</div>
<div class=' ps-3 fw-bolder pb-3 fs-5'>
Dưới đây là một số hình ảnh được ghi nhân:
</div>
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.trungthu20241} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.trungthu20242} class='rounded-3' width='100%' height='400' />
</div>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.trungthu20243} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.trungthu20244} class='rounded-3' width='100%' height='400' />
</div>
</div>
</div>
 <div class='d-block d-md-none pt-3'>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.trungthu20241} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.trungthu20242} class='rounded-3' width='49%' height='170' />
</div>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.trungthu20243} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.trungthu20244} class='rounded-3' width='49%' height='170' />
</div>
</div>
`;
export const tintuc_sukien_bai4= `
<div class='fw-bolder fs-1  text-success py-3'>
NỐI TIẾP CHƯƠNG TRÌNH TRI ÂN 77 NĂM NGÀY THƯƠNG BINH LIỆT SĨ (27/7/1947 - 27/7/2024)
</div>
<div class=' ps-5 fs-5'>
• Sáng ngày 18/7/2024, Công ty Cổ phần Tập đoàn DABOSA kết hợp cùng Công ty Cổ phần Quý Phát trao tặng 30 suất quà tri ân các gia đình có công với cách mạng tại Uỷ Ban MTTQ Việт Naм quận Hoàng Mai. Mỗi suất quà bao gồm 01 nồi cơm điện cao cấp HSK-833 và 01 phong bì ti.ền mặt.
</div>
<div class=' ps-5 pb-3 fs-5'>
• Thay mặt các gia đình, bà Nguyễn Thị Ánh Tuyết - Phó chủ tịch Uỷ ban MTTQ quận Hoàng Mai đã đón nhận những phần quà trên và gửi lời cảm ơn đến tấm lòng của hai doanh nghiệp. Đây cũng là một trong những hoạt động ý nghĩa, tiếp nối tinh thần nhớ nghĩ, tri ân đến thế hệ đi trước, góp phần lan toả tình yêu thương, uống nước nhớ nguồn của dân тộс Việт Naм.
</div>
<div class=' ps-3 fw-bolder pb-3 fs-5'>
Dưới đây là những hình ảnh được ghi nhận
</div>
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.thuongbinhlietsi1} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.thuongbinhlietsi2} class='rounded-3' width='100%' height='400' />
</div>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.thuongbinhlietsi3} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.thuongbinhlietsi4} class='rounded-3' width='100%' height='400' />
</div>
</div>
</div>
 <div class='d-block d-md-none pt-3'>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.thuongbinhlietsi1} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.thuongbinhlietsi2} class='rounded-3' width='49%' height='170' />
</div>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.thuongbinhlietsi3} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.thuongbinhlietsi4} class='rounded-3' width='49%' height='170' />
</div>
</div>
`;
export const tintuc_sukien_bai5= `

<div class='fw-bolder fs-1  text-success py-3'>
MÓN QUÀ ĐẶC BIỆT TỪ ẤN ĐỘ CỦA CHỦ TỊCH NGUYỄN MINH QUÝ
</div>
<div class=' ps-5 fs-5'>
• Hạnh phúc, xúc động, biết ơn là những cảm xúc của tập thể cán bộ nhân viên Dabosa khi được đón nhận món quà ý nghĩa từ Chủ tịch Nguyễn Minh Quý. Đó là chiếc lá Bồ Đề có hình Đức Phật thích ca mâu ni được Chủ tịch mang trực tiếp từ Bồ Đề Đạo Tràng nơi Đức Phật Thành đạo tại Ấn Độ sau chuyến hành hương nơi miền đất Phật về Việt Nam . Đây không chỉ là một kỷ vật bình thường mà còn là biểu tượng cho sự bình an, trí tuệ, sự quan tâm và tình yêu thương của Chủ tịch dành cho những người cộng sự của mình. 
</div>
<div class=' ps-5 pb-3 fs-5'>
• Tập thể cán bộ nhân viên Dabosa xin gửi lời tri ân sâu sắc đến Chủ tịch Nguyễn Minh Quý vì đã luôn dành những điều tốt đẹp nhất cho mọi người. Món quà này sẽ là động lực to lớn, nhắc nhở mỗi thành viên trong Dabosa sống thiện, làm thiện, cống hiến hết mình trong công việc và cuộc sống để đem lại nhiều giá trị, lợi ích cho cộng đồng. 
</div>
<div class=' ps-5 py-3 fs-5'>
• Kính chúc Chủ tịch luôn dồi dào sức khỏe, bình an, hạnh phúc, vững bước đưa Dabosa đoàn kết và phát triển lớn mạnh!
</div>
<div class=' ps-3 fw-bolder pb-3 fs-5'>
Dưới đây là một số hình ảnh được ghi nhân:
</div>
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.quaando1} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.quaando2} class='rounded-3' width='100%' height='400' />
</div>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.quaando3} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.quaando4} class='rounded-3' width='100%' height='400' />
</div>
</div>
</div>
 
<div class='d-block d-md-none pt-3'>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.quaando1} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.quaando2} class='rounded-3' width='49%' height='170' />
</div>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.quaando3} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.quaando4} class='rounded-3' width='49%' height='170' />
</div>
</div>
`;
export const tintuc_sukien_bai6= `
<div class='fw-bolder fs-1  text-success py-3'>
NHỮNG KHOẢNH KHẮC ẤN TƯỢNG TRONG HỘI NGHỊ KHÁCH HÀNG HASUKA - DABOSA
</div>
<div class=' ps-5 fs-5'>
• Vừa qua, Hội nghị khách hàng giữa Công ty Cổ phần Tập đoàn Dabosa và Công ty Cổ phần Quý Phát đã diễn ra vô cùng long trọng tại trung tâm sự kiện Trống Đồng Palace.
</div>
<div class=' ps-5 pb-3 fs-5'>
• Trong không khí sang trọng, chuyên nghiệp, các khách mời đã được trải nghiệm, tìm hiểu về sản phẩm, dịch vụ, thành tựu và định hướng của hai thương hiệu trong tương lai. Với sự lãnh đạo trí tuệ từ ông Nguyễn Minh Quý - Chủ tịch Hội đồng Quản trị của hai công ty, Hasuka và Dabosa đã để lại nhiều ấn tượng sâu sắc và niềm tin yêu trong lòng người dùng.
</div>
<div class=' ps-5 pb-3 fs-5'>
• Tại buổi lễ, ông Đoàn Quang Quân - Tổng Giám đốc Công ty Cổ phần Tập đoàn Dabosa đã có những lời chia sẻ đầy cảm hứng về sự tâm huyết xây dựng, phát triển dịch vụ du học, du lịch Dabosa, khẳng định tập trung chất lượng, đem đến những giá trị tốt đẹp nhất cho khách hàng.
</div>
<div class=' ps-5 pb-3 fs-5'>
• Chương trình kết thúc trong niềm vui hân hoan, hai công ty đã gửi tặng trực tiếp đến tay khách hàng những món quà đặc biệt thay cho lời cảm ơn. Sự hiện diện và tin tưởng của quý khách hàng dành cho Dabosa nói riêng và hai công ty nói chung chính là động lực to lớn giúp Hasuka - Dabosa tiếp tục phát triển và gặt hái được nhiều thành công rực rỡ hơn nữa!
</div>
<div class=' ps-3 fw-bolder pb-3 fs-5'>
Dưới đây là một số hình ảnh được ghi nhân:
</div>
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.hoinghikhachhanghasuka1} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.hoinghikhachhanghasuka2} class='rounded-3' width='100%' height='400' />
</div>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.hoinghikhachhanghasuka3} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.hoinghikhachhanghasuka4} class='rounded-3' width='100%' height='400' />
</div>
</div>
</div>
 <div class='d-block d-md-none pt-3'>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.hoinghikhachhanghasuka1} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.hoinghikhachhanghasuka2} class='rounded-3' width='49%' height='170' />
</div>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.hoinghikhachhanghasuka3} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.hoinghikhachhanghasuka4} class='rounded-3' width='49%' height='170' />
</div>
</div>
`;
export const tintuc_sukien_bai7= `
<div class='fw-bolder fs-1  text-success py-3'>
Khách quốc tế Ấn Độ đến tìm hiểu và thăm quan DABOSA</div>
<div class=' ps-5 fs-5 pb-3'>
• Trụ sở Dabosa tại Hà Nội vinh dự được liên tục tiếp đón các đoàn khách quốc tế đến thăm quan và tìm hiểu các chương trình du học, du lịch của Dabosa. Với sự chào đón nồng hậu cùng sự tư vấn chuyên nghiệp, Dabosa đã để lại nhiều ấn tượng trong lòng khách hàng.</div>
<div class=' ps-3 fw-bolder fs-5'>
Dưới đây là những hình ảnh ghi nhận:</div>
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.khachando1} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.khachando2} class='rounded-3' width='100%' height='400' />
</div>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.khachando3} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.khachando4} class='rounded-3' width='100%' height='400' />
</div>
</div>
</div>
 <div class='d-block d-md-none pt-3'>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.khachando1} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.khachando2} class='rounded-3' width='49%' height='170' />
</div>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.khachando3} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.khachando4} class='rounded-3' width='49%' height='170' />
</div>
</div>
`;

export const tintuc_sukien_bai8= `
<div class='fw-bolder fs-1  text-success py-3'>
Khách quốc tế Ấn Độ đến tìm hiểu và thăm quan DABOSA
</div>
<div class=' ps-5 fs-5'>
• Lấy chất lượng làm yếu tố quan trọng hàng đầu, Dabosa không chỉ chú trọng vào dịch vụ mà còn tập trung vào việc đào tạo và phát triển con người để mỗi nhân sự tại Dabosa đều sẽ trở thành những chuyên gia kết nối, đem đến những giải pháp về du học, du lịch tốt nhất cho khách hàng.
</div>
<div class=' ps-5 fs-5'>
• Trên tinh thần đó, vừa qua, buổi đào tạo nội bộ dành cho tất cả các CBNV và hơn 100 CTV tại khắp các tỉnh thành trên toàn quốc đã diễn ra thành công tốt đẹp.
</div>
<div class=' ps-5 fs-5'>
• Đặc biệt hơn, trong cùng ngày đào tạo, Dabosa đã chính thức ký quyết định thành lập địa điểm kinh doanh tại một số tỉnh thành.
</div>
<div class=' ps-5 fs-5'>
• Kết thúc chương trình đào tạo, hy vọng mỗi nhân sự trong đội ngũ Dabosa sẽ luôn giữ vững nhiệt huyết, tự tin chung sức, chung lòng đưa Dabosa ngày một vươn cao, bay xa, bứt phá thành công, mang đến những giá trị tốt đẹp cho khách hàng và xã hội!
</div>
<div class=' ps-3 fw-bolder pb-3 fs-5'>
Dưới đây là một số hình ảnh được ghi nhân:
</div>
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.daotaonhansu1} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.daotaonhansu2} class='rounded-3' width='100%' height='400' />
</div>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.daotaonhansu3} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.daotaonhansu4} class='rounded-3' width='100%' height='400' />
</div>
</div>
</div>
 
<div class='d-block d-md-none pt-3'>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.daotaonhansu1} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.daotaonhansu2} class='rounded-3' width='49%' height='170' />
</div>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.daotaonhansu3} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.daotaonhansu4} class='rounded-3' width='49%' height='170' />
</div>
</div>
`;

export const tintuc_sukien_bai9= `
<div class='fw-bolder fs-1  text-success py-3'>
Chào đón Quý đối tác, khách hàng tới tham quan và tìm hiểu dịch vụ Du học, Du lịch tại DABOSA Group 
</div>
<div class=' ps-5 fs-5'>
• Vừa qua, Dabosa Group rất hân hạnh được chào đón các đoàn đối tác và khách hàng đến tham quan, tìm hiểu dịch vụ du học và du lịch. Buổi tham quan diễn ra trong không khí sôi nổi, ngập tràn niềm vui, sự hào hứng từ các vị khách quý. 
</div>
<div class=' ps-5 fs-5'>
• Cũng nhân dịp này, Dabosa Group được lắng nghe trực tiếp ý kiến, cảm nhận từ Quý đối tác, khách hàng để không ngừng nâng cao chất lượng dịch vụ, mang đến những giải pháp du học, du lịch toàn diện.
</div>
<div class=' ps-5 fs-5'>
• Xin chân thành cảm ơn sự quan tâm và tin tưởng từ các Quý đối tác, khách hàng dành cho Dabosa Group. Chúng tôi sẽ tiếp tục phát triển để đáp ứng mọi nhu cầu và mong đợi của tất cả các khách hàng!
</div>
<div class=' ps-3 fw-bolder pb-3 fs-5'>
Dưới đây là một số hình ảnh được ghi nhân:
</div>
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.quydoitackhachhang1} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.quydoitackhachhang2} class='rounded-3' width='100%' height='400' />
</div>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.quydoitackhachhang3} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.quydoitackhachhang4} class='rounded-3' width='100%' height='400' />
</div>
</div>
</div>
 
<div class='d-block d-md-none pt-3'>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.quydoitackhachhang1} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.quydoitackhachhang2} class='rounded-3' width='49%' height='170' />
</div>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.quydoitackhachhang3} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.quydoitackhachhang4} class='rounded-3' width='49%' height='170' />
</div>
</div>
`;
export const tintuc_sukien_bai10= `
<div class='fw-bolder fs-1  text-success py-3'>
[DABOSA STORY] - Tư vấn du học Úc 
</div>
<div class=' ps-3  fs-5'>
Trong không khí bận rộn đầu tuần mới, chúng tôi được tiếp đón một cô bé nhỏ nhắn, xinh xắn nhưng đầy những hoài bão, ước mơ đi du học Úc tại trụ sở Dabosa. Điều ấn tượng đầu tiên khi gặp em là sự chủ động tìm hiểu chương trình và mạnh dạn chia sẻ nguyện vọng của mình để Dabosa đưa ra lời tư vấn tốt nhất cho em.
</div>
<div class=' ps-3 pb-3 fs-5'>
Qua buổi tư vấn, chắc hẳn em đã có cho mình định hướng, lộ trình rõ ràng về con đường du học sắp tới.
</div>
<div class=' ps-3 py-3 fs-5'>
Dabosa chúc em sẽ sớm thực hiện được ước mơ du học của mình! Chúng tôi luôn sẵn sàng đồng hành cùng các bạn trẻ trên sự nghiệp trau dồi trí tuệ, phát triển bản thân!
</div>
<div class=' ps-3 fw-bolder pb-3 fs-5'>
Dưới đây là một số hình ảnh được ghi nhân:
</div>
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.duhocuc1} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.duhocuc2} class='rounded-3' width='100%' height='400' />
</div>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.duhocuc3} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.duhocuc4} class='rounded-3' width='100%' height='400' />
</div>
</div>
</div>
 
<div class='d-block d-md-none pt-3'>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.duhocuc1} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.duhocuc2} class='rounded-3' width='49%' height='170' />
</div>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.duhocuc3} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.duhocuc4} class='rounded-3' width='49%' height='170' />
</div>
</div>
`;

export const tintuc_sukien_bai11= `

<div class='fw-bolder fs-1  text-success py-3'>
DABOSA GROUP TRAO TẶNG QUÀ NHÂN KỶ NIỆM 77 NĂM NGÀY THƯƠNG BINH LIỆT SĨ (27/7/1947 - 27/7/2024)
</div>
<div class=' ps-5 fs-5'>
<div>
• Sáng ngày 16/7/2024, ông Đoàn Quang Quân - Tổng Giám đốc Dabosa Group đã đại diện doanh nghiệp kết hợp cùng Công ty Cổ phần Quý Phát trao tặng 30 suất quà tri ân các gia đình có công với cách mạng tại Uỷ Ban MTTQ Việт Naм tỉnh Bắc Ninh. Mỗi suất quà bao gồm 01 nồi cơm điện cao cấp HSK-833 và 01 phong bì ti.ền mặt.
</div>
<div class=' ps-5 fs-5'>
• Thay mặt các gia đình, bà Nguyễn Thị Hà -  Chủ tịch Uỷ ban MTTQ tỉnh Bắc Ninh đã đón nhận những phần quà trên và gửi lời cảm ơn đến tấm lòng của hai doanh nghiệp. Đây cũng là một trong những hoạt động ý nghĩa, tiếp nối tinh thần nhớ nghĩ, tri ân đến thế hệ đi trước, góp phần lan toả tình yêu thương, uống nước nhớ nguồn của dân тộс Việт Naм.
</div>
<div class=' ps-3 fw-bolder pb-3 fs-5'>
Dưới đây là một số hình ảnh được ghi nhân:
</div>
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.thuongbinh1} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.thuongbinh2} class='rounded-3' width='100%' height='400' />
</div>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.thuongbinh3} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.thuongbinh4} class='rounded-3' width='100%' height='400' />
</div>
</div>
</div>
 <div class='d-block d-md-none pt-3'>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.thuongbinh1} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.thuongbinh2} class='rounded-3' width='49%' height='170' />
</div>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.thuongbinh3} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.thuongbinh4} class='rounded-3' width='49%' height='170' />
</div>
</div>
`;
export const tintuc_sukien_bai12= `
<div class='fw-bolder fs-1  text-success py-3'>
 DABOSA HÂN HẠNH ĐƯỢC ĐÓN TIẾP ĐOÀN KHÁCH QUỐC TẾ TỚI THĂM VÀ LÀM VIỆC TẠI TRỤ SỞ DABOSA GROUP! 
</div>
<div class=' ps-3 fs-5'>
Sáng ngày 19/6/2024, Mr. Zhou - Giám đốc phát triển thị trường Hải ngoại công ty Ouning Electric cùng phái đoàn khách quốc tế đã đến tham quan và tìm hiểu các dịch vụ của Dabosa Group.
</div>
<div class=' ps-3 pb-3 fs-5'>
Buổi tham quan diễn ra trong không khí vui tươi. Sự tiếp đón nồng nhiệt cùng tác phong chuyên nghiệp, dịch vụ chỉnh chu của Dabosa đã để lại nhiều ấn tượng đối với phái đoàn.
</div>
<div class=' ps-3 py-3 fs-5'>
Dưới đây là những hình ảnh được ghi nhận:
</div>
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.tourhainam1} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.tourhainam2} class='rounded-3' width='100%' height='400' />
</div>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.tourhainam3} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.tourhainam4} class='rounded-3' width='100%' height='400' />
</div>
</div>
</div>
 
<div class='d-block d-md-none pt-3'>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.tourhainam1} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.tourhainam2} class='rounded-3' width='49%' height='170' />
</div>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.tourhainam3} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.tourhainam4} class='rounded-3' width='49%' height='170' />
</div>
</div>
`;
export const tintuc_sukien_bai13= `
<div class='fw-bolder fs-1  text-success py-3'>
Kính cẩn nghiêng mình tri ân và tưởng nhớ Tổng Bí thư Nguyễn Phú Trọng!
</div>
<div class=' ps-5 fs-5'>
• Tổng Bí thư Nguyễn Phú Trọng là nhà lãnh đạo lỗi lạc, trọn đời vì nước, vì dân. Sự ra đi của Người là nỗi mất mát lớn đối với dân tộc.
</div>
<div class=' ps-5 fs-5'>
• Sáng nay, ngày 25/7/2024, toàn thể đội ngũ CBNV và học viên Dabosa đã hướng tâm, tham gia lễ tang của Tổng Bí thư Nguyễn Phú Trọng qua truyền hình trực tuyến.
</div>
<div class=' ps-5 fs-5'>
• Với tấm lòng tri ân nhỏ bé của mình, Dabosa xin được hoà cùng dòng tâm của đồng bào cả nước bày tỏ lòng kính trọng và tiếc thương Tổng Bí thư - một trái tim Việt Nam vĩ đại, một nhân cách lớn của dân tộc! 
</div>
<div class=' ps-3 fw-bolder pb-3 fs-5'>
Dưới đây là một số hình ảnh được ghi nhân:
</div>
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.tuongnhotongbithu1} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.tuongnhotongbithu2} class='rounded-3' width='100%' height='400' />
</div>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.tuongnhotongbithu3} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.tuongnhotongbithu4} class='rounded-3' width='100%' height='400' />
</div>
</div>
</div>
 
<div class='d-block d-md-none pt-3'>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.tuongnhotongbithu1} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.tuongnhotongbithu2} class='rounded-3' width='49%' height='170' />
</div>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.tuongnhotongbithu3} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.tuongnhotongbithu4} class='rounded-3' width='49%' height='170' />
</div>
</div>
`;
export const tintuc_sukien_bai14= `
<div class='fw-bolder fs-1  text-success py-3'>
Buổi liên hoan ý nghĩa, tràn ngập niềm vui của các học viên nhà Dabosa
</div>
<div class=' ps-3 fs-5'>
• Sau những giờ học miệt mài trên lớp, các học viên nhà Dabosa lại được cùng nhau liên hoan trong khuôn viên lung linh ánh đèn.
</div>
<div class=' ps-3 pb-3 fs-5'>
• Không khí bữa tối trở nên thật gần gũi, vui vẻ khi các bạn được lắng nghe những chia sẻ trực tiếp và nhận những phần quà động viên tinh thần từ Ban lãnh đạo và các thầy cô giáo. Từ đó, các bạn được tiếp thêm sức mạnh để quyết tâm phấn đấu, cố gắng trong học tập, sớm thực hiện ước mơ du học, trở thành niềm tự hào cho gia đình.
</div>
<div class=' ps-3 pb-3 fs-5'>
• Hy vọng rằng các bạn học viên sẽ có thêm thật nhiều khoảnh khắc đáng nhớ và ý nghĩa tại DABOSA.
</div>
<div class=' ps-3 pb-3 fs-5'>
• DABOSA sẽ luôn đồng hành và chắp cánh ước mơ du học cho các học viên thân yêu!
</div>
<div class=' ps-3 fw-bolder pb-2 fs-5'>
Dưới đây là một số hình ảnh được ghi nhân:
</div>
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.lienhoanynghia1} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.lienhoanynghia2} class='rounded-3' width='100%' height='400' />
</div>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.lienhoanynghia3} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.lienhoanynghia4} class='rounded-3' width='100%' height='400' />
</div>
</div>
</div>
 
<div class='d-block d-md-none pt-3'>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.lienhoanynghia1} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.lienhoanynghia2} class='rounded-3' width='49%' height='170' />
</div>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.lienhoanynghia3} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.lienhoanynghia4} class='rounded-3' width='49%' height='170' />
</div>
</div>
`;

export const tintuc_sukien_bai15= `
<div class='fw-bolder fs-1  text-success pb-3'>
LỄ KHAI GIẢNG KHÓA HỌC NGOẠI NGỮ K24 TẠI DABOSA</div>
<div class=' ps-5 fs-5'>
• Quý phụ huynh, thầy cô và các bạn học viên K24 đã cùng nhau tạo nên một buổi lễ khai giảng đầy cảm xúc và ý nghĩa. Những chia sẻ định hướng từ thầy cô, những lời động viên chân thành và những ước mơ hoài bão của các bạn học viên đã thắp lên ngọn lửa đam mê. Cùng Dabosa đồng hành trên chặng đường chinh phục tri thức, hướng tới một tương lai rực rỡ!
</div>
<div class=' ps-3 fw-bolder pb-3 fs-5'>
Dưới đây là một số hình ảnh được ghi nhân:
</div>
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.lekhaigiangk241} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.lekhaigiangk242} class='rounded-3' width='100%' height='400' />
</div>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.lekhaigiangk243} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.lekhaigiangk244} class='rounded-3' width='100%' height='400' />
</div>
</div>
</div>
 
<div class='d-block d-md-none pt-3'>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.lekhaigiangk241} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.lekhaigiangk242} class='rounded-3' width='49%' height='170' />
</div>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.lekhaigiangk243} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.lekhaigiangk244} class='rounded-3' width='49%' height='170' />
</div>
</div>
`;
export const tintuc_sukien_bai16= `
<div class='fw-bolder fs-1  text-success pb-3 '>
DABOSA CHUNG TAY CỨU TRỢ ĐỒNG BÀO MIỀN BẮC TRONG LŨ DỮ 2024
</div>
<div class=' ps-5 fs-5'>
• Chiều ngày 13/09/2024, tại Toà soạn Báo điện tử Dân Trí, đại diện Công ty Cổ phần Tập đoàn DABOSA cùng Công ty Cổ phần Quý Phát đã trao gửi số tiền 30 triệu VND với mong muốn chung tay cứu trợ đồng bào miền Bắc đã và đang gặp thiệt hại nặng nề trong cơn lũ dữ vừa qua do ảnh hưởng của bão Yagi.
</div>
<div class=' ps-5 fs-5'>
• Mong rằng, với chút tấm lòng nhỏ này sẽ góp phần giúp đỡ những người dân vùng lũ vơi bớt phần nào những khó khăn, tiếp thêm động lực để vượt qua nghịch cảnh!
</div>
<div class=' ps-3 fw-bolder pb-3 fs-5'>
Dưới đây là một số hình ảnh được ghi nhân:
</div>
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.cuutrodongbao1} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.cuutrodongbao2} class='rounded-3' width='100%' height='400' />
</div>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.cuutrodongbao3} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.cuutrodongbao4} class='rounded-3' width='100%' height='400' />
</div>
</div>
</div>
 <div class='d-block d-md-none pt-3'>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.cuutrodongbao1} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.cuutrodongbao2} class='rounded-3' width='49%' height='170' />
</div>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.cuutrodongbao3} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.cuutrodongbao4} class='rounded-3' width='49%' height='170' />
</div>
</div>
`;

export const tintuc_sukien_bai17 = `


<div class='fw-bolder  text-success  fs-1 py-3'>
[DABOSA TOUR] HÀNH TRÌNH CHINH PHỤC ĐẢO HẢI NAM (TRUNG QUỐC) CÙNG VÔ VÀN CẢM XÚC
</div>

<div class=' ps-5 fs-5'>
• Vừa qua, Dabosa Group đã kết hợp với Hasuka Việt Nam tổ chức thành công tốt đẹp chuyến du lịch Trung Quốc đặc biệt dành riêng cho các nhà phân phối độc quyền sản phẩm Hasuka trên toàn quốc. Đây không chỉ là dịp chiêm ngưỡng, khám phá cảnh đẹp xứ Trung mà còn là cơ hội để thắt chặt tinh thần đoàn kết của hai công ty. 
</div>

<div class=' ps-5 fs-5'>

• Chuyến đi du lịch diễn ra trong 5 ngày 4 đêm. Các nhà phân phối được tham quan những danh lam thắng cảnh tại Tam Á và khám phá ẩm thực, văn hóa đặc sắc tại Hải Hoa Đảo cùng nhiều hoạt động, kỷ niệm vô cùng ý nghĩa. 
</div>

<div class=' ps-5 fs-5'>

• Đặc biệt, chuyến đi có sự tham gia của Chủ tịch Nguyễn Minh Quý - người lãnh đạo có tâm, có tầm của Dabosa Group. Với nhiều năm kinh nghiệm của mình, Chủ tịch đã xung phong làm đoàn trưởng và đồng thời chăm sóc cho đoàn tham quan tour Hải Nam. Đây là tiền đề để tạo nên hệ thống cung cấp dịch vụ Dabosa vững vàng, không ngừng phát triển vươn tới đỉnh cao. 
</div>

<div class=' ps-5 fs-5'>

• Xin chân thành cảm ơn Hasuka Việt Nam đã tin tưởng và lựa chọn đồng hành cùng Dabosa Group trong chuyến đi khám phá đảo Hải Nam vô cùng tuyệt vời này. 
</div>

<div class=' ps-3 fw-bolder pb-3 fs-5'>
Dưới đây là một số hình ảnh được ghi nhân:
</div>

<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.tourhainam1} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.tourhainam2} class='rounded-3' width='100%' height='400' />
</div>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.tourhainam3} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.tourhainam4} class='rounded-3' width='100%' height='400' />
</div>
</div>
</div>
 
<div class='d-block d-md-none pt-3'>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.tourhainam1} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.tourhainam2} class='rounded-3' width='49%' height='170' />
</div>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.tourhainam3} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.tourhainam4} class='rounded-3' width='49%' height='170' />
</div>
</div>
`;
 
export const tintuc_sukien_bai18 = `

<div class='fs-1 text-success' >
 [DABOSA TOUR] HÀNH TRÌNH KHÁM PHÁ THÁI LAN CÙNG V99 GROUP 
</div>

<div class=' ps-5 fs-5'>

• Vừa qua, Dabosa Group rất vinh dự khi được đồng hành cùng V99 Group trong chuyến du lịch Bangkok - Pattaya (5 ngày 4 đêm) với vô vàn cảm xúc và kỷ niệm đáng nhớ! 
</div>

<div class=' ps-5 fs-5'>

• Chuyến đi không chỉ là dịp để khách hàng của V99 chiêm ngưỡng những cảnh đẹp tuyệt vời mà Thái Lan mang đến mà còn là cơ hội để gắn kết tinh thần đồng đội và tạo ra những khoảnh khắc vui vẻ bên nhau. 
</div>

<div class=' ps-5 fs-5'>

• Đoàn đã có những tải nghiệm vô cùng đáng nhớ qua các điểm đến nổi bật như:
</div>

<div class=' ps-5 fs-5'>

• Ghé thăm Chùa Wat Yansangwararam (Chùa Sáp) – biểu tượng văn hóa Phật giáo. 
</div>

<div class=' ps-5 fs-5'>

• Trải nghiệm tại Chợ Nổi 4 Miền – sắc màu văn hóa đường phố Thái Lan. 
</div>

<div class=' ps-5 fs-5'>
• Chinh phục Khao Chee Chan với hình Phật khắc trên đá. 
</div>

<div class=' ps-5 fs-5'>
• Xem Alcazar/Colosseum Show – biểu diễn nghệ thuật đầy sắc màu. 
</div>

<div class=' ps-5 fs-5'>
• Check-in Cafe Boeing 747 độc đáo, không gian thú vị trên máy bay. 
</div>

<div class=' ps-5 fs-5'>
• Thưởng thức Buffet 5 sao tại Baiyoke Sky với view thành phố từ tầng cao. 
</div>

<div class=' ps-5 fs-5'>
• Khám phá Đảo Coral – thiên đường biển xanh và các hoạt động thể thao nước. 
</div>

<div class=' ps-5 fs-5'>
• Chiêm bái Phật Bốn Mặt – biểu tượng tâm linh Thái Lan.
</div>

<div class=' ps-5 fs-5'>
• Tham quan Viện nghiên cứu Rắn Độc (Snake Farm) – trải nghiệm màn trình diễn hồi hộp. 
</div>

<div class=' ps-5 fs-5'>

Đặc biệt, sự tham gia của các lãnh đạo từ V99 Group đã tạo thêm không khí vui vẻ và gắn kết cho cả đoàn. Cảm ơn V99 Group đã tin tưởng và lựa chọn Dabosa Group làm đối tác đồng hành trong chuyến du lịch đầy thú vị này!
</div>
<div class=' ps-3 fw-bolder pb-3 fs-5'>
Dưới đây là một số hình ảnh được ghi nhân:
</div>

<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.tourv991} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.tourv992} class='rounded-3' width='100%' height='400' />
</div>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.tourv993} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.tourv994} class='rounded-3' width='100%' height='400' />
</div>
</div>
</div>
 
<div class='d-block d-md-none pt-3'>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.tourv991} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.tourv992} class='rounded-3' width='49%' height='170' />
</div>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.tourv993} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.tourv994} class='rounded-3' width='49%' height='170' />
</div>
</div>
`;
 

export const tintuc_sukien_bai19 = `
 
<img src=${TourInternationalImages.giaiphong} class='rounded-3' width='100%' height='500' />
<div class='fw-bolder  text-success  fs-1 py-3'>
HÀ NỘI - KỶ NIỆM 70 NĂM GIẢI PHÓNG THỦ ĐÔ VÀ LÒNG TỰ HÀO DÂN TỘC! 
</div>
 
<div class=' ps-5 fs-5'>

• Ngày 10/10/1954 – một ngày lịch sử không thể nào quên, khi Thủ đô Hà Nội rực rỡ cờ hoa đón chào đoàn quân chiến thắng trở về, khép lại những năm tháng chiến tranh ác liệt và mở ra một trang sử mới cho dân tộc. Từ trái tim của cả nước, Hà Nội – Thăng Long ngàn năm văn hiến, nơi hội tụ tinh hoa và chí khí kiên cường của biết bao thế hệ, đã đứng lên mạnh mẽ, trở thành biểu tượng cho tinh thần bất khuất và khát vọng hòa bình.
</div>

<div class=' ps-5 fs-5'>

• 70 năm qua, dấu ấn hào hùng của ngày Giải phóng Thủ đô mãi khắc sâu trong ký ức của mỗi người con đất Việt. Từng trận chiến đầy bi tráng, oai hùng, từng con phố hay mái trường đều ghi dấu sự hy sinh, lòng yêu nước của cha ông ta, những người đã ngã xuống vì độc lập, vì hòa bình và tương lai của đất nước. Sự kiện Giải phóng Thủ đô chính là mốc son chói lọi, khẳng định sức mạnh và bản lĩnh của cả dân tộc Việt Nam, dưới sự lãnh đạo sáng suốt của Đảng và Bác Hồ kính yêu.
</div>

<div class=' ps-5 fs-5'>
• Nhân dịp kỷ niệm 70 năm ngày Giải phóng Thủ đô, Công ty Cổ phần Tập đoàn DABOSA xin nghiêng mình kính cẩn, bày tỏ lòng tri ân sâu sắc đến những người anh hùng, liệt sĩ đã hiến dâng cả cuộc đời mình cho nền độc lập thiêng liêng của Tổ quốc. Hôm nay, dưới bầu trời tự do, chúng ta càng thêm tự hào và vững tin vào con đường phát triển của đất nước, giữ vững tinh thần đoàn kết, tiếp bước xây dựng Thủ đô Hà Nội giàu mạnh, văn minh, xứng đáng với truyền thống anh hùng.
</div>

`;
export const tintuc_sukien_bai20 = `
<div class='fw-bolder  text-success  fs-1 py-3'>
 Chào mừng Ngày Phụ nữ Việt Nam 20/10! 
</div>
<div class=' ps-5 fs-5'>
• Nhân dịp đặc biệt này, Dabosa Group xin gửi lời chúc tốt đẹp nhất đến tất cả những người phụ nữ Việt Nam tuyệt vời. Các chị, các mẹ chính là nguồn cảm hứng bất tận, biểu tượng của sự yêu thương và sự hy sinh cao cả.
</div>

<div class=' ps-5 fs-5'>

• Hãy cùng Dabosa tôn vinh vẻ đẹp, sự mạnh mẽ và lòng kiên cường của phụ nữ Việt Nam! Chúc các chị em luôn rạng ngời, hạnh phúc và thành công trong cuộc sống!
</div>

<div class=' ps-3 fw-bolder pb-3 fs-5'>
Dưới đây là một số hình ảnh được ghi nhân:
</div>

<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.ngay20101} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.ngay20102} class='rounded-3' width='100%' height='400' />
</div>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.ngay20103} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.ngay20104} class='rounded-3' width='100%' height='400' />
</div>
</div>
</div>
 
<div class='d-block d-md-none pt-3'>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.ngay20101} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.ngay20102} class='rounded-3' width='49%' height='170' />
</div>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.ngay20103} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.ngay20104} class='rounded-3' width='49%' height='170' />
</div>
</div>
`;
 export const tintuc_sukien_bai21= `
<div class='fw-bolder  text-success  fs-1 py-3'>
DABOSA GROUP & V99 GROUP – Thắt Chặt Hợp Tác Phát Triển Bền Vững
</div>
<div class=' ps-5 fs-5'>

• Ngày 21/10/2024 vừa qua, Dabosa Group vô cùng vinh hạnh được đón tiếp Công ty Cổ phần Dịch vụ và Thương mại V99 Group đến tham quan trụ sở Dabosa Group Hà Nội.
</div>

<div class=' ps-5 fs-5'>

• Buổi gặp gỡ đã giúp khách hàng hiểu rõ hơn về quy mô hoạt động, cơ sở vật chất hiện đại, đội ngũ nhân viên chuyên nghiệp và các chương trình du học, du lịch trong nước và quốc tế mà Dabosa cung cấp. Đây cũng là cơ hội để hai bên thắt chặt mối quan hệ, xây dựng niềm tin và thúc đẩy hợp tác bền vững. 
</div>

<div class=' ps-5 fs-5'>

• Dabosa xin chân thành cảm ơn Quý khách hàng đã dành thời gian quý báu ghé thăm và tìm hiểu về chúng tôi. Chúng tôi cam kết luôn đồng hành, mang đến giá trị và sự hài lòng cao nhất cho khách hàng. 
</div>

<div class=' ps-3 fw-bolder pb-3 fs-5'>
Dưới đây là một số hình ảnh được ghi nhân:
</div>

<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.v991} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.v992} class='rounded-3' width='100%' height='400' />
</div>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.v993} class='rounded-3' width='100%' height='400' />
<img src=${TourInternationalImages.v994} class='rounded-3' width='100%' height='400' />
</div>
</div>
</div>
 
<div class='d-block d-md-none pt-3'>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.v991} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.v992} class='rounded-3' width='49%' height='170' />
</div>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.v993} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.v994} class='rounded-3' width='49%' height='170' />
</div>
</div>
`;
 export const tintuc_sukien_bai22= `

<div class='fw-bolder fs-1  text-success pb-3 '>
[DABOSA TOUR] - CHUYẾN ĐI ĐÀ NẴNG ĐÁNG NHỚ BÊN GIA ĐÌNH 
</div>
<div class=' pb-3 ps-3 fs-5'>
Với Dabosa Travel, mỗi chuyến đi vừa là dịp để vui chơi, cũng là dịp để mỗi thành viên đc gắn kết tình cảm. 
</div>

<div class=' pb-3 ps-3 fs-5'>
Cùng nhìn lại những khoảnh khắc đầy ý nghĩa trong chuyến hành trình này nhé
</div>

<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.danang1} class='rounded-3' width='90%' height='20%' />
<img src=${TourInternationalImages.danang2} class='rounded-3' width='90%' height='20%' />
</div>
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInternationalImages.danang3} class='rounded-3' width='90%' height='40%' />
<img src=${TourInternationalImages.danang4} class='rounded-3' width='90%' height='54%' />
</div>
</div>
</div>
 
<div class='d-block d-md-none pt-3'>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.danang1} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.danang2} class='rounded-3' width='49%' height='170' />
</div>
<div class='d-flex align-items-center gap-3'>
<img src=${TourInternationalImages.danang3} class='rounded-3 my-2' width='49%' height='170' />
<img src=${TourInternationalImages.danang4} class='rounded-3' width='49%' height='170' />
</div>
</div>

`;

export const tuyendung_content = `

<div class='fw-bolder  text-success  fs-1 py-3'>
JD - Nhân Viên Kinh Doanh
</div>

<div class='fw-bolder ps-3 fs-4 '>
Phòng Ban: Kinh Doanh
</div>

<div class='fw-bolder ps-3 fs-4 '>
Chức danh: Nhân viên kinh doanh 
</div>

<div class='fw-bolder ps-3 fs-4 '>
Chế độ làm việc: Toàn thời gian
</div>

<div class='fw-bolder ps-3 fs-4 '>
Báo cáo cho: Trưởng phòng kinh doanh
</div>

<div class='fw-bolder  text-success fs-3 py-3 '>
I. Mô tả công việc:
</div>
<div class=' ps-5 fs-5  pb-3 '>
- Mục đích của công việc: đảm bảo tuyển dụng đúng người, đúng việc, đúng thời điểm cần cho Dabosa Group
</div>
<div class='fw-bolder fs-4 pb-3 '>
* Nhiệm vụ chính của công việc: 
</div>
<div class='fw-bolder fs-5 ps-3 '>
1.Tư vấn và hỗ trợ khách hàng: 
</div>
<div class=' ps-5 fs-5 '>
- Tư vấn cho học sinh, sinh viên và phụ huynh về các chương trình du học, bao gồm các quốc gia, trường học, ngành học, và cơ hội học bổng. 
</div>
<div class=' ps-5 fs-5  pb-3'>
- Giải đáp thắc mắc và cung cấp thông tin chi tiết để giúp khách hàng lựa chọn chương trình du học phù hợp.
</div>
<div class='fw-bolder fs-5 ps-3 '>
2. Tìm kiếm và phát triển khách hàng:
</div>
<div class=' ps-5 fs-5'>
- Xây dựng và duy trì mối quan hệ với các đối tác, trường học, và đại diện giáo dục tại các quốc gia khác nhau. 
</div>
<div class=' ps-5 fs-5  pb-3 '>
- Tìm kiếm và phát triển nguồn khách hàng tiềm năng thông qua các kênh tiếp thị, sự kiện, và hội thảo.
</div>
<div class='fw-bolder fs-5 ps-3 '>
3. Xử lý hồ sơ và thủ tục: 
</div>
<div class=' ps-5 fs-5'>
- Hỗ trợ khách hàng trong việc chuẩn bị và nộp hồ sơ xin học, xin visa, và các giấy tờ liên quan. 
</div>
<div class=' ps-5 fs-5 pb-3 '>
- Đảm bảo các thủ tục được thực hiện đúng quy trình và đúng hạn.
</div>
<div class='fw-bolder fs-5 ps-3 '>
4. Đàm phán và thương lượng:
</div>
<div class=' ps-5 fs-5 pb-3'>
- Đàm phán với các trường học và đối tác để đạt được các điều khoản có lợi cho khách hàng, bao gồm học phí, học bổng, và điều kiện học tập.
</div>
<div class='fw-bolder fs-5 ps-3 '>
5. Tiếp thị và quảng bá: 
</div>
<div class=' ps-5 fs-5  pb-3 '>
- Tham gia vào các chiến dịch tiếp thị để quảng bá các chương trình du học, bao gồm việc tạo nội dung, tổ chức sự kiện, và quản lý kênh truyền thông xã hội.
</div>
<div class='fw-bolder fs-5 ps-3 '>
6. Theo dõi và chăm sóc khách hàng: 
</div>
<div class=' ps-5   pb-3 fs-5'>
- Duy trì liên lạc với khách hàng trong suốt quá trình du học, giải quyết các vấn đề phát sinh, và hỗ trợ họ trong quá trình học tập tại nước ngoài.
</div>
<div class='fw-bolder fs-5 ps-3 '>
7. Báo cáo và phân tích: 
</div>
<div class=' ps-5 fs-5 '>
- Thu thập dữ liệu và báo cáo kết quả kinh doanh, đánh giá hiệu quả của các chiến dịch tiếp thị, và đưa ra các đề xuất để cải thiện quy trình kinh doanh.
</div>
<div class='fw-bolder  text-success fs-3 py-3 '>
II. Yêu cầu công việc:
</div>
<div class='fw-bolder fs-5 ps-3 '>
Giới tính: Không yêu cầu
</div>
<div class='fw-bolder fs-5 ps-3 '>
Độ tuổi: từ 25 - 35
</div>
<div class='fw-bolder fs-5 ps-3 '>
Bằng cấp: 
</div>
<div class=' ps-5 fs-5'>
- Tốt nghiệp ĐH các chuyên ngành như: Marketing, Kinh Doanh, Quản trị hoặc các lĩnh vực liên quan…
</div>
<div class=' ps-5 fs-5'>
- Có kinh nghiệm tối thiểu từ 2 năm trở lên nghiệm trong lĩnh vực liên quan
</div>
<div class=' ps-5 fs-5  pb-3 '>
- Kinh nghiệm xây dựng và thực hiện chiến lược marketing toàn diện.
</div>
<div class='fw-bolder fs-5 ps-3 '>
Kiến thức chuyên môn: 
</div>
<div class=' ps-5 fs-5'>
- Quản trị kinh doanh, kinh tế , quản trị nhân sự, quản trị tài chính
</div>
<div class=' ps-5 fs-5'>
- Có hiểu biết về các mô hình kinh doanh 
</div>
<div class=' ps-5 fs-5'>
- Có hiểu biết về văn hóa doanh nghiệp
</div>
<div class=' ps-5 fs-5  pb-3 '>
- Từng có kinh nghiệm tư vấn trong mảng: giáo dục là một lợi thế.
</div>
<div class='fw-bolder fs-5 ps-3 '>
Kỹ năng:
</div>
<div class=' ps-5 fs-5'>
- Kỹ năng giao tiếp, kỹ năng thuyết phục khách hàng, kỹ năng thuyết phục khách hàng truyền. 
</div>
<div class=' ps-5 fs-5  pb-3 '>
- Kỹ năng đàm phán để đạt được các thỏa thuận có lợi trong hợp đồng và quan hệ đối tác.
</div>
<div class='fw-bolder fs-5 ps-3 '>
Thái độ:
</div>
<div class=' ps-5 fs-5'>
- Luôn có tinh thần trách nhiệm cao đối với công việc và sẵn sàng đối mặt với thử thách.
</div>
<div class=' ps-5  pb-3 fs-5'>
- Tận tụy với công việc và luôn hướng tới việc đạt mục tiêu đề ra
</div>
<div class='fw-bolder fs-5 ps-3 '>
Yêu cầu khác: 
</div>
<div class=' ps-5 fs-5'>
- Có laptop
</div>
<div class=' ps-5 fs-5'>
- Tự túc phương tiện đi lại 
</div>
<div class='fw-bolder  text-success fs-3 py-3 '>
III. Quyền lợi: 
</div>
<div class='fw-bolder fs-5 ps-3 '>
Thu nhập: 
</div>
<div class=' ps-5 fs-5  pb-3 '>
- Từ 5.000.000 + % doanh số + % tỷ lệ hoàn thành công việc
</div>
<div class='fw-bolder fs-5 ps-3 '>
Thưởng:
</div>
<div class=' ps-5 fs-5'>
- Thưởng theo hiệu suất kinh doanh của doanh nghiệp
</div>
<div class=' ps-5 fs-5  pb-3 '>
- Các chính sách thưởng ngày lễ 30/4 – 1/5 , 2/9, tết dương lịch
</div>
<div class='fw-bolder fs-5 ps-3 '>
Phụ cấp và quyền lợi khác:
</div>
<div class=' ps-5 fs-5'>
- Phụ cấp ăn trưa và các phụ cấp khác theo quy định của luật lao động & chính sách công ty
</div>
<div class=' ps-5 fs-5'>
- Chế độ BHXH 
</div>
<div class=' ps-5 fs-5'>
- Khám sức khỏe định kỳ
</div>
<div class=' ps-5 fs-5'>
- Du lịch cùng Công ty mỗi năm 1 lần và các chương trình gắn kết
</div>
<div class=' ps-5 fs-5 '>
- Làm việc trong một môi trường doanh nghiệp
</div>
<div class='fw-bolder  text-success fs-3 py-3 '>
 IV. Thông tin liên hệ:
</div>
<div class=' ps-3 fs-4 fw-bolder '>
 Địa điểm làm việc: Biệt thự số 2, OBT2, KĐT Bắc Linh Đàm, 
 Quận Hoàng Mai, HN
</div>
<div class=' ps-3 fs-4 fw-bolder '>
 Thời gian làm việc: Từ thứ 2 đến thứ 7: 8h15 - 17h30.
</div>
<div class=' ps-3 fs-4 fw-bolder '>
 Gửi CV về mail: tuyendung.dabosagroup@gmail.com - Tiêu đề’’ Vị trí ứng tuyển - Họ tên’’
</div>
<div class=' ps-3 fs-4 fw-bolder '>
 Liên hệ: Ms. Vũ Thủy - P. Nhân sự: 0912.810.305
</div>
`;
export const tuyendung_kinhdoanh = `

<div class='fw-bolder  text-success  fs-1 py-3'>
JD - Nhân Viên Kinh Doanh
</div>
<div class='fw-bolder ps-3 fs-4 '>
Phòng Ban: Kinh Doanh
</div>
<div class='fw-bolder ps-3 fs-4 '>
Chức danh: Nhân viên kinh doanh 
</div>
<div class='fw-bolder ps-3 fs-4 '>
Chế độ làm việc: Toàn thời gian
</div>
<div class='fw-bolder ps-3 fs-4 '>
Báo cáo cho: Trưởng phòng kinh doanh
</div>
<div class='fw-bolder  text-success fs-3 py-3 '>
I. Mô tả công việc:
</div>
<div class=' ps-5 fs-5  pb-3 '>
- Mục đích của công việc: đảm bảo tuyển dụng đúng người, đúng việc, đúng thời điểm cần cho Dabosa Group
</div>
<div class='fw-bolder fs-4 pb-3 '>
* Nhiệm vụ chính của công việc: 
</div>
<div class='fw-bolder fs-5 ps-3 '>
1.Tư vấn và hỗ trợ khách hàng: 
</div>
<div class=' ps-5 fs-5 '>
- Tư vấn cho học sinh, sinh viên và phụ huynh về các chương trình du học, bao gồm các quốc gia, trường học, ngành học, và cơ hội học bổng. 
</div>
<div class=' ps-5 fs-5  pb-3'>
- Giải đáp thắc mắc và cung cấp thông tin chi tiết để giúp khách hàng lựa chọn chương trình du học phù hợp.
</div>
<div class='fw-bolder fs-5 ps-3 '>
2. Tìm kiếm và phát triển khách hàng:
</div>
<div class=' ps-5 fs-5'>
- Xây dựng và duy trì mối quan hệ với các đối tác, trường học, và đại diện giáo dục tại các quốc gia khác nhau. 
</div>
<div class=' ps-5 fs-5  pb-3 '>
- Tìm kiếm và phát triển nguồn khách hàng tiềm năng thông qua các kênh tiếp thị, sự kiện, và hội thảo.
</div>
<div class='fw-bolder fs-5 ps-3 '>
3. Xử lý hồ sơ và thủ tục: 
</div>
<div class=' ps-5 fs-5'>
- Hỗ trợ khách hàng trong việc chuẩn bị và nộp hồ sơ xin học, xin visa, và các giấy tờ liên quan. 
</div>
<div class=' ps-5 fs-5 pb-3 '>
- Đảm bảo các thủ tục được thực hiện đúng quy trình và đúng hạn.
</div>
<div class='fw-bolder fs-5 ps-3 '>
4. Đàm phán và thương lượng:
</div>
<div class=' ps-5 fs-5 pb-3'>
- Đàm phán với các trường học và đối tác để đạt được các điều khoản có lợi cho khách hàng, bao gồm học phí, học bổng, và điều kiện học tập.
</div>
<div class='fw-bolder fs-5 ps-3 '>
5. Tiếp thị và quảng bá: 
</div>
<div class=' ps-5 fs-5  pb-3 '>
- Tham gia vào các chiến dịch tiếp thị để quảng bá các chương trình du học, bao gồm việc tạo nội dung, tổ chức sự kiện, và quản lý kênh truyền thông xã hội.
</div>
<div class='fw-bolder fs-5 ps-3 '>
6. Theo dõi và chăm sóc khách hàng: 
</div>
<div class=' ps-5   pb-3 fs-5'>
- Duy trì liên lạc với khách hàng trong suốt quá trình du học, giải quyết các vấn đề phát sinh, và hỗ trợ họ trong quá trình học tập tại nước ngoài.
</div>
<div class='fw-bolder fs-5 ps-3 '>
7. Báo cáo và phân tích: 
</div>
<div class=' ps-5 fs-5 '>
- Thu thập dữ liệu và báo cáo kết quả kinh doanh, đánh giá hiệu quả của các chiến dịch tiếp thị, và đưa ra các đề xuất để cải thiện quy trình kinh doanh.
</div>
<div class='fw-bolder  text-success fs-3 py-3 '>
II. Yêu cầu công việc:
</div>
<div class='fw-bolder fs-5 ps-3 '>
Giới tính: Không yêu cầu
</div>
<div class='fw-bolder fs-5 ps-3 '>
Độ tuổi: từ 25 - 35
</div>
<div class='fw-bolder fs-5 ps-3 '>
Bằng cấp: 
</div>
<div class=' ps-5 fs-5'>
- Tốt nghiệp ĐH các chuyên ngành như: Marketing, Kinh Doanh, Quản trị hoặc các lĩnh vực liên quan…
</div>
<div class=' ps-5 fs-5'>
- Có kinh nghiệm tối thiểu từ 2 năm trở lên nghiệm trong lĩnh vực liên quan
</div>
<div class=' ps-5 fs-5  pb-3 '>
- Kinh nghiệm xây dựng và thực hiện chiến lược marketing toàn diện.
</div>
<div class='fw-bolder fs-5 ps-3 '>
Kiến thức chuyên môn: 
</div>
<div class=' ps-5 fs-5'>
- Quản trị kinh doanh, kinh tế , quản trị nhân sự, quản trị tài chính
</div>
<div class=' ps-5 fs-5'>
- Có hiểu biết về các mô hình kinh doanh 
</div>
<div class=' ps-5 fs-5'>
- Có hiểu biết về văn hóa doanh nghiệp
</div>
<div class=' ps-5 fs-5  pb-3 '>
- Từng có kinh nghiệm tư vấn trong mảng: giáo dục là một lợi thế.
</div>
<div class='fw-bolder fs-5 ps-3 '>
Kỹ năng:
</div>
<div class=' ps-5 fs-5'>
- Kỹ năng giao tiếp, kỹ năng thuyết phục khách hàng, kỹ năng thuyết phục khách hàng truyền. 
</div>
<div class=' ps-5 fs-5  pb-3 '>
- Kỹ năng đàm phán để đạt được các thỏa thuận có lợi trong hợp đồng và quan hệ đối tác.
</div>
<div class='fw-bolder fs-5 ps-3 '>
Thái độ:
</div>
<div class=' ps-5 fs-5'>
- Luôn có tinh thần trách nhiệm cao đối với công việc và sẵn sàng đối mặt với thử thách.
</div>
<div class=' ps-5  pb-3 fs-5'>
- Tận tụy với công việc và luôn hướng tới việc đạt mục tiêu đề ra
</div>
<div class='fw-bolder fs-5 ps-3 '>
Yêu cầu khác: 
</div>
<div class=' ps-5 fs-5'>
- Có laptop
</div>
<div class=' ps-5 fs-5'>
- Tự túc phương tiện đi lại 
</div>
<div class='fw-bolder  text-success fs-3 py-3 '>
III. Quyền lợi: 
</div>
<div class='fw-bolder fs-5 ps-3 '>
Thu nhập: 
</div>
<div class=' ps-5 fs-5  pb-3 '>
- Từ 5.000.000 + % doanh số + % tỷ lệ hoàn thành công việc
</div>
<div class='fw-bolder fs-5 ps-3 '>
Thưởng:
</div>
<div class=' ps-5 fs-5'>
- Thưởng theo hiệu suất kinh doanh của doanh nghiệp
</div>
<div class=' ps-5 fs-5  pb-3 '>
- Các chính sách thưởng ngày lễ 30/4 – 1/5 , 2/9, tết dương lịch
</div>
<div class='fw-bolder fs-5 ps-3 '>
Phụ cấp và quyền lợi khác:
</div>
<div class=' ps-5 fs-5'>
- Phụ cấp ăn trưa và các phụ cấp khác theo quy định của luật lao động & chính sách công ty
</div>
<div class=' ps-5 fs-5'>
- Chế độ BHXH 
</div>
<div class=' ps-5 fs-5'>
- Khám sức khỏe định kỳ
</div>
<div class=' ps-5 fs-5'>
- Du lịch cùng Công ty mỗi năm 1 lần và các chương trình gắn kết
</div>
<div class=' ps-5 fs-5 '>
- Làm việc trong một môi trường doanh nghiệp
</div>
<div class='fw-bolder  text-success fs-3 py-3 '>
 IV. Thông tin liên hệ:
</div>
<div class=' ps-3 fs-4 fw-bolder '>
 Địa điểm làm việc: Biệt thự số 2, OBT2, KĐT Bắc Linh Đàm, 
 Quận Hoàng Mai, HN
</div>
<div class=' ps-3 fs-4 fw-bolder '>
 Thời gian làm việc: Từ thứ 2 đến thứ 7: 8h15 - 17h30
</div>
<div class=' ps-3 fs-4 fw-bolder '>
 Gửi CV về mail: tuyendung.dabosagroup@gmail.com - Tiêu đề’’ Vị trí ứng tuyển - Họ tên’’
</div>
<div class=' ps-3 fs-4 fw-bolder '>
 Liên hệ: Ms. Vũ Thủy - P. Nhân sự: 0912.810.305
</div>
`;
export const tuyendung_dieuhanhtour = `

<div class='fw-bolder  text-success  fs-1 py-3'>
 JD - ĐIỀU HÀNH TOUR
</div>
<div class='fw-bolder ps-3 fs-4 '>
Phòng Ban:Du Lịch
</div>
<div class='fw-bolder ps-3 fs-4 '>
Chức danh: Chuyên viên điều hành tour
</div>
<div class='fw-bolder ps-3 fs-4 '>
Chế độ làm việc: Toàn thời gian
</div>
<div class='fw-bolder ps-3 fs-4 '>
Báo cáo cho: Giám đốc Du Lịch
</div>
<div class='fw-bolder  text-success fs-3 py-3 '>
1. Mô tả công việc:
</div>
<div class=' ps-5 fs-5 '>
- Tiếp nhận và xử lý yêu cầu của khách hàng đi du lịch trong và ngoài nước, tư vấn và xây dựng chương trình tour, tính giá tour dựa theo yêu cầu của khách hàng, tư vấn thuyết phục khách hàng đặt dịch vụ với công ty.
</div>

<div class=' ps-5 fs-5 '>
- Xử lý các yêu cầu và khiếu nại của khách hàng.
</div>

<div class=' ps-5 fs-5 '>
- Đặt các dịch vụ (Khách sạn, vé máy bay, nhà hàng, vé tham quan,....các loại dịch vụ liên quan đến tour) khi chốt đoàn, hỗ trợ hướng dẫn viên vận hành tour, xử lí các vấn đề phát sinh trong tour.


</div>
<div class=' ps-5 fs-5 '>
- Chủ động thu thập hóa đơn chứng từ liên quan làm quyết toán tour sau khi kết thúc tour, trực tiếp thu hồi công nợ của khách hàng, phối hợp với kế toán đối chiếu, chốt công nợ với khách hàng và thanh toán cho đối tác.


</div>
<div class=' ps-5 fs-5 '>
- Thường xuyên liên lạc và giữ mối liên hệ của khách hàng.
</div>
<div class=' ps-5 fs-5 '>
- Báo cáo công việc theo định kỳ: hàng ngày, hàng tuần, hàng tháng.
</div>
<div class=' ps-5 fs-5 pb-3 '>
- Các công việc khác theo yêu cầu của cấp trên.
</div>

<div class='fw-bolder  text-success fs-3 py-3 '>
2. YÊU CẦU:
</div>
<div class=' ps-5 fs-5 '>
- Tốt nghiệp Cao đẳng, Đại học các ngành QTKD, Du lịch, Khách sạn, Ngoại ngữ…
</div>
<div class=' ps-5 fs-5 '>
- Ưu tiên ứng viên có kinh nghiệm 02 năm trở lên ở vị trí Sales Tour.
</div>
<div class=' ps-5 fs-5 '>
- Có kiến thức và kinh nghiệm làm việc tại các lĩnh vực: Du lịch, khách sạn, tuyến điểm du lịch,...trong nước.
</div>
<div class=' ps-5 fs-5 '>
- Có khả năng tư vấn , thuyết phục khách hàng (Biết Tiếng Anh là một lợi thế,...)
</div>
<div class=' ps-5 fs-5 '>
- Có khả năng làm việc độc lập và làm việc nhóm.
</div>
<div class=' ps-5 fs-5 '>
- Có trách nhiệm trong công việc , nhiệt huyết và chịu được áp lực.
</div>
<div class=' ps-5 fs-5 pb-3 '>
- Khả năng giải quyết tình huống tốt, chủ động và linh hoạt trong công việc
</div>

<div class='fw-bolder  text-success fs-3 py-3 '>
3. QUYỀN LỢI: 
</div>
<div class=' ps-5 fs-5 '>
- Thu nhập từ 8.000.000 - 12.000.000 vnđ/tháng (thỏa thuận theo năng lực) + hoa hồng bán hàng + thưởng kinh doanh.
</div>
<div class=' ps-5 fs-5 '>
- Thử việc 1-2 tháng tùy theo năng lực.
</div>
<div class=' ps-5 fs-5 '>
- Thời gian làm việc: 8h - 17h30 từ thứ 2 - thứ 7, Nghỉ trưa 11h45 - 13h30.
</div>
<div class=' ps-5 fs-5 '>
- Xét tăng lương định kỳ 2 lần/năm.
</div>
<div class=' ps-5 fs-5 '>
- Đảm bảo chế độ BHXH, du lịch hàng năm.
</div>
<div class=' ps-5 fs-5 '>
- Nghỉ phép hàng năm, nghỉ ốm, nghỉ thai sản (nếu có), và các ngày nghỉ lễ theo quy định.
</div>
<div class=' ps-5 fs-5 '>
- Được tham gia các khóa đào tạo định kỳ.
</div>
<div class=' ps-5 fs-5 '>
- Đánh giá tăng lương định kì 2 lần/năm.
</div>
<div class=' ps-5 fs-5 '>
- Môi trường làm việc năng động , BLĐ luôn quan tâm đến đời sống CNV
</div>
<div class=' ps-5 fs-5 '>
- Đồng nghiệp cởi mở, chân thành và nhiệt huyết…
</div>
<div class='fw-bolder  text-success fs-3 py-3 '>
 4. Thông tin liên hệ:
</div>
<div class=' ps-3 fs-4 fw-bolder '>
 Địa điểm làm việc: Biệt thự số 2, OBT2, KĐT Bắc Linh Đàm, 
 Quận Hoàng Mai, HN
</div>
<div class=' ps-3 fs-4 fw-bolder '>
 Thời gian làm việc: Từ thứ 2 đến thứ 7: 8h15 - 17h30
</div>
<div class=' ps-3 fs-4 fw-bolder '>
 Gửi CV về mail: tuyendung.dabosagroup@gmail.com - Tiêu đề’’ Vị trí ứng tuyển - Họ tên’’
</div>
<div class=' ps-3 fs-4 fw-bolder '>
 Liên hệ: Ms. Vũ Thủy - P. Nhân sự: 0912.810.305
</div>
`;
export const tuyendung_saletour = `

<div class='fw-bolder  text-success  fs-1 py-3'>
JD - Sales Tour
</div>

<div class='fw-bolder ps-5 fs-4 '>
Phòng Ban:Du Lịch
</div>

<div class='fw-bolder ps-5 fs-4 '>
Chức danh: Chuyên viên Sales Tour
</div>

<div class='fw-bolder ps-5 fs-4 '>
Chế độ làm việc: Toàn thời gian
</div>

<div class='fw-bolder ps-5 fs-4 '>
Báo cáo cho: Giám đốc Du Lịch
</div>

<div class='fw-bolder  text-success fs-3 py-3 '>
1. Mô tả công việc:
</div>
<div class=' ps-5 fs-5 '>
- Tư vấn cho khách hàng về các gói tour, dịch vụ đi kèm, dịch vụ và điểm đến, thuyết phục khách hàng lựa chọn các tour phù hợp với nhu cầu và ngân sách của họ.
</div>
<div class=' ps-5 fs-5 '>
- Lập kế hoạch tìm kiếm và phát triển danh sách khách hàng mới thông qua các kênh online, offline hoặc là các mối quan hệ có sẵn.
</div>
<div class=' ps-5 fs-5 '>
- Thực hiện các công việc liên quan đến hợp đồng và thanh toán của khách hàng.
</div>
<div class=' ps-5 fs-5 '>
- Hỗ trợ khách hàng trong quá trình chuẩn bị và tham gia tour du lịch.
</div>
<div class='fw-bolder  text-success fs-3 py-3 '>
2. YÊU CẦU:
</div>
<div class=' ps-5 fs-5 '>
- Tốt nghiệp Cao đẳng/Đại học chuyên ngành Du lịch, Quản trị Kinh doanh hoặc các ngành liên quan.
</div>
<div class=' ps-5 fs-5 '>
- Kỹ năng giao tiếp và đàm phán tốt.
</div>
<div class=' ps-5 fs-5 '>
- Năng động, nhiệt tình, có khả năng làm việc độc lập và theo nhóm.
</div>
<div class=' ps-5 fs-5 '>
- Ưu tiên ứng viên có ngoại ngữ (Anh, Hàn, Nhật...) và hiểu biết về thị trường du lịch.
</div>
<div class='fw-bolder  text-success fs-3 py-3 '>
3. QUYỀN LỢI: 
</div>
<div class=' ps-5 fs-5 '>
-  Mức lương thỏa thuận theo năng lực + hoa hồng
</div>
<div class=' ps-5 fs-5 '>
- Thử việc 2 tháng , hưởng 85% lương cơ bản
</div>
<div class=' ps-5 fs-5 '>
- Thời gian làm việc: 8h00 - 17h30 từ thứ 2 - thứ 7
</div>
<div class=' ps-5 fs-5 '>- Được hưởng chế độ BHXH , nghỉ các ngày lễ, nghỉ phép năm theo quy định nhà nước.
</div>
<div class=' ps-5 fs-5 '>
- Được đi theo tour thực tế để học hỏi lấy kinh nghiệm. 
</div>
<div class=' ps-5 fs-5 '>
- Được đào tạo, học hỏi, có cơ hội thăng tiến
</div>
<div class=' ps-5 fs-5 '>
- Môi trường chuyên nghiệp, trẻ trung, năng động.
</div>
<div class='fw-bolder  text-success fs-3 py-3 '>
 4. Thông tin liên hệ:
</div>
<div class=' ps-5 fs-4 fw-bolder '>
 Địa điểm làm việc: Biệt thự số 2, OBT2, KĐT Bắc Linh Đàm, 
 Quận Hoàng Mai, HN
</div>
<div class=' ps-5 fs-4 fw-bolder '>
 Thời gian làm việc: Từ thứ 2 đến thứ 7: 8h15 - 17h30
</div>
<div class=' ps-5 fs-4 fw-bolder '>
 Gửi CV về mail: tuyendung.dabosagroup@gmail.com - Tiêu đề’’ Vị trí ứng tuyển - Họ tên’’
</div>
<div class=' ps-5 fs-4 fw-bolder '>
 Liên hệ: Ms. Vũ Thủy - P. Nhân sự: 0912.810.305
</div>
`;
