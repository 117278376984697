import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import HeadTab from "src/components/HeadTab/HeadTab";
import API_STORE from "src/controllers/ApiStore";

const ChiTietCamNang = () => {
  const { state } = useLocation();
  const NewID = state?.NewID;
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = () => {
    API_STORE.getNewByID(NewID)
      .then((res: any) => {
        // console.log("res", res);
        setData(res?.Data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div>
      <HeadTab />
      <div className="container-xxl py-5">
        <div dangerouslySetInnerHTML={{ __html: data?.DetailNote }}></div>
      </div>
    </div>
  );
};

export default ChiTietCamNang;
