import { UnorderedListOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import React from "react";
import HeaderImages from "../../../assets/Header";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ItemRightForMobile = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <div className="btn border border-0 p-0">GIỚI THIỆU</div>,
      children: [
        {
          key: "1-1",
          label: (
            <button
              onClick={() => navigate("/introducing-dabosa-group")}
              className="btn border border-0 p-0"
            >
              Về Dabosa Group
            </button>
          ),
        },
        {
          key: "1-2",
          label: (
            <button
              onClick={() => navigate("/lanh-dao")}
              className="btn border border-0 p-0"
            >
              Ban Lãnh Đạo
            </button>
          ),
        },
      ],
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => navigate("/course")}
          className="btn border border-0 p-0"
        >
          KHOÁ HỌC
        </div>
      ),
    },
    {
      key: "3",
      label: <div className="btn border border-0 p-0">DU LỊCH</div>,
      children: [
        {
          key: "3-1",
          label: (
            <div
              onClick={() => navigate("/du-lich", { state: { id: 7 } })}
              className="btn border border-0 p-0"
            >
              Du lịch nội địa
            </div>
          ),
        },
        {
          key: "3-2",
          label: (
            <div
              className="btn border border-0 p-0"
              onClick={() => navigate("/du-lich", { state: { id: 8 } })}
            >
              Du lịch quốc tế
            </div>
          ),
        },
        {
          key: "3-3",
          label: (
            <div
              className="btn border border-0 p-0"
              onClick={() =>
                navigate("/cam-nang-du-lich", { state: { id: 9 } })
              }
            >
              {t("Travel guide")}
            </div>
          ),
        },
        {
          key: "3-4",
          label: (
            <div
              onClick={() => navigate("/cac-hoat-dong-tieu-bieu/dulich", {})}
              className="btn border border-0 p-0"
            >
              {t("Typical activities")}
            </div>
          ),
        },
      ],
    },
    {
      key: "4",
      label: (
        <div
          onClick={() => navigate("/news")}
          className="btn border border-0 p-0"
        >
          TIN TỨC
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <div
          onClick={() => navigate("/contact")}
          className="btn border border-0 p-0"
        >
          LIÊN HỆ
        </div>
      ),
    },
    {
      key: "6",
      label: (
        <div
          onClick={() => navigate("/recruitment")}
          className="btn border border-0 p-0"
        >
          TUYỂN DỤNG
        </div>
      ),
    },
    // {
    //   key: "8",
    //   label: (
    //     <button className="btn border border-0 p-0 d-flex align-items-center gap-2">
    //       EN <img src={HeaderImages.my} width={24} />
    //     </button>
    //   ),
    // },
  ];

  return (
    <div className="d-block d-lg-none">
      <Dropdown menu={{ items }} placement="bottomRight">
        <button className="btn border border-0 p-0">
          <UnorderedListOutlined style={{ fontSize: 20, color: "#589a83" }} />
        </button>
      </Dropdown>
    </div>
  );
};

export default ItemRightForMobile;
