import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import React, { useEffect, useRef, useState } from "react";
import "./home.css";
import HeaderImages from "src/assets/Header";
import TourInland from "./Components/TourInland";
import TourInternational from "./Components/TourInternational";
import Travel_Guide from "./Components/TravelGuide";
import Typical from "./Components/Typical";
import Travel from "./Components/Travel";
import AOS from "aos";
import "aos/dist/aos.css";
import { useLocation } from "react-router-dom";
import Tour from "./Components/Tour";
import Register from "../HomePage/Components/Register";
import { useTranslation } from "react-i18next";

function Home() {
  const myRef = useRef<any>(null);
  const { state } = useLocation();
  const { t } = useTranslation();
  const id = state?.id || "";
  const [currentIndex, setCurrentIndex] = useState(-1);

  useEffect(() => {
    AOS.init({ duration: 1000 });
    switch (id) {
      case 7:
        window.scrollTo(0, 900);
        break;
      case 8:
        window.scrollTo(0, 2200);
        break;
      case 9:
        window.scrollTo(0, 3550);
        break;
      default:
        break;
    }
  }, [id]);

  return (
    <div>
      <Travel aos="fade-up" />
      <div className="pt-3">
        <div className="d-flex align-items-center  mb-3 w-100">
          <button
            onClick={() => setCurrentIndex(0)}
            className={
              currentIndex === 0
                ? "international rounded-5 bg-success text-white"
                : "international rounded-5"
            }
          >
            <div className="fs-6 fw-bolder">{t("VietNam Tour").toUpperCase()}</div>
          </button>
          <button
            className={
              currentIndex === 1
                ? "international rounded-5 bg-success text-white"
                : "international rounded-5"
            }
            style={{
              height: 42,
            }}
            onClick={() => setCurrentIndex(1)}
          >
            <div className="fs-6 fw-bolder">
              {t("International Tour").toUpperCase()}
            </div>
          </button>
        </div>

        {currentIndex === 0 ? (
          <TourInland aos="fade-up" />
        ) : currentIndex > 0 ? (
          <TourInternational aos="fade-up" />
        ) : (
          <div />
        )}
      </div>
      
      <Travel_Guide currentIndex={currentIndex} aos="fade-up" />
      <Register aos="fade-up" />
      <Typical aos="fade-up" />
    </div>
  );
}

export default Home;
