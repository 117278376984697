import { ArrowRightOutlined } from "@ant-design/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import HomePageImages from "src/assets/HomePage";
import HeadTab from "src/components/HeadTab/HeadTab";
import { Rec_List } from "src/constant/Array";

const Recruitment = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div>
      <HeadTab />
      <div className="container-xxl py-5">
        <div
          className="d-flex justify-content-center flex-column"
          style={{
            width: "max-content",
          }}
        >
          <div className="fw-bolder fs-2 text-success">
          {t("Vacancies")}
          </div>
          <div
            style={{
              height: 3,
              backgroundColor: "#589a83",
            }}
          />
        </div>

        <div className="pt-5 row col-md-12">
          {Rec_List.map((item) => (
            <>
              <div className="d-block d-sm-none mt-3" />
              <div className="col-md-3">
                <div className="w-100">
                  <img src={item.img} className="w-100 rounded-2" alt="" />
                  <div className="py-2 text-success fw-semibold">
                    {item.title}
                  </div>
                  <button
                    onClick={() => navigate("/detail-recruitment",{
                      state: {content:item.content}
                    })}
                    className="btn-see-more-rec"
                  >
                    <ArrowRightOutlined />
                    <div className="fw-bolder">Đọc tiếp</div>
                  </button>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Recruitment;
