import { DownOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const DropdownDuLich = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          onClick={() => navigate("/du-lich", { state: { id: 7 } })}
          className="btn border border-0 p-0"
        >
         {t("Domestic tourism")}
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          className="btn border border-0 p-0"
          onClick={() => navigate("/du-lich", { state: { id: 8 } })}
        >
          {t("International travel")}
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          className="btn border border-0 p-0"
          onClick={() => navigate("/du-lich", { state: { id: 9 } })}
        >
         {t("Travel guide")}
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div
          onClick={() => navigate("/cac-hoat-dong-tieu-bieu/dulich", {})}
          className="btn border border-0 p-0"
        >
         {t("TYPICAL ACTIVITIES")}
        </div>
      ),
    },
  ];

  return (
    <Dropdown menu={{ items }} placement="bottomRight">
      <button className="btn-right fw-medium gap-1 d-flex align-items-center">
      {t("TOURISM")}
        <DownOutlined style={{ fontSize: 10 }} />
      </button>
    </Dropdown>
  );
};

export default DropdownDuLich;
