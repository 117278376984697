import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import IntroImages from "src/assets/Intro";
import HeadTab from "src/components/HeadTab/HeadTab";

const Col1 = ({ aos }: any) => {
  const { t } = useTranslation();
  return (
    <div data-aos={aos}>
      <HeadTab />
      <div
        className="py-5"
        style={{
          backgroundImage: `url(${IntroImages.bg_1})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "max-content",
        }}
      >
        <div className="container-xxl">
          <div className="row col-md-12 justify-content-md-between">
            <div className="col-md-6">
              <div
                className="d-flex justify-content-center flex-column"
                style={{
                  width: "max-content",
                }}
              >
                <div className="fs-2 fw-bolder" style={{ color: "#ffd75c" }}>
                  {t("Open letter")}
                </div>
                <div
                  style={{
                    height: 3,
                    backgroundColor: "#ffd75c",
                  }}
                />
              </div>
              <div
                className="fw-semibold pt-5 fs-5"
                style={{
                  color: "#ffd75c",
                }}
              >
                {t("DABOSA Group Joint Stock Company would like to welcome customers and partners!")}
              </div>
              <br />
              <div className="text-white fw-semibold">
                {t("First of all, we would like to wish health and success to our customers and partners!")}
              </div>
              <br />
              <div className="text-white fw-semibold">
                {t("DABOSA Group Joint Stock Company was born with the mission of becoming a reliable bridge, providing optimal service solutions in the fields of Study Abroad - Tourism and foreign language training, contributing to making dreams come true. Vietnam's young generation and provide great travel experiences.")}
              </div>
              <div className="text-white pt-5 fw-semibold">
            {t("Gathering a team of leading experts with 5 - 20 years of experience in the field, DABOSA GROUP is committed to providing transparent information, consulting on study abroad and travel programs at reasonable costs, and personalizing them accordingly. suitable for each need of customers and partners. We are always ready to listen and constantly innovate to become a reputable study abroad consulting, foreign language training and travel service provider on the market.")}
          </div>
          <br />
          <div className="text-white fw-semibold">
            {t(" Finally, we would like to send wishes of health, peace, happiness, and successful work to all customers and partners who have been and will be using DABOSA GROUP's services.")}
          </div>
          <br />
          <div
            className="fw-semibold"
            style={{
              color: "#ffd75c",
            }}
          >
          {t("Thank you very much and we are pleased to serve you!")}
          </div>
            </div>
            <div className="d-block d-sm-none mt-3" />
            <div className="col-md-5 ">
              <img
                src={IntroImages.avt_right}
                className="avt-right shadow-sm "
                alt=""
              />
            </div>
          </div>

          
        </div>
      </div>
    </div>
  );
};

export default Col1;
