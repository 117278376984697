import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Hand_Book, TravelGuide } from "src/constant/Array";
import { current_language, GetCookies } from "src/constant/Cookies";
import API_STORE from "src/controllers/ApiStore";
import i18n from "src/locales/i18n";

const Handbook = ({ aos }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [listTour, setListTour] = useState<any>([]);

  useEffect(() => {
    getCurrentLanguage();
  }, [i18n.resolvedLanguage]);

  const getCurrentLanguage = () => {
    const language: any = GetCookies(current_language);
    getNews(language);
  };

  const getNews = (language: string) => {
    const typeByLanguage = language === "vn" ? 0 : language === "en" ? 1 : 2;
    const params = {
      type: "6",
      language: typeByLanguage,
      pageIndex: "1",
      pageSize: "12",
    };

    API_STORE.getNewsByTypeAndLanguage(params)
      .then((res: any) => {
        console.log("res", res);
        setListTour(res?.Data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div
      data-aos={aos}
      style={{
        background: "#fed842",
      }}
    >
      <div className="container-xxl">
        <div
          className="d-flex justify-content-center flex-column"
          style={{
            width: "max-content",
          }}
        >
          <div className="title-body-study">{t("STUDY ABROAD GUIDE")}</div>
          <div
            style={{
              height: 3,
              backgroundColor: "#00b09b",
            }}
          />
        </div>

        <div className="row mt-4">
          {listTour.map((item: any) => {
            return (
              <div key={item.NewsID} className="col-6 col-md-6 col-lg-3 mb-4">
                <div className="btn-item-travel pb-3 w-100">
                  <div className="w-100">
                    <img src={item.Avatar} className="img-item-travel" />
                    <div className="txt-title-study">{item.Title}</div>
                  </div>
                  <button
                    onClick={() =>
                      navigate("/cam-nang-chi-tiet", {
                        state: { NewID: item?.NewsID },
                      })
                    }
                    className="btn-see-more"
                  >
                    Đọc tiếp
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className="d-flex align-items-center justify-content-center">
        <button className="btn-see-all-tour-inland mb-4">Xem tất cả</button>
      </div> */}
    </div>
  );
};

export default Handbook;
