
import client from './Client';


const API_STORE = {
    async getBanner() {
        try {
            const res = await client.post(`/WebApi/GetBanner`,);
            return res;
        } catch (err) {
            console.log(err?.message);
        }
    },

    //     với điều kiện : 
    // type : 
    // public enum News_Type
    // {
    //     [Description("Giới thiệu")]
    //     Intro = 1,

    //     [Description("Tin tức")]
    //     Block = 2,

    //     [Description("Liên hệ")]
    //     Contact = 3,

    //     [Description("Tuyển dụng")]
    //     HrNew = 4,

    //     [Description("Du học - Các chương trình du học")]
    //     StudyAbroad_1 = 5,

    //     [Description("Du học - Cẩm nang du học")]
    //     StudyAbroad_2 = 6,

    //     [Description("Du học - Các hoạt động tiêu biểu")]
    //     StudyAbroad_3 = 7,

    //     [Description("Du lịch - Tour nội địa")]
    //     Travel_1 = 8,

    //     [Description("Du lịch - Tour quốc tế")]
    //     Travel_2 = 9,

    //     [Description("Du lịch - Cẩm nang du lịch")]
    //     Travel_3 = 10,

    //     [Description("Du lịch - Các hoạt động tiêu biểu")]
    //     Travel_4 = 11,

    //     [Description("Khóa học")]
    //     Study = 12,
    // }

    // language : 
    //     public enum News_Language
    //     {
    //         [Description("Tiếng Anh")]
    //         English = 1,
    //         [Description("Tiếng Việt")]
    //         VietNam = 0,
    //         [Description("Tiếng Trung")]
    //         China = 2,
    //     }

    async getNewsByTypeAndLanguage(params: any) {
        try {
            const res = await client.get(`/WebApi/GetNewsByTypeAndLanguage?type=${params?.type}&language=${params?.language}&pageIndex=${params?.pageIndex}&pageSize=${params?.pageSize}`);
            return res;
        } catch (err) {
            console.log(err?.message);
        }
    },

    async getNewByID(id: number | string) {
        try {
            const res = await client.get(`/WebApi/GetByID?ID=${id}`);
            return res;
        } catch (err) {
            console.log(err?.message);
        }
    },

    async AddOrder(params: any) {
        try {
            const res = await client.post(`/WebApi/AddOrUpdate`, params);
            return res;
        } catch (err) {
            console.log(err?.message);
        }
    },
}

export default API_STORE