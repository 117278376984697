import React from "react";
import { useTranslation } from "react-i18next";
import CourseImages from "src/assets/Course";
import HeadTab from "src/components/HeadTab/HeadTab";

const Course = () => {
  const { t } = useTranslation();
  return (
    <div>
      <HeadTab />
      <div className="container-xxl py-5">
        <div
          className="d-flex justify-content-center flex-column"
          style={{
            width: "max-content",
          }}
        >
          <div className="fw-bolder fs-2 text-success">
           {t("Foreign language courses at DABOSA")}
          </div>
          <div
            style={{
              height: 3,
              backgroundColor: "#589a83",
            }}
          />
        </div>

        <div
          className="row col-md-12 rounded-3 p-3 my-4"
          style={{
            background: "#f5f5f5",
          }}
        >
          <div className="col-md-6">
            <div className="container-img-p-1">
              <img
                src={CourseImages[1]}
                width={"100%"}
                style={{ borderRadius: 8 }}
                alt=""
              />
            </div>
          </div>
          <div className="col-md-6 pt-2">
            <div className="text-success fw-bolder fs-3">
              {t("Japanese language course")}
            </div>
            <div className="fw-semibold fs-5">{t("Online learning form")}</div>
            <br />
            <div className="text-success fw-bolder fs-5">
              {t("Course information:")}
            </div>
            <div className="pt-2 fs-5">{t("")}</div>
            <div className="py-1 fs-5">
            {t("• Target: Pupils/students/working people")}
            </div>
            <div className='fs-5'> {t("• Output N4 or N5")}</div>
            <div className="py-1 fs-5"> {t("• Minano Nihongo textbook")}</div>
            <div className='fs-5'> {t("• Learn all 4 basic communication skills")}</div>
            <div className="py-1 fs-5"> {t("• Interact with local people")}</div>
            <div className='fs-5'> {t("• Online learning form")}</div>
            <button
              onClick={() => window.scrollTo(0, 1800)}
              className="btn-see-register-course mb-5 mt-3"
            >
             {t("Register now")}
            </button>
          </div>
        </div>

        <div
          className="row col-md-12 rounded-3 p-3 mb-4"
          style={{
            background: "#f5f5f5",
          }}
        >
          <div className="col-md-6 pt-2">
            <div className="text-success fw-bolder fs-3">
             {t("Korean language course")}
            </div>
            <div className="fw-semibold fs-5">{t("Online learning form")}</div>
            <br />
            <div className="text-success fw-bolder fs-5">
            {t("Course information:")}
            </div>
            <div className="pt-2 fs-5">{t("• Age from 16 - 30")}</div>
            <div className="py-1 fs-5">
             {t("• Target: Pupils/students/working people")}
            </div>
            <div className="fs-5">{t("• Topik 2 output")} </div>
            <div className="py-1 fs-5">{t("• General Korean language textbook")}</div>
            <div className="fs-5">{t("• Learn all 4 basic communication skills")}</div>
            <div className="py-1 fs-5">{t("• Interact with local people")}</div>
            <div className="fs-5">{t("• Online learning form")}</div>
            <button
              onClick={() => window.scrollTo(0, 1800)}
              className="btn-see-register-course mb-5 mt-3"
            >
              {t("Register now")}
            </button>
          </div>
          <div className="col-md-6">
            <div className="container-img-p-1">
              <img
                src={CourseImages[2]}
                width={"100%"}
                style={{ borderRadius: 8 }}
                alt=""
              />
            </div>
          </div>
        </div>

        <div
          className="row col-md-12 rounded-3 p-3 my-4"
          style={{
            background: "#f5f5f5",
          }}
        >
          <div className="col-md-6">
            <div className="container-img-p-1">
              <img
                src={CourseImages[3]}
                width={"100%"}
                style={{ borderRadius: 8 }}
                alt=""
              />
            </div>
          </div>
          <div className="col-md-6 pt-2">
            <div className="text-success fw-bolder fs-3">
         {t("Chinese language course")}
            </div>
            <div className="fw-semibold fs-5">{t("Online learning form")}</div>
            <br />
            <div className="text-success fw-bolder fs-5">
            {t("Course information:")}
            </div>
            <div className="pt-2 fs-5">{t("• Age from 16 - 30")}</div>
            <div className="py-1 fs-5">
            {t("• Target: Pupils/students/working people")}
            </div>
            <div className="fs-5 fs-5">{t("• HSK 3 output")}</div>
            <div className="py-1 fs-5">
            {t("• HSK textbook Learn all 4 basic communication skills. Interact with native people")}
            </div>
            <button
              onClick={() => window.scrollTo(0, 1800)}
              className="btn-see-register-course mb-5 mt-3"
            >
              {t("Register now")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Course;
