import { ArrowRightOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import HomePageImages from "src/assets/HomePage";
import HeadTab from "src/components/HeadTab/HeadTab";
import { News_List } from "src/constant/Array";
import { current_language, GetCookies } from "src/constant/Cookies";
import API_STORE from "src/controllers/ApiStore";
import i18n from "src/locales/i18n";

const News = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [listNews, setListNews] = useState<any>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getCurrentLanguage();
  }, [i18n.resolvedLanguage]);

  const getCurrentLanguage = () => {
    const language: any = GetCookies(current_language);
    getNews(language);
  };

  const getNews = (language: string) => {
    const typeByLanguage = language === "vn" ? 0 : language === "en" ? 1 : 2;
    const params = {
      type: "2",
      language: typeByLanguage,
      pageIndex: "1",
      pageSize: "50",
    };

    API_STORE.getNewsByTypeAndLanguage(params)
      .then((res: any) => {
        console.log("res", res);
        setListNews(res?.Data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div>
      <HeadTab />
      <div className="container-xxl py-5">
        <div
          className="d-flex justify-content-center flex-column"
          style={{
            width: "max-content",
          }}
        >
          <div className="fw-bolder fs-2 text-success">Tin tức & sự kiện</div>
          <div
            style={{
              height: 3,
              backgroundColor: "#589a83",
            }}
          />
        </div>
        <div className="row col-md-12">
          {listNews.map((i: any) => (
            <div className="col-md-4 mt-3" key={i.id}>
              <button
                onClick={() => {
                  navigate("/chi-tiet-tin-tuc", {
                    state: { NewID: i?.NewsID },
                  });
                }}
                className="btn border border-0 p-0 d-flex flex-column text-start w-100"
              >
                <img
                  src={i?.Avatar}
                  style={{ width: "100%", height: 250, borderRadius: 8 }}
                />
                <div className="py-2 fw-semibold">{i?.Title}</div>
                <div
                  onClick={() => navigate("/chi-tiet-tin-tuc")}
                  className="d-flex align-items-center gap-2"
                >
                  <ArrowRightOutlined className="text-success" />
                  <div className="fw-bolder text-success">{t("See more")}</div>
                </div>
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default News;
