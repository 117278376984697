import React from "react";
import { useTranslation } from "react-i18next";
import IntroImages from "src/assets/Intro";

const Col3 = ({ aos }: any) => {
  const { t } = useTranslation();
  return (
    <div
      data-aos={aos}
      className="pt-5"
      style={{
        background: "linear-gradient(90deg,#007261, #21c586)",
        height: "max-content",
      }}
    >
      <div className="container-xxl pb-5">
        <div className="row col-md-12 justify-content-md-center gap-2">
          <div className="col-md-5">
            <div className='d-flex align-items-center justify-content-center'>
            <div
              className="p-5 intro-img-min-5"
              style={{
                backgroundImage: `url(${IntroImages.min_5})`,
              }}
            >
              <div
                className="d-flex justify-content-center flex-column pt-5"
                style={{
                  width: "max-content",
                }}
              >
                <div className="fs-2 fw-bolder text-success">{t("Vision")}</div>
                <div
                  style={{
                    height: 3,
                    backgroundColor: "#007261",
                  }}
                />
              </div>
              <div className="pt-3">
               {t("DABOSA aims to become one of the leading reputable units in Vietnam in the field of global study abroad and travel, a 'soul friend' accompanying customers anytime, anywhere.")}
              </div>
            </div>
              </div>
          </div>
          <div className="col-md-5">
          <div className='d-flex align-items-center justify-content-center'>
          <div
              className="p-5 intro-img-min-5"
              style={{
                backgroundImage: `url(${IntroImages.min_6})`,
              
              }}
            >
              <div
                className="d-flex justify-content-center flex-column pt-5"
                style={{
                  width: "max-content",
                }}
              >
                <div className="fs-2 fw-bolder text-success">{t("Mission")}</div>
                <div
                  style={{
                    height: 3,
                    backgroundColor: "#007261",
                  }}
                />
              </div>
              <div className="pt-3">
               {t("DABOSA constantly strives to become a bridge of trust and sincere goodwill with partners and customers, bringing optimal solutions with good quality and reasonable costs, contributing to giving wings to the dreams of the young generation. Vietnam has the opportunity to develop a sustainable career and have relaxing trips and wonderful experiences when customers choose us as their companion.")}
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          background: "#fff8ed",
        }}
      >
        <div className="row col-md-12">
          <div className="col-md-6 pt-5 ">
            <div className="px-5 ">
              <div
                className="d-flex justify-content-center flex-column pt-5 pb-2"
                style={{
                  width: "max-content",
                }}
              >
                <div className="fs-2 fw-bolder text-success ">
                  {t("Core values")}
                </div>
                <div
                  style={{
                    height: 3,
                    backgroundColor: "#007261",
                  }}
                />
              </div>
              <br />
              <div className="fs-5 fw-bolder fw-4">
              {t("HEART :")}
                </div>
              <div className="fs-5">
                {t("Work and contribute, serve customers with all your enthusiasm and enthusiastic heart, taking customers as the center.")}
              </div>
              <br />
              <div className="fs-5 fw-bolder fw-4">{t("RANGE :")}</div>
              <div className="fs-5">
               {t("Constantly trying to improve business positioning, product and service quality in the market")}
              </div>
              <br />
              <div className="fs-5 fw-bolder fw-4">{t("Credit :")}</div>
              <div className="fs-5">
               {t("Appreciate reputation, always strive to improve reputation")}
              </div>
              <br />
              <div className="fs-5 fw-bolder fw-4">{t("MIND :")}</div>
              <div className="fs-5">
               {t("Cultivate and learn with a spirit of inquiry to improve knowledge, depth of understanding and wisdom.")}
              </div>
              <br />
              <div className="fs-5 fw-bolder fw-4">{t("VIRTUE :")}</div>
              <div className="fs-5">
                {t("Cultivate and practice morality, preserve your dignity and personality.")}
              </div>
            </div>
          </div>
          <div className="d-block d-sm-none mt-3" />
          <div className="col-md-6">
            <img src={IntroImages.min_14} className="img-min-7" />
          </div>
        </div>
        <div className="d-block d-sm-none mt-3" />
        <div className="col-md-12 row">
          <div className="col-md-6">
            <img src={IntroImages.min_8} className="img-min-8" alt="" />
          </div>
          <div className="col-md-6">
            <div className="px-5">
              <div
                className="d-flex justify-content-center flex-column pt-5 pb-2"
                style={{
                  width: "max-content",
                }}
              >
                <div className="fs-2 fw-bolder text-success">
                  {t("Operating philosophy:")}
                </div>
                <div
                  style={{
                    height: 3,
                    backgroundColor: "#007261",
                  }}
                />
              </div>
              <br />
              <div className="fs-5">{t("• Take the word Heart as a guideline.")}</div>
              <div className="fs-5">
                {t("• Always inquiring, constantly innovating and creating.")}
              </div>
              <div className="fs-5">
                {t("• An ideal place to study, work, and become a soul mate.")}
              </div>
            </div>
          </div>
        </div>

        <div className="row col-md-12">
          <div className="col-md-6">
            <div className="px-5">
              <div
                className="d-flex justify-content-center flex-column pt-5 pb-2"
                style={{
                  width: "max-content",
                }}
              >
                <div className="fs-2 fw-bolder text-success">
                {t("Corporate culture:")}
                </div>
                <div
                  style={{
                    height: 3,
                    backgroundColor: "#007261",
                  }}
                />
              </div>
              <br />
              <div className='fs-5' >
              {t("• Advise, care for, and help each other develop together; sincere, honest")}
              </div>
              <div className='fs-5'>{t("• The spirit of encouragement and building to help each other improve.")}</div>
              <div className='fs-5'>
                {t("• Respect and listen to each other's opinions, take high responsibility for work.")}
              </div>
              <div className='fs-5'>
                {t("• Discipline, fairness, equality in behavior and each other's legitimate interests.")}
              </div>
              <div className='fs-5'>{t("• Not afraid of difficulties and challenges.")}</div>
              <div className='fs-5'>{t("• Transparency and clarity in work.")}</div>
            </div>
          </div>
          <div className="d-block d-sm-none mt-3" />
          <div className="col-md-6">
            <img src={IntroImages.min_9} className='intro-img-min-9' alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Col3;
