import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TravelGuide } from "src/constant/Array";
import { current_language, GetCookies } from "src/constant/Cookies";
import API_STORE from "src/controllers/ApiStore";
import i18n from "src/locales/i18n";

const Travel_Guide = ({ aos, currentIndex }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [listTour, setListTour] = useState<any>([]);

  useEffect(() => {
    getCurrentLanguage();
  }, [i18n.resolvedLanguage, currentIndex]);

  const getCurrentLanguage = () => {
    const language: any = GetCookies(current_language);
    getNews(language);
  };

  console.log("currentIndex", currentIndex);

  const getNews = (language: string) => {
    const typeByLanguage = language === "vn" ? 0 : language === "en" ? 1 : 2;
    const params = {
      type: currentIndex === 0 ? "10" : currentIndex === 1 ? "13" : "",
      language: typeByLanguage,
      pageIndex: "1",
      pageSize: "8",
    };

    if (currentIndex === 0 || currentIndex === 1) {
      API_STORE.getNewsByTypeAndLanguage(params)
        .then((res: any) => {
          console.log("res", res);
          setListTour(res?.Data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  return (
    <div
      data-aos={aos}
      style={{
        background: "linear-gradient(210deg,#007261, #21c586)",
      }}
    >
      {currentIndex === 0 || currentIndex === 1 ? (
        <>
          <div className="container-xxl">
            <div
              className="d-flex justify-content-center flex-column"
              style={{
                width: "max-content",
              }}
            >
              <div className="title-body-3">{t("Travel guide")}</div>
              <div
                style={{
                  height: 3,
                  backgroundColor: "#fed842",
                }}
              />
            </div>

            <div className="row mt-4">
              {listTour.map((item: any) => {
                return (
                  <div
                    key={item.NewsID}
                    className="col-6 col-md-6 col-lg-3 mb-4"
                  >
                    <div
                      onClick={() => {
                        navigate("/chi-tiet-cam-nang", {
                          state: { NewID: item.NewsID },
                        });
                      }}
                      className="btn-item-travel pb-3 w-100"
                    >
                      <div className="w-100">
                        <img
                          src={item.Avatar}
                          className="img-item-tour-inland"
                        />
                        <div className="fw-semibold py-2 px-3 title-travel-guide">
                          {item.Title}
                        </div>
                      </div>
                      <button
                        onClick={() => navigate("/chi-tiet-cam-nang")}
                        className="btn-see-more"
                      >
                        Đọc tiếp
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <button
              onClick={() => navigate("/cam-nang-du-lich")}
              className="btn-see-all-tour-inland mb-4"
            >
              {t("See all")}
            </button>
          </div>
        </>
      ) : (
        <div />
      )}
    </div>
  );
};

export default Travel_Guide;
