const TourInternationalImages = {
    bangkok: require('./bangkok.png'),
    nhat: require('./nhat.png'),
    seoul: require('./seoul.png'),
    sing: require('./sing.png'),
    cotran: require('./cotran.png'),
    legiang: require('./legiang.png'),
    hainam: require('./hainam.png'),
    thanhdo: require('./thanhdo.png'),
    quangdong: require('./quangdong.png'),
    aoyama1: require('./aoyama1.png'),
    aoyama2: require('./aoyama2.png'),
    aoyama3: require('./aoyama3.png'),
    aoyama4: require('./aoyama4.png'),
    ngoaingu1: require('./ngoaingu1.png'),
    ngoaingu2: require('./ngoaingu2.png'),
    ngoaingu3: require('./ngoaingu3.png'),
    ngoaingu4: require('./ngoaingu4.png'),
    trungthu20241: require('./trungthu20241.png'),
    trungthu20242: require('./trungthu20242.png'),
    trungthu20243: require('./trungthu20243.png'),
    trungthu20244: require('./trungthu20244.png'),
    thuongbinhlietsi1: require('./thuongbinhlietsi1.png'),
    thuongbinhlietsi2: require('./thuongbinhlietsi2.png'),
    thuongbinhlietsi3: require('./thuongbinhlietsi3.png'),
    thuongbinhlietsi4: require('./thuongbinhlietsi4.png'),
    quaando1: require('./quaando1.png'),
    quaando2: require('./quaando2.png'),
    quaando3: require('./quaando3.png'),
    quaando4: require('./quaando4.png'),
    hoinghikhachhanghasuka1: require('./hoinghikhachhanghasuka1.png'),
    hoinghikhachhanghasuka2: require('./hoinghikhachhanghasuka1.png'),
    hoinghikhachhanghasuka3: require('./hoinghikhachhanghasuka3.png'),
    hoinghikhachhanghasuka4: require('./hoinghikhachhanghasuka4.png'),
    khachando1: require('./khachando1.png'),
    khachando2: require('./khachando2.png'),
    khachando3: require('./khachando3.png'),
    khachando4: require('./khachando4.png'),
    daotaonhansu1: require('./daotaonhansu1.png'),
    daotaonhansu2: require('./daotaonhansu2.png'),
    daotaonhansu3: require('./daotaonhansu3.png'),
    daotaonhansu4: require('./daotaonhansu4.png'),
    v991: require('./v991.png'),
    v992: require('./v992.png'),
    v993: require('./v993.png'),
    v994: require('./v994.png'),
    tourv991: require('./tourv991.png'),
    tourv992: require('./tourv992.png'),
    tourv993: require('./tourv993.png'),
    tourv994: require('./tourv994.png'),
    tourhainam1: require('./tourhainam1.png'),
    tourhainam2: require('./tourhainam2.png'),
    tourhainam3: require('./tourhainam3.png'),
    tourhainam4: require('./tourhainam4.png'),
    cuutrodongbao1: require('./cuutrodongbao1.png'),
    cuutrodongbao2: require('./cuutrodongbao2.png'),
    cuutrodongbao3: require('./cuutrodongbao3.png'),
    cuutrodongbao4: require('./cuutrodongbao4.png'),
    lekhaigiangk241: require('./lekhaigiangk241.png'),
    lekhaigiangk242: require('./lekhaigiangk242.png'),
    lekhaigiangk243: require('./lekhaigiangk243.png'),
    lekhaigiangk244: require('./lekhaigiangk244.png'),
    lienhoanynghia1: require('./lienhoanynghia1.png'),
    lienhoanynghia2: require('./lienhoanynghia2.png'),
    lienhoanynghia3: require('./lienhoanynghia3.png'),
    lienhoanynghia4: require('./lienhoanynghia4.png'),
    tuongnhotongbithu1: require('./tuongnhotongbithu1.png'),
    tuongnhotongbithu2: require('./tuongnhotongbithu2.png'),
    tuongnhotongbithu3: require('./tuongnhotongbithu3.png'),
    tuongnhotongbithu4: require('./tuongnhotongbithu4.png'),
    thuongbinh1: require('./thuongbinh1.png'),
    thuongbinh2: require('./thuongbinh2.png'),
    thuongbinh3: require('./thuongbinh3.png'),
    thuongbinh4: require('./thuongbinh4.png'),
    duhocuc1: require('./duhocuc1.png'),
   duhocuc2: require('./duhocuc2.png'),
   duhocuc3: require('./duhocuc3.png'),
   duhocuc4: require('./duhocuc4.png'),
  quydoitackhachhang1: require('./quydoitackhachhang1.png'),
  quydoitackhachhang2: require('./quydoitackhachhang2.png'),
  quydoitackhachhang3: require('./quydoitackhachhang3.png'),
  quydoitackhachhang4: require('./quydoitackhachhang4.png'),
  giaiphong: require('./70giaiphong.png'),
  ngay20101: require('./ngay20101.png'),
  ngay20102: require('./ngay20102.png'),
  ngay20103: require('./ngay20103.png'),
  ngay20104: require('./ngay20104.png'),
  danang1: require('./danang1.png'),
  danang2: require('./danang2.png'),
  danang3: require('./danang3.png'),
  danang4: require('./danang4.png'),
}

export default TourInternationalImages