const EventImages = {
    "1": require('./1.png'),
    "2": require('./2.png'),
    "3": require('./3.png'),
    "4": require('./4.png'),
    "5": require('./5.png'),
    "6": require('./6.png'),
    "7": require('./7.png'),
    anhbia1: require('./anhbia1.png'),
    anhbia2: require('./anhbia2.png'),
    anhbia3: require('./anhbia3.png'),
    anhbia4: require('./anhbia4.png'),
    anhbia5: require('./anhbia5.png'),
    anhbia6: require('./anhbia6.png'),
    anhbia7: require('./anhbia7.png'),
    anhbai8: require('./anhbai8.png'),
    anhbai9: require('./anhbai9.png'),
    anhbai10: require('./anhbai10.png'),
    anhbai11: require('./anhbai11.png'),
    anhbai12: require('./anhbai12.png'),
    anhbai13: require('./anhbai13.png'),
    anhbai14: require('./anhbai14.png'),
    anhbai15: require('./anhbai15.png'),
    anhbai16: require('./anhbai16.png'),
    anhbai17: require('./anhbai17.png'),
    anhbai18: require('./anhbai18.png'),
    anhbai19: require('./anhbai19.png'),
    anhbai20: require('./anhbai20.png'),
    anhbai21: require('./anhbai21.png'),
    anhbai22: require('./anhbai22.png'),
}

export default EventImages