import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import HomePageImages from "src/assets/HomePage";
import {
  tintuc_sukien_bai1,
  tintuc_sukien_bai2,
  tintuc_sukien_bai3,
  tintuc_sukien_bai4,
  tintuc_sukien_bai5,
  tintuc_sukien_bai6,
  tintuc_sukien_bai7,
} from "src/constant/Choore";
import { GetCookies } from "src/constant/Cookies";
import API_STORE from "src/controllers/ApiStore";
import i18n from "src/locales/i18n";
import { current_language } from "src/constant/Cookies";

const Event = ({ aos }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [listNews, setListNews] = useState<any>([]);

  // useEffect(() => {
  //   getNews();
  // }, []);
  // console.log(" i18n.languages", i18n.resolvedLanguage);

  useEffect(() => {
    getCurrentLanguage();
  }, [i18n.resolvedLanguage]);

  const getCurrentLanguage = () => {
    const language: any = GetCookies(current_language);
    getNews(language);
  };

  const getNews = (language: string) => {
    const typeByLanguage = language === "vn" ? 0 : language === "en" ? 1 : 2;
    const params = {
      type: "2",
      language: typeByLanguage,
      pageIndex: "1",
      pageSize: "50",
    };

    API_STORE.getNewsByTypeAndLanguage(params)
      .then((res: any) => {
        console.log("res", res);
        setListNews(res?.Data || []);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div
      data-aos={aos}
      style={{
        background: "#fef8e3",
      }}
    >
      <div className="container-xxl">
        <div
          className="d-flex justify-content-center flex-column"
          style={{
            width: "max-content",
          }}
        >
          <div className="title-body-2">Tin tức & Sự kiện</div>
          <div
            style={{
              height: 3,
              backgroundColor: "#00b09b",
            }}
          />
        </div>
        <div className="row mt-4">
          <div className="col-md-12 col-lg-6">
            <div
              className="event-image-1"
              style={{
                backgroundImage: `url(${listNews[0]?.Avatar})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <div className="p-3 d-flex flex-column justify-content-end w-100 h-100">
                <div
                  style={{
                    backgroundColor: `rgba(0,0,0,0.3)`,
                    borderRadius: 8,
                    padding: 8,
                  }}
                >
                  <div className="txt-title-event">{listNews[0]?.Title}</div>
                  <button
                      onClick={() => {
                        navigate("/chi-tiet-hoat-dong-tieu-bieu/duhoc", {
                          state: { NewID: listNews[0]?.NewsID },
                        });
                      }}
                    className="btn-see-more-event"
                  >
                    <img
                      src={HomePageImages.arrow_right}
                      width={18}
                      height={12}
                    />
                    <div className="fw-bolder">Đọc tiếp</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-lg-6">
            <div className="d-block d-md-none mt-2" />
            <div
              className="event-image-2"
              style={{
                backgroundImage: `url(${listNews[1]?.Avatar})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <div className="p-3 d-flex flex-column justify-content-end w-100 h-100 ">
                <div
                  style={{
                    backgroundColor: `rgba(0,0,0,0.3)`,
                    borderRadius: 8,
                    padding: 8,
                  }}
                >
                  <div className="txt-title-event">{listNews[1]?.Title}</div>
                  <button
                    onClick={() => {
                      navigate("/chi-tiet-hoat-dong-tieu-bieu/duhoc", {
                        state: { NewID: listNews[1]?.NewsID },
                      });
                    }}
                    className="btn-see-more-event"
                  >
                    <img
                      src={HomePageImages.arrow_right}
                      width={18}
                      height={12}
                    />{" "}
                    <div className="fw-bolder">Đọc tiếp</div>
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-2">
              <div
                className="event-image-3"
                style={{
                  backgroundImage: `url(${listNews[2]?.Avatar})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  height: 280,
                }}
              >
                <div className="p-3 d-flex flex-column justify-content-end w-100 h-100 ">
                  <div
                    style={{
                      backgroundColor: `rgba(0,0,0,0.3)`,
                      borderRadius: 8,
                      padding: 8,
                    }}
                  >
                    <div className="txt-title-event">{listNews[2]?.Title}</div>
                    <button
                     onClick={() => {
                      navigate("/chi-tiet-hoat-dong-tieu-bieu/duhoc", {
                        state: { NewID: listNews[2]?.NewsID },
                      });
                    }}
                      className="btn-see-more-event"
                    >
                      <img
                        src={HomePageImages.arrow_right}
                        width={18}
                        height={12}
                      />
                      <div className="fw-bolder">Đọc tiếp</div>
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="event-image-4"
                style={{
                  backgroundImage: `url(${listNews[3]?.Avatar})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  height: 280,
                }}
              >
                <div className="p-3 d-flex flex-column justify-content-end w-100 h-100 ">
                  <div
                    style={{
                      backgroundColor: `rgba(0,0,0,0.3)`,
                      borderRadius: 8,
                      padding: 8,
                    }}
                  >
                    <div className="txt-title-event">{listNews[3]?.Title}</div>
                    <button
                      onClick={() => {
                        navigate("/chi-tiet-hoat-dong-tieu-bieu/duhoc", {
                          state: { NewID: listNews[3]?.NewsID },
                        });
                      }}
                      className="btn-see-more-event"
                    >
                      <img
                        src={HomePageImages.arrow_right}
                        width={18}
                        height={12}
                      />
                      <div className="fw-bolder">Đọc tiếp</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row col-lg-12 mt-2">
          <div className="col-lg-3">
            <div
              className="event-image-5"
              style={{
                backgroundImage: `url(${listNews[4]?.Avatar})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                height: 280,
                borderRadius: 8,
              }}
            >
              <div className="p-3 d-flex flex-column justify-content-end w-100 h-100 ">
                <div
                  style={{
                    backgroundColor: `rgba(0,0,0,0.3)`,
                    borderRadius: 8,
                    padding: 8,
                  }}
                >
                  <div className="txt-title-event">{listNews[4]?.Title}</div>
                  <button
                    onClick={() => {
                      navigate("/chi-tiet-hoat-dong-tieu-bieu/duhoc", {
                        state: { NewID: listNews[4]?.NewsID },
                      });
                    }}
                    className="btn-see-more-event"
                  >
                    <img
                      src={HomePageImages.arrow_right}
                      width={18}
                      height={12}
                    />{" "}
                    <div className="fw-bolder">Đọc tiếp</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 event-image-6">
            <div
              className="event-image-6"
              style={{
                backgroundImage: `url(${listNews[5]?.Avatar})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                height: 280,
                borderRadius: 8,
              }}
            >
              <div className="p-3 d-flex flex-column justify-content-end w-100 h-100 ">
                <div
                  style={{
                    backgroundColor: `rgba(0,0,0,0.3)`,
                    borderRadius: 8,
                    padding: 8,
                  }}
                >
                  <div className="txt-title-event">{listNews[5]?.Title}</div>
                  <button
                    onClick={() => {
                      navigate("/chi-tiet-hoat-dong-tieu-bieu/duhoc", {
                        state: { NewID: listNews[5]?.NewsID },
                      });
                    }}
                    className="btn-see-more-event"
                  >
                    <img
                      src={HomePageImages.arrow_right}
                      width={18}
                      height={12}
                    />{" "}
                    <div className="fw-bolder">Đọc tiếp</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 event-image-7">
            <div
              className="event-image-7"
              style={{
                backgroundImage: `url(${listNews[6]?.Avatar})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                height: 280,
                borderRadius: 8,
              }}
            >
              <div className="p-3 d-flex flex-column justify-content-end w-100 h-100 ">
                <div
                  style={{
                    backgroundColor: `rgba(0,0,0,0.3)`,
                    borderRadius: 8,
                    padding: 8,
                  }}
                >
                  <div className="txt-title-event">{listNews[6]?.Title}</div>
                  <button
                    onClick={() => {
                      navigate("/chi-tiet-hoat-dong-tieu-bieu/duhoc", {
                        state: { NewID: listNews[6]?.NewsID },
                      });
                    }}
                    className="btn-see-more-event"
                  >
                    <img
                      src={HomePageImages.arrow_right}
                      width={18}
                      height={12}
                    />
                    <div className="fw-bolder">Đọc tiếp</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-center mt-4">
          <button
            onClick={() => navigate("/news")}
            className="btn-see-all-tour-inland mb-5"
          >
            {t("See all")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Event;
