import { DownOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  Study_DaiLoan,
  Study_Duc,
  Study_Han,
  Study_Nhat,
  Study_TrungQuoc,
  Study_UC,
} from "src/constant/Choore";

const DropdownDuHoc = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          onClick={() =>
            navigate("/chi-tiet-du-hoc", {
              state: { content: Study_Nhat },
            })
          }
          className="btn border border-0 p-0"
        >
          {t("Study abroad in Japan")}
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() =>
            navigate("/chi-tiet-du-hoc", {
              state: { content: Study_Han },
            })
          }
          className="btn border border-0 p-0"
        >
         {t("Study abroad in Korea")}
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          onClick={() =>
            navigate("/chi-tiet-du-hoc", {
              state: { content: Study_Duc },
            })
          }
          className="btn border border-0 p-0"
        >
          {t("Study abroad in Germany")}
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div
          onClick={() =>
            navigate("/chi-tiet-du-hoc", {
              state: { content: Study_UC },
            })
          }
          className="btn border border-0 p-0"
        >
         {t("Study abroad in Australia")}
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <div
          onClick={() =>
            navigate("/chi-tiet-du-hoc", {
              state: { content: Study_TrungQuoc },
            })
          }
          className="btn border border-0 p-0"
        >
       {t("Study abroad in China")}
        </div>
      ),
    },
    {
      key: "6",
      label: (
        <div
          onClick={() =>
            navigate("/chi-tiet-du-hoc", {
              state: { content: Study_DaiLoan },
            })
          }
          className="btn border border-0 p-0"
        >
         {t("Study abroad in Taiwan")}
        </div>
      ),
    },
    {
      key: "7",
      label: (
        <div
          className="btn border border-0 p-0"
          onClick={() => {
            navigate("/du-hoc")
            window.scrollTo(0,2400)
          }}
        >
          {t("STUDY ABROAD GUIDE")}
        </div>
      ),
    },
    {
      key: "8",
      label: (
        <div
          onClick={() => navigate("/cac-hoat-dong-tieu-bieu/duhoc", {})}
          className="btn border border-0 p-0"
        >
        {t("TYPICAL ACTIVITIES")}
        </div>
      ),
    },
  ];

  return (
    <Dropdown menu={{ items }} placement="bottomRight">
      <button className="btn-right fw-medium gap-1 d-flex align-items-center">
       {t("STUDY ABROAD")} 
        <DownOutlined style={{ fontSize: 10 }} />
      </button>
    </Dropdown>
  );
};

export default DropdownDuHoc;
