import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import YouTube from "react-youtube";
import { StudyPrograms } from "src/constant/Array";
import { current_language, GetCookies } from "src/constant/Cookies";
import API_STORE from "src/controllers/ApiStore";
import i18n from "src/locales/i18n";

const Programs = (props: any) => {
  const { aos } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [listTour, setListTour] = useState<any>([]);

  const opts = {
    height: "720",
    width: "1300",
  };

  useEffect(() => {
    getCurrentLanguage();
  }, [i18n.resolvedLanguage]);

  const getCurrentLanguage = () => {
    const language: any = GetCookies(current_language);
    getNews(language);
  };

  const getNews = (language: string) => {
    const typeByLanguage = language === "vn" ? 0 : language === "en" ? 1 : 2;
    const params = {
      type: "5",
      language: typeByLanguage,
      pageIndex: "1",
      pageSize: "12",
    };

    API_STORE.getNewsByTypeAndLanguage(params)
      .then((res: any) => {
        console.log("res", res);
        setListTour(res?.Data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div
      style={{
        background: "#fef8e3",
      }}
    >
      <div data-aos={aos} className="container-xxl">
        <div className="d-none d-md-block">
          <div className="container-video-advise">
            <YouTube opts={opts} videoId="JAfmZ7KLd_c" />
          </div>
        </div>
        <div className="d-block d-md-none">
          <div className="container-video-advise">
            <YouTube
              opts={{
                width: 400,
                height: 230,
              }}
              videoId="JAfmZ7KLd_c"
            />
          </div>
        </div>
        <div
          className="d-flex justify-content-center flex-column"
          style={{
            width: "max-content",
          }}
        >
          <div className="title-body-study">{t("STUDY ABROAD PROGRAMS")}</div>
          <div
            style={{
              height: 3,
              backgroundColor: "#00b09b",
            }}
          />
        </div>

        <div className="row mt-5">
          {listTour.map((item: any) => {
            return (
              <div key={item.NewsID} className="col-6 col-md-6 col-lg-3 mb-4">
                <div className="btn-item-study">
                  <div className="w-100">
                    <img
                      src={item.Avatar}
                      style={{
                        width: "100%",
                        height: 200,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                      }}
                    />
                    <div
                      className="fw-semibold py-2 px-3"
                      style={{
                        color: "#007261",
                      }}
                    >
                      {item.Title}
                    </div>
                  </div>
                  <button
                    onClick={() =>
                      navigate("/chi-tiet-du-hoc", {
                        state: { NewID: item.NewsID },
                      })
                    }
                    className="btn-see-more"
                  >
                    Đọc tiếp
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className="d-flex align-items-center justify-content-center">
        <button className="btn-see-all-tour-inland mb-4">Xem tất cả</button>
      </div> */}
    </div>
  );
};

export default Programs;
