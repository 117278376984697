import { ArrowRightOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import HeadTab from "src/components/HeadTab/HeadTab";
import { News_List, News_List_Du_Lich } from "src/constant/Array";
import API_STORE from "src/controllers/ApiStore";

const CacHoatDongTieuBieuDuLich = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getNews();
  }, []);

  const getNews = () => {
    const params = {
      type: "11",
      language: "0",
      pageIndex: "1",
      pageSize: "8",
    };

    API_STORE.getNewsByTypeAndLanguage(params)
      .then((res: any) => {
        console.log("res", res);
        setData(res?.Data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div>
      <HeadTab />
      <div className="container-xxl py-5">
        <div
          className="d-flex justify-content-center flex-column"
          style={{
            width: "max-content",
          }}
        >
          <div className="fw-bolder fs-2 text-success">
            {t("TYPICAL ACTIVITIES")}
          </div>
          <div
            style={{
              height: 3,
              backgroundColor: "#589a83",
            }}
          />
        </div>
        <div className="row col-md-12">
          {News_List_Du_Lich.map((i: any) => (
            <div className="col-md-4 mt-3" key={i.NewsID}>
              <button
                onClick={() => {
                  navigate("/chi-tiet-hoat-dong-tieu-bieu/dulich", {
                    state: { NewID: i.NewsID },
                  });
                }}
                className="btn border border-0 p-0 d-flex flex-column text-start w-100"
              >
                <img
                  src={i.Avatar}
                  style={{ width: "100%", height: 250, borderRadius: 8 }}
                />
                <div className="text-success py-2 fw-semibold">{i.Title}</div>
                <div
                  onClick={() => navigate("/chi-tiet-hoat-dong-tieu-bieu")}
                  className="d-flex align-items-center gap-2"
                >
                  <ArrowRightOutlined className="text-success" />
                  <div className="fw-bolder text-success">Đọc tiếp</div>
                </div>
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CacHoatDongTieuBieuDuLich;
