import React, { useState } from "react";
import HeaderImages from "../../assets/Header";
import "./footer.css";
import { Input } from "antd";
import Footer_Button from "../../constant/Array";
import {
  CaretUpFilled,
  ChromeOutlined,
  MailOutlined,
  PhoneOutlined,
  UpOutlined,
} from "@ant-design/icons";
import FooterImages from "src/assets/Footer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import API_STORE from "src/controllers/ApiStore";
import { toast } from "react-toastify";

const Footer = () => {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [note, setNote] = useState("");
  const { t } = useTranslation();
  const handleUpload = () => {};

  const handleNavigate = (i: number) => {
    switch (i) {
      case 7:
        navigate("/du-lich", { state: { id: 7 } });
        break;
      case 8:
        navigate("/du-lich", { state: { id: 8 } });
        break;
      case 9:
        navigate("/du-lich", { state: { id: 9 } });
        break;

      default:
        break;
    }
  };

  const onSubmit = () => {
    const params = {
      FullName: fullName,
      Email: email,
      Phone: phone,
      Note: note,
    };

    API_STORE.AddOrder(params)
      .then(() => {
        toast.success("Đăng ký tư vấn thành công !");
      })
      .catch(() => {
        toast.error("Có lỗi xảy ra,vui lòng thử lại sau !");
      });
  };

  return (
    <div className="">
      {/* <div
        className="row col-md-12 mb-5"
        style={{
          background: "#fdf7e1",
        }}
      >
        <img src={FooterImages.dang_ky_tu_van} className="col-md-6" alt="" />
        <div className="col-md-6 pt-4 px-4">
          <h3 className="text-success fw-bolder">Đăng ký tư vấn</h3>
          <div>
            <div className="fw-semibold pb-2">Họ và tên:</div>
            <Input
              value={fullName}
              onChange={(text) => setFullName(text.target.value)}
              placeholder="..."
              className="input-form-register"
            />
          </div>
          <div className="pt-2">
            <div className="fw-semibold pb-2">Email:</div>
            <Input
              value={email}
              onChange={(text) => setEmail(text.target.value)}
              placeholder="..."
              className="input-form-register"
            />
          </div>
          <div className="pt-2">
            <div className="fw-semibold pb-2">Số điện thoại:</div>
            <Input
              value={phone}
              onChange={(text) => setPhone(text.target.value)}
              placeholder="..."
              className="input-form-register"
            />
          </div>
          <div className="pt-2">
            <div className="fw-semibold pb-2">Dịch vụ cần tư vấn:</div>
            <Input
              placeholder="..."
              className="input-form-register"
              value={note}
              onChange={(text) => setNote(text.target.value)}
            />
          </div>
          <button
            onClick={onSubmit}
            className="btn border border-0 p-0 mb-5 mt-3 text-white bg-success rounded-5 px-5 py-2 fw-semibold"
          >
            Đăng ký
          </button>
        </div>
      </div> */}
      <div className="container-xxl">
        <div className="row col-md-12">
          <div className="col-md-4">
            <img
              src={require("src/assets/Logo/logo_app.jpg")}
              height={45}
              className="img-logo-footer"
            />
          </div>
          <div className="col-md-8">
            <div className="d-block d-sm-none mt-5" />
           

            <div>
              <div className="d-flex align-items-center justify-content-center gap-3 pt-4">
                <button
                  className="btn-fb-link"
                  onClick={() =>
                    window
                      .open(
                        `https://www.facebook.com/DabosaGroup.Official`,
                        "_blank"
                      )
                      ?.focus()
                  }
                >
                  <img
                    src={FooterImages.facebook}
                    width={24}
                    height={24}
                    alt=""
                  />
                  <div className="fw-semibold" style={{ fontSize: 14 }}>
                    DABOSA GROUP
                  </div>
                </button>
                <button
                  className="btn-fb-link"
                  onClick={() =>
                    window
                      .open(`https://www.facebook.com/DabosaTravel/`, "_blank")
                      ?.focus()
                  }
                >
                  <img
                    src={FooterImages.facebook}
                    width={24}
                    height={24}
                    alt=""
                  />
                  <div className="fw-semibold" style={{ fontSize: 14 }}>
                    DABOSA TRAVEL
                  </div>
                </button>
                <div className="d-none d-md-block">
                  <button
                    className="btn-fb-link"
                    onClick={() =>
                      window
                        .open(`https://www.youtube.com/@GROUPDABOSA`, "_blank")
                        ?.focus()
                    }
                  >
                    <img
                      src={FooterImages.youtube}
                      width={24}
                      height={24}
                      alt=""
                    />
                    <div className="fw-semibold" style={{ fontSize: 14 }}>
                      DABOSA GROUP
                    </div>
                  </button>
                </div>
                <div className="d-none d-md-block">
                  <button
                    className="btn-fb-link"
                    onClick={() =>
                      window
                        .open(`https://www.tiktok.com/@dabosa.group`, "_blank")
                        ?.focus()
                    }
                  >
                    <img
                      src={FooterImages.tiktok}
                      onClick={() =>
                        window
                          .open(
                            `https://www.tiktok.com/@dabosa.group`,
                            "_blank"
                          )
                          ?.focus()
                      }
                      className="btn border border-0 p-0"
                      width={24}
                      height={24}
                      alt=""
                    />
                    <div className="fw-semibold" style={{ fontSize: 14 }}>
                      DABOSA GROUP
                    </div>
                  </button>
                </div>
              </div>
              <div className="d-block d-md-none">
                <div className="d-flex justify-content-center align-items-center gap-3 pt-3">
                  <button
                    className="btn-fb-link"
                    onClick={() =>
                      window
                        .open(`https://www.youtube.com/@GROUPDABOSA`, "_blank")
                        ?.focus()
                    }
                  >
                    <img
                      src={FooterImages.youtube}
                      width={24}
                      height={24}
                      alt=""
                    />
                    <div className="fw-semibold" style={{ fontSize: 14 }}>
                      DABOSA GROUP
                    </div>
                  </button>
                  <button
                    className="btn-fb-link"
                    onClick={() =>
                      window
                        .open(`https://www.tiktok.com/@dabosa.group`, "_blank")
                        ?.focus()
                    }
                  >
                    <img
                      src={FooterImages.tiktok}
                      onClick={() =>
                        window
                          .open(
                            `https://www.tiktok.com/@dabosa.group`,
                            "_blank"
                          )
                          ?.focus()
                      }
                      className="btn border border-0 p-0"
                      width={24}
                      height={24}
                      alt=""
                    />
                    <div className="fw-semibold" style={{ fontSize: 14 }}>
                      DABOSA GROUP
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row col-lg-12">
          <div className="col col-lg-4">
            <div className="txt-title-footer">DABOSA GROUP</div>
            <div className="txt-title-down">
              CÔNG TY CỔ PHẦN TẬP ĐOÀN DABOSA
            </div>
            <div className="txt-content-footer">Slogan: We are specialists</div>
            <div className="txt-content-footer">
              Mã số doanh nghiệp: 0110668093
            </div>
            <div className="txt-content-footer">
              Trụ sở: Biệt thự số 2 - OBT2 Khu đô thị <br /> Bắc Linh Đàm, Đại
              Kim, Hoàng Mai, Hà Nội
            </div>
          </div>
          {/* <div className="col-lg-4">
            <div className="txt-title-footer">{t("Quick access:")}</div>
            <div className="d-flex gap-5">
              <div className="d-flex flex-column gap-1 mt-2">
                {Footer_Button.slice(0, 8).map((i) => {
                  return (
                    <button
                      onClick={() => {
                        if (i.id === 7 || i.id === 8 || i.id === 9) {
                          handleNavigate(i.id);
                        } else {
                          navigate("/chi-tiet-du-hoc", {
                            state: { content: i.content },
                          });
                        }
                      }}
                      key={i.id}
                      className="btn-content-footer"
                    >
                      {i.text}
                    </button>
                  );
                })}
              </div>
              <div className="d-flex flex-column gap-1 mt-2">
                {Footer_Button.slice(8, 11).map((i) => {
                  return (
                    <button
                      onClick={() => {
                        if (i.id === 7 || i.id === 8 || i.id === 9) {
                          handleNavigate(i.id);
                        } else {
                          navigate("/chi-tiet-du-hoc", {
                            state: { content: i.content },
                          });
                        }
                      }}
                      key={i.id}
                      className="btn-content-footer"
                    >
                      {i.text}
                    </button>
                  );
                })}
              </div>
            </div>
          </div> */}
          <div className="col-lg-3">
            <div className="container-contact-footer">
              <div className="txt-title-footer">
                {t("Contact information:")}
              </div>
              <div className="d-flex align-items-center gap-2 mt-2">
                <PhoneOutlined style={{ fontSize: 20, color: "#027662" }} />
                <div>
                  <div className="txt-hotline">
                    Hotline Du Học: 096 163 1818
                  </div>
                  <div className="txt-hotline">
                    Hotline Du Lịch: 096 135 1818
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center gap-2 mt-1">
                <MailOutlined style={{ fontSize: 20, color: "#027662" }} />
                <div className="txt-email">Email: dabosagroup@gmail.com</div>
              </div>
              <div className="d-flex align-items-center gap-2 mt-1">
                <ChromeOutlined style={{ fontSize: 20, color: "#027662" }} />
                <div className="txt-email">Website: dabosagroup.com</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          background: "linear-gradient(210deg,#007261, #21c586)",
          marginTop: 24,
        }}
      >
        <div className="container-xxl d-flex justify-content-between align-items-center py-4">
          <div className="text-white fw-medium">
            DABOSAGROUP@All rights reserved.
          </div>
          <button
            className="border border-0 p-0 btn text-white fw-medium"
            onClick={() => window.scrollTo(0, 0)}
          >
            Trở về đầu trang
            <CaretUpFilled style={{ marginLeft: 8 }} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Footer;
