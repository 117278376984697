import React, { useEffect, useState } from "react";
import { TourInland } from "src/constant/Array";
import "../home.css";
import TourInlandImages from "src/assets/Home/TourInland";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import API_STORE from "src/controllers/ApiStore";
import { current_language, GetCookies } from "src/constant/Cookies";
import i18n from "src/locales/i18n";

const Tour_Inland = ({ aos, ref }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [listTour, setListTour] = useState<any>([]);

  useEffect(() => {
    getCurrentLanguage();
  }, [i18n.resolvedLanguage]);

  const getCurrentLanguage = () => {
    const language: any = GetCookies(current_language);
    getNews(language);
  };

  const getNews = (language: string) => {
    const typeByLanguage = language === "vn" ? 0 : language === "en" ? 1 : 2;
    const params = {
      type: "8",
      language: typeByLanguage,
      pageIndex: "1",
      pageSize: "12",
    };

    API_STORE.getNewsByTypeAndLanguage(params)
      .then((res: any) => {
        console.log("res", res);
        setListTour(res?.Data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div data-aos={aos} ref={ref} className="container-xxl">
      <div className="fs-3  title-item-tour-inland1" >{t("TOUR PROGRAM")}
            </div>
      <div
        className="d-flex justify-content-center flex-column"
        style={{
          width: "max-content",
        }}
      >
        {/* <div className="title-body-2">{t("domestic tour")}</div> */}
        <div
          style={{
            height: 3,
            backgroundColor: "#00b09b",
          }}
        />
      </div>
      <div className="d-sm-block d-none">
        <div className="row mt-4">
          {listTour.map((item: any) => {
            return (
              <div key={item.NewsID} className="col-6 col-md-6 col-lg-3 mb-4">
                <button
                  onClick={() => {
                    navigate("/chi-tiet-du-lich", {
                      state: { NewID: item?.NewsID },
                    });
                  }}
                  className="item-tour-inland"
                >
                  <div className="d-flex align-items-center flex-column">
                    <img src={item?.Avatar} className="img-item-tour-inland" />
                    <div className="title-item-tour-inland">{item?.Title}</div>
                    {item.Description ? (
                      <div className="title-item-tour-red">
                        {item.Description}
                      </div>
                    ) : (
                      <div />
                    )}

                    <button className="btn-item-tour-inland">
                      Xem chi tiết
                    </button>
                  </div>
                </button>
              </div>
            );
          })}
        </div>
      </div>
      <div className="d-sm-none d-block">
        <div className="row mt-4">
          {listTour.map((item: any) => {
            return (
              <div key={item.NewsID} className="col-6 col-md-6 col-lg-3 mb-4">
                <button
                  onClick={() => {
                    navigate("/chi-tiet-du-lich", {
                      state: { NewID: item.NewsID },
                    });
                  }}
                  className="item-tour-inland-mobile"
                >
                  <div>
                    <img src={item.Avatar} className="img-item-tour-inland" />
                    <div className="title-item-tour-inland">{item.Title}</div>
                    {item.Description ? (
                      <div className="title-item-tour-red">
                        {item.Description}
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                  <button className="btn-item-tour-inland">Xem chi tiết</button>
                </button>
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className="d-flex align-items-center justify-content-center my-4">
        <button className="btn-see-all-tour-inland">{t("See all")}</button>
      </div> */}
    </div>
  );
};

export default Tour_Inland;
