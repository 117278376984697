import { Input } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import FooterImages from "src/assets/Footer";
import API_STORE from "src/controllers/ApiStore";

const Register = ({ aos }: any) => {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [note, setNote] = useState("");
  const { t } = useTranslation();
  const handleUpload = () => {};

  const onSubmit = () => {
    const params = {
      FullName: fullName,
      Email: email,
      Phone: phone,
      Note: note,
    };

    API_STORE.AddOrder(params)
      .then(() => {
        toast.success("Đăng ký tư vấn thành công !");
      })
      .catch(() => {
        toast.error("Có lỗi xảy ra,vui lòng thử lại sau !");
      });
  };

  return (
    <div
      data-aos={aos}
      className="row col-md-12"
      style={{
        background: "#fdf7e1",
      }}
    >
      <img src={FooterImages.dang_ky_tu_van} className="col-md-6" alt="" />
      <div className="col-md-6 pt-4 px-4">
        <h3 className="text-success fw-bolder">{t("Sign up for consultation")}</h3>
        <div>
          <div className="fw-semibold pb-2">{t("Full name:")}</div>
          <Input
            value={fullName}
            onChange={(text) => setFullName(text.target.value)}
            placeholder="..."
            className="input-form-register"
          />
        </div>
        <div className="pt-2">
          <div className="fw-semibold pb-2">Email:</div>
          <Input
            value={email}
            onChange={(text) => setEmail(text.target.value)}
            placeholder="..."
            className="input-form-register"
          />
        </div>
        <div className="pt-2">
          <div className="fw-semibold pb-2">{t("Phone number:")}</div>
          <Input
            value={phone}
            onChange={(text) => setPhone(text.target.value)}
            placeholder="..."
            className="input-form-register"
          />
        </div>
        <div className="pt-2">
          <div className="fw-semibold pb-2">{t("Services requiring consultation:")}</div>
          <Input
            placeholder="..."
            className="input-form-register"
            value={note}
            onChange={(text) => setNote(text.target.value)}
          />
        </div>
        <button
          onClick={onSubmit}
          className="btn border border-0 p-0 mb-5 mt-3 text-white bg-success rounded-5 px-5 py-2 fw-semibold"
        >
        {t("Register")}
        </button>
      </div>
    </div>
  );
};

export default Register;
