import React from "react";
import { useTranslation } from "react-i18next";
import HomePageImages from "src/assets/HomePage";
import bg_reason from "src/assets/HomePage/bg_reason.png";
import { ReasonRight } from "src/constant/Array";

const Reason = ({ aos }: any) => {
  const { t } = useTranslation();
  return (
    <div
      data-aos={aos}
      className="py-5"
      style={{
        backgroundImage: `url(${bg_reason})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        width: "100%",
      }}
    >
      <div className="container-xxl">
        <div className="d-none d-md-block">
          <div className="d-flex align-items-center justify-content-center gap-2 pb-4">
            <img src={HomePageImages.ic_star} width={24} height={24} alt="" />
            <div className="d-flex align-items-center gap-2 txt-title-6-reason">
            {t("6 Reasons why customers trust and choose")}
              <div className="fw-bolder">DABOSA GROUP</div>
            </div>
          </div>
        </div>
        <div className="d-block d-md-none">
          <div className="fw-bolder txt-title-6-reason pb-4">
            {t("6 Reasons why customers trust and choose Dabosa Group")}
          </div>
        </div>
        <div className="row col-lg-12">
          <div className="col-lg-5">
            <div>
              <div className="reason-left">
                <img
                  src={HomePageImages.img_6_reason}
                  className="img-6-reason"
                  alt=""
                />
               {t("With a clear strategic vision, the Board of Directors and a team of experienced experts at Dabosa Group are committed to providing comprehensive solutions, meeting all customer requirements!")}
              </div>
            </div>
          </div>
          <div className="mt-3 d-block d-md-none" />
          <div className="col-lg-7">
            <div className="row col-lg-12">
              {ReasonRight.map((item) => (
                <div key={item.id} className="col col-sm-6 col-md-3 col-lg-4">
                  <div className="reason-right">
                    <img src={item.image} className="reason-image" alt="" />
                    <div className="text-center pt-3">{item.title}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reason;
