import React, { useEffect } from "react";
import Advise from "./Components/Advise";
import AOS from "aos";
import "aos/dist/aos.css";
import Reason from "./Components/Reason";
import Event from "./Components/Event";
import Reviews from "./Components/Reviews";
import Register from "./Components/Register";

const HomePage = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div>
      <Advise aos="fade-up" />
      <Register aos="fade-up" />
      <Reason aos="fade-up" />
      <Event aos="fade-up" />
      <Reviews aos="fade-up" />
    </div>
  );
};

export default HomePage;
