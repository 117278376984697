import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Head.css";
import { useTranslation } from "react-i18next";

const HeadTab = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div>
      <div className="d-flex align-items-center  ">
        <button onClick={() => navigate("/du-hoc")} className="travel">
          <div className="fs-5 fw-bolder">{t("STUDY ABROAD")}</div>
        </button>
        <button
          className="study-abroad"
          style={{
            height: 42,
          }}
          onClick={() => navigate("/du-lich")}
        >
          <div className="fs-5 fw-bolder text-white">{t("TOURISM")}</div>
        </button>
      </div>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          background: "#fef8e3",
          height: 64,
        }}
      >
        <div className="w-50 d-flex align-items-center justify-content-center gap-4">
          <Input
            className="container-input"
            size="large"
            placeholder="Nhập từ khoá tìm kiếm..."
            suffix={
              <SearchOutlined
                className="btn border border-0 p-0"
                style={{ color: "#00b09b" }}
              />
            }
          />
          <div className="btn border border-0 p-0 fw-semibold d-none d-md-block">
            <div className="btn-search">
              <div>{t("search")}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeadTab;
