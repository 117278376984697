import HandBookImages from "src/assets/Home/Handbook"
import ProgramImages from "src/assets/Home/Progams"
import TourInlandImages from "src/assets/Home/TourInland"
import TourInternationalImages from "src/assets/Home/TourInternational"
import TravelGuideImages from "src/assets/Home/TravelGuide"
import HomePageImages from "src/assets/HomePage"
import ReviewImages from "src/assets/HomePage/Reviews"
import NewImages from "src/assets/News"
import RecImages from "src/assets/Rec"
import { cam_nang_hanquoc, cam_nang_nghe_duc, cam_nang_nhat, cam_nang_uc, Study_DaiLoan, Study_Duc, Study_Han, Study_Nhat, Study_TrungQuoc, Study_UC, tour_bangkok, tour_danang, tour_hagiang, tour_phuonghoangcotran , tour_han, tour_hoangsuphi, tour_hoian, tour_hue, tour_mucangchai, tour_nhat, tour_nhatrang, tour_ninhbinh, tour_phanthiet, tour_quangbinh, tour_quangdongquangtay, tour_sapa, tour_taxua, tour_singapore, tour_thailan, tour_trungquoc, tour_thanhdo, cam_nang_phuquoc, ky_thuat_tai_uc, top5_hanquoc, camnang_duhoc_nendiduhocnhatbanhayhanquoc, camnang_dulich_top10baibiendep, camnang_dulich_khamphamucangchai,camnang_dulich_thienduongdulichdaongoc, camnang_dulich_5monanhoian, camnang_dulich_top5diemcheckinchaymaytaidanang, camnang_dulich_nenmanggikhididulichthailan5ngay4dem, camnang_dulich_lamhochieuonline, camnang_dulich_bikipdulichhainam, tintuc_sukien_bai1, tintuc_sukien_bai2, tintuc_sukien_bai3, tintuc_sukien_bai4, tintuc_sukien_bai5, tintuc_sukien_bai6, tintuc_sukien_bai7, tintuc_sukien_bai8, tintuc_sukien_bai9, tintuc_sukien_bai10, tintuc_sukien_bai11, tintuc_sukien_bai12, tintuc_sukien_bai13, tintuc_sukien_bai14, tintuc_sukien_bai15, tintuc_sukien_bai16, tintuc_sukien_bai17, tintuc_sukien_bai18, tintuc_sukien_bai19, tintuc_sukien_bai20, tintuc_sukien_bai21, tintuc_sukien_bai22, tuyendung_content, tuyendung_kinhdoanh, tuyendung_dieuhanhtour, tuyendung_saletour,} from "./Choore"
import EventImages from "src/assets/HomePage/Event"

const Footer_Button = [
    {
        id: 0,
        text: 'Du học Nhật Bản',
        content: Study_Nhat
        },
    {
        id: 1,
        text: 'Du học Hàn Quốc',
        content: Study_Han
    },
    {
        id: 2,
        text: 'Du học Đức',
        content: Study_Duc
    },
    {
        id: 3,
        text: 'Du học Úc',
        content: Study_UC
    },
    {
        id: 4,
        text: 'Du học Trung Quốc',
        content: Study_TrungQuoc
    },
    {
        id: 5,
        text: 'Du học Đài Loan',
        content: Study_DaiLoan
    },
    {
        id: 10,
        text: 'Du học New Zealand',
        content: ''
    },
    {
        id: 6,
        text: 'Du học Mỹ',
        content: ''
    },
    {
        id: 7,
        text: 'Du lịch nội địa',
        
    },
    {
        id: 8,
        text: 'Du lịch quốc tế',
    },
    {
        id: 9,
        text: 'Cẩm nang du lịch',
    },
]

export const TourInland = [
    {
        id: -1,
        image: TourInlandImages.sapa,
        title: 'Tour Sapa',
        content: tour_sapa,
        money:' Chỉ từ 1,000,000'
    },
    {
        id: 0,
        image: TourInlandImages.suphi,
        title: 'Tour Hoàng Su Phì',
        content: tour_hoangsuphi,
         money:' Chỉ từ 1,000,000 - 2.350,000'
    },
    {
        id: 1,
        image: TourInlandImages.taxua,
        title: 'Săn mây Tà Xùa',
        content: tour_taxua,
         money:' Chỉ từ '
    },
    {
        id: 2,
        image: TourInlandImages.mucangchai,
        title: 'Tour Mù Cang Chải',
        content: tour_mucangchai,
         money:' Chỉ từ '
    },
    {
        id: 3,
        image: TourInlandImages.bia_hagiang,
        title: 'Tour Hà Giang',
        content: tour_hagiang,
         money:' Chỉ từ '
    },
    {
        id: 4,
        image: TourInlandImages.phanthiet,
        title: 'Tour Phan Thiết',
        content: tour_phanthiet,
         money:' Chỉ từ '
    },
    {
        id: 5,
        image: TourInlandImages.ninhbinh,
        title: 'Tour Ninh Bình - Yên Tử',
        content: tour_ninhbinh,
         money:' Chỉ từ '
    },
    {
        id: 6,
        image: TourInlandImages.quangbinh,
        title: 'Tour Quảng Bình',
        content: tour_quangbinh,
         money:' Chỉ từ '
    },
    {
        id: 7,
        image: TourInlandImages.hue,
        title: 'Tour HUẾ',
        content: tour_hue,
         money:' Chỉ từ '
    },
    {
        id: 8,
        image: TourInlandImages.bia_danang,
        title: 'Tour ĐÀ NẴNG',
        content: tour_danang,
         money:' Chỉ từ '
    },
    {
        id: 9,
        image: TourInlandImages.bia_nhatrang,
        title: 'Tour NHA TRANG',
        content: tour_nhatrang,
         money:' Chỉ từ '
    },
    {
        id: 10,
        image: TourInlandImages.bia_hoian,
        title: 'Tour Hội An',
        content: tour_hoian,
         money:' Chỉ từ '
    },
]

export const TourInternational = [
    {
        id: -1,
        image: TourInternationalImages.quangdong,
        title: 'Tour Quảng Đông - Quảng Tây',
        content: tour_quangdongquangtay,
         money:' Chỉ từ '
    },
    {
        id: 0,
        image: TourInternationalImages.legiang,
        title: 'Tour Lệ Giang - Shangri-La',
        content: tour_bangkok,
         money:' Chỉ từ '
    },
    {
        id: 1,
        image: TourInternationalImages.thanhdo,
        title: 'Tour Thành Đô - Trùng Khánh ',
        content: tour_thanhdo,
         money:' Chỉ từ '
    },
   
    {
        id: 2,
        image: TourInternationalImages.cotran,
        title: 'Phượng Hoàng Cổ Trấn',
        content: tour_phuonghoangcotran,
         money:' Chỉ từ '
    },
    {
        id: 3,
        image: TourInternationalImages.hainam,
        title: 'Tour Hải Nam - Hải Khẩu - Tam Á',
        content: tour_trungquoc,
         money:' Chỉ từ '
    },
    {
        id: 4,
        image: TourInternationalImages.bangkok,
        title: 'Tour Thái Lan: Bangkok - Pattaya',
        content: tour_thailan,
         money:' Chỉ từ '
    },
    {
        id: 5,
        image: TourInternationalImages.sing,
        title: 'Singapore',
        content: tour_singapore,
         money:' Chỉ từ '
    },
    {
        id: 6,
        image: TourInternationalImages.seoul,
        title: 'Tour Hàn Quốc',
        content: tour_han,
         money:' Chỉ từ '
    },
    {
        id: 7,
        image: TourInternationalImages.nhat,
        title: 'Nhật Bản',
        content: tour_nhat,
         money:' Chỉ từ '
    },
]

export const TravelGuide = [
    {
        id: 0,
        image: TravelGuideImages[1],
        title: 'Top 10 bãi biển đẹp nhất miền Trung',
        content: camnang_dulich_top10baibiendep
    },
    {
        id: 1,
        image: TravelGuideImages[2],
        title: 'Cẩm nang du lịch Mù Cang Chải từ A-Z',
        content: camnang_dulich_khamphamucangchai
    },
    {
        id: 2,
        image: TravelGuideImages[3],
        title: `Review Du lịch Phú Quốc`,
        content: camnang_dulich_thienduongdulichdaongoc
    },
    {
        id: 3,
        image: TravelGuideImages[4],
        title: '5 món ăn bạn không thể bỏ qua khi đến Hội An',
        content: camnang_dulich_5monanhoian
    },
    {
        id: 4,
        image: TravelGuideImages[5],
        title: 'Top 5 điểm check-on cháy máy tại Đà Nẵng',
        content: camnang_dulich_top5diemcheckinchaymaytaidanang
    },
    {
        id: 5,
        image: TravelGuideImages[6],
        title: 'Bỏ túi bí kíp du lịch Hải Nam',
        content: camnang_dulich_bikipdulichhainam
    },
    {
        id: 6,
        image: TravelGuideImages[7],
        title: 'Hướng dẫn tự làm hộ chiếu online tại nhà',
        content: camnang_dulich_lamhochieuonline
    },
    {
        id: 7,
        image: TravelGuideImages[8],
        title: 'Nên mang gì khi đi du lịch Thái Lan 5 ngày 4 đêm?',
        content: camnang_dulich_nenmanggikhididulichthailan5ngay4dem
    },
]

export const StudyPrograms = [
    {
        id: 0,
        title: 'Du học Nhật Bản',
        image: ProgramImages.nhatban,
        content: Study_Nhat
    },
    {
        id: 1,
        title: 'Du học Hàn Quốc',
        image: ProgramImages.han,
        content: Study_Han
    },
    {
        id: 4,
        title: 'Du học Đức',
        image: ProgramImages.duc,
        content: Study_Duc
    },
    {
        id: 5,
        title: 'Du học Úc',
        image: ProgramImages.uc,
        content: Study_UC
    },
    {
        id: 2,
        title: 'Du học Trung Quốc',
        image: ProgramImages.trungquoc,
        content: Study_TrungQuoc
    },
    {
        id: 3,
        title: 'Du học Đài Loan',
        image: ProgramImages.dailoan,
        content: Study_DaiLoan
    },
    {
        id: 6,
        title: 'Du học New Zealand',
        image: ProgramImages.new
    },
    {
        id: 8,
        title: 'Du học Mỹ',
        image: ProgramImages.my
    },

]

export const Hand_Book = [
    {
        id: 0,
        title: 'Du học Nhật Bản 2024 ngành gì?',
        image: HandBookImages[1],
        content: cam_nang_nhat
    },
    {
        id: 1,
        title: 'Cẩm nang Du học nghề Đức từ A – Z',
        image: HandBookImages[2],
        content: cam_nang_nghe_duc
    },
    {
        id: 2,
        title: 'Những điều thú vị khi sinh sống và học tâp tại Úc',
        image: HandBookImages[3],
        content: cam_nang_uc
    },
    {
        id: 3,
        title: '8 lý do bạn nên du học Hàn Quốc',
        image: HandBookImages[4],
        content: cam_nang_hanquoc
    },
    {
        id: 4,
        title: 'Nên đi du học Nhật Bản hay Hàn Quốc?',
        image: HandBookImages[5],
        content: camnang_duhoc_nendiduhocnhatbanhayhanquoc
    },
    {
        id: 5,
        title: 'Top 5 khu vực nên chọn khi đi du học ở Hàn Quốc',
        image: HandBookImages[6],
        content: top5_hanquoc
    },
    {
        id: 6,
        title: 'Tại sao học kỹ thuật tại Úc là lựa chọn hoàn hảo cho bạn?',
        image: HandBookImages[7],
        content:ky_thuat_tai_uc
    },
]

export const Introduce = [
    {
        id: 0,
        image: HomePageImages.vtv
    },
    {
        id: 1,
        image: HomePageImages.dantri
    },
    {
        id: 2,
        image: HomePageImages.bacninh_online
    },
    {
        id: 3,
        image: HomePageImages.vn
    },
]

export const ReasonRight = [
    {
        id: 0,
        title: 'Đội ngũ chuyên gia lên đến 20 năm kinh nghiệm',
        image: HomePageImages.right_1,
    },
    {
        id: 1,
        title: 'Chi phí hợp lý, nhiều ưu đãi tốt',
        image: HomePageImages.right_2,
    },
    {
        id: 2,
        title: '+ 1000 tour trong nước và quốc tế',
        image: HomePageImages.right_3,
    },
    {
        id: 3,
        title: 'Đào tạo bài bản ngoại ngữ, kỹ năng mềm',
        image: HomePageImages.right_4,
    },
    {
        id: 4,
        title: 'Cơ sở vật chất và ký túc xá tiện nghi, hiện đại',
        image: HomePageImages.right_5,
    },
    {
        id: 5,
        title: 'Đồng hành hỗ trợ xuyên suốt quá trình',
        image: HomePageImages.right_6,
    },
]

export const Review = [
    {
        id: 0,
        avatar: ReviewImages[1],
        full_name: 'Trần Thị Thu',
        comp: 'Công ty V99 Group',
        content: "Tôi xin gửi lời cảm ơn chân thành đến Dabosa Travel vì đã tổ chức một chuyến đi Thái Lan thật sự tuyệt vời. Sự chuyên nghiệp trong việc lên kế hoạch, sự tận tâm trong phục vụ và sự am hiểu sâu sắc về văn hóa địa phương của các bạn đã để lại ấn tượng sâu sắc trong lòng tôi. Nhờ có Dabosa Travel, chuyến đi của tôi trở nên thật sự ý nghĩa và đáng nhớ."
    },
    {
        id: 1,
        avatar: ReviewImages[2],
        full_name: 'Nguyễn Trà My',
        comp: 'Học viên lớp tiếng Đức',
        content: "Ở đây, mình không chỉ học tiếng mà còn được tham gia rất nhiều hoạt động, chương trình bổ ích. Kết thúc khoá học, Dabosa đã giúp mình tự tin hơn rất nhiều khi đi học tại nước ngoài. "
    },
    {
        id: 2,
        avatar: ReviewImages[3],
        full_name: 'Chị Hà',
        comp: 'Công ty TNHH Nhật Tiến',
        content: 'Từ lúc tư vấn đến lúc lên lịch trình tour cụ thể, nhân viên rất nhiệt tình và luôn đồng hành cùng đoàn. Trong quá trình đi tour, Dabosa cũng chuẩn bị rất chu đáo, đầy đủ các vật dụng như cờ, mũ, thuốc y tế… cho đoàn. Tôi rất hài lòng với dịch vụ của công ty Dabosa'
    },
    {
        id: 3,
        avatar: ReviewImages[4],
        full_name: 'Vũ Thị Thuỷ',
        comp: 'Phụ huynh',
        content: "Không chỉ đơn giản là công ty du học, ở đây con mình còn được học và nâng cao rất nhiều kỹ năng mềm như giao tiếp, làm việc nhóm, ứng xử… Mình rất an tâm và cảm thấy vui khi con có sự thay đổi tích cực. "
    }

]

export const News_List = [
    {
        id: 0,
        img: EventImages.anhbia1,
        title: "Hiệu trưởng và thầy cô giáo trường Nhật ngữ Aoyama đến tham quan...",
        content: tintuc_sukien_bai1
     
    },
    {
        id: 1,
        img: EventImages.anhbia2,
        title: " Ký kết hợp tác giáo viên nước Đức và Anh",
        content: tintuc_sukien_bai2
    },
    {
        id: 2,
        img: EventImages.anhbia3,
        title: "Trung Thu cùng các bạn học viên",
        content: tintuc_sukien_bai3
    },
    {
        id: 3,
        img: EventImages.anhbia4,
        title: "Dabosa tiếp nối chương trình...",
        content:tintuc_sukien_bai4
    },
    {
        id: 4,
        img: EventImages.anhbia5,
        title: "Món quà từ Ấn Độ của Chủ tịch ...",
        content: tintuc_sukien_bai5
    },
    {
        id: 5,
        img: EventImages.anhbia6,
        title: "NHỮNG KHOẢNH KHẮC ẤN TƯỢNG TRONG HỘI NGHỊ KHÁCH HÀNG HASUKA - DABOSA",
        content: tintuc_sukien_bai6
    },
    {
        id: 6,
        img: EventImages.anhbia7,
        title: "Những khoảnh khắc ấn tượng trong hội nghị khách hàng Hasuka - Dabosa",
        content: tintuc_sukien_bai7
    },
    {
        id: 7,
        img: EventImages.anhbai8,
        title: 'Khách quốc tế Ấn Độ đến tìm hiểu và thăm quan DABOSA',
        content: tintuc_sukien_bai8
    },
    {
        id: 8,
        img: EventImages.anhbai9,
        title: "Chào đón Quý đối tác, khách hàng tới tham quan và tìm hiểu dịch vụ Du học, Du lịch tại DABOSA Group ",
        content: tintuc_sukien_bai9
    },
    {
        id: 9,
        img: EventImages.anhbai10,
        title: "[DABOSA STORY] - Tư vấn du học Úc ",
        content: tintuc_sukien_bai10
    },
    {
        id: 10,
        img: EventImages.anhbai11,
        title: "DABOSA GROUP TRAO TẶNG QUÀ NHÂN KỶ NIỆM 77 NĂM NGÀY THƯƠNG BINH LIỆT SĨ (27/7/1947 - 27/7/2024)",
        content: tintuc_sukien_bai11
    },
    {
        id: 11,
        img: EventImages.anhbai12,
        title: " DABOSA HÂN HẠNH ĐƯỢC ĐÓN TIẾP ĐOÀN KHÁCH QUỐC TẾ TỚI THĂM VÀ LÀM VIỆC TẠI TRỤ SỞ DABOSA GROUP! ",
        content: tintuc_sukien_bai12
    },
    {
        id: 12,
        img: EventImages.anhbai13,
        title: "Kính cẩn nghiêng mình tri ân và tưởng nhớ Tổng Bí thư Nguyễn Phú Trọng!",
        content: tintuc_sukien_bai13
    },
    {
        id: 13,
        img: EventImages.anhbai14,
        title: "Buổi liên hoan ý nghĩa, tràn ngập niềm vui của các học viên nhà Dabosa",
        content: tintuc_sukien_bai14
    },
    {
        id: 14,
        img: EventImages.anhbai15,
        title: "LỄ KHAI GIẢNG KHÓA HỌC NGOẠI NGỮ K24 TẠI DABOSA",
        content: tintuc_sukien_bai15
    },
    {
        id: 15,
        img: EventImages.anhbai16,
        title: "DABOSA CHUNG TAY CỨU TRỢ ĐỒNG BÀO MIỀN BẮC TRONG LŨ DỮ 2024",
        content: tintuc_sukien_bai16
    },
    {
        id: 16,
        img: EventImages.anhbai17,
        title: "[DABOSA TOUR] HÀNH TRÌNH CHINH PHỤC ĐẢO HẢI NAM (TRUNG QUỐC) CÙNG VÔ VÀN CẢM XÚC",
        content: tintuc_sukien_bai17
    },
    {
        id: 17,
        img: EventImages.anhbai18,
        title: "[DABOSA TOUR] HÀNH TRÌNH KHÁM PHÁ THÁI LAN CÙNG V99 GROUP ",
        content: tintuc_sukien_bai18
    },
    {
        id: 18,
        img: EventImages.anhbai19,
        title: "HÀ NỘI - KỶ NIỆM 70 NĂM GIẢI PHÓNG THỦ ĐÔ VÀ LÒNG TỰ HÀO DÂN TỘC! ",
        content: tintuc_sukien_bai19
    },
    {
        id: 19,
        img: EventImages.anhbai20,
        title: "Chào mừng Ngày Phụ nữ Việt Nam 20/10! ",
        content: tintuc_sukien_bai20
    },
    {
        id: 20,
        img: EventImages.anhbai21,
        title: "DABOSA GROUP & V99 GROUP – Thắt Chặt Hợp Tác Phát Triển Bền Vững",
        content: tintuc_sukien_bai21
    },
    {
        id: 21,
        img: EventImages.anhbai22,
        title: "[DABOSA TOUR] - CHUYẾN ĐI ĐÀ NẴNG ĐÁNG NHỚ BÊN GIA ĐÌNH ",
        content: tintuc_sukien_bai22
    },
]
export const News_List_Du_Hoc = [
    {
        id: 0,
        img:EventImages.anhbia1,
        title: "Hiệu trưởng và thầy cô giáo trường Nhật ngữ Aoyama đến tham quan trụ sở Dabosa Group.",
        content: tintuc_sukien_bai1
     
    },
    {
        id: 1,
        img: EventImages.anhbia2,
        title: "DABOSA KÝ KẾT HỢP TÁC VỚI CHUYÊN GIA CỐ VẤN ĐÀO TẠO NGOẠI NGỮ",
        content: tintuc_sukien_bai2
    },
    {
        id: 2,
        img: EventImages.anhbia4,
        title: "NỐI TIẾP CHƯƠNG TRÌNH TRI ÂN 77 NĂM NGÀY THƯƠNG BINH LIỆT SĨ (27/7/1947 - 27/7/2024)",
        content: tintuc_sukien_bai4
    },
    {
        id: 4,
        img: EventImages.anhbai9,
        title: "Hiệu trưởng và thầy cô giáo trường Nhật ngữ Aoyama đến tham quan trụ sở Dabosa Group.",
        content: tintuc_sukien_bai9
    },
    {
        id: 5,
        img: EventImages.anhbai10,
        title: "[DABOSA STORY] - Tư vấn du học Úc",
        content: tintuc_sukien_bai10
    },
    {
        id: 6,
        img: EventImages.anhbai14,
        title: "Buổi liên hoan ý nghĩa, tràn ngập niềm vui của các học viên nhà Dabosa",
        content: tintuc_sukien_bai14
    },
    {
        id: 7,
        img: EventImages.anhbai15,
        title: "LỄ KHAI GIẢNG KHÓA HỌC NGOẠI NGỮ K24 TẠI DABOSA",
        content: tintuc_sukien_bai15
    },
]

export const News_List_Du_Lich = [
    {
        id: 0,
        img: EventImages.anhbai17,
        title: "[DABOSA TOUR] HÀNH TRÌNH CHINH PHỤC ĐẢO HẢI NAM (TRUNG QUỐC) CÙNG VÔ VÀN CẢM XÚC",
        content: tintuc_sukien_bai17
     
    },
    {
        id: 1,
        img: EventImages.anhbai18,
        title: "[DABOSA TOUR] HÀNH TRÌNH KHÁM PHÁ THÁI LAN CÙNG V99 GROUP",
        content: tintuc_sukien_bai18
    },
    {
        id: 2,
        img: EventImages.anhbai22,
        title: "[DABOSA TOUR] - CHUYẾN ĐI ĐÀ NẴNG ĐÁNG NHỚ BÊN GIA ĐÌNH",
        content: tintuc_sukien_bai22
    },
]

export const Rec_List = [
    {
        id: 0,
        img: RecImages[1],
        title: '[TUYỂN DỤNG] Chuyên viên Content Marketing',
        content: tuyendung_content

    },
    {
        id: 1,
        img: RecImages[2],
        title: '[TUYỂN DỤNG] Chuyên viên tuyển sinh du học',
        content: tuyendung_kinhdoanh
    },
    {
        id: 2,
        img: RecImages[3],
        title: '[TUYỂN DỤNG] 02 Chuyên viên Sale du lịch',
        content: tuyendung_saletour
    },
    {
        id: 3,
        img: RecImages[4],
        title: '[TUYỂN DỤNG] 01 chuyên viên Điều hành Tour du lịch',
        content: tuyendung_dieuhanhtour
    },
]

export default Footer_Button