import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import IntroImages from "src/assets/Intro";
import "src/pages/IntroDabosa/intro.css";
import AOS from "aos";
import "aos/dist/aos.css";
import HeadTab from "src/components/HeadTab/HeadTab";
import { useTranslation } from "react-i18next";

const Leader = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div data-aos="fade-up">
     <HeadTab />
      <div className="container-xxl py-5">
        <div
          className="d-flex justify-content-center flex-column"
          style={{
            width: "max-content",
          }}
        >
          <div className="fw-bolder fs-2 text-success">
            {t("DABOSA GROUP Board of Directors")}
          </div>
          <div
            style={{
              height: 3,
              backgroundColor: "#589a83",
            }}
          />
        </div>
        <div className="row col-md-12 pt-5">
          <div className="col-md-6">
            <div className="container-img-p-1">
              <img
                src={IntroImages.p_1}
                width={"100%"}
                style={{ borderRadius: 8 }}
                alt=""
              />
            </div>
          </div>
          <div className="col-md-6 pt-2">
            <div className="fs-4 fw-bolder text-success">
            {t("Mr. Nguyen Minh Quy")}
            </div>
            <div className="fs-5 fw-bolder text-success">
            {t("Chairman of the Board of Directors")}
            </div>
            <div className="fw-semibold pt-2">
            {t("• Qualification: Economics expert")}
            </div>
            <div className="fw-semibold py-2">
              {t("• Foreign language: Advanced Chinese")}
            </div>
            <div className="fw-semibold">
            {t("• Experience: Experienced working for large domestic and foreign corporations. Currently Chairman of the Board of Directors and General Director of Quy Phat Joint Stock Company with HASUKA household brand for more than 14 years of operation, 4 consecutive years receiving the National Quality Award awarded by the Prime Minister (2019, 2020 , 2021, 2022).")}
            </div>
          </div>
        </div>

        <div className="w-100 border border-1 border-success my-5" />

        <div className="row col-md-12 pt-5">
          <div className="col-md-6">
            <div className="container-img-p-1">
              <img
                src={IntroImages.p_2}
                width={"100%"}
                style={{ borderRadius: 8 }}
                alt=""
              />
            </div>
          </div>
          <div className="col-md-6 pt-2">
            <div className="fs-4 fw-bolder text-success">
              {t("Mr. Đoan Quang Quan")}
            </div>
            <div className="fs-5 fw-bolder text-success">{t("General Director")}</div>
            <div className="fw-semibold pt-2">
             {t("• Qualification: International Business Administration")}
            </div>
            <div className="fw-semibold py-2">{t("• Foreign language: English")}</div>
            <div className="fw-semibold">
              {t("• Experience: More than 08 years working and holding department head positions. Tour Operator, Project Development Director at a number of travel and tourism businesses, study abroad consulting and labor export.")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leader;
